import { gql } from '@apollo/client';

export const TRANSLATION_PLAN_FRAGMENT = gql`
  fragment TranslatorPlanFragment on TranslatorPlan {
    id
    name
    price
    duration
    lang_1
    lang_2
    description
  }
`;
