export const SERVICE_TYPES_VALUES = {
  radiology: 1,
  video_call: 2,
  // department: 3,
  treatment: 4,
  find_doctor: 5
  // other: 6
} as const;

export const SERVICE_TYPES = [
  {
    value: SERVICE_TYPES_VALUES.radiology,
    label: 'Radiology'
  },
  {
    value: SERVICE_TYPES_VALUES.video_call,
    label: 'Video Call'
  },
  // {
  //   value: SERVICE_TYPES_VALUES.department,
  //   label: 'Department'
  // },
  {
    value: SERVICE_TYPES_VALUES.treatment,
    label: 'Treatment'
  },
  {
    value: SERVICE_TYPES_VALUES.find_doctor,
    label: 'Find Me a Doctor'
  }
  // {
  //   value: SERVICE_TYPES_VALUES.other,
  //   label: 'Other'
  // }
];

export const SERVICE_STATUSES = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 2,
    label: 'Inactive'
  }
];
