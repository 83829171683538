import { usePermission } from 'hooks';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { PERMISSION_ACTIONS } from 'constants/permissions';

import { IPrivateProps } from './types';

const Private: FC<IPrivateProps> = ({
  route,
  action = PERMISSION_ACTIONS.read,
  defaultRoute = '/',
  isRoute,
  children
}) => {
  // context
  const { checkUserMultiPermission } = usePermission();

  if (!route) {
    return <>{children}</>;
  }

  const hasPermission = checkUserMultiPermission(route, action);

  if (!hasPermission) {
    if (isRoute) {
      return <Navigate to={defaultRoute} />;
    }

    return null;
  }

  return <>{children}</>;
};

export default Private;
