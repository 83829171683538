import { gql } from '@apollo/client';

import { DICOM_LINK_FRAGMENT } from './fragments';

export const GET_DICOM = gql`
  query GetDicom($query: GetDicomLinksQuery!) {
    getDicom(query: $query) {
      data {
        total
        results {
          id
          ...DicomLinkShareFragment
        }
      }
    }
  }
  ${DICOM_LINK_FRAGMENT}
`;
