import { ReactNode } from 'react';

export const ActionTypes = {
  SELECT_SUB_ORGANIZATION: 'SELECT_SUB_ORGANIZATION',
  SELECT_PARENT_ORGANIZATION: 'SELECT_PARENT_ORGANIZATION',
  SET_ORGANIZATIONS: 'SET_ORGANIZATIONS',
  SET_SUB_ORGANIZATIONS: 'SET_SUB_ORGANIZATIONS'
} as const;

export interface IOrganizationsProps {
  children: ReactNode;
}

type StateResult = {
  label: string;
  value: string;
  icon?: string;
};

export type State = {
  selected: string;
  parentSelected: string;
  subSelected: string;
  results: StateResult[];
  subResults: StateResult[];
};

type SelectOrganization = {
  type:
    | typeof ActionTypes.SELECT_SUB_ORGANIZATION
    | typeof ActionTypes.SELECT_PARENT_ORGANIZATION;
  data: string;
};

type SetOrganizations = {
  type:
    | typeof ActionTypes.SET_ORGANIZATIONS
    | typeof ActionTypes.SET_SUB_ORGANIZATIONS;
  data: StateResult[];
};

export type Action = SelectOrganization | SetOrganizations;

export type DispatchContext = {
  onChangeSub: (value: string) => void;
  onChangeParent: (value: string) => void;
};

export type Dispatch = (action: Action) => void;
