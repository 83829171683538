import styled from 'styled-components';
import { Space } from 'antd';

export const LoginContent = styled.div`
  width: 100%;
`;

export const SocialAuthSpace = styled(Space)`
  width: 100%;

  .ant-space-item {
    flex: 1;

    a {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(87, 86, 112, 0.2);
      border-radius: 10px;
    }
  }
`;
