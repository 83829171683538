import { Badge, Layout, Menu } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  &&& {
    background: var(--secondary);

    .ant-layout-sider-trigger {
      background: var(--secondary);
    }
  }
`;

export const StyledMenu = styled(Menu)`
  &&& {
    background: var(--secondary);

    .ant-menu-submenu-title,
    .ant-menu-item {
      color: var(--white);
    }
    .ant-menu-submenu-title:hover,
    .ant-menu-item:hover,
    .ant-menu-item-selected {
      color: var(--white);
      background-color: var(--primary);
      font-weight: 600;
    }
  }
`;

export const StyledIcon = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuLabel = styled.div``;

export const StyledLabel = styled(Badge)`
  color: var(--white);
`;
