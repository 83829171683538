import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_BLOG_NEWS } from 'gql/blog/queries';
import {
  BlogNews as BlogNewsType,
  BlogNews_blogs_data_results as BlogNewsItemType
} from 'gql/blog/__generated__/BlogNews';
import { useUIDispatchContext } from 'providers/UI';
import { DELETE_BLOG } from 'gql/blog/mutations';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { OrganizationAlert, Private } from 'components/shared';

const PAGE_LIMIT = 10;

const BlogNews: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const [getBlogNews, { data, loading, refetch }] = useLazyQuery<BlogNewsType>(
    GET_BLOG_NEWS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const [deleteBlog] = useMutation(DELETE_BLOG, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  // ui
  const { toggleModal } = useUIDispatchContext();

  useEffect(() => {
    getBlogNews({
      variables: {
        query: {
          lang: 'en',
          pagination: {
            page,
            limit: PAGE_LIMIT
          }
        }
      }
    });
  }, [getBlogNews, page]);

  const onDeleteBlog = useCallback(
    (id: number) => {
      toggleModal('confirm', true, {
        onConfirm: () =>
          deleteBlog({
            variables: {
              id
            }
          })
      });
    },
    [deleteBlog, toggleModal]
  );

  const columns: ColumnProps<BlogNewsItemType>[] = useMemo(
    () => [
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('blog.title'),
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        align: 'right',
        render: id => (
          <ActionMenu
            data={[
              {
                title: t('table.edit'),
                permission: {
                  route: PERMISSION_ROUTES.blog,
                  action: PERMISSION_ACTIONS.update
                },
                action: () => navigate(`/blog-news/details/${id}`)
              },
              {
                title: t('table.delete'),
                permission: {
                  route: PERMISSION_ROUTES.blog,
                  action: PERMISSION_ACTIONS.update
                },
                action: () => onDeleteBlog(id),
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                }
              }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [navigate, onDeleteBlog, t]
  );

  const results = data?.blogs?.data.results || [];

  return (
    <PageWrapper
      title={t('blog.page_title')}
      color="white"
      extra={[
        <Private
          key="add-blog-button"
          route={PERMISSION_ROUTES.blog}
          action={PERMISSION_ACTIONS.create}
        >
          <OrganizationAlert>
            <Button
              type="primary"
              onClick={() => navigate('/blog-news/details')}
            >
              <PlusOutlined />
              {t('blog.add_new')}
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.blogs?.data.total || 0,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default BlogNews;
