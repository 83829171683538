import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    first_name
    last_name
    avatar
    phone
    email
    confirm
    lang
    created_at
    role {
      name
    }
  }
`;
