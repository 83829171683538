import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs, Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { FULL_DATE_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { PLAN_TYPE_VALUES } from 'constants/users';
import { usePermission } from 'hooks';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { APPOINTMENT_STATUSES } from 'constants/appointments';

import {
  Details,
  Files,
  Reports,
  Notes,
  RejectModal,
  DateModal,
  PaymentInfo,
  State,
  StateModal,
  StatusSelect
} from './components';
import { Loading, PageWrapper } from 'components/ui';
import { Private } from 'components/shared';

const AppointmentsDetails: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { checkUserPermission } = usePermission();

  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const appointment = useMemo(
    () => data?.appointmentForAdmin?.data,
    [data?.appointmentForAdmin?.data]
  );

  const isVideoCall = useMemo(
    () => appointment?.plan.type === PLAN_TYPE_VALUES.video_call,
    [appointment?.plan.type]
  );

  const date = useMemo(() => {
    if (!isVideoCall || !appointment?.dates) return '';

    const accepted = appointment.dates.find(item => item.accepted);

    if (accepted) {
      return dayjs(accepted.date).format(FULL_DATE_FORMAT);
    }

    return '';
  }, [appointment?.dates, isVideoCall]);

  const showLogsButton =
    appointment?.status === APPOINTMENT_STATUSES.started ||
    appointment?.status === APPOINTMENT_STATUSES.completed;

  const hasPaymentReadPermission = checkUserPermission(
    PERMISSION_ROUTES.appointments,
    PERMISSION_ACTIONS.appointmentViewPayment
  );

  const hasNotesReadPermission = checkUserPermission(
    PERMISSION_ROUTES.appointmentNote,
    PERMISSION_ACTIONS.read
  );

  const hasReportsReadPermission = checkUserPermission(
    PERMISSION_ROUTES.appointmentReport,
    PERMISSION_ACTIONS.read
  );

  if (!appointment) return <Loading />;

  return (
    <PageWrapper
      title={t('appointments_details.page_title')}
      onClickBack={() => navigate('/appointments')}
      extra={[
        <Private
          route={PERMISSION_ROUTES.appointments}
          action={PERMISSION_ACTIONS.changeStatus}
          key="status-select"
        >
          <StatusSelect
            appointmentId={params.id as string}
            status={appointment.status}
          />
        </Private>,
        <Private
          route={PERMISSION_ROUTES.super}
          action={PERMISSION_ACTIONS.read}
          key="logs"
        >
          {showLogsButton ? (
            <Button
              onClick={() => navigate(`/logs/meetings?search=${params.id}`)}
            >
              View call logs
            </Button>
          ) : null}
        </Private>,
        appointment.paid ? (
          <Tag color="success" key="paid">
            {t('appointments_details.paid')}
          </Tag>
        ) : null
      ]}
      color="white"
      back
    >
      <Tabs
        defaultActiveKey="1"
        style={{ padding: '0 24px' }}
        items={[
          {
            key: '1',
            label: t('appointments_details.appointment_tab_1'),
            style: { paddingBottom: 40 },
            children: <Details date={date} isVideoCall={isVideoCall} />
          },
          {
            key: '2',
            label: t('appointments_details.appointment_tab_2'),
            style: { paddingBottom: 40 },
            children: <Files />
          },
          ...(hasReportsReadPermission
            ? [
                {
                  key: '3',
                  label: t('appointments_details.appointment_tab_3'),
                  style: { paddingBottom: 40 },
                  children: <Reports />
                }
              ]
            : []),
          ...(hasNotesReadPermission
            ? [
                {
                  key: '5',
                  label: t('appointments_details.appointment_tab_5'),
                  style: { paddingBottom: 40 },
                  children: <Notes />
                }
              ]
            : []),
          ...(hasPaymentReadPermission
            ? [
                {
                  key: '6',
                  label: 'Payment information',
                  style: { paddingBottom: 40 },
                  children: <PaymentInfo />
                }
              ]
            : []),
          {
            key: '7',
            label: 'State',
            style: { paddingBottom: 40 },
            children: <State />
          }
        ]}
      />
      <RejectModal />
      <DateModal />
      <StateModal />
    </PageWrapper>
  );
};

export default AppointmentsDetails;
