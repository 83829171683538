import { Dropdown } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';

export const StyledDraggerFileItem = styled.div<{ $isError?: boolean }>`
  padding: 8px;
  border: 1px solid ${({ $isError }) => ($isError ? '#ff5b5b' : '#f5f6f8')};
  border-radius: 8px;
  margin-bottom: 12px;
  color: #18212c;
  .file-icon {
    background-color: #fbfbfb;
    border: 1px solid #f5f6f8;
    width: 48px;
    height: 37px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file-info {
    h6 {
      font-size: 14px;
      line-height: 1.35;
      font-weight: 600;
      margin: 0;
    }
    p {
      margin: 0;
      color: #a4a8b5;
      font-size: 12px;
      line-height: 1.58;
      &.file-upload-error {
        color: #ff5b5b;
      }
    }
  }
  .ant-progress {
    width: 278px;
    .ant-progress-text {
      font-size: 14px;
      color: #63687a;
    }
  }
  button {
    padding: 0;
    border: none;
    background-color: transparent;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  @media only screen and (max-width: 992px) {
    position: relative;
    .remove-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }
    .file-info {
      h6 {
        padding-right: 36px;
      }
    }
    .ant-progress {
      margin: 0;
      width: 100%;
      min-width: 100%;
    }
    .ant-flex {
      min-width: 100%;
    }
  }
`;

export const StyledDropDown = styled(Dropdown)`
  border: 1px solid var(--primary-color-100);
  padding: 10px 24px;
  border-radius: 100px;
`;

export const StyledDragger = styled(Dragger)`
  &&& {
    .ant-upload-drag {
      padding-block: 40px;
      border-color: var(--primary-color-100);
      margin: 0;
    }

    .ant-upload-drag .ant-upload {
      padding: 0;
    }
  }
`;
