import { FC, memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  message,
  Row,
  Space,
  TimePicker,
  Typography
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_WORKING_HOURS } from 'gql/doctors/mutations';
import client from 'apolloClient';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { DoctorById } from 'gql/doctors/__generated__/DoctorById';
import { useParams, useSearchParams } from 'react-router-dom';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { usePermission } from 'hooks';

import { WeekDay } from './styled';
import { DayStateType } from './types';
import { IconButton } from 'components/ui';
import { parseJson } from 'utils/json';
import { showErrorMessage } from 'utils/showErrorMessage';
import { Private } from 'components/shared';

const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

export const initialWorkingHours = {
  sunday: {
    enabled: true,
    hours: [
      {
        start: '00:00',
        end: '23:30'
      }
    ]
  },
  monday: {
    enabled: true,
    hours: [
      {
        start: '00:00',
        end: '23:30'
      }
    ]
  },
  tuesday: {
    enabled: true,
    hours: [
      {
        start: '00:00',
        end: '23:30'
      }
    ]
  },
  wednesday: {
    enabled: true,
    hours: [
      {
        start: '00:00',
        end: '23:30'
      }
    ]
  },
  thursday: {
    enabled: true,
    hours: [
      {
        start: '00:00',
        end: '23:30'
      }
    ]
  },
  friday: {
    enabled: true,
    hours: [
      {
        start: '00:00',
        end: '23:30'
      }
    ]
  },
  saturday: {
    enabled: true,
    hours: [
      {
        start: '00:00',
        end: '23:30'
      }
    ]
  }
};

const { Title } = Typography;

const WorkingHours: FC = () => {
  // translations
  const { t } = useTranslation();
  // states
  const [data, setData] = useState<DayStateType>(initialWorkingHours);
  // navigation
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  // graphql
  const lang = useMemo(() => searchParams.get('lang'), [searchParams]);
  const { checkUserPermission } = usePermission();

  const [updateWorkingHours] = useMutation(UPDATE_WORKING_HOURS, {
    onCompleted() {
      client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
      message.success(t('messages.successfully_saved'));
    },
    onError: err => showErrorMessage(err)
  });

  const { data: profileData } = useQuery<DoctorById>(GET_DOCTOR_PROFILE, {
    fetchPolicy: 'cache-only',
    variables: {
      id,
      lang
    },
    onCompleted(data) {
      const workingHours = parseJson(data?.doctorById?.data?.working_hours);

      if (workingHours) {
        setData(workingHours);
      }
    }
  });

  const doctorProfileId = profileData?.doctorById?.data.id;

  const hasUpdatePermission = checkUserPermission(
    PERMISSION_ROUTES.doctors,
    PERMISSION_ACTIONS.update
  );

  const onTimeSelect = useCallback(
    (day: string, i: number, type: 'start' | 'end', value: Dayjs | null) => {
      if (!value || !hasUpdatePermission) return;

      setData(prev => {
        const current = prev[day] || {};

        const hours = [...current.hours];
        hours[i] = {
          ...hours[i],
          [type]: value.format('HH:mm')
        };

        return {
          ...prev,
          [day]: {
            ...current,
            hours
          }
        };
      });
    },
    [hasUpdatePermission]
  );

  const onSelectDay = useCallback(
    (day: string, value: boolean) => {
      if (!hasUpdatePermission) return;

      setData(prev => {
        return {
          ...prev,
          [day]: {
            enabled: value,
            hours: [
              {
                start: '00:00',
                end: '23:30'
              }
            ]
          }
        };
      });
    },
    [hasUpdatePermission]
  );

  const addHour = useCallback((day: string) => {
    setData(prev => {
      const current = prev[day] || {};
      const prevEnd =
        +current.hours[current.hours.length - 1].end.split(':')[0] + 1;

      const start = `${prevEnd > 0 ? prevEnd : `${prevEnd}:00`}:00`;

      return {
        ...prev,
        [day]: {
          ...current,
          hours: [
            ...current.hours,
            {
              start,
              end: '23:30'
            }
          ]
        }
      };
    });
  }, []);

  const deleteHour = useCallback((day: string, i: number) => {
    setData(prev => {
      const current = prev[day] || {};
      const hours = current.hours.filter((_, index) => index !== i);

      return {
        ...prev,
        [day]: {
          ...current,
          hours
        }
      };
    });
  }, []);

  const getDisabledStartTime = useCallback(
    (day: string, index: number) => {
      if (index < 1) return {};

      const prevEndHour = +data[day].hours[index - 1].end.split(':')[0];
      const disabledHours: number[] = [];

      for (let i = 0; i <= prevEndHour; i++) {
        disabledHours.push(i);
      }

      return {
        disabledHours: () => disabledHours
      };
    },
    [data]
  );

  const getDisabledEndTime = useCallback(
    (day: string, index: number) => {
      const startHour = +data[day].hours[index].start.split(':')[0];

      const disabledHours: number[] = [];

      for (let i = 0; i <= startHour; i++) {
        disabledHours.push(i);
      }

      return {
        disabledHours: () => disabledHours
      };
    },
    [data]
  );

  const handleSave = useCallback(() => {
    updateWorkingHours({
      variables: {
        input: {
          working_hours: data,
          user_id: doctorProfileId
        }
      }
    });
  }, [data, doctorProfileId, updateWorkingHours]);

  return (
    <div>
      <Space size={16} direction="vertical" style={{ width: '100%' }}>
        <Row justify="space-between">
          <Space size={12}>
            {DAYS.map(item => {
              const isSelected = data[item].enabled;

              return (
                <WeekDay
                  onClick={() => onSelectDay(item, !isSelected)}
                  isSelected={isSelected}
                  key={`day-button-${item}`}
                  disabled={!hasUpdatePermission}
                >
                  {t(`dates.${item[0]}`)}
                </WeekDay>
              );
            })}
          </Space>
        </Row>
        {DAYS.map(item => {
          const isSelected = data[item].enabled;
          if (!isSelected) return null;

          return (
            <div key={`day-item-${item}`}>
              <Title level={4}>{t(`dates.${item}`)}</Title>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                {data[item].hours.map((hour, i, arr) => {
                  const endHour = +hour.end.split(':')[0];

                  return (
                    <Space key={`day-item-hour-${item}-${i}`}>
                      <TimePicker
                        onChange={e => onTimeSelect(item, i, 'start', e)}
                        value={dayjs(hour.start, 'HH:mm')}
                        format="HH:mm"
                        minuteStep={30}
                        showNow={false}
                        inputReadOnly
                        disabledTime={() => getDisabledStartTime(item, i)}
                        disabled={!hasUpdatePermission}
                      />
                      <TimePicker
                        onChange={e => onTimeSelect(item, i, 'end', e)}
                        value={dayjs(hour.end, 'HH:mm')}
                        format="HH:mm"
                        minuteStep={30}
                        showNow={false}
                        inputReadOnly
                        disabledTime={() => getDisabledEndTime(item, i)}
                        disabled={!hasUpdatePermission}
                      />
                      <Private
                        route={PERMISSION_ROUTES.doctors}
                        action={PERMISSION_ACTIONS.update}
                      >
                        {i === arr.length - 1 && endHour < 23 && (
                          <IconButton onClick={() => addHour(item)}>
                            <PlusOutlined />
                          </IconButton>
                        )}
                        {i > 0 && (
                          <IconButton onClick={() => deleteHour(item, i)}>
                            <MinusOutlined />
                          </IconButton>
                        )}
                      </Private>
                    </Space>
                  );
                })}
              </Space>
            </div>
          );
        })}
      </Space>
      <Private
        route={PERMISSION_ROUTES.doctors}
        action={PERMISSION_ACTIONS.update}
      >
        <Flex justify="flex-end" style={{ paddingTop: 24 }}>
          <Button size="large" type="primary" onClick={handleSave}>
            {t('common.save')}
          </Button>
        </Flex>
      </Private>
    </div>
  );
};

export default memo(WorkingHours);
