import { Select } from 'antd';
import styled, { css } from 'styled-components';

const stylesForSelect = css`
  background-color: #fbfbfb;
  border-radius: 4px;
  border-color: #f5f6f8;
`;

export const DisabledButton = styled.button`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  border: none;
  background-color: #ffffff;
  padding: 0 12px;
`;

export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 11px;
  border: 1px solid;
  width: 200px;
  height: 40px;
  ${stylesForSelect}
`;

export const StyledSelect = styled(Select)`
  height: 40px;
  .ant-select-selector {
    ${stylesForSelect}

    .ant-image {
      display: flex;
    }
  }
`;
