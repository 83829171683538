import { RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledCard, StyledCardHeader } from './styled';

const { Title } = Typography;

interface IProps {
  title: string;
  count?: number;
  to?: string | null;
  children: ReactNode;
}

const CountCard: FC<IProps> = ({ title, count, to, children }) => {
  const navigate = useNavigate();

  return (
    <>
      <StyledCard clickable={!!to} onClick={() => to && navigate(to)}>
        <StyledCardHeader>
          <Title level={4} style={{ margin: 0 }}>
            {title}
          </Title>
          {!!to && (
            <RightOutlined style={{ fontSize: 18, color: 'var(--primary)' }} />
          )}
        </StyledCardHeader>
        {typeof count !== 'undefined' && (
          <Title
            level={2}
            style={{ color: '#18212C', margin: 0, fontSize: 26 }}
          >
            {count}
          </Title>
        )}
      </StyledCard>
      {children}
    </>
  );
};

export default CountCard;
