import { FC, useCallback, useState } from 'react';
import { Button, Typography, message } from 'antd';
import { useAuthDispatchContext, useAuthStateContext } from 'providers/Auth';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import { Content, InputContainer, LinkWrapper } from './styled';
import { refreshTokenStorage, tokenStorage } from 'utils/storage';

const { Title } = Typography;

const Auth2FA: FC = () => {
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  const { tmpToken } = useAuthStateContext();
  const { verify2FA, getUserData } = useAuthDispatchContext();

  const onFinish = useCallback(
    async (code?: string) => {
      setLoading(true);
      await verify2FA(code || value, tmpToken)
        .then(res => {
          if (res.data) {
            const { data } = res.data;

            if (data.token) {
              tokenStorage.set(data.token);
            }

            if (data.refreshToken) {
              refreshTokenStorage.set(data.refreshToken);
            }

            getUserData();
          }
        })
        .catch(err => {
          setLoading(false);
          message.error(err.message);
        });
    },
    [verify2FA, value, tmpToken, getUserData]
  );

  return (
    <Content>
      <Title level={2} style={{ marginBottom: 24 }}>
        Two-Factor Authentication
      </Title>
      <InputContainer>
        <VerificationInput
          classNames={{
            container: 'container',
            character: 'character',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected'
          }}
          placeholder=""
          autoFocus
          onComplete={onFinish}
          validChars="0-9"
          onChange={code => setValue(code)}
          value={value}
        />
      </InputContainer>
      <Button
        type="primary"
        onClick={() => onFinish()}
        shape="round"
        loading={isLoading}
        disabled={value.length !== 6}
        block
      >
        Submit
      </Button>
      <LinkWrapper>
        <Link to="/login" style={{ fontSize: 12, color: '#2D2C4C' }}>
          <ArrowLeftOutlined style={{ marginRight: 12 }} />
          Back to Login
        </Link>
      </LinkWrapper>
    </Content>
  );
};

export default Auth2FA;
