/* eslint-disable no-console */
import { DraggerProps, RcFile } from 'antd/lib/upload';
import instance from 'services/api';

import { StyledDragger } from './styled';

interface IProps extends DraggerProps {
  path?: string;
  onStartUploading?(id: string): void;
  onEndUploading?(id: string): void;
}

function Dragger({
  path = '/aws-s3',
  onStartUploading,
  onEndUploading,
  children,
  ...rest
}: IProps) {
  const handleUploadStart = (uid: string) => {
    onStartUploading?.(uid);
  };

  const handleUploadEnd = (uid: string) => {
    onEndUploading?.(uid);
  };

  return (
    <StyledDragger
      {...rest}
      className="select-files-ant-dragger"
      customRequest={async ({ file, onSuccess, onProgress, onError }) => {
        const uid = (file as RcFile).uid;
        handleUploadStart(uid);
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await instance.post(path, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: e => {
              const percentCompleted = Math.round((e.loaded * 100) / e.total);

              onProgress?.({ percent: percentCompleted });
            }
          });

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSuccess?.(response.data, file);
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onError?.(error);
        } finally {
          handleUploadEnd(uid);
        }
      }}
    >
      {children}
    </StyledDragger>
  );
}

export default Dragger;
