export const DOCTOR_STATUSES = {
  waiting: '1',
  active: '2',
  disabled: '3',
  all: '4'
} as const;

export const DOCTOR_STATUS_LABEL = {
  [DOCTOR_STATUSES.active]: 'active',
  [DOCTOR_STATUSES.disabled]: 'disabled',
  [DOCTOR_STATUSES.waiting]: 'waiting',
  [DOCTOR_STATUSES.all]: 'all'
} as const;

export const DOCTOR_STATUS = [
  {
    value: DOCTOR_STATUSES.active,
    label: 'active'
  },
  {
    value: DOCTOR_STATUSES.disabled,
    label: 'disabled'
  },
  {
    value: DOCTOR_STATUSES.waiting,
    label: 'waiting'
  },
  {
    value: DOCTOR_STATUSES.all,
    label: 'all'
  }
];
