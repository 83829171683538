import { gql } from '@apollo/client';

export const GET_REVIEWS = gql`
  query Reviews($query: Pagination) {
    reviews(query: $query) {
      data {
        results {
          id
          rating
          review
          sender {
            id
            first_name
            last_name
            email
          }
          receiver {
            id
            first_name
            last_name
            email
          }
          created_at
        }
        total
      }
    }
  }
`;

export const GET_CALL_REVIEWS = gql`
  query CallReviews($query: Pagination!) {
    callReviews(query: $query) {
      data {
        total
        results {
          id
          issues
          review
          satisfied
          appointment_id
          created_at
          sender {
            id
            email
            last_name
            first_name
          }
        }
      }
    }
  }
`;
