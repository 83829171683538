import { gql } from '@apollo/client';

export const GET_FAQ = gql`
  query Faq($query: GetFAQQuery!) {
    faq(query: $query) {
      data {
        total
        results {
          id
          question
          answer
          sort_index
          created_at
        }
      }
    }
  }
`;
export const GET_FAQ_BY_ID = gql`
  query GetFAQId($id: ID!) {
    getFAQId(id: $id) {
      data {
        id
        answer
        question
        sort_index
        service_id
      }
    }
  }
`;
