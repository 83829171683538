import { createContext, useReducer, useContext, FC, useCallback } from 'react';

import {
  State,
  Dispatch,
  DispatchContext,
  ActionTypes,
  ModalState,
  ModalType,
  ModalParams,
  IUIProps
} from './types';
import reducer from './reducers';

const initialState: State = {
  modal: {
    confirm: { visible: false },
    addTranslator: { visible: false },
    translationPlans: { visible: false },
    sendNotification: { visible: false },
    education: { visible: false },
    experience: { visible: false },
    certificate: { visible: false },
    membership: { visible: false },
    publication: { visible: false },
    plans: { visible: false },
    profile: { visible: false },
    password: { visible: false },
    services: { visible: false },
    doctorDetails: { visible: false },
    sessionPay: { visible: false },
    // specialties
    specialtyModal: { visible: false },
    // specialties
    blogNewsModal: { visible: false },
    // appointment
    rejectAppointment: { visible: false },
    paymentStatus: { visible: false },
    dateModal: { visible: false },
    appointmentState: { visible: false },
    ipInfo: { visible: false },
    settings: { visible: false },
    updateStatus: { visible: false },
    staticElements: { visible: false }
  }
};

const ModalStateContext = createContext<ModalState | undefined>(undefined);
const UIDispatchContext = createContext<Dispatch | undefined>(undefined);

const UIProvider: FC<IUIProps> = ({ children }) => {
  const [{ modal }, dispatch] = useReducer(reducer, {
    ...initialState
  });

  return (
    <ModalStateContext.Provider value={modal}>
      <UIDispatchContext.Provider value={dispatch}>
        {children}
      </UIDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

const useModalStateContext = (): ModalState => {
  const context = useContext(ModalStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useModalStateContext must be used within a ModalStateContext'
    );
  }

  return context;
};

const useUIDispatchContext = (): DispatchContext => {
  const dispatch = useContext(UIDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useUIDispatchContext must be used within a useUIDispatchContext'
    );
  }

  const toggleModal = useCallback(
    (key: keyof ModalType, value: boolean, params?: ModalParams) => {
      dispatch({
        type: ActionTypes.TOGGLE_MODAL,
        data: { key, value, params }
      });
    },
    [dispatch]
  );

  return { toggleModal };
};

export default UIProvider;
export { useUIDispatchContext, useModalStateContext };
