import { PERMISSION_ROUTES } from './permissions';

export const DASHBOARD_TOUR_DATA = [
  {
    step: 1,
    key: 'dashboard',
    permissions: [PERMISSION_ROUTES.dashboard]
  },
  {
    step: 2,
    key: 'specialties',
    permissions: [PERMISSION_ROUTES.specialties]
  },
  {
    step: 3,
    key: 'translation_plans',
    permissions: [PERMISSION_ROUTES.translatorPlans]
  },
  {
    step: 4,
    key: 'organizations',
    permissions: [PERMISSION_ROUTES.organization]
  },
  {
    step: 5,
    key: 'blog_news',
    permissions: [PERMISSION_ROUTES.blog]
  },
  {
    step: 6,
    key: 'notifications',
    permissions: [PERMISSION_ROUTES.notification]
  },
  {
    step: 7,
    key: 'ai-health',
    permissions: [PERMISSION_ROUTES.ai]
  },
  {
    step: 8,
    key: 'financial',
    permissions: [PERMISSION_ROUTES.super, PERMISSION_ROUTES.reports]
  },
  {
    step: 9,
    key: 'appointments',
    permissions: [
      PERMISSION_ROUTES.appointments,
      PERMISSION_ROUTES.patientRequests
    ]
  },
  {
    step: 10,
    key: 'cms',
    permissions: [PERMISSION_ROUTES.staticContent]
  },
  {
    step: 11,
    key: 'sessions',
    permissions: [PERMISSION_ROUTES.sessions, PERMISSION_ROUTES.sessionsPaid]
  },
  {
    step: 12,
    key: 'reviews',
    permissions: [PERMISSION_ROUTES.reviews, PERMISSION_ROUTES.callReviews]
  },
  {
    step: 13,
    key: 'users_and_access',
    permissions: [
      PERMISSION_ROUTES.roles,
      PERMISSION_ROUTES.super,
      PERMISSION_ROUTES.doctors,
      PERMISSION_ROUTES.patients,
      PERMISSION_ROUTES.translators
    ]
  },
  {
    step: 15,
    key: 'static-pages',
    permissions: [PERMISSION_ROUTES.staticContent]
  }
];
