import { gql } from '@apollo/client';

export const BLOG_LIST_FRAGMENT = gql`
  fragment BlogList on Blog {
    id
    title
    short_desc
    slug
  }
`;
