import { useQuery, useMutation } from '@apollo/client';
import { getIdStart } from '@medicusunion/front-utils';
import { Typography, Tag, Space, Button, Table, Image, Flex } from 'antd';
import { ColumnProps } from 'antd/es/table';
import {
  AppointmentStatusType,
  APPOINTMENT_STATUS_VALUES
} from 'constants/appointments';
import { DATE_FORMAT } from 'constants/dates';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import dayjs from 'dayjs';
import { GET_TRANSLATION_REQUESTS } from 'gql/appointments/queries';
import { DELETE_TRANSLATION_REQUEST } from 'gql/translationPlans/mutations';
import { useUIDispatchContext } from 'providers/UI';
import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  TranslatorRequests as TranslatorRequestsType,
  TranslatorRequests_translatorRequests_data_results as TranslatorRequestsResult,
  TranslatorRequests_translatorRequests_data_results_appointment as TranslatorRequestAppointment
} from 'gql/appointments/__generated__/TranslatorRequests';

import { getStatusColor } from 'utils/appointments';
import { money } from 'utils/price';
import { showErrorMessage } from 'utils/showErrorMessage';
import Private from '../Private';
import { getFileUrl } from 'utils/file';

const { Text } = Typography;

interface IProps {
  limit?: number;
  className?: string;
  pagination?: boolean;
}

const Translator: FC<IProps> = ({
  limit = 10,
  className,
  pagination = true
}) => {
  // translations
  const { t } = useTranslation();
  // context
  const { toggleModal } = useUIDispatchContext();
  // states
  const [page, setPage] = useState(0);
  // graphql
  const { data, loading, refetch } = useQuery<TranslatorRequestsType>(
    GET_TRANSLATION_REQUESTS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'no-cache',
      variables: {
        query: {
          page,
          limit
        }
      }
    }
  );

  const [removeTranslator, { loading: isRemoving }] = useMutation(
    DELETE_TRANSLATION_REQUEST,
    {
      onCompleted() {
        refetch();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const results = data?.translatorRequests?.data.results || [];
  const total = data?.translatorRequests?.data.total || 0;

  const columns: ColumnProps<TranslatorRequestsResult>[] = useMemo(
    () => [
      {
        title: t('dashboard.doctor_name'),
        dataIndex: 'appointment',
        key: 'doctor',
        render: (value: TranslatorRequestAppointment) => (
          <Flex gap={8}>
            <Image
              src={getFileUrl(value.doctor.avatar || '')}
              width={32}
              height={32}
              preview={false}
              style={{
                objectFit: 'cover',
                borderRadius: '50%',
                minHeight: 32,
                minWidth: 32
              }}
            />
            <Space>
              <Text>
                {value.doctor.first_name} {value.doctor.last_name}
              </Text>
            </Space>
          </Flex>
        )
      },
      {
        title: t('dashboard.patient_name'),
        dataIndex: 'appointment',
        key: 'patient',
        render: (value: TranslatorRequestAppointment) => (
          <Flex gap={8}>
            <Image
              src={getFileUrl(value.patient.avatar || '')}
              width={32}
              height={32}
              preview={false}
              style={{
                objectFit: 'cover',
                borderRadius: '50%',
                minHeight: 32,
                minWidth: 32
              }}
            />
            <Space>
              <Text>
                {value.patient.first_name} {value.patient.last_name}
              </Text>
            </Space>
          </Flex>
        )
      },
      {
        title: t('dashboard.appointment_id'),
        dataIndex: 'appointment',
        key: 'appointment_id',
        render: value => (
          <Link to={`/appointments/${value.id}`}>{getIdStart(value.id)}</Link>
        )
      },
      {
        title: t('dashboard.languages'),
        dataIndex: 'id',
        key: 'lang',
        render: (_, record) => (
          <Text>
            {record.translator_plan?.lang_1}, {record.translator_plan?.lang_2}
          </Text>
        )
      },
      {
        title: t('dashboard.status'),
        dataIndex: 'appointment',
        key: 'status',
        render: value => (
          <Tag color={getStatusColor(value.status as AppointmentStatusType)}>
            {t(
              `dashboard.${
                APPOINTMENT_STATUS_VALUES[value.status as AppointmentStatusType]
              }`
            )}
          </Tag>
        )
      },
      {
        title: t('dashboard.paid'),
        dataIndex: 'appointment',
        key: 'paid',
        render: value =>
          value.paid ? (
            <Tag color="#32b579">{t('dashboard.yes')}</Tag>
          ) : (
            <Tag color="#d40001">{t('dashboard.no')}</Tag>
          )
      },
      {
        title: t('dashboard.amount'),
        dataIndex: 'translator_plan',
        key: 'price',
        render: value => money(value?.price || 0)
      },
      {
        title: 'Created At',
        dataIndex: 'appointment',
        key: 'created_at',
        render: (value: { created_at: string }) =>
          dayjs(value?.created_at).format(DATE_FORMAT)
      },
      {
        title: t('dashboard.action'),
        dataIndex: 'id',
        align: 'center',
        key: 'id',
        render: (id, record) => {
          return (
            <Space>
              <Button
                onClick={() => toggleModal('addTranslator', true, { id })}
                type="primary"
              >
                {t('dashboard.add')}
              </Button>
              <Private
                route={PERMISSION_ROUTES.dashboard}
                action={PERMISSION_ACTIONS.removeTranslatorRequest}
              >
                <Button
                  onClick={() =>
                    toggleModal('confirm', true, {
                      onConfirm: () =>
                        removeTranslator({
                          variables: {
                            id: record.id
                          }
                        })
                    })
                  }
                  type="default"
                >
                  {t('dashboard.remove')}
                </Button>
              </Private>
            </Space>
          );
        }
      }
    ],
    [removeTranslator, t, toggleModal]
  );

  return (
    <>
      <Table
        dataSource={results}
        loading={isRemoving || loading}
        columns={columns}
        rowKey={row => row.appointment.id}
        pagination={
          pagination
            ? {
                total: total,
                pageSize: limit,
                onChange: p => setPage(p - 1),
                showSizeChanger: false
              }
            : false
        }
        className={className}
      />
    </>
  );
};

export default Translator;
