import { App, Button, Popover } from 'antd';
import { LANGUAGES } from 'constants/languages';
import { FC, memo } from 'react';

import { exportJsonFile } from 'utils/file';
import {
  Languages,
  ResultType,
  TranslationSelectedTab,
  transformDataSingleJsonSingleLanguage,
  transformSingleLanguage
} from 'utils/translations';
import { StyledList } from './styled';

interface IProps {
  getData(): ResultType[] | undefined;
  selectedTab: TranslationSelectedTab;
}

const Export: FC<IProps> = ({ getData, selectedTab }) => {
  const { modal } = App.useApp();

  const onExportClick = async (locale: Languages) => {
    const data = getData();

    try {
      if (!data) {
        return;
      }

      if (typeof selectedTab.fileNames === 'string') {
        const transformedData = transformSingleLanguage(data, locale);

        const json = JSON.stringify(transformedData, null, 4);

        exportJsonFile(json, selectedTab.fileNames);
      } else {
        const JSONs: { key: string; json: string }[] = [];
        selectedTab.fileNames.forEach(key => {
          const item = data.find(item => item.section === key);

          if (item) {
            const transformedData = transformDataSingleJsonSingleLanguage(
              item,
              locale
            );

            const json = JSON.stringify(transformedData, null, 4);

            JSONs.push({ json, key });
          }
        });

        for (let i = 0; i < JSONs.length; i++) {
          const item = JSONs[i];

          exportJsonFile(item.json, item.key);
          await new Promise(resolve => setTimeout(resolve, 500));
        }
      }
    } catch (error) {
      modal.error({
        title: 'Error',
        content: (
          <>
            <p>Oops something went wrong.</p>
            <p>
              <b>{`${error}`}</b>
            </p>
          </>
        )
      });
    }
  };

  const content = (
    <StyledList>
      {LANGUAGES.filter(item =>
        selectedTab.languages.includes(item.locale)
      ).map(item => (
        <li key={item.key}>
          <Button onClick={() => onExportClick(item.locale as Languages)} block>
            {item.label} ({item.locale})
          </Button>
        </li>
      ))}
    </StyledList>
  );

  return (
    <Popover content={content} title="Please select language" trigger="click">
      <Button key="export">Export JSON(s)</Button>
    </Popover>
  );
};

export default memo(Export);
