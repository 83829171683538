import { gql } from '@apollo/client';

export const CREATE_STORY = gql`
  mutation Mutation($input: StoriesInput!) {
    createStory(input: $input) {
      message
    }
  }
`;

export const UPDATE_STORY = gql`
  mutation UpdateStory($updateStoryId: ID!, $input: StoriesInput!) {
    updateStory(id: $updateStoryId, input: $input) {
      message
    }
  }
`;

export const DELETE_STORY = gql`
  mutation DeleteStory($deleteStoryId: ID!) {
    deleteStory(id: $deleteStoryId) {
      message
    }
  }
`;
