import { RootState } from 'store';

import { ShowToursType, ToursStepsType, ToursVersionType } from './types';

export const selectShowTours = () => (state: RootState) =>
  state.tours.shownTours;

export const selectShowTourItem =
  (key: keyof ShowToursType) => (state: RootState) => {
    const tours = selectShowTours()(state);

    return tours[key];
  };

export const selectTourItemStep =
  (key: keyof ToursStepsType) => (state: RootState) => {
    return state.tours.toursSteps[key];
  };

export const selectFinishedTourItem =
  (key: keyof ShowToursType) => (state: RootState) => {
    const tour = selectTourItemStep(key)(state);

    return tour === -1;
  };

export const selectTourVersion =
  (key: keyof ToursVersionType) => (state: RootState) => {
    return state.tours.toursVersion[key];
  };
