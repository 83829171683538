import { Form, Input, Modal } from 'antd';
import { FC, memo, useCallback } from 'react';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { OrganizationsAdmin as Organizations } from 'gql/organizations/__generated__/OrganizationsAdmin';
import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION
} from 'gql/organizations/mutations';
import { GET_ORGANIZATION_BY_ID } from 'gql/organizations/queries';
import { OrganizationById } from 'gql/organizations/__generated__/OrganizationById';

import { showErrorMessage } from 'utils/showErrorMessage';

const { TextArea } = Input;

interface IProps {
  refetch(): Promise<ApolloQueryResult<Organizations>>;
  onClose(): void;
  id?: string;
}

const OrganizationsModal: FC<IProps> = ({ id, refetch, onClose }) => {
  const [form] = Form.useForm();

  const closeModal = () => {
    onClose();
    form.resetFields();
  };

  useQuery<OrganizationById>(GET_ORGANIZATION_BY_ID, {
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: {
      id
    },
    onCompleted(data) {
      const result = data.organizationById.data;

      if (result) {
        form.setFieldsValue({
          name: result.name,
          additional_info: result.additional_info || ''
        });
      }
    }
  });

  const [createOrganization, { loading: isCreating }] = useMutation(
    CREATE_ORGANIZATION,
    {
      onCompleted() {
        refetch();
        closeModal();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [updateOrganization, { loading: isUpdating }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      onCompleted() {
        refetch();
        closeModal();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onFinish = useCallback(
    (values: Record<string, string>) => {
      if (id) {
        return updateOrganization({
          variables: {
            id: id,
            input: values
          }
        });
      }

      createOrganization({
        variables: {
          input: values
        }
      });
    },
    [id, createOrganization, updateOrganization]
  );

  return (
    <Modal
      title={id ? 'Edit' : 'Create'}
      onCancel={closeModal}
      open
      width={600}
      onOk={form.submit}
      confirmLoading={isCreating || isUpdating}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} layout="vertical" size="large">
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="additional_info"
          label="Additional information"
          rules={[{ required: false }]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(OrganizationsModal);
