import { Alert, Button, Modal } from 'antd';
import { FC } from 'react';

import { IBlockedModalProps } from './types';

const BlockedModal: FC<IBlockedModalProps> = ({ open, onClose }) => (
  <Modal
    open={open}
    onCancel={onClose}
    footer={[
      <Button
        key="ok-button"
        shape="round"
        type="primary"
        style={{ width: 113 }}
        onClick={onClose}
        block
      >
        OK
      </Button>
    ]}
  >
    <Alert
      message="Account is Blocked"
      description="This account is blocked due to too many login attempts. Please try again later"
      type="error"
      style={{ backgroundColor: 'transparent', border: 'none' }}
      showIcon
    />
  </Modal>
);

export default BlockedModal;
