import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div<{ space?: number }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff; // component-background
  box-shadow: 0px 4px 20px #e7eaf6; // box-shadow
  padding: 16px 24px;

  ${({ space }) =>
    space &&
    `
    margin-bottom: ${space}px;
  `}
`;

export const StyledLink = styled(Link)`
  text-transform: capitalize;
`;
