import { APPOINTMENT_STATUSES } from 'constants/appointments';

export const EVENT_COLORS = [
  '#92A8F9',
  '#3f50b5',
  '#d40001',
  '#e67b73',
  '#f4511e',
  '#f5bf25',
  '#32b579',
  '#0a8043',
  '#14B3BD',
  '#9254de'
];

export const getStatusColor = (status: number) => {
  switch (status) {
    case APPOINTMENT_STATUSES.awaiting_confirmation:
      return EVENT_COLORS[5];
    case APPOINTMENT_STATUSES.approved:
      return EVENT_COLORS[6];
    case APPOINTMENT_STATUSES.started:
      return EVENT_COLORS[4];
    case APPOINTMENT_STATUSES.completed:
      return EVENT_COLORS[7];
    case APPOINTMENT_STATUSES.canceled:
      return EVENT_COLORS[9];
    case APPOINTMENT_STATUSES.rejected:
      return EVENT_COLORS[2];
    case APPOINTMENT_STATUSES.finished:
      return EVENT_COLORS[8];
  }
};
