import { gql } from '@apollo/client';

export const CREATE_SPECIALTY = gql`
  mutation CreateSpecialty($input: SpecialtyLangInput) {
    createSpecialty(input: $input) {
      message
    }
  }
`;

export const UPDATE_SPECIALTY = gql`
  mutation UpdateSpecialty($id: ID!, $input: SpecialtyLangInput) {
    updateSpecialty(id: $id, input: $input) {
      message
    }
  }
`;

export const DELETE_SPECIALTY = gql`
  mutation DeleteSpecialty($id: ID!) {
    deleteSpecialty(id: $id) {
      message
    }
  }
`;
