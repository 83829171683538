import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { App, Collapse, Form, Input, Modal, Typography } from 'antd';
import { LANGUAGES } from 'constants/languages';
import { UPDATE_FAQ } from 'gql/faq/mutations';
import { GET_FAQ, GET_FAQ_BY_ID } from 'gql/faq/queries';
import { FC, memo, useEffect, useState } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useSearchParams } from 'react-router-dom';

import { showErrorMessage } from 'utils/showErrorMessage';
import { getInputDir } from 'utils/helpers';

import { GetFaqIdQuery } from '@/generated/graphql';

interface FieldsValue {
  sort_index?: number | null;
  question: {
    [key: string]: string;
  };
  answer: {
    [key: string]: string;
  };
}

interface IProps {
  id: string;
  onClose: () => void;
  maxSortIndex: number;
}

const UpdateFaq: FC<IProps> = ({ id, onClose, maxSortIndex }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('service');

  const { data: faqById } = useQuery<GetFaqIdQuery>(GET_FAQ_BY_ID, {
    variables: {
      id: id
    },
    fetchPolicy: 'no-cache'
  });

  const faqIdData = faqById?.getFAQId?.data;

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  const [updateFaq, { loading: isUpdating }] = useMutation(UPDATE_FAQ, {
    onCompleted() {
      message.success('Successfully updated');
      client.refetchQueries({ include: [GET_FAQ] });
      closeModal();
    },
    onError: err => showErrorMessage(err)
  });

  const onFinish = (values: Record<string, string>) => {
    setErrorKeys([]);
    updateFaq({
      variables: {
        id: id,
        input: {
          ...values,
          sort_index: values.sort_index ? Number(values.sort_index) : null,
          service_id: Number(serviceId) || null
        }
      }
    });
  };

  const onFinishError = (
    errorInfo: ValidateErrorEntity<Record<string, string>>
  ) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  useEffect(() => {
    if (id && faqById) {
      const fieldsValue: FieldsValue = {
        sort_index: faqIdData?.sort_index || null,
        question: {},
        answer: {}
      };

      LANGUAGES.forEach(language => {
        fieldsValue.question[language.locale] =
          faqIdData?.question?.[language.locale] || '';
        fieldsValue.answer[language.locale] =
          faqIdData?.answer?.[language.locale] || '';
      });

      form.setFieldsValue(fieldsValue);
    }
  }, [faqById, faqIdData, form, id]);

  return (
    <Modal
      open
      onCancel={closeModal}
      confirmLoading={isUpdating}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishError}
      >
        <Form.Item
          name="sort_index"
          label="Display Order"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: 'Please enter a valid number'
            },
            {
              validator: (_, value) =>
                value && value > maxSortIndex
                  ? Promise.reject(
                      `Number must be less than or equal to ${maxSortIndex}`
                    )
                  : Promise.resolve()
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Collapse
          accordion
          defaultActiveKey={LANGUAGES[0].locale}
          items={LANGUAGES.map(item => {
            const hasError = errorKeys.includes(item.locale);

            return {
              key: item.locale,
              forceRender: true,
              label: (
                <Typography.Text
                  style={{
                    color: `var(--${hasError ? 'error-color' : 'text-color'})`
                  }}
                >
                  {item.label}
                  {hasError ? ' (error)' : ''}
                </Typography.Text>
              ),
              children: (
                <>
                  <Form.Item
                    name={['question', item.locale]}
                    label="Question"
                    rules={[{ required: true }]}
                  >
                    <Input dir={getInputDir(item.locale)} />
                  </Form.Item>
                  <Form.Item
                    name={['answer', item.locale]}
                    label="Answer"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea dir={getInputDir(item.locale)} />
                  </Form.Item>
                </>
              )
            };
          })}
        />
      </Form>
    </Modal>
  );
};

export default memo(UpdateFaq);
