import { gql } from '@apollo/client';

import { ORGANIZATION_MINI_FRAGMENT, ORGANIZATION_FRAGMENT } from './fragments';

export const GET_ORGANIZATIONS = gql`
  query OrganizationsAdmin {
    organizationsAdmin {
      data {
        ...OrganizationMiniFragment
      }
    }
  }
  ${ORGANIZATION_MINI_FRAGMENT}
`;

export const GET_ORGANIZATION_BY_ID = gql`
  query OrganizationById($id: ID!) {
    organizationById(id: $id) {
      data {
        ...OrganizationFragment
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const GET_SUB_ORGANIZATIONS = gql`
  query OrganizationByParentId($query: OrganizationParent) {
    organizationByParentId(query: $query) {
      data {
        id
        name
        image
      }
    }
  }
`;
