import { Empty, Form, Select } from 'antd';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TAGS } from 'gql/tags/queries';
import { useParams, useSearchParams } from 'react-router-dom';
import { GetTags } from 'gql/tags/__generated__/GetTags';
import { CreateTag } from 'gql/tags/__generated__/CreateTag';
import { ATTACH_TAG, CREATE_TAGS, DETACH_TAG } from 'gql/tags/mutations';
import { RTL_LANGUAGES } from 'constants/translations';

import { StyledItem } from './styled';
import useDoctorProfileCache from 'hooks/useDoctorProfileCache';

export type TagsSelectRef = {
  save(): Promise<void>;
};

interface IProps {
  language: string;
}

const TagsSelect: ForwardRefRenderFunction<TagsSelectRef, IProps> = (
  { language },
  ref
) => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const lang = useMemo(() => searchParams.get('lang') || 'en', [searchParams]);

  const { profile } = useDoctorProfileCache({ id: id as string, lang });

  const { data } = useQuery<GetTags>(GET_TAGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    context: {
      headers: {
        locale: lang
      }
    }
  });

  const [attachTags] = useMutation(ATTACH_TAG);
  const [detachTags] = useMutation(DETACH_TAG);

  const [createTags] = useMutation<CreateTag>(CREATE_TAGS, {
    update(cache, newData) {
      cache.modify({
        fields: {
          getTags(existing = {}) {
            const result = newData.data?.createTag?.data;

            if (!result) return;

            return {
              ...existing,
              data: [...existing.data, result]
            };
          }
        }
      });
    }
  });

  const [value, setValue] = useState('');

  const onCreateTag = () => {
    createTags({
      variables: {
        input: {
          lang,
          name: value
        }
      }
    });
    setValue('');
  };

  useImperativeHandle(ref, () => ({
    save: async () => {
      try {
        const promises: Promise<unknown>[] = [];

        const finalTags: string[] = form.getFieldValue('tags');

        const added = finalTags.filter(tag => !currentTags.includes(tag));

        if (added.length) {
          promises.push(
            attachTags({
              variables: {
                input: {
                  doctor_id: profile?.id,
                  tag_ids: added
                }
              }
            })
          );
        }

        const deleted = currentTags.filter(tag => !finalTags.includes(tag));

        if (deleted.length) {
          promises.push(
            detachTags({
              variables: {
                doctor_id: profile?.id,
                query: {
                  tag_ids: deleted
                }
              }
            })
          );
        }

        await Promise.all(promises);

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }));

  const currentTags = profile?.tags?.map(item => item.id) || [];

  if (!profile) {
    return null;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      size="large"
      initialValues={{
        tags: currentTags
      }}
    >
      <Form.Item name="tags" label="Tags">
        <Select
          direction={RTL_LANGUAGES.includes(language) ? 'rtl' : 'ltr'}
          mode="multiple"
          placeholder="Please select or create new"
          style={{ width: '100%' }}
          onSearch={v => setValue(v)}
          searchValue={value}
          filterOption={(input, option) =>
            (option?.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          notFoundContent={
            value ? (
              <StyledItem onClick={onCreateTag}>
                {value} (Create new)
              </StyledItem>
            ) : (
              <Empty />
            )
          }
          options={data?.getTags?.data.map(item => ({
            label: item.name,
            value: item.id
          }))}
        />
      </Form.Item>
    </Form>
  );
};

export default forwardRef(TagsSelect);
