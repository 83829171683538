import { gql } from '@apollo/client';

export const GET_CARTS_DATA = gql`
  query GetChartsData {
    getChartsData {
      data {
        doctors
        patients
        patient_month
        patients_country
      }
    }
  }
`;

export const GET_APPOINTMENTS_CARTS_DATA = gql`
  query GetAppointmentCharts {
    getAppointmentCharts {
      data {
        appointment_count
        appointments_month
        appointments_status
      }
    }
  }
`;

export const GET_ORGANIZATION_CARTS_DATA = gql`
  query GetOrganizationCharts {
    getOrganizationCharts {
      data {
        organization_count
      }
    }
  }
`;

export const GET_IP_CARTS_DATA = gql`
  query GetIpData {
    getIpData {
      data {
        ip_country_data
      }
    }
  }
`;
