import { FC } from 'react';
import {
  App,
  Button,
  Flex,
  Form,
  Image,
  InputNumber,
  Select,
  Space,
  Switch,
  Typography
} from 'antd';
import medicusIcon from 'assets/img/medicus_meetings.png';
import zoomIcon from 'assets/img/zoom.jpg';
import {
  PAYMENT_BEHAVIOR_BY_ORGANIZATION,
  PAYMENT_BEHAVIOR_LABEL
} from 'constants/organization';

import { StyledPage, StyledStatus } from './styled';
import useOrganizationSettings from 'pages/OrganizationSettings/hooks/useUpdateSettigns';

enum MeetingServicesEnum {
  medicus,
  zoom
}

type UiFormValues = {
  translatorByDefault: boolean;
  meeting: MeetingServicesEnum;
  paymentBehavior: (typeof PAYMENT_BEHAVIOR_BY_ORGANIZATION)[keyof typeof PAYMENT_BEHAVIOR_BY_ORGANIZATION];
  initialPercent: string;
};

const defaultValues = {
  translatorByDefault: false,
  meeting: MeetingServicesEnum.medicus,
  paymentBehavior: PAYMENT_BEHAVIOR_BY_ORGANIZATION.NO_PAYMENT,
  initialPercent: ''
};

interface IProps {
  id: string;
}

const MEETING_OPTIONS = [
  {
    image: medicusIcon,
    title: 'MedicusUnion Meetings',
    value: MeetingServicesEnum.medicus
  },
  {
    image: zoomIcon,
    title: 'Zoom',
    value: MeetingServicesEnum.zoom
  }
];

const AppointmentSettings: FC<IProps> = ({ id }) => {
  const { modal } = App.useApp();
  const [form] = Form.useForm<UiFormValues>();

  const { loading, settings, onUpdateSettings } = useOrganizationSettings(id);

  const onFinish = (values: UiFormValues) => {
    onUpdateSettings({
      meeting: values.meeting,
      translatorByDefault: values.translatorByDefault,
      payment: {
        paymentBehavior: values.paymentBehavior,
        initialPercent: values.initialPercent
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image: values.image?.[0]?.response
    });
  };

  const onReset = () => {
    modal.confirm({
      title: 'Waring',
      content: (
        <Typography.Text>
          Are you sure you want to reset settings to default?{' '}
          <b>Changes will be automatically saved.</b>
        </Typography.Text>
      ),
      onOk() {
        form.setFieldsValue({
          ...defaultValues
        });
        form.submit();
      }
    });
  };

  const paymentStatus = Form.useWatch('paymentBehavior', form);

  return (
    <StyledPage>
      <Form
        initialValues={{
          translatorByDefault:
            settings?.translatorByDefault || defaultValues.translatorByDefault,
          meeting: settings?.meeting || defaultValues.meeting,
          paymentBehavior:
            settings?.payment?.paymentBehavior || defaultValues.paymentBehavior,
          initialPercent:
            settings?.payment?.initialPercent || defaultValues.initialPercent
        }}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="translatorByDefault"
          label="Translator included by default"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item name="meeting" label="Meeting Service Provider">
          <Select style={{ height: 48 }}>
            {MEETING_OPTIONS.map(item => (
              <Select.Option value={item.value} key={`meeting-${item.value}`}>
                <Space size={8} align="center">
                  <Image
                    src={item.image}
                    preview={false}
                    width={40}
                    height={40}
                    style={{ borderRadius: 8 }}
                  />
                  <Typography.Text>{item.title}</Typography.Text>
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <StyledStatus>
          <Form.Item
            style={{ width: '100%' }}
            name="paymentBehavior"
            label="Payment behavior"
            rules={[{ required: true }]}
          >
            <Select>
              {Object.entries(PAYMENT_BEHAVIOR_BY_ORGANIZATION).map(
                ([key, value]) => (
                  <Select.Option key={key + value} value={value}>
                    {PAYMENT_BEHAVIOR_LABEL[value]}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
          {paymentStatus === PAYMENT_BEHAVIOR_BY_ORGANIZATION.PERCENT_PAY && (
            <Form.Item
              style={{ width: '100%' }}
              name="initialPercent"
              label="Percent"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                max={100}
                formatter={value => `${value}%`}
              />
            </Form.Item>
          )}
        </StyledStatus>
      </Form>
      <Flex justify="end" gap={12}>
        <Button size="large" onClick={onReset} disabled={loading}>
          Reset to default
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={form.submit}
          loading={loading}
        >
          Save
        </Button>
      </Flex>
    </StyledPage>
  );
};

export default AppointmentSettings;
