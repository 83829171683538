import { FC } from 'react';
import { Button, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { ICardForm } from './types';
import { StyledCard } from './styled';

const { Title } = Typography;

const CardForm: FC<ICardForm> = ({ back, title, children }) => {
  // navigation
  const navigate = useNavigate();

  return (
    <>
      <StyledCard
        title={
          <Title level={2}>
            {back && (
              <Button
                type="link"
                icon={<ArrowLeftOutlined color="#2D2C4C" />} // primary-color-dark
                style={{ color: '#2D2C4C', marginRight: 10 }}
                onClick={() => navigate(-1)}
              />
            )}
            {title}
          </Title>
        }
      >
        {children}
      </StyledCard>
    </>
  );
};

export default CardForm;
