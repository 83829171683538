import { gql } from '@apollo/client';

import { BLOG_LIST_FRAGMENT } from './fragments';

export const GET_BLOG_NEWS = gql`
  query BlogNews($query: getBlogByLang!) {
    blogs(query: $query) {
      data {
        results {
          ...BlogList
        }
        total
      }
    }
  }
  ${BLOG_LIST_FRAGMENT}
`;

export const GET_BLOG_NEWS_BY_ID = gql`
  query Blog($id: ID!) {
    blog(id: $id) {
      data {
        id
        image
        slug
        translates {
          id
          title
          body
          short_desc
          keywords
          lang
        }
      }
    }
  }
`;
