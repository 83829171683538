import { PERMISSION_ACTIONS } from 'constants/permissions';

interface PermissionGroup {
  [key: string]: number;
}

class PermissionGroup {
  constructor(data: string[] = []) {
    this[PERMISSION_ACTIONS.read] = 2;
    data.forEach((key, index) => {
      this[key] = Math.pow(2, index + 2);
    });
  }
}

export default PermissionGroup;
