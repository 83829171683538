import { InboxOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Form, Input, Select, InputNumber, Upload, Result } from 'antd';
import client from 'apolloClient';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { UPDATE_DOCTOR_PAYMENT } from 'gql/appointments/mutations';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { useFileUpload } from 'hooks';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { GET_APPOINTMENT_REPORT } from 'gql/financial/queries';

import { showErrorMessage } from 'utils/showErrorMessage';
import { Loading } from 'components/ui';
import { getFileUrl } from 'utils/file';

const { TextArea } = Input;

const PaymentStatusModal: FC = () => {
  // form
  const [form] = Form.useForm();
  // context
  const { paymentStatus } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  const [showFileList, setShowFileList] = useState(true);
  const [percent, setPercent] = useState(0);
  const { upload, setResult, onCancel, result } = useFileUpload({
    handleResult: true,
    loading: true
  });

  const { data, loading: isFetching } = useQuery<AppointmentById>(
    GET_APPOINTMENT_ITEM,
    {
      skip: !paymentStatus.params?.id || !paymentStatus?.visible,
      variables: {
        id: paymentStatus.params?.id
      },
      fetchPolicy: 'cache-first'
    }
  );

  const closeModal = () => {
    toggleModal('paymentStatus', false);
    setResult('');
    form.resetFields();
  };

  const [updateDoctorPayment, { loading: isSending }] = useMutation(
    UPDATE_DOCTOR_PAYMENT,
    {
      onCompleted() {
        closeModal();
        client.refetchQueries({ include: [GET_APPOINTMENT_ITEM] });
        client.refetchQueries({ include: [GET_APPOINTMENT_REPORT] });
      },
      onError: err => showErrorMessage(err)
    }
  );

  const appointment = useMemo(() => data?.appointmentForAdmin?.data, [data]);

  const status = Form.useWatch('status', form);

  useEffect(() => {
    const adminPayment = appointment?.admin_payment;

    if (adminPayment?.file) {
      setResult(adminPayment.file);
    }

    form.setFieldsValue({
      status: adminPayment?.paid ? 1 : 0,
      transfer: adminPayment?.transfer || 0,
      note: adminPayment?.note
    });
  }, [appointment, form, setResult]);

  const onFinish = (values: {
    note: string;
    status: number;
    transfer: number;
  }) => {
    const paid = values.status === 1;

    updateDoctorPayment({
      variables: {
        input: {
          appointment_id: paymentStatus.params?.id,
          doctor_id: appointment?.doctor.id,
          note: values.note,
          transfer: paid ? values.transfer?.toString() : '0',
          file: result || undefined,
          paid
        }
      }
    });
  };

  const onUploadFile = async (e: UploadRequestOption) => {
    try {
      setResult('');
      setShowFileList(false);
      const res = await upload(e.file as File, {
        onUploadProgress({ percent: value }) {
          setPercent(value);

          if (value === 100) {
            setTimeout(() => {
              setPercent(0);
            }, 100);
          }
        }
      });

      e.onSuccess?.(res);
    } catch (err) {
      e.onError?.(err as ProgressEvent<EventTarget>);
    } finally {
      setShowFileList(true);
    }
  };

  const isUploading = percent > 0;

  return (
    <Modal
      title="Payment status"
      open={paymentStatus.visible}
      onOk={appointment ? form.submit : closeModal}
      onCancel={closeModal}
      confirmLoading={isSending}
    >
      {isFetching ? (
        <Loading type="static" />
      ) : appointment ? (
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  label: 'Not paid',
                  value: 0
                },
                {
                  label: 'Paid',
                  value: 1
                }
              ]}
            />
          </Form.Item>
          {status === 1 && (
            <Form.Item
              label="Amount"
              name="transfer"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          )}
          <Form.Item label="Notes" name="note" rules={[{ required: false }]}>
            <TextArea style={{ height: 130 }} />
          </Form.Item>
          <Form.Item
            label="Receipt of payment"
            valuePropName="fileList"
            getValueProps={value => value}
            rules={[{ required: false }]}
          >
            <Upload.Dragger
              name="file"
              customRequest={onUploadFile}
              onRemove={f => onCancel(f.uid)}
              maxCount={1}
              disabled={isUploading}
              showUploadList={showFileList}
              fileList={
                result
                  ? [
                      {
                        uid: result,
                        name: result,
                        url: getFileUrl(result)
                      }
                    ]
                  : []
              }
              listType="picture"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          {isUploading && <Loading type="static" percent={percent} />}
        </Form>
      ) : (
        <Result
          status="error"
          title="Oops"
          subTitle="We've detected a potential issue with this appointment. Please review all the fields. Alternatively, if this is a paid session, please note that setting payment details is not applicable"
        />
      )}
    </Modal>
  );
};

export default memo(PaymentStatusModal);
