import { gql } from '@apollo/client';

import { DICOM_LINK_FRAGMENT } from './fragments';

export const CREATE_DICOM_LINK = gql`
  mutation CreateDicomLink($input: DicomInput) {
    createDicomLink(input: $input) {
      message
      data {
        ...DicomLinkShareFragment
      }
    }
  }
  ${DICOM_LINK_FRAGMENT}
`;

export const DELETE_DICOM_LINK = gql`
  mutation DestroyDicom($id: ID) {
    destroyDicom(id: $id) {
      message
    }
  }
`;
