import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MAIN_ROUTES, RouteObject } from 'constants/routes';
import { usePermission } from 'hooks';

import { Private } from 'components/shared';
import NotFound from 'pages/NotFound';
import { PrivateLayout } from 'components/layout';
import PdfViewer from 'pages/PdfViewer';

const PrivateRoutes = () => {
  const { checkUserMultiPermission } = usePermission();

  const defaultRoute = useMemo(() => {
    const route = MAIN_ROUTES.find(item => checkUserMultiPermission(item.key));

    if (route) {
      if (!route?.children || route.renderParent) {
        return route.path;
      }

      const child = route.children.find(item =>
        checkUserMultiPermission(item.key, item.action)
      );

      if (child) {
        return `${route.path}/${child.path}`;
      }
    }
  }, [checkUserMultiPermission]);

  const renderRoute = (item: RouteObject | null): React.ReactElement | null => {
    if (!item) {
      return null;
    }

    if (item.element) {
      return (
        <Route
          key={`${item.path}-${item.key}`}
          path={item.path}
          element={
            <Private route={item.key} defaultRoute={defaultRoute} isRoute>
              {item.element}
            </Private>
          }
        />
      );
    }

    if (item.children) {
      const hasPermission = checkUserMultiPermission(item.key);

      if (!hasPermission) {
        return null;
      }

      return (
        <Route key={`${item.path}-${item.key}`} path={item.path}>
          {item.children.map(renderRoute)}
        </Route>
      );
    }

    return null;
  };

  if (!defaultRoute) {
    return <NotFound />;
  }

  return (
    <PrivateLayout>
      <Routes>
        {MAIN_ROUTES.map(renderRoute)}
        <Route path="/pdf-viewer/:id" element={<PdfViewer />} />
        <Route path="*" element={<Navigate to={defaultRoute} />} />
      </Routes>
    </PrivateLayout>
  );
};

export default PrivateRoutes;
