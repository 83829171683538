import { Modal } from 'antd';
import { useUIDispatchContext, useModalStateContext } from 'providers/UI';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmModal: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { confirm } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();

  const onClickOk = useCallback(() => {
    confirm.params?.onConfirm();

    toggleModal('confirm', false);
  }, [confirm.params, toggleModal]);

  return (
    <Modal
      title={confirm.params?.title || t('common.confirm_modal.title')}
      onCancel={() => toggleModal('confirm', false)}
      open={confirm.visible}
      okText={t('common.ok')}
      onOk={onClickOk}
    >
      {confirm.params?.message || t('common.confirm_modal.text')}
    </Modal>
  );
};

export default ConfirmModal;
