import config from 'config';
import placeholder from 'assets/img/placeholder.png';
import instance from 'services/api';
import { Modal } from 'antd';
import pdfIcon from 'assets/icons/pdf.svg';
import linkIcon from 'assets/icons/link.svg';
import dcmIcon from 'assets/icons/dcm.png';

export enum FileTypesEnum {
  pdf = 'pdf',
  dcm = 'dcm',
  image = 'image',
  zip = 'zip'
}

export const getFileUrl = (file?: string | null, size: number | null = 150) => {
  if (!file) {
    return placeholder;
  }

  return `${config.base_url}/aws-s3/file/${file}${size ? `?size=${size}` : ''}`;
};

export const openFile = async (
  path: string,
  setPercent?: (value: number) => void
) => {
  try {
    setPercent?.(1);
    const urlParams = path.split('/');
    const name = urlParams[urlParams.length - 1];
    const response = await instance.get(path, {
      responseType: 'blob',
      onDownloadProgress(e) {
        const percent = Math.ceil(e.loaded * (100 / e.total));

        if (percent >= 100) {
          setTimeout(() => {
            setPercent?.(0);
          }, 100);
        } else {
          setPercent?.(percent);
        }
      }
    });

    const file = new Blob([response.data]);

    const fileURL = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();

    return true;
  } catch (e) {
    setPercent?.(0);
    Modal.error({
      title: `We're sorry, but there was an issue processing the file. It appears to be corrupted or damaged. Please contact technical team for further assistance.`
    });

    return false;
  }
};

export const exportJsonFile = (json: string, name: string) => {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(json);

  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${name}.json`);
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const downloadFileFromUrl = (url: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.rel = 'noreferrer';
  a.download = fileName || 'download';

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};

export const getFileTypeName = (file?: string) => {
  if (!file) {
    return null;
  }

  const fileName = file.split('.');

  switch (fileName[fileName.length - 1]?.toLowerCase()) {
    case 'pdf':
      return FileTypesEnum.pdf;
    case 'dcm':
      return FileTypesEnum.dcm;
    case 'zip':
      return FileTypesEnum.zip;
    default:
      return FileTypesEnum.image;
  }
};

export const getFileIcon = (type?: FileTypesEnum | null) => {
  if (!type) {
    return '';
  }

  switch (type) {
    case FileTypesEnum.pdf:
      return pdfIcon;
    case FileTypesEnum.dcm:
      return dcmIcon;
    default:
      return linkIcon;
  }
};
