import { FC } from 'react';
import { Typography, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { reviewIssuesValue } from 'constants/reviews';

import { StyledCard, NoteWrapper, Content, NoteInfo } from './styled';

const { Text, Paragraph, Title } = Typography;

type IProps = {
  appointmentId: string;
  issues: string[];
  review: string | null;
  satisfied: boolean | null;
  date: string;
  sender: {
    firstName: string;
    lastName: string;
    email: string;
  } | null;
};

const CardNote: FC<IProps> = ({
  appointmentId,
  issues,
  review,
  satisfied,
  date,
  sender
}) => {
  // translations
  const { t } = useTranslation();

  return (
    <StyledCard bordered={false}>
      <NoteWrapper>
        <Content>
          <Title level={3}>
            {t('call_reviews.appointment_id')}:{' '}
            <Link to={`appointments/${appointmentId}`}>#{appointmentId}</Link>
          </Title>
          <div style={{ marginBottom: 16 }}>
            <Title level={5}>
              {t('call_reviews.satisfied')} -{' '}
              {satisfied ? (
                <Tag color="green">{t('call_reviews.yes')}</Tag>
              ) : (
                <Tag color="red">{t('call_reviews.no')}</Tag>
              )}
            </Title>
          </div>
          {!!issues?.length && (
            <div style={{ marginBottom: 16 }}>
              <Title level={5}>{t('call_reviews.issues')}</Title>
              <Text>
                {issues
                  .map(key =>
                    t(
                      `call_reviews.${
                        reviewIssuesValue[key as keyof typeof reviewIssuesValue]
                      }`
                    )
                  )
                  .join(', ')}
              </Text>
            </div>
          )}
          {!!review && (
            <div style={{ marginBottom: 16 }}>
              <Title level={5}>{t('call_reviews.review')}</Title>
              <Text>{review}</Text>
            </div>
          )}
        </Content>
      </NoteWrapper>
      <NoteInfo>
        <Space direction="vertical">
          {sender && (
            <Paragraph>
              {sender.firstName} {sender.lastName} |{' '}
              <a href={`mailto:${sender.email}`}>{sender.email}</a>
            </Paragraph>
          )}
          <Paragraph
            style={{
              color: '#0B1A30'
            }}
          >
            {dayjs(date).format('DD MMM YYYY | HH:mm')}
          </Paragraph>
        </Space>
      </NoteInfo>
    </StyledCard>
  );
};

export default CardNote;
