import { gql } from '@apollo/client';

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: OrganizationInput!) {
    createOrganizations(input: $input) {
      message
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($id: ID!, $input: OrganizationInput!) {
    updateOrganizations(id: $id, input: $input) {
      message
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: ID!) {
    deleteOrganizations(id: $id) {
      message
    }
  }
`;

export const UPDATE_ORGANIZATION_SETTINGS = gql`
  mutation UpdateOrganizationSettings(
    $id: ID!
    $input: UpdateOrganizationSettingsInput!
  ) {
    updateOrganizationSettings(id: $id, input: $input) {
      message
    }
  }
`;

export const UPDATE_FAST_APPOINTMENT_ORGANIZATION = gql`
  mutation UpdateOrganizationFastAppointments(
    $updateOrganizationFastAppointmentsId: ID!
    $input: UpdateOrganizationInput
  ) {
    updateOrganizationFastAppointments(
      id: $updateOrganizationFastAppointmentsId
      input: $input
    ) {
      message
    }
  }
`;
