import styled from 'styled-components';

export const StyledPage = styled.div`
  position: relative;
  .ant-upload {
    width: 100%;
    display: block;
    cursor: pointer;
    .ant-input-disabled {
      background-color: #ffffff !important;
    }
    input {
      pointer-events: none;
      background-color: #ffffff;
      width: 100%;
    }
  }
  .page-row-remove-button {
    position: absolute;
    top: -16px;
    right: -16px;
    background-color: transparent !important;
  }
`;
