import React, { FC, useMemo, useState } from 'react';
import {
  Alert,
  App,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Space
} from 'antd';
import { DATE_FORMAT, DATE_FORMAT_REVERSE } from 'constants/dates';
import dayjs from 'dayjs';
import { OrganizationsAdmin as OrganizationsType } from 'gql/organizations/__generated__/OrganizationsAdmin';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATIONS } from 'gql/organizations/queries';
import { GET_APPOINTMENT_REPORT_CHARTS } from 'gql/financial/queries';
import { AppointmentReportCharts } from 'gql/financial/__generated__/AppointmentReportCharts';

import { Loading, PageWrapper } from 'components/ui';
import { TotalCard } from '../components';
import { money } from 'utils/price';
import {
  ClusteredBarChart,
  BarsMovingBullets,
  DonutChart,
  TwoLevelPieChart
} from 'components/shared';
import { getFileUrl } from 'utils/file';

const MAX_MONTHS_FILTERS = 11;

const FinancialAnalytics: FC = () => {
  const { modal } = App.useApp();
  const defaultValues = useMemo(() => {
    const start = dayjs().subtract(1, 'months').format();
    const end = dayjs().format();

    return {
      date: [start, end]
    };
  }, []);

  const [filters, setFilters] = useState(defaultValues);
  const { data: organizationsData, loading: isFetchingOrganization } =
    useQuery<OrganizationsType>(GET_ORGANIZATIONS, {
      fetchPolicy: 'no-cache'
    });

  const { data: chartsData, loading: isFetchingReports } =
    useQuery<AppointmentReportCharts>(GET_APPOINTMENT_REPORT_CHARTS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        query: {
          startDate: filters.date[0],
          endDate: filters.date[1]
        }
      }
    });

  const [form] = Form.useForm();

  const organizations = useMemo(
    () => organizationsData?.organizationsAdmin.data || [],
    [organizationsData]
  );

  const totalByDayData = useMemo(
    () => chartsData?.appointmentReportCharts?.data?.totalByDayData,
    [chartsData?.appointmentReportCharts?.data?.totalByDayData]
  );

  const totalByOrganization = useMemo(
    () => chartsData?.appointmentReportCharts?.data?.totalByOrganization,
    [chartsData?.appointmentReportCharts?.data?.totalByOrganization]
  );

  const totalBySessionDay = useMemo(
    () => chartsData?.appointmentReportCharts?.data?.totalBySessionDay,
    [chartsData?.appointmentReportCharts?.data?.totalBySessionDay]
  );

  const totalChartsData = useMemo(
    () => chartsData?.appointmentReportCharts?.data?.totalChartsData,
    [chartsData?.appointmentReportCharts?.data?.totalChartsData]
  );

  const onFinish = (values: typeof filters) => {
    const isTheSame =
      dayjs(filters.date[0]).isSame(dayjs(values.date[0]), 'day') &&
      dayjs(filters.date[1]).isSame(dayjs(values.date[1]), 'day');

    if (isTheSame) {
      return;
    }

    const diff = Math.abs(
      dayjs(values.date[0]).diff(dayjs(values.date[1]), 'months')
    );

    const setValues = () => {
      setFilters(prev => ({
        ...prev,
        ...values
      }));
    };

    if (diff > MAX_MONTHS_FILTERS) {
      return modal.confirm({
        title: 'Warning',
        content:
          'Filtering data over a period exceeding one year may negatively impact performance',
        cancelText: 'cancel',
        okButtonProps: {
          children: 'Continue'
        },
        onOk() {
          setValues();
        }
      });
    }

    setValues();
  };

  const isFetching = isFetchingOrganization || isFetchingReports;

  const totalAmount = useMemo(
    () => totalChartsData?.total_amount || 0,
    [totalChartsData?.total_amount]
  );

  const totalRevenue = useMemo(
    () => totalChartsData?.total_revenue || 0,
    [totalChartsData?.total_revenue]
  );

  const totalSession = useMemo(
    () => totalChartsData?.total_amount_session || 0,
    [totalChartsData?.total_amount_session]
  );

  const totalAmountByDate = useMemo(() => {
    const results = [];

    const appointmentTotal = totalByDayData?.total_amount_by_day || {};
    const sessionTotal = totalBySessionDay?.total_amount_by_session_day || {};

    const isEmpty =
      !Object.values(appointmentTotal).length &&
      !Object.values(sessionTotal).length;

    if (isEmpty) {
      return [];
    }

    const start = dayjs(filters.date[0]);
    const end = dayjs(filters.date[1]);

    for (let i = start; i.isBefore(end); i = i.add(1, 'day')) {
      const date = i.format(DATE_FORMAT_REVERSE);
      results.push({
        date,
        appointments: appointmentTotal[date] || 0,
        paid_sessions: sessionTotal[date] || 0
      });
    }

    return results;
  }, [
    filters.date,
    totalByDayData?.total_amount_by_day,
    totalBySessionDay?.total_amount_by_session_day
  ]);

  const totalByPatient = useMemo(
    () => totalChartsData?.patient || {},
    [totalChartsData?.patient]
  );

  const reportsByOrganization = useMemo(() => {
    const data = totalByOrganization?.organization_record || {};

    const results = organizations.map(item => ({
      name: item.name,
      value: data[item.id] || 0,
      pictureSettings: {
        src: item.image ? getFileUrl(item.image) : ''
      }
    }));

    return results;
  }, [organizations, totalByOrganization?.organization_record]);

  const resetFilters = () => {
    form.resetFields();
    setFilters(defaultValues);
  };

  const filtersRangeMonths = useMemo(() => {
    const start = dayjs(filters.date[0]);
    const end = dayjs(filters.date[1]);

    return Math.abs(start.diff(end, 'months'));
  }, [filters.date]);

  return (
    <PageWrapper title="Financial Analytics">
      <Row gutter={[24, 24]}>
        <Col sm={24}>
          <Card>
            <Form
              form={form}
              size="middle"
              initialValues={{
                date: defaultValues.date.map(item => dayjs(item))
              }}
              onFinish={onFinish}
            >
              <Row gutter={12}>
                <Col sm={6}>
                  <Form.Item name="date" style={{ margin: 0 }}>
                    <DatePicker.RangePicker
                      format={DATE_FORMAT}
                      style={{ width: '100%' }}
                      allowClear={false}
                      disabledDate={current => current > dayjs().endOf('day')}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Space>
                    <Button onClick={resetFilters}>Reset</Button>
                    <Button type="primary" onClick={() => form.submit()}>
                      Apply
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        {filtersRangeMonths > MAX_MONTHS_FILTERS && (
          <Col sm={24}>
            <Alert
              type="warning"
              message="Warning"
              description="Filtering data over a period exceeding one year may negatively impact performance"
              action={
                <Space>
                  <Button type="text" size="small" ghost onClick={resetFilters}>
                    Reset
                  </Button>
                </Space>
              }
              showIcon
              closable
            />
          </Col>
        )}
        {isFetching ? (
          <Col sm={24}>
            <Loading />
          </Col>
        ) : (
          <>
            <Col sm={8}>
              <TotalCard
                title="Total amount of appointments"
                value={money(totalAmount)}
              />
            </Col>
            <Col sm={8}>
              <TotalCard title="Total revenue" value={money(totalRevenue)} />
            </Col>
            <Col sm={8}>
              <TotalCard
                title="Total amount of paid sessions"
                value={money(totalSession)}
              />
            </Col>
            <Col sm={16}>
              <Card title="Appointments/Paid sessions">
                <ClusteredBarChart
                  results={totalAmountByDate}
                  xKey="date"
                  yKeys={['appointments', 'paid_sessions']}
                  labels={{
                    appointments: 'Appointments',
                    paid_sessions: 'Paid sessions'
                  }}
                  id="ClusteredBarChart"
                />
              </Card>
            </Col>
            <Col sm={8}>
              <Card title="Revenue/Expenses">
                <DonutChart
                  id="DonutChart"
                  results={[
                    { category: 'Revenue', value: totalRevenue },
                    { category: 'Expenses', value: totalAmount - totalRevenue }
                  ]}
                />
              </Card>
            </Col>
            <Col sm={8}>
              <Card title="Total Patient Paid/Remaining">
                <TwoLevelPieChart
                  id="TwoLevelPieChart"
                  remaining={{
                    label: 'Remaining',
                    value: totalByPatient.not_paid || 0
                  }}
                  done={{ label: 'Paid', value: totalByPatient.paid || 0 }}
                />
              </Card>
            </Col>
            <Col sm={16}>
              <Card title="Total amount/Organization">
                <BarsMovingBullets
                  id="BarsMovingBullets"
                  results={reportsByOrganization}
                />
              </Card>
            </Col>
          </>
        )}
      </Row>
    </PageWrapper>
  );
};

export default FinancialAnalytics;
