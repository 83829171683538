import { Col, Space, Form, Row, Table, Input, Button } from 'antd';
import { t } from 'i18next';
import { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { useQuery } from '@apollo/client';
import { Users as UsersType } from 'gql/users/__generated__/Users';
import { GET_USERS } from 'gql/users/queries';
import { USER_ROLES } from 'constants/users';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { ADMIN_USER_ID } from 'constants/permissions';

import { ActionMenu, PageWrapper } from 'components/ui';
import { ChangeUserPassword, UsersModal } from '../components';

const Admins: FC = () => {
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [detailsId, setDetailsId] = useState('');
  const [page, setPage] = useState(0);
  const [changePasswordId, setChangePasswordId] = useState('');
  const [, setSearch] = useState('');
  const timer = useRef<NodeJS.Timeout | null>(null);

  const { data, loading, refetch } = useQuery<UsersType>(GET_USERS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      query: {
        role: USER_ROLES.admin,
        page,
        limit: 10
      }
    }
  });

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    },
    []
  );

  const onDetailsClose = useCallback(() => {
    setDetailsId('');
    setDetailsVisible(false);
  }, []);

  const results = data?.users?.data.results || [];
  const total = data?.users?.data.total || 0;

  return (
    <PageWrapper
      title="Admin users"
      color="white"
      extra={[
        <Button
          type="primary"
          key="create-role"
          onClick={() => setDetailsVisible(true)}
        >
          <PlusOutlined />
          Create user
        </Button>
      ]}
    >
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              <Input.Search
                placeholder={t('common.search')}
                onChange={onSearchChange}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render(_, record) {
              return `${record.first_name} ${record.last_name}`;
            }
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: value => <a href={`mailto:${value}`}>{value}</a>
          },
          {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render(role) {
              return role?.name;
            }
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
          },
          {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            align: 'right',
            render: id =>
              `${id}` !== ADMIN_USER_ID && (
                <ActionMenu
                  data={[
                    {
                      title: 'Change password',
                      action: () => setChangePasswordId(id)
                    }
                  ]}
                  placement="bottomRight"
                  icon={<EllipsisOutlined />}
                />
              )
          }
        ]}
        loading={loading}
        rowKey="id"
        pagination={{
          total,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
      {isDetailsVisible && (
        <UsersModal
          onClose={onDetailsClose}
          id={detailsId}
          role={USER_ROLES.admin}
          refetch={refetch}
        />
      )}
      {!!changePasswordId && (
        <ChangeUserPassword
          id={changePasswordId}
          onClose={() => setChangePasswordId('')}
        />
      )}
    </PageWrapper>
  );
};

export default Admins;
