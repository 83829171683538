import { useQuery } from '@apollo/client';
import { Form, Select } from 'antd';
import { RTL_LANGUAGES } from 'constants/translations';
import { GetServicesForAdmin } from 'gql/services/__generated__/GetServicesForAdmin';
import { GET_SERVICES } from 'gql/services/queries';
import { FC, memo } from 'react';

interface IProps {
  lang?: string;
}

const ServicesSelect: FC<IProps> = ({ lang }) => {
  const { data } = useQuery<GetServicesForAdmin>(GET_SERVICES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        name: '',
        pagination: {
          page: 0,
          limit: 999
        }
      }
    }
  });

  return (
    <Form.Item label="Services" name="services" rules={[{ required: false }]}>
      <Select
        direction={RTL_LANGUAGES.includes(lang as string) ? 'rtl' : 'ltr'}
        mode="multiple"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.label.toLowerCase().includes(input.toLowerCase()) || false
        }
        filterSort={(optionA, optionB) =>
          optionA.label
            .toLowerCase()
            .localeCompare(optionB?.label.toLowerCase())
        }
        options={
          data?.getServicesForAdmin?.data.results.map(item => ({
            label: item.title,
            value: item.id
          })) || []
        }
      />
    </Form.Item>
  );
};

export default memo(ServicesSelect);
