import { SendOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Select } from 'antd';
import { EMAIL_FIELD_VARIABLES } from 'constants/email';
import { FC } from 'react';

interface IProps {
  onClose(): void;
  patientIds: string[];
}

const SendEmailModal: FC<IProps> = ({ patientIds, onClose }) => {
  const [form] = Form.useForm();

  const onFinish = (values: unknown) => {
    console.log('values', values, patientIds);
  };

  return (
    <Modal
      title="Send Email"
      okText="Send Now"
      okButtonProps={{ icon: <SendOutlined /> }}
      onOk={() => form.submit()}
      onCancel={onClose}
      open
    >
      <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
        <Form.Item
          name="template_id"
          label="Email template ID"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="variables" label="Add Variables">
          <Select
            mode="multiple"
            options={EMAIL_FIELD_VARIABLES.map(item => ({
              label: item,
              value: item
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SendEmailModal;
