import { PERMISSIONS_GLOBAL, SECTIONS_ALL } from 'constants/permissions';

interface SectionType {
  [key: string]: number;
}

export const findSectionName = (params: string[]): SectionType => {
  return params.reduce((accumulator: SectionType, currentValue: string) => {
    if (SECTIONS_ALL[currentValue]) {
      return {
        currentValue: SECTIONS_ALL[currentValue],
        ...accumulator
      };
    }

    return {};
  }, {});
};

export const findSectionValue = (
  params: string[],
  section: number
): SectionType => {
  return params.reduce((accumulator: SectionType, currentValue: string) => {
    if (PERMISSIONS_GLOBAL[section]) {
      return {
        currentValue: PERMISSIONS_GLOBAL[section][currentValue],
        ...accumulator
      };
    }

    return {};
  }, {});
};

export const checkPermission = (required: number, value: number) =>
  (required & value) === required;
