import { Button, Card } from 'antd';
import styled from 'styled-components';

export const StyledRemove = styled(Button)`
  position: absolute;
  top: -20px;
  right: -20px;
  opacity: 0;
  transition: opacity 0.4s;
`;

export const StyledCard = styled(Card)`
  &:hover {
    ${StyledRemove} {
      opacity: 1;
    }
  }
`;
