import styled from 'styled-components';

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const StyledStatus = styled.div`
  display: flex;
  gap: 16px;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
