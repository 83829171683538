import styled from 'styled-components';

export const StyledImage = styled.img`
  width: 250px;
  height: 250px;
`;

export const StyledResult = styled.ul`
  list-style: none;
  text-align: left;
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  li {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
