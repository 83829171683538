import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Button, message, Table } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_APPOINTMENT, OPEN_ROOM } from 'gql/appointments/mutations';
import { GET_PAID_APPOINTMENT_BY_CREATOR } from 'gql/appointments/queries';
import {
  AppointmentByCreatorPay_appointmentByCreatorPay_data_results as AppointmentType,
  AppointmentByCreatorPay
} from 'gql/appointments/__generated__/AppointmentByCreatorPay';
import { OpenRoom } from 'gql/appointments/__generated__/OpenRoom';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { useUIDispatchContext } from 'providers/UI';
import instance from 'services/api';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useNavigate } from 'react-router-dom';

import SessionsPay from './Modals';
import { ActionMenu, PageWrapper } from 'components/ui';
import useCopyText from 'hooks/useCopyText';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getIdStart } from 'utils/string';
import { money } from 'utils/price';
import { Private, OrganizationAlert } from 'components/shared';

const PAGE_LIMIT = 10;

const Sessions: FC = () => {
  const { t } = useTranslation();
  const { toggleModal } = useUIDispatchContext();
  const { copyText } = useCopyText();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { data, loading, refetch } = useQuery<AppointmentByCreatorPay>(
    GET_PAID_APPOINTMENT_BY_CREATOR,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  );

  const [deleteAppointment] = useMutation(DELETE_APPOINTMENT, {
    onCompleted() {
      message.success(t('messages.successfully_deleted'));
      setPage(0);
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  const [openRoom] = useMutation<OpenRoom>(OPEN_ROOM, {
    onError: err => showErrorMessage(err)
  });

  const onCopyRoom = useCallback(
    (id: string) => {
      openRoom({ variables: { input: { id } } }).then(({ data }) => {
        const url = data?.openRoom.data.redirect_url;
        const roomId = data?.openRoom.data.meetUrl;

        if (url) {
          const link = `${url}/home/${roomId}`;

          copyText(link);
        }
      });
    },
    [copyText, openRoom]
  );

  const onCopyPassword = useCallback(
    (id: string) => {
      instance
        .post('meetings/create-room', {
          appointmentId: id
        })
        .then(res => {
          if (res?.data?.data?.password) {
            copyText(res.data.data.password);
          }
        });
    },
    [copyText]
  );

  const columns: ColumnProps<AppointmentType>[] = useMemo(
    () => [
      {
        title: t('sessions.id'),
        dataIndex: 'id',
        key: 'id',
        render: id => getIdStart(id)
      },
      {
        title: t('sessions.price'),
        dataIndex: 'price',
        key: 'price',
        render: price => money(price)
      },
      {
        title: 'Paid',
        dataIndex: 'paid',
        key: 'paid',
        render(value) {
          return value ? 'Yes' : 'No';
        }
      },
      {
        title: t('sessions.notes'),
        dataIndex: 'reason',
        key: 'notes'
      },
      {
        title: t('sessions.created'),
        dataIndex: 'created_at',
        key: 'created_at',
        width: '15%',
        render: createdAt => dayjs(createdAt).format(FULL_DATE_FORMAT)
      },
      {
        title: t('sessions.actions'),
        dataIndex: 'id',
        key: 'actions',
        width: '5%',
        align: 'right',
        render: (id, record) => (
          <ActionMenu
            data={[
              {
                title: t('sessions.copy_payment'),
                permission: {
                  route: PERMISSION_ROUTES.sessionsPaid,
                  action: PERMISSION_ACTIONS.generateUrl
                },
                action() {
                  copyText(record.url || '');
                }
              },
              {
                title: t('sessions.copy'),
                permission: {
                  route: PERMISSION_ROUTES.sessionsPaid,
                  action: PERMISSION_ACTIONS.generateUrl
                },
                action() {
                  onCopyRoom(id);
                }
              },
              {
                title: 'Copy password',
                permission: {
                  route: PERMISSION_ROUTES.sessionsPaid,
                  action: PERMISSION_ACTIONS.generateUrl
                },
                action() {
                  onCopyPassword(id);
                }
              },
              {
                title: 'Logs',
                permission: {
                  route: PERMISSION_ROUTES.super,
                  action: PERMISSION_ACTIONS.read
                },
                action() {
                  navigate(`/logs/meetings?search=${id}`);
                }
              },
              {
                title: t('table.delete'),
                permission: {
                  route: PERMISSION_ROUTES.sessionsPaid,
                  action: PERMISSION_ACTIONS.delete
                },
                action() {
                  deleteAppointment({ variables: { id } });
                },
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                }
              }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [copyText, deleteAppointment, navigate, onCopyPassword, onCopyRoom, t]
  );

  const results = data?.appointmentByCreatorPay?.data.results || [];

  return (
    <PageWrapper
      title="Paid Calls"
      color="white"
      extra={[
        <Private
          key="add-sessions-button"
          route={PERMISSION_ROUTES.sessionsPaid}
          action={PERMISSION_ACTIONS.create}
        >
          <OrganizationAlert>
            <Button
              type="primary"
              onClick={() => toggleModal('sessionPay', true)}
              loading={false}
            >
              <PlusOutlined />
              {t('sessions.create')}
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.appointmentByCreatorPay?.data.total || 0,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
      <SessionsPay />
    </PageWrapper>
  );
};

export default Sessions;
