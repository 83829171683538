import { FC, useCallback, useState } from 'react';
import { App, Button, Table } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useMutation, useQuery } from '@apollo/client';
import { CONFIG_PAGES } from 'constants/configs';
import { GetConfig } from 'gql/configs/__generated__/GetConfig';
import { GET_CONFIGS } from 'gql/configs/queries';
import { useNavigate } from 'react-router-dom';
import { CREATE_CONFIG } from 'gql/configs/mutations';
import { useCheckOrganization } from 'hooks';

import { OrganizationAlert, Private } from 'components/shared';
import { ActionMenu, PageWrapper } from 'components/ui';
import { HowItWorksModal } from './components';
import { showErrorMessage } from 'utils/showErrorMessage';

const HowItWorks: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const [selectedId, setSelectedId] = useState('');
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const { checkOrganizationWrapper } = useCheckOrganization();

  const { data, loading, refetch } = useQuery<GetConfig>(GET_CONFIGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        type: CONFIG_PAGES.HOW_IT_WORKS
      }
    }
  });

  const [createConfig, { loading: isCreating }] = useMutation(CREATE_CONFIG, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  const results = data?.getConfig?.data.results?.[0]?.body?.data || [];

  const onCloseDetails = useCallback(() => {
    setSelectedId('');
    setDetailsVisible(false);
  }, []);

  const deleteItem = (id: string) => {
    modal.confirm({
      title: 'Warning',
      content: <>Are you sure?</>,
      onOk() {
        createConfig({
          variables: {
            input: {
              body: JSON.stringify({
                data: results.filter((item: { id: string }) => item.id !== id)
              }),
              type: CONFIG_PAGES.HOW_IT_WORKS
            }
          }
        });
      }
    });
  };

  return (
    <PageWrapper
      title="How It Works"
      color="white"
      extra={[
        <Private
          route={PERMISSION_ROUTES.staticContent}
          action={PERMISSION_ACTIONS.create}
          key="add-how-it-works"
        >
          <OrganizationAlert>
            <Button type="primary" onClick={() => setDetailsVisible(true)}>
              <PlusOutlined />
              Create
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Table
        dataSource={results}
        loading={loading || isCreating}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Title',
            dataIndex: 'id',
            key: 'title',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render(_, record: any) {
              return record?.values?.en?.title || '-';
            }
          },
          {
            title: 'Description',
            dataIndex: 'id',
            key: 'description',
            width: '40%',
            render(_, record) {
              return record?.values?.en?.description || '-';
            }
          },
          {
            title: '',
            dataIndex: 'id',
            width: '5%',
            align: 'right',
            render: (_, record) => (
              <ActionMenu
                data={[
                  {
                    title: 'Update content',
                    permission: {
                      route: PERMISSION_ROUTES.staticContent,
                      action: PERMISSION_ACTIONS.update
                    },
                    action() {
                      checkOrganizationWrapper(() => {
                        navigate(`details/${record.id}`);
                      });
                    }
                  },
                  {
                    title: t('table.edit'),
                    permission: {
                      route: PERMISSION_ROUTES.staticContent,
                      action: PERMISSION_ACTIONS.update
                    },
                    action() {
                      setSelectedId(record.id);
                      setDetailsVisible(true);
                    }
                  },
                  {
                    title: t('table.delete'),
                    permission: {
                      route: PERMISSION_ROUTES.staticContent,
                      action: PERMISSION_ACTIONS.delete
                    },
                    action() {
                      deleteItem(record.id);
                    },
                    menuProps: {
                      style: {
                        color: 'var(--danger-color)'
                      }
                    }
                  }
                ]}
                placement="bottomRight"
                icon={<EllipsisOutlined />}
              />
            )
          }
        ]}
        pagination={{
          showSizeChanger: false
        }}
        rowKey="id"
      />
      {isDetailsVisible && (
        <HowItWorksModal id={selectedId} onClose={onCloseDetails} />
      )}
    </PageWrapper>
  );
};

export default HowItWorks;
