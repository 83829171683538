import { TFunction } from 'react-i18next';

export const PATTERNS = {
  price: /^[0-9]*\.?[0-9]*$/
};

export const URL_VALIDATION = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
);

export const validateMessages = (t: TFunction) => ({
  default: t('validation.default'),
  required: t('validation.required'),
  enum: t('validation.enum'),
  whitespace: t('validation.whitespace'),
  date: {
    format: t('validation.date.format'),
    parse: t('validation.date.parse'),
    invalid: t('validation.date.invalid')
  },
  types: {
    string: t('validation.types.string'),
    method: t('validation.types.method'),
    array: t('validation.types.array'),
    object: t('validation.types.object'),
    number: t('validation.types.number'),
    date: t('validation.types.date'),
    boolean: t('validation.types.boolean'),
    integer: t('validation.types.integer'),
    float: t('validation.types.float'),
    regexp: t('validation.types.regexp'),
    email: t('validation.types.email'),
    url: t('validation.types.url'),
    hex: t('validation.types.hex')
  },
  string: {
    len: t('validation.string.len'),
    min: t('validation.string.min'),
    max: t('validation.string.max'),
    range: t('validation.string.range')
  },
  number: {
    len: t('validation.number.len'),
    min: t('validation.number.min'),
    max: t('validation.number.max'),
    range: t('validation.number.range')
  },
  array: {
    len: t('validation.array.len'),
    min: t('validation.array.min'),
    max: t('validation.array.max'),
    range: t('validation.array.range')
  },
  pattern: {
    mismatch: t('validation.pattern.mismatch')
  }
});
