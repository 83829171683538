import { FC } from 'react';
import { Card, Row, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';

import { IconButton } from 'components/ui';
import { ISectionProps } from './types';
import { Private } from 'components/shared';

const { Title } = Typography;

const Section: FC<ISectionProps> = ({ title, action, children }) => {
  return (
    <Card>
      <Row
        style={{ marginBottom: 'var(--margin-md)' }}
        justify="space-between"
        align="middle"
      >
        <Title level={4} style={{ margin: 0 }}>
          {title}
        </Title>

        {action && (
          <Private
            route={PERMISSION_ROUTES.doctors}
            action={PERMISSION_ACTIONS.update}
          >
            <IconButton onClick={action.click}>
              {action.type === 'add' ? <PlusOutlined /> : <EditOutlined />}
            </IconButton>
          </Private>
        )}
      </Row>
      {children}
    </Card>
  );
};

export default Section;
