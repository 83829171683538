import { gql } from '@apollo/client';

import { SPECIALTY_ITEM_FRAGMENT, SPECIALTY_LIST_FRAGMENT } from './fragments';

export const GET_SPECIALTIES = gql`
  query Specialties($query: getSpecialtiesByLang) {
    specialties(query: $query) {
      data {
        ...SpecialtyList
      }
    }
  }
  ${SPECIALTY_LIST_FRAGMENT}
`;

export const GET_SPECIALTY_BY_ID = gql`
  query Specialty($id: ID!) {
    specialty(id: $id) {
      data {
        ...SpecialtyItem
      }
    }
  }
  ${SPECIALTY_ITEM_FRAGMENT}
`;
