import {
  AdvancedSettings,
  PersonalInformation,
  Plans,
  ProfileDetails,
  WorkingHours,
  AdditionalFields
} from './pages';

export const SIDEBAR_OPTIONS = [
  {
    id: 'personal-information',
    slug: 'personal-information',
    name: 'Personal Information',
    component: PersonalInformation
  },
  {
    id: 'profile-details',
    slug: 'profile-details',
    name: 'Profile Details',
    component: ProfileDetails
  },
  {
    id: 'plans',
    slug: 'plans',
    name: 'Plans',
    component: Plans
  },
  {
    id: 'working-hours',
    slug: 'working-hours',
    name: 'Working Hours',
    component: WorkingHours
  },
  {
    id: 'advanced-settings',
    slug: 'advanced-settings',
    name: 'Advanced Settings',
    component: AdvancedSettings
  },
  {
    id: 'additional-fields',
    slug: 'additional-fields',
    name: 'Additional Fields',
    component: AdditionalFields
  }
];
