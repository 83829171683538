import { gql } from '@apollo/client';

export const NOTIFICATIONS_FRAGMENT = gql`
  fragment NotificationsFragment on Notification {
    id
    type
    title
    action
    trigger
    template
    created_at
  }
`;
