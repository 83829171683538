import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { Tag, Table, Row, Col, Button } from 'antd';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENTS } from 'gql/appointments/queries';
import { PLAN_TYPE_VALUES } from 'constants/users';
import {
  appointmentsForAdmin_appointmentsForAdmin_data_results as AppointmentType,
  appointmentsForAdmin
} from 'gql/appointments/__generated__/appointmentsForAdmin';
import {
  AppointmentStatusType,
  APPOINTMENT_STATUS_VALUES,
  APPOINTMENT_STATES,
  AppointmentStateType
} from 'constants/appointments';
import { AppointmentById_appointmentForAdmin_data_dates as DateType } from 'gql/appointments/__generated__/AppointmentById';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { usePermission } from 'hooks';

import { IconButton, PageWrapper } from 'components/ui';
import { money } from 'utils/price';
import { getStatusColor } from 'utils/appointments';
import { getIdStart } from 'utils/string';
import { Filters } from './components';
import { FormFilters } from './components/Filters/types';
import { Private, OrganizationAlert } from 'components/shared';

const PAGE_LIMIT = 10;

const Appointments: FC = () => {
  // translations
  const { t } = useTranslation();
  // navigation
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { checkUserPermission } = usePermission();

  const initialValues = useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    return {
      name: params.get('name') || undefined,
      type: params.get('type') || undefined,
      status: params.get('status') || undefined,
      admin_paid: params.get('admin_paid') || undefined
    };
  }, []);

  // states
  const [filters, setFilters] = useState<FormFilters>(initialValues);

  const page = Number(searchParams.get('page') || 0);

  // graphql
  const { data, loading } = useQuery<appointmentsForAdmin>(GET_APPOINTMENTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        name: filters.name,
        plan_type: filters.type,
        admin_paid: filters.admin_paid ? filters.admin_paid : undefined,
        status: filters.status ? Number(filters.status) : undefined,
        pagination: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const columns: ColumnProps<AppointmentType>[] = useMemo(() => {
    const hasPaymentReadPermission = checkUserPermission(
      PERMISSION_ROUTES.appointments,
      PERMISSION_ACTIONS.appointmentViewPayment
    );

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => getIdStart(id)
      },
      {
        title: 'Doctor Name',
        dataIndex: 'doctor',
        key: 'doctor',
        render: value => `${value.first_name} ${value.last_name}`
      },
      {
        title: 'Patient Name',
        dataIndex: 'patient',
        key: 'patient',
        render: value => `${value.first_name} ${value.last_name}`
      },
      {
        title: 'Date and time',
        dataIndex: 'dates',
        key: 'dates',
        render: (value: DateType[]) => {
          const dateItem = value.find(item => item.accepted);

          if (dateItem) {
            return dayjs(dateItem.date).format(FULL_DATE_FORMAT);
          }

          return '-';
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: AppointmentStatusType) => (
          <Tag color={getStatusColor(value)}>
            {t(`appointments.${APPOINTMENT_STATUS_VALUES[value]}`)}
          </Tag>
        )
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (value: AppointmentStateType) => APPOINTMENT_STATES[value]
      },
      ...(hasPaymentReadPermission
        ? [
            {
              title: 'Patient Payment',
              dataIndex: 'paid',
              key: 'paid',
              render: (value: boolean) =>
                value ? t('appointments.paid') : t('appointments.not_paid')
            },
            {
              title: 'Doctor payment',
              dataIndex: 'admin_paid',
              key: 'admin_paid',
              render: (value: boolean) =>
                value ? t('appointments.paid') : t('appointments.not_paid')
            },
            {
              title: 'Amount',
              dataIndex: 'price',
              key: 'price',
              render: (value: number) => money(value)
            }
          ]
        : []),
      {
        title: 'Type',
        dataIndex: 'plan',
        key: 'plan',
        render: (_, record) =>
          record.plan.type === PLAN_TYPE_VALUES.report ? 'Report' : 'Video call'
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        align: 'right',
        render: id => (
          <IconButton onClick={() => navigate(id)}>
            <EyeOutlined />
          </IconButton>
        )
      }
    ];
  }, [checkUserPermission, navigate, t]);

  const onFiltersSubmit = useCallback(
    (values: FormFilters) => {
      searchParams.set('page', '0');
      Object.entries(values).forEach(([key, value]) => {
        searchParams.set(key, value && value !== 'undefined' ? value : '');
      });
      setSearchParams(searchParams);
      setFilters({
        name: values.name && values.name !== 'undefined' ? values.name : '',
        type: values.type && values.type !== 'undefined' ? values.type : '',
        admin_paid:
          values.admin_paid && values.admin_paid !== 'undefined'
            ? values.admin_paid
            : '',
        status:
          values.status && values.status !== 'undefined' ? values.status : ''
      });
    },
    [searchParams, setSearchParams]
  );

  const appointments = data?.appointmentsForAdmin?.data.results || [];
  const total = data?.appointmentsForAdmin?.data.total || 0;

  return (
    <PageWrapper title={t('appointments.page_title')} color="white">
      <Row justify="space-between">
        <Col>
          <Filters onSubmit={onFiltersSubmit} initialValues={initialValues} />
        </Col>
        <Private
          route={PERMISSION_ROUTES.appointments}
          action={PERMISSION_ACTIONS.create}
        >
          <Col>
            <OrganizationAlert>
              <Button
                size="large"
                type="primary"
                onClick={() => navigate('create')}
              >
                <PlusOutlined />
                {t('appointments.create')}
              </Button>
            </OrganizationAlert>
          </Col>
        </Private>
      </Row>
      <Table
        dataSource={appointments}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: total || 0,
          current: page + 1,
          onChange: p => {
            searchParams.set('page', `${p - 1}`);
            setSearchParams(searchParams);
          },
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default Appointments;
