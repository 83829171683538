import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  margin-bottom: 16px;
  box-shadow: 0px 4px 20px #e7eaf6;
  .ant-card-body {
    padding: 24px 24px 18px 10px;
  }
`;

export const NoteWrapper = styled.div`
  background-color: #f6f7fb;
  padding: 14px;
`;

export const Content = styled.div`
  max-width: 50%;
`;

export const NoteInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
`;
