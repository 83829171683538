export const CONFIG_PAGES = {
  TERMS: '1',
  POLICY: '2',
  FAQ: '3',
  LEGAL: '4',
  HOW_IT_WORKS: '5',
  PATIENT_MOBILE: '6',
  DOCTOR_MOBILE: '7',
  DOCTOR_HOW_IT_WORKS: '8'
} as const;
