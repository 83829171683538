import { Space } from 'antd';
import styled from 'styled-components';

export const StyledContent = styled(Space)`
  width: 100%;
  .ant-table-row-level-1 {
    .ant-table-cell-with-append {
      button {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -8px;
      }
    }
  }
  .ant-table-row-level-2 {
    .ant-table-cell {
      padding-left: 48px !important;
    }
  }
  .ant-table-row-level-2,
  .ant-table-row-level-1:has(+ .ant-table-row-level-2) {
    background-color: #f8f8f8;
  }
`;

export const Container = styled.div`
  height: 44px;
  min-width: 300px;
  border: 1px solid #c8d2e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
  .ant-typography {
    .ant-input {
      margin: 0 !important;
      border: none;
      position: static;
      &:focus {
        box-shadow: none;
      }
    }
  }
`;
