import { FC } from 'react';
import { Avatar, Dropdown, Flex, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { useAuthDispatchContext } from 'providers/Auth';
import { useUIDispatchContext } from 'providers/UI';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/selectors';

import {
  StyledHeader,
  StyledLogo,
  StyledMainHeader,
  StyledUserName
} from './styled';
import OrganizationSelect from '../OrganizationSelect';

const { Text } = Typography;

const Header: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { toggleModal } = useUIDispatchContext();
  const { signOut } = useAuthDispatchContext();
  // graphql
  const data = useAppSelector(selectUserData());

  const handleClickLogout = () => {
    toggleModal('confirm', true, {
      onConfirm: signOut
    });
  };

  const handleClickSettings = () => {
    toggleModal('settings', true);
  };

  return (
    <StyledMainHeader>
      <StyledLogo>
        <Link to="/">
          <Logo width={150} />
        </Link>
      </StyledLogo>
      <StyledHeader>
        <OrganizationSelect />
        <Dropdown
          menu={{
            items: [
              {
                key: 'settings',
                label: 'Settings',
                onClick: handleClickSettings
              },
              {
                key: 'logout',
                label: t('common.logout'),
                onClick: handleClickLogout
              }
            ]
          }}
          placement="bottom"
          trigger={['hover']}
        >
          <Space style={{ cursor: 'pointer' }} size={8}>
            <Avatar size={40}>{data?.first_name?.[0]}</Avatar>
            <Flex vertical>
              <StyledUserName>
                {data?.first_name} {data?.last_name}
              </StyledUserName>
              <Text style={{ color: '#63687A', fontSize: 12 }}>
                {t('common.welcome_back')}
              </Text>
            </Flex>
            <DownOutlined style={{ fontSize: 14 }} />
          </Space>
        </Dropdown>
      </StyledHeader>
    </StyledMainHeader>
  );
};

export default Header;
