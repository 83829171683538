import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import client from './apolloClient';
import App from './App';

import 'theme/variables.css';
import 'theme/index.css';
import 'locales';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </ApolloProvider>
    </BrowserRouter>
  );
}
