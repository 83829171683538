import { ApolloError } from '@apollo/client';
import { handleErrorMessage } from '@medicusunion/front-utils';
import { message } from 'antd';
import { store } from 'store';
import { setIsLoggedIn, setUserLoading } from 'store/user/slice';

import { jwt } from './jwt';

type ErrorResponseType = {
  body?: {
    message?: string;
  };
};

export const showErrorMessage = (err: ApolloError, cb?: () => void) => {
  handleErrorMessage(err, async data => {
    switch (data.status) {
      case 401: {
        const accessToken = await jwt.refreshToken();

        if (accessToken) {
          cb?.();
        } else {
          store.dispatch(setUserLoading(false));
          store.dispatch(setIsLoggedIn(false));
        }

        break;
      }

      default:
        message.error(data.message);
        break;
    }
  });
};

export const getFirstErrorMessage = (err: ApolloError) => {
  const response = err?.graphQLErrors?.[0]?.extensions
    ?.response as ErrorResponseType;

  return response?.body?.message || 'Something went wrong';
};
