import { FC, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';

import { PageWrapper } from 'components/ui';
import AiRequest from 'components/shared/AiRequest';

const AiHealth: FC = () => {
  const [status, setStatus] = useState<number>();

  return (
    <PageWrapper title="Ai Assistant" color="white">
      <Form size="large" layout="vertical">
        <Row>
          <Col md={6} xs={24}>
            <Form.Item label="Status">
              <Select
                value={status}
                onChange={v => setStatus(v)}
                options={[
                  {
                    label: 'Submitted',
                    value: 1
                  },
                  {
                    label: 'Not Submitted',
                    value: 0
                  }
                ]}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <AiRequest status={status} />
    </PageWrapper>
  );
};

export default AiHealth;
