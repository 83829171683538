import { FC } from 'react';
import { Breadcrumb, Flex, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import { IPageWrapper } from './types';
import {
  Wrapper,
  StyledButton,
  StyledTitle,
  PageContainer,
  PageHeader
} from './styled';
import Loading from '../Loading';

const PageWrapper: FC<IPageWrapper> = ({
  title,
  children,
  breadcrumbs,
  back,
  color = 'layout-body-background',
  extra,
  loading,
  onClickBack
}) => {
  // navigation
  const navigate = useNavigate();

  return (
    <Wrapper $color={color}>
      <PageHeader>
        <Flex gap={12} align="center">
          {back && (
            <StyledButton
              type="link"
              icon={<LeftOutlined />}
              onClick={() => (onClickBack ? onClickBack() : navigate(-1))}
            />
          )}
          {breadcrumbs && <Breadcrumb {...breadcrumbs} />}
          {title && <StyledTitle level={2}>{title}</StyledTitle>}
        </Flex>
        {extra && <Space>{extra.map(item => item)}</Space>}
      </PageHeader>
      <PageContainer>{loading ? <Loading /> : children}</PageContainer>
    </Wrapper>
  );
};

export default PageWrapper;
