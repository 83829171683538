import { gql } from '@apollo/client';

import { TAGS_FRAGMENT } from './fragments';

export const GET_TAGS = gql`
  query GetTags {
    getTags {
      data {
        ...TagsFragment
      }
    }
  }
  ${TAGS_FRAGMENT}
`;
