import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { App, Button, Tabs } from 'antd';
import instance from 'services/api';
import { TRANSLATIONS_FILES } from 'constants/translations';
import { parseJson } from '@medicusunion/front-utils';

import { Loading, PageWrapper } from 'components/ui';
import { Content, Export } from './components';
import {
  Languages,
  TranslationSelectedTab,
  TranslationsDataType,
  transformData,
  transformDataSingleJson
} from 'utils/translations';
import { TranslationsContentRef } from './components/Content';

// TODO: make this useful
const CAN_DOWNLOAD_UPLOAD_TRANSLATIONS = !!Number(
  process.env.REACT_APP_CAN_DOWNLOAD_UPLOAD_TRANSLATIONS
);

const Translations: FC = () => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const contentRef = useRef<TranslationsContentRef>(null);
  const [formattedData, setFormattedData] = useState<TranslationsDataType>();
  const [selectedTab, setSelectedTab] = useState<TranslationSelectedTab>(
    TRANSLATIONS_FILES[0]
  );

  const [isLoading, setLoading] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const onTabChange = useCallback((tab: string) => {
    const selected = TRANSLATIONS_FILES.find(item => item.key === tab);
    setFormattedData({});
    contentRef.current?.reset();

    setSelectedTab(selected as TranslationSelectedTab);
  }, []);

  const getTranslationData = useCallback(async () => {
    try {
      setFetching(true);
      // get initial JSONs for each language
      const data = await Promise.all(
        selectedTab.languages?.map(async locale => {
          if (typeof selectedTab.fileNames === 'string') {
            const res = await instance.get(
              `/aws-s3/get-data/${selectedTab.folderKey}/${locale}/${selectedTab.fileNames}`
            );

            return { [locale]: res.data };
          }

          const res = await instance.get(
            `/aws-s3/list-json/${selectedTab.folderKey}/${locale}`
          );

          const result: Record<string, unknown> = {};

          const namespaces = selectedTab.fileNames.map(fileName => ({
            key: fileName,
            data: parseJson(res.data[fileName]) || {}
          }));

          namespaces.forEach(item => {
            result[item.key] = item.data;
          });

          return { [locale]: result };
        })
      );

      // make a single object by locale
      const convertedData = data.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      );

      setFormattedData(convertedData);
    } catch (error) {
      modal.error({
        title: 'Error',
        content: (
          <>
            <p>Oops something went wrong please try again later.</p>
            <p>{`${error}`}</p>
          </>
        )
      });
    } finally {
      setFetching(false);
    }
  }, [
    modal,
    selectedTab.fileNames,
    selectedTab.folderKey,
    selectedTab.languages
  ]);

  useEffect(() => {
    getTranslationData();
  }, [getTranslationData]);

  const onExport = () => {
    return contentRef.current?.getData();
  };

  const onClickSave = async () => {
    try {
      setLoading(true);
      const data = contentRef.current?.getData(
        typeof selectedTab.fileNames !== 'string'
      );

      if (!data) {
        throw new Error('no data');
      }

      if (typeof selectedTab.fileNames === 'string') {
        const transformedData = transformData(
          data,
          selectedTab.languages as Languages[]
        );

        await instance.post('/aws-s3/create', transformedData, {
          params: {
            folderKey: selectedTab.folderKey,
            fileName: selectedTab.fileNames
          },
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
      } else {
        await Promise.all(
          selectedTab.fileNames.map(key => {
            const item = data.find(item => item.section === key);

            if (item) {
              const transformedData = transformDataSingleJson(
                item,
                selectedTab.languages as Languages[]
              );

              return instance.post('/aws-s3/create', transformedData, {
                params: {
                  folderKey: selectedTab.folderKey,
                  fileName: key
                },
                headers: {
                  'Content-Type': 'application/json; charset=utf-8'
                }
              });
            }
          })
        );
      }

      setDisabled(true);

      modal.success({
        title: 'Success',
        content: <p>Translations has been successfully saved</p>,
        closable: false,
        okCancel: true
      });
      getTranslationData();
    } catch (error) {
      modal.error({
        title: 'Error',
        content: (
          <>
            <p>Oops something went wrong.</p>
            <p>
              <b>{`${error}`}</b>
            </p>
          </>
        )
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpload: ChangeEventHandler<HTMLInputElement> = e => {
    const file = e.currentTarget.files?.[0];

    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsText(file, 'UTF-8');

      fileReader.onload = e => {
        if (e.target?.result) {
          const result = parseJson(e.target.result);

          if (typeof selectedTab.fileNames === 'string') {
            instance.post('/aws-s3/create', result, {
              params: {
                folderKey: selectedTab.folderKey,
                fileName: selectedTab.fileNames
              },
              headers: {
                'Content-Type': 'application/json; charset=utf-8'
              }
            });
          }
        }
      };
    }
  };

  if (!formattedData) {
    return <Loading />;
  }

  return (
    <PageWrapper
      title={t('translations.page_title')}
      color="white"
      extra={[
        CAN_DOWNLOAD_UPLOAD_TRANSLATIONS ? (
          <div key="import">
            <label htmlFor="import-file">Import JSON</label>
            <input id="import-file" type="file" hidden onChange={onUpload} />
          </div>
        ) : null,
        <Export key="export" getData={onExport} selectedTab={selectedTab} />,
        <Button
          key="save"
          type="primary"
          onClick={onClickSave}
          loading={isLoading}
          disabled={disabled}
        >
          Save
        </Button>
      ]}
    >
      <Tabs
        onChange={onTabChange}
        items={TRANSLATIONS_FILES.map(item => ({
          label: item.title,
          key: item.key,
          destroyInactiveTabPane: true,
          children: (
            <Content
              ref={contentRef}
              isFetching={isFetching}
              data={formattedData}
              languages={item.languages}
              onChange={() => {
                setDisabled(false);
              }}
            />
          )
        }))}
      />
    </PageWrapper>
  );
};

export default Translations;
