import { FC } from 'react';
import {
  CalendarOutlined,
  HeartOutlined,
  TagOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Space, Typography } from 'antd';

import { StyledIcon } from './styled';
import { IInfoItemProps } from './types';

const { Text, Title } = Typography;

const icons = {
  tag: <TagOutlined style={{ color: 'var(--primary)' }} />,
  calendar: <CalendarOutlined style={{ color: 'var(--primary)' }} />,
  heart: <HeartOutlined style={{ color: 'var(--primary)' }} />,
  info: <InfoCircleOutlined style={{ color: 'var(--primary)' }} />
};

const InfoItem: FC<IInfoItemProps> = ({ title, subtitle, icon, extra }) => {
  return (
    <Space size={12}>
      <StyledIcon>{icons[icon]}</StyledIcon>
      <div>
        <Text>{title}</Text>
        <Space style={{ display: 'flex' }}>
          {!!subtitle && (
            <Title style={{ margin: 0 }} level={5}>
              {subtitle}
            </Title>
          )}
          {extra}
        </Space>
      </div>
    </Space>
  );
};

export default InfoItem;
