import { PageWrapper } from 'components/ui';
import { TranslatorRequests } from 'components/shared';

const TranslatorRequestsPage = () => (
  <PageWrapper title="Translator Requests" color="white">
    <TranslatorRequests />
  </PageWrapper>
);

export default TranslatorRequestsPage;
