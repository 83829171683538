import { Space } from 'antd';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganizationsStateContext } from 'providers/Organizations';
import { useModalStateContext } from 'providers/UI';

import { Private } from 'components/shared';
import { PageWrapper } from 'components/ui';
import TotalCards from './components/TotalCards';
import NewRequests from './components/NewRequests';
import Charts from './components/Charts';

const Dashboard: FC = () => {
  const { selected } = useOrganizationsStateContext();
  const { settings } = useModalStateContext();
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('dashboard.title')}>
      <Space size={32} direction="vertical" style={{ width: '100%' }}>
        <Private
          route={PERMISSION_ROUTES.dashboard}
          action={PERMISSION_ACTIONS.viewAnalytics}
        >
          <TotalCards />
        </Private>
        <NewRequests />
        {!settings.visible && (
          <Private
            route={PERMISSION_ROUTES.dashboard}
            action={PERMISSION_ACTIONS.viewAnalytics}
          >
            <Charts key={selected} />
          </Private>
        )}
      </Space>
    </PageWrapper>
  );
};

export default Dashboard;
