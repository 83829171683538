import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CARTS_DATA } from 'gql/analytics/queries';
import { Row, Col, Card, Typography } from 'antd';
import { GetChartsData } from 'gql/analytics/__generated__/GetChartsData';
import { COUNTRIES } from '@medicusunion/front-utils';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { usePermission } from 'hooks';

import { Loading } from 'components/ui';
import CountCard from '../CountCard';
import AnalyticDateChart from '../AnalyticDateChart';
import PatientsCountriesChart from '../PatientsCountriesChart';
import { PieChartContainer } from './styled';
import AnalyticsMap from '../AnalyticsMap';
import PieChart from '../PieChart';

const PatientsCharts: FC = () => {
  const { checkUserPermission } = usePermission();
  const { data, loading } = useQuery<GetChartsData>(GET_CARTS_DATA, {
    fetchPolicy: 'cache-first'
  });

  const results = useMemo(
    () => data?.getChartsData?.data,
    [data?.getChartsData?.data]
  );

  const hasPatientReadPermission = checkUserPermission(
    PERMISSION_ROUTES.patients,
    PERMISSION_ACTIONS.read
  );

  if (loading || !results) {
    return <Loading />;
  }

  return (
    <>
      <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
        <Col lg={{ span: 16 }}>
          <Card
            bodyStyle={{ padding: '20px 24px 16px' }}
            style={{ height: '100%' }}
          >
            <Typography.Title level={4} style={{ marginBottom: 20 }}>
              Patients by dates
            </Typography.Title>
            <AnalyticDateChart
              color="#35B678"
              data={Object.entries(results.patient_month || {})?.map(
                ([key, value]) => ({
                  Date: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
                  Count: (value as number) || 0
                })
              )}
            />
          </Card>
        </Col>
        <Col lg={{ span: 8 }}>
          <Card
            bodyStyle={{
              padding: '20px 24px 16px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
            style={{ height: '100%' }}
          >
            <CountCard
              title="Total patients"
              to={hasPatientReadPermission ? '/access/patients' : null}
              count={results.patients?.total || 0}
            >
              <PieChart
                id="patientsPieChart"
                innerRadius={false}
                data={[
                  {
                    status: `Not verified`,
                    value: results.patients?.not_confirm || 0,
                    color: '#A4A8B5'
                  },
                  {
                    status: `Email verified `,
                    value: results.patients?.email_confirm || 0,
                    color: '#4F84E8'
                  },
                  {
                    status: `Verified`,
                    value: results.patients?.confirm || 0,
                    color: '#35B678'
                  }
                ]}
              />
            </CountCard>
          </Card>
        </Col>
        <Col
          lg={{ span: 8 }}
          style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
        >
          <PieChartContainer>
            <CountCard title="Patients Gender">
              <PieChart
                id="patientsGenderChart"
                data={[
                  {
                    status: `Male`,
                    value: results.patients?.male || 0,
                    color: '#35B678'
                  },
                  {
                    status: `Female`,
                    value: results.patients?.female || 0,
                    color: '#FFA25C'
                  }
                ]}
              />
            </CountCard>
          </PieChartContainer>
          <PieChartContainer>
            <CountCard title="Patients Age">
              <PieChart
                innerRadius={false}
                id="patientsAgeChart"
                data={[
                  {
                    status: 'Over-40',
                    value: results.patients?.over_40 || 0,
                    color: '#35B678'
                  },
                  {
                    status: 'Under-40',
                    value: results.patients?.under_40 || 0,
                    color: '#A4A8B5'
                  }
                ]}
              />
            </CountCard>
          </PieChartContainer>
        </Col>
        <Col lg={{ span: 16 }}>
          <Card
            bodyStyle={{ padding: '20px 24px 16px' }}
            style={{ height: '100%' }}
          >
            <Typography.Title level={4} style={{ marginBottom: 20 }}>
              Patients from countries
            </Typography.Title>
            <PatientsCountriesChart
              dataCountry={Object.entries(results.patients_country || {})?.map(
                ([key, value]) => {
                  const country = COUNTRIES.find(item => item.value === key);

                  const name = country?.label || 'Unknown';

                  return {
                    country: `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
                    visits: (value as number) || 0
                  };
                }
              )}
            />
          </Card>
        </Col>
        <Col lg={{ span: 24 }}>
          <AnalyticsMap />
        </Col>
      </Row>
    </>
  );
};

export default PatientsCharts;
