import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_BY_ID } from 'gql/organizations/queries';
import { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { OrganizationById } from 'gql/organizations/__generated__/OrganizationById';
import { Avatar, Flex, Layout, Menu, Typography } from 'antd';
import dayjs from 'dayjs';

import { Loading, PageWrapper } from 'components/ui';
import { StyledLayout } from './styled';
import { getFileUrl } from 'utils/file';
import { AppointmentsSettings, Contact, UISettings } from './components';

const { Content, Sider } = Layout;

const OrganizationSettings: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery<OrganizationById>(GET_ORGANIZATION_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: params.id
    }
  });

  const organization = data?.organizationById.data;

  const renderContent = () => {
    switch (params.page) {
      case 'contact':
        return <Contact id={params.id as string} />;
      case 'ui':
        return <UISettings id={params.id as string} />;
      case 'appointments':
        return <AppointmentsSettings id={params.id as string} />;
      default:
        return null;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper
      back
      onClickBack={() => navigate('/organizations')}
      breadcrumbs={{
        items: [
          {
            title: <Link to="/organizations">Organizations</Link>
          },
          {
            title: 'Settings'
          }
        ]
      }}
    >
      <StyledLayout>
        <Sider width={340}>
          <Flex align="center" gap={16} style={{ marginBottom: 32 }}>
            <Avatar src={getFileUrl(organization?.image)} size={60} />
            <div>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {organization?.name}
              </Typography.Title>
              <Typography.Paragraph style={{ margin: 0 }}>
                Created At:{' '}
                {dayjs(organization?.created_at).format('DD-MM-YYYY')}
              </Typography.Paragraph>
            </div>
          </Flex>
          <Menu
            selectedKeys={[`${params.page}`]}
            items={[
              {
                key: 'contact',
                label: (
                  <Link to={`/organizations/settings/${params.id}/contact`}>
                    Contact
                  </Link>
                )
              },
              {
                key: 'ui',
                label: (
                  <Link to={`/organizations/settings/${params.id}/ui`}>UI</Link>
                )
              },
              {
                key: 'appointments',
                label: (
                  <Link
                    to={`/organizations/settings/${params.id}/appointments`}
                  >
                    Appointments
                  </Link>
                )
              }
            ]}
          />
        </Sider>
        <Content>{renderContent()}</Content>
      </StyledLayout>
    </PageWrapper>
  );
};

export default OrganizationSettings;
