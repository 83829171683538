import { App, Flex, Typography } from 'antd';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import ParentOrganizationSelect from './ParentOrganizationSelect';
import SubOrganizationSelect from './SubOrganizationSelect';

const ROUTES_TO_DISABLE = [
  '/access/doctors/',
  '/services/details/',
  '/appointments/create',
  '/access/roles/details',
  '/static-pages/how-it-works/details',
  '/organizations/settings/'
];

const OrganizationSelect: FC = () => {
  const { pathname } = useLocation();
  const { modal } = App.useApp();
  const isDisabled = ROUTES_TO_DISABLE.some(route =>
    pathname.startsWith(route)
  );

  const onClickDisabled = () => {
    modal.confirm({
      title: 'Warning',
      content: (
        <Typography.Text>
          Your organization cannot be changed on this screen
        </Typography.Text>
      ),
      type: 'warning',
      cancelButtonProps: {
        style: {
          display: 'none'
        }
      }
    });
  };

  return (
    <Flex align="center" gap={16}>
      <ParentOrganizationSelect
        isDisabled={isDisabled}
        onClickDisabled={onClickDisabled}
      />
      <SubOrganizationSelect
        isDisabled={isDisabled}
        onClickDisabled={onClickDisabled}
      />
    </Flex>
  );
};

export default OrganizationSelect;
