import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table } from 'antd';
import {
  Users,
  Users_users_data_results as UserType
} from 'gql/users/__generated__/Users';
import { useQuery } from '@apollo/client';
import { USER_ROLES } from 'constants/users';
import { GET_USERS } from 'gql/users/queries';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { PlusOutlined } from '@ant-design/icons';

import { PageWrapper } from 'components/ui';
import { Private, OrganizationAlert } from 'components/shared';
import { UsersModal } from '../components';

const PAGE_LIMIT = 10;

const Translators: FC = () => {
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [detailsId, setDetailsId] = useState('');
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const { data, loading, refetch } = useQuery<Users>(GET_USERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        page,
        limit: PAGE_LIMIT,
        role: USER_ROLES.translator
      }
    }
  });

  const columns: ColumnProps<UserType>[] = useMemo(
    () => [
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('table.name'),
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => `${record.first_name} ${record.last_name}`
      },
      {
        title: t('table.email'),
        dataIndex: 'email',
        key: 'email',
        render: value => <a href={`mailto:${value}`}>{value}</a>
      },
      {
        title: t('table.phone'),
        dataIndex: 'phone',
        key: 'phone',
        render: value => <a href={`tel:${value}`}>{value}</a>
      }
      // {
      //   title: '',
      //   dataIndex: 'id',
      //   key: 'id',
      //   width: '5%',
      //   align: 'right',
      //   render: id => (
      //     <Private
      //       route={PERMISSION_ROUTES.translators}
      //       action={PERMISSION_ACTIONS.update}
      //     >
      //       <Button
      //         onClick={() => {
      //           setDetailsVisible(true);
      //           setDetailsId(id);
      //         }}
      //       >
      //         {t('common.edit')}
      //       </Button>
      //     </Private>
      //   )
      // }
    ],
    [t]
  );

  const onDetailsClose = useCallback(() => {
    setDetailsId('');
    setDetailsVisible(false);
  }, []);

  const results = data?.users?.data.results || [];

  return (
    <PageWrapper
      title={t('translator.page_title')}
      color="white"
      extra={[
        <Private
          key="add-translator"
          route={PERMISSION_ROUTES.translators}
          action={PERMISSION_ACTIONS.create}
        >
          <OrganizationAlert>
            <Button type="primary" onClick={() => setDetailsVisible(true)}>
              <PlusOutlined />
              {t('translator.add')}
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.users?.data.total || 0,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
      {isDetailsVisible && (
        <UsersModal
          onClose={onDetailsClose}
          role={USER_ROLES.translator}
          id={detailsId}
          refetch={refetch}
        />
      )}
    </PageWrapper>
  );
};

export default Translators;
