import { Flex, Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;
export const TitleStyled = styled(Title)`
  font-weight: 700;
  margin-top: 44px;
  margin-bottom: 16px;
`;

export const Container = styled(Flex)`
  padding: 12px 24px;
  background: #ffffff;
  border: 1px solid #f5f6f8;
  border-radius: 6px;
  .table_head .ant-table-thead > tr > td {
    background-color: #ffffff;
  }
  .table_head .ant-table-thead > tr > th {
    background-color: #ffffff;
    color: #63687a;
    font-size: 14px;
    font-weight: 500;
  }
`;
