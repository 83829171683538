import { FC, memo, useCallback } from 'react';
import { Form, Input, Modal } from 'antd';
import { useUIDispatchContext, useModalStateContext } from 'providers/UI';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import client from 'apolloClient';
import { CREATE_ADMIN_PAID_APPOINTMENT } from 'gql/appointments/mutations';
import { GET_PAID_APPOINTMENT_BY_CREATOR } from 'gql/appointments/queries';

import { PATTERNS } from 'utils/validation';
import { showErrorMessage } from 'utils/showErrorMessage';

const { TextArea } = Input;

const SessionsPay: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { sessionPay } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // form
  const [form] = Form.useForm();
  // navigation
  const closeModal = useCallback(() => {
    form.resetFields();
    toggleModal('sessionPay', false);
  }, [form, toggleModal]);

  const [createAppointment, { loading: isCreating }] = useMutation(
    CREATE_ADMIN_PAID_APPOINTMENT,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_PAID_APPOINTMENT_BY_CREATOR] });
        closeModal();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const handleSubmit = useCallback(
    (values: { price: string }) => {
      createAppointment({
        variables: {
          input: {
            ...values
          }
        }
      });
    },
    [createAppointment]
  );

  return (
    <Modal
      open={sessionPay.visible}
      onCancel={closeModal}
      onOk={form.submit}
      confirmLoading={isCreating}
      title={t('sessions.create')}
    >
      <Form form={form} layout="vertical" size="large" onFinish={handleSubmit}>
        <Form.Item
          name="price"
          label={t('sessions.price')}
          rules={[{ required: true }, { pattern: PATTERNS.price }]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <Form.Item name="reason" label={t('sessions.notes')}>
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(SessionsPay);
