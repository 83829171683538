// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Form, Typography, Collapse, Button, Tabs, App } from 'antd';
import { FC, useState, useCallback } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useMutation, useQuery } from '@apollo/client';
import { CONFIG_PAGES } from 'constants/configs';
import { GetConfig } from 'gql/configs/__generated__/GetConfig';
import { GET_CONFIGS } from 'gql/configs/queries';
import { CREATE_CONFIG } from 'gql/configs/mutations';
import client from 'apolloClient';
import { useNavigate, useParams } from 'react-router-dom';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { CollapseProps } from 'antd/lib';

import { showErrorMessage } from 'utils/showErrorMessage';
import { Loading, PageWrapper } from 'components/ui';
import { CKEditor, Private } from 'components/shared';

const { Text } = Typography;

const LANGUAGES = [
  {
    label: 'English',
    key: 'english',
    locale: 'en'
  },
  {
    label: 'German',
    key: 'german',
    locale: 'de'
  }
] as const;

const itemValues = {
  content: ''
};

type ValuesType = typeof itemValues;
type BodyType = Record<string, ValuesType>;

enum DeviceTypes {
  desktop = 'desktop',
  mobile = 'mobile'
}

const TABS_DATA = [
  {
    key: DeviceTypes.desktop,
    tab: 'Desktop'
  },
  {
    key: DeviceTypes.mobile,
    tab: 'Mobile'
  }
];

const { Panel } = Collapse;

const DoctorHowItWorksContent: FC = () => {
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const { id } = useParams();

  const [form] = Form.useForm();

  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const [selected, setSelected] = useState(DeviceTypes.desktop);

  const [createConfig, { loading: isCreating }] = useMutation(CREATE_CONFIG, {
    onCompleted() {
      client.refetchQueries({ include: [GET_CONFIGS] });
      navigate('/static-pages/doctor-how-it-works');
      modal.success({
        title: 'Success',
        text: `Doctor How It Works: ${id} page updated successfully!`
      });
    },
    onError: err => showErrorMessage(err)
  });

  const { data, loading } = useQuery<GetConfig>(GET_CONFIGS, {
    fetchPolicy: 'cache-first',
    variables: {
      query: {
        type: CONFIG_PAGES.DOCTOR_HOW_IT_WORKS
      }
    },
    onCompleted({ getConfig }) {
      const values = getConfig?.data.results?.[0]?.body?.[id];

      form.setFieldsValue(values);
    }
  });

  const onFinish = useCallback(
    (values: BodyType) => {
      setErrorKeys([]);

      const isDesktopError =
        !values[DeviceTypes.desktop] ||
        Object.values(values[DeviceTypes.desktop]).some(item => !item);

      const isMobileError =
        !values[DeviceTypes.mobile] ||
        Object.values(values[DeviceTypes.mobile]).some(item => !item);

      if (isDesktopError) {
        setSelected(DeviceTypes.desktop);

        return modal.error({
          title: 'Error',
          content: (
            <p>
              Please ensure that the <b>desktop</b> version contains content in
              all languages
            </p>
          )
        });
      }

      if (isMobileError) {
        setSelected(DeviceTypes.mobile);

        return modal.error({
          title: 'Error',
          content: (
            <p>
              Please ensure that the <b>mobile</b> version contains content in
              all languages
            </p>
          )
        });
      }

      return createConfig({
        variables: {
          input: {
            body: JSON.stringify({
              ...(data?.getConfig?.data.results?.[0]?.body || {}),
              [id]: values
            }),
            type: CONFIG_PAGES.DOCTOR_HOW_IT_WORKS
          }
        }
      });
    },
    [createConfig, data?.getConfig?.data.results, id, modal]
  );

  const onFinishError = (
    errorInfo: ValidateErrorEntity<
      {
        visible: boolean;
      } & BodyType
    >
  ) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  const onDeviceTypeChange = (key: DeviceTypes) => {
    setSelected(key);
  };

  const items: CollapseProps['items'] = LANGUAGES.map(item => {
    const hasError = errorKeys.includes(item.locale);

    return {
      key: item.key,
      label: item.label,
      forceRender: true,
      children: (
        <Panel
          header={
            <Text
              style={{
                color: `var(--${hasError ? 'error-color' : 'text-color'})`
              }}
            >
              {item.label}
              {hasError ? ' (error)' : ''}
            </Text>
          }
          key={item.locale}
          forceRender
        >
          <div
            style={{
              display: 'flex',
              rowGap: 16,
              flexDirection: 'column'
            }}
          >
            <Form.Item
              label="Content"
              name={[tab.key, item.locale, 'content']}
              rules={[{ required: true }]}
            >
              <CKEditor />
            </Form.Item>
          </div>
        </Panel>
      )
    };
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper
      back
      onClickBack={() => {
        navigate('/static-pages/doctor-how-it-works');
      }}
      title={`Doctor How It Works: ${id}`}
      extra={[
        <Private
          route={PERMISSION_ROUTES.staticContent}
          action={PERMISSION_ACTIONS.update}
          key="save-content"
        >
          <Button type="primary" onClick={form.submit} loading={isCreating}>
            Save
          </Button>
        </Private>
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishError}
        layout="vertical"
        size="large"
        disabled={isCreating}
      >
        <Tabs
          activeKey={selected}
          onChange={key => onDeviceTypeChange(key as DeviceTypes)}
          items={TABS_DATA.map(tab => ({
            label: tab.tab,
            key: tab.key,
            children: (
              <Collapse
                items={items}
                accordion
                defaultActiveKey={LANGUAGES[0].locale}
              />
            )
          }))}
        />
      </Form>
    </PageWrapper>
  );
};

export default DoctorHowItWorksContent;
