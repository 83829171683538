import { gql } from '@apollo/client';

export const GET_INVOICE_TEMPLATES = gql`
  query GetInvoiceTemplatesQuery($query: GetInvoiceTemplates) {
    getInvoiceTemplates(query: $query) {
      data {
        results {
          id
          name
          created_at
          organization_id
          keys {
            sort_index
          }
        }
        total
      }
    }
  }
`;

export const GET_INVOICE_TEMPLATE = gql`
  query GetInvoiceTemplate($id: ID!) {
    getInvoiceTemplate(id: $id) {
      data {
        created_at
        id
        name
        keys {
          key
          label
          required
          type
          template_id
          id
          sort_index
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_REPORT = gql`
  query GetAppointmentReport($query: AppointmentsInputReport) {
    getAppointmentReport(query: $query) {
      data {
        total
        results {
          id
          paid
          price
          doctor
          patient
          created_at
          doctor_paid
          doctor_invoice {
            id
            file
          }
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_REPORT_CHARTS = gql`
  query AppointmentReportCharts($query: IAppointmentReportCharts) {
    appointmentReportCharts(query: $query) {
      data {
        doctorData
        totalByDayData
        totalByOrganization
        totalBySessionDay
        totalChartsData
      }
    }
  }
`;
