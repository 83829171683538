import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_REVIEWS } from 'gql/reviews/queries';
import { Reviews } from 'gql/reviews/__generated__/Reviews';
import { Button, Empty, Row } from 'antd';

import { PageWrapper } from 'components/ui';
import { Review } from './components';

const PAGE_LIMIT = 10;

const DoctorReviews: FC = () => {
  // translations
  const { t } = useTranslation();
  // states
  const [page, setPage] = useState(0);
  const [isPaging, setPaging] = useState(false);
  // gql
  const { data, fetchMore, loading } = useQuery<Reviews>(GET_REVIEWS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        limit: PAGE_LIMIT,
        page: 0
      }
    }
  });

  const results = data?.reviews?.data.results || [];
  const total = data?.reviews?.data.total || 0;

  useEffect(() => {
    if (page) {
      setPaging(true);
      fetchMore({
        variables: {
          query: {
            page,
            limit: PAGE_LIMIT
          }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newResult = fetchMoreResult.reviews?.data.results || [];

          if (!previousResult.reviews?.data.results || !newResult) {
            return previousResult;
          }

          return {
            reviews: {
              ...previousResult.reviews,
              data: {
                ...previousResult.reviews.data,
                results: [...previousResult.reviews.data.results, ...newResult]
              }
            }
          };
        }
      }).finally(() => {
        setPaging(false);
      });
    }
  }, [fetchMore, page]);

  return (
    <PageWrapper
      title={t('doctor_reviews.page_title')}
      color="white"
      loading={loading}
    >
      {results.length ? (
        <>
          {results.map(item => (
            <Review
              key={item.id}
              rate={Number(item.rating)}
              content={item.review || ''}
              date={item.created_at}
              sender={
                item.sender
                  ? {
                      firstName: item.sender?.first_name || '',
                      lastName: item.sender?.last_name || '',
                      email: item.sender?.email
                    }
                  : null
              }
              receiver={
                item.receiver
                  ? {
                      firstName: item.receiver?.first_name || '',
                      lastName: item.receiver?.last_name || '',
                      email: item.receiver?.email
                    }
                  : null
              }
            />
          ))}
          {total > results.length && (
            <Row justify="center">
              <Button
                type="default"
                onClick={() => setPage(prev => prev + 1)}
                loading={isPaging}
              >
                {t('doctor_reviews.load_more')}
              </Button>
            </Row>
          )}
        </>
      ) : (
        <Empty />
      )}
    </PageWrapper>
  );
};

export default DoctorReviews;
