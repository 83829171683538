import { FC, useState } from 'react';
import {
  Form,
  Input,
  Select,
  InputNumber,
  Space,
  Button,
  DatePicker
} from 'antd';
import {
  SearchOutlined,
  DollarCircleOutlined,
  StarFilled,
  RightOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { IFilterMain } from './types';
import { FilterCollapsingItem } from './styled';

const { Option } = Select;

const FilterMain: FC<IFilterMain> = ({
  filterFields,
  moreFilter,
  dateFilter
}) => {
  // translations
  const { t } = useTranslation();
  // form
  const [form] = Form.useForm();
  // context
  const [collapse, setCollapse] = useState<boolean>(false);

  return (
    <Form form={form} layout="inline">
      <Form.Item name="search" style={{ width: 290, marginBottom: 24 }}>
        <Input
          placeholder={t('filter.search')}
          prefix={<SearchOutlined />}
          size="large"
        />
      </Form.Item>
      {filterFields?.map(field => (
        <Form.Item
          key={field.fieldName}
          name={field.fieldName}
          style={{ width: 200, marginBottom: 24 }}
        >
          <Select placeholder={t(field.placeholder)} size="large">
            {field.options.map(opt => (
              <Option key={opt.value} value={opt.value}>
                {opt.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ))}
      {!!moreFilter && (
        <>
          <FilterCollapsingItem expand={collapse}>
            <Form.Item name="price" style={{ width: 290, marginBottom: 24 }}>
              <Space>
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={t('filter.filter_min_price')}
                  prefix={<DollarCircleOutlined style={{ fontSize: 12 }} />}
                  size="large"
                />
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={t('filter.filter_max_price')}
                  prefix={<DollarCircleOutlined style={{ fontSize: 12 }} />}
                  size="large"
                />
              </Space>
            </Form.Item>
          </FilterCollapsingItem>
          <FilterCollapsingItem expand={collapse}>
            <Form.Item name="Rate" style={{ width: 200, marginBottom: 24 }}>
              <Select placeholder={t('filter.filter_rate')} size="large">
                <Option value="option1">
                  <StarFilled style={{ color: '#FFC200' }} /> 1
                </Option>
                <Option value="option2">
                  <StarFilled style={{ color: '#FFC200' }} /> 2
                </Option>
                <Option value="option3">
                  <StarFilled style={{ color: '#FFC200' }} /> 3
                </Option>
                <Option value="option4">
                  <StarFilled style={{ color: '#FFC200' }} /> 4 +
                </Option>
              </Select>
            </Form.Item>
          </FilterCollapsingItem>
          <Button
            size="large"
            type="text"
            style={{ fontSize: 14 }}
            onClick={() => setCollapse(prev => !prev)}
          >
            {collapse ? t('filter.filter_less') : t('filter.filter_more')}
            <RightOutlined
              style={{ fontSize: 8, position: 'relative', top: '-1px' }}
            />
          </Button>
        </>
      )}
      {dateFilter && (
        <Form.Item style={{ width: 200, marginBottom: 24 }}>
          <DatePicker size="large" style={{ width: '100%' }} />
        </Form.Item>
      )}
    </Form>
  );
};

export default FilterMain;
