import { FC } from 'react';

import { StyledIconButton } from './styled';
import { IIconButtonProps } from './types';

const IconButton: FC<IIconButtonProps> = ({
  mode = 'filled',
  children,
  onClick
}) => {
  return (
    <StyledIconButton mode={mode} onClick={onClick}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
