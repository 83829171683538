export const USER_ROLES = {
  doctor: 'doctor',
  patient: 'patient',
  translator: 'translator',
  admin: 'admin'
} as const;

export const PROFILE_STATUS = {
  waiting: 1,
  active: 2,
  disabled: 3
} as const;

// PLANS

export const PLAN_TYPE_VALUES = {
  video_call: 1,
  report: 2
};

export const PLAN_TYPE_LABELS = {
  1: 'video_call',
  2: 'report'
};

export const PLAN_TYPES = [
  {
    value: PLAN_TYPE_VALUES.video_call,
    label: 'video_call'
  },
  {
    value: PLAN_TYPE_VALUES.report,
    label: 'report'
  }
];

export const PLAN_DURATIONS = [
  {
    label: '15m',
    value: 0.25
  },
  {
    label: '30m',
    value: 0.5
  },
  {
    label: '1h',
    value: 1
  },
  {
    label: '2h',
    value: 2
  },
  {
    label: '3h',
    value: 3
  }
];

// GANDER

const GANDER_MALE_VALUE = 1;
const GANDER_FEMALE_VALUE = 2;

export const GENDERS = [
  {
    value: GANDER_MALE_VALUE,
    label: 'Male'
  },
  {
    value: GANDER_FEMALE_VALUE,
    label: 'Female'
  }
];

export const GENDERS_VALUES = {
  [GANDER_MALE_VALUE]: 'male',
  [GANDER_FEMALE_VALUE]: 'female'
};

export type GenderKeyType = keyof typeof GENDERS_VALUES;

export const USER_VERIFY = {
  phone: 2,
  email: 4,
  verified: 6
} as const;

export const VERIFY_USERS = [
  {
    value: USER_VERIFY.email,
    label: 'Email'
  },
  {
    value: USER_VERIFY.phone,
    label: 'Phone'
  },
  {
    value: USER_VERIFY.verified,
    label: 'Full Verified'
  }
];
export const LANGUAGES_USER = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'ru',
    label: 'Russian'
  },
  {
    value: 'de',
    label: 'German'
  }
];
