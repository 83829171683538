import { FC, useState, useMemo, useRef, useEffect } from 'react';
import { Row, Col, Form, Table, Input, Button } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_MEETING_LOGS } from 'gql/logs/queries';
import { ColumnProps } from 'antd/lib/table';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { useSearchParams } from 'react-router-dom';
import {
  GetMeetingLogs,
  GetMeetingLogs_getAllMeetingLogs_data_results as GetMeetingLogsResults
} from 'gql/logs/__generated__/GetMeetingLogs';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { ReloadOutlined } from '@ant-design/icons';

import { PageWrapper } from 'components/ui';
import { IPInfo } from '../components';
import { getIdStart } from 'utils/string';
import useCopyText from 'hooks/useCopyText';

const { Search } = Input;
const PAGE_LIMIT = 10;

const MeetingsLogs: FC = () => {
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') || ''
  );

  const [isRefreshing, setRefreshing] = useState(false);
  const [page, setPage] = useState(0);
  const { toggleModal } = useUIDispatchContext();
  const { ipInfo } = useModalStateContext();
  const timer = useRef<NodeJS.Timeout | null>(null);
  const { copyText } = useCopyText();

  const { data, refetch, loading } = useQuery<GetMeetingLogs>(
    GET_MEETING_LOGS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          pagination: {
            limit: PAGE_LIMIT,
            page
          },
          search: searchValue
        }
      }
    }
  );

  const results = data?.getAllMeetingLogs?.data.results || [];
  const total = data?.getAllMeetingLogs?.data.total || 0;

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setSearchValue(search);
    }, 500);
  }, [search]);

  const columns: ColumnProps<GetMeetingLogsResults>[] = useMemo(
    () => [
      {
        title: 'Room ID',
        dataIndex: 'roomID',
        key: 'roomID',
        render(value) {
          return (
            <Button type="text" onClick={() => copyText(value)}>
              {getIdStart(value)}
            </Button>
          );
        }
      },
      {
        title: 'Event',
        dataIndex: 'name',
        key: 'name',
        width: '10%'
      },
      {
        title: 'IP',
        dataIndex: 'ip',
        key: 'ip',
        width: '10%',
        render(ip) {
          if (!ip) {
            return '-';
          }

          return (
            <Button
              type="text"
              onClick={() => {
                toggleModal('ipInfo', true, { ip });
              }}
            >
              {ip}
            </Button>
          );
        }
      },
      {
        title: 'Options',
        dataIndex: 'options',
        key: 'options',
        width: '25%',
        render(item) {
          if (!item) {
            return '-';
          }

          return (
            <pre
              style={{
                maxWidth: 200,
                maxHeight: 200,
                overflow: 'auto',
                cursor: 'pointer'
              }}
              onClick={() => copyText(JSON.stringify(item, null, 4))}
            >
              {JSON.stringify(item, null, 4)}
            </pre>
          );
        }
      },
      {
        title: 'Peer ID',
        dataIndex: 'peerID',
        key: 'peerID',
        width: '10%'
      },
      {
        title: 'User Agent',
        dataIndex: 'userAgent',
        key: 'userAgent',
        width: '15%',
        render(value) {
          return <p>{value}</p>;
        }
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '10%',
        render(value) {
          return dayjs(value).format(FULL_DATE_FORMAT);
        }
      }
    ],
    [copyText, toggleModal]
  );

  const onRefresh = () => {
    setRefreshing(true);
    refetch().finally(() => {
      setRefreshing(false);
    });
  };

  return (
    <PageWrapper title="Meeting logs" color="white">
      <Row justify="space-between">
        <Col sm={8}>
          <Form.Item>
            <Search
              placeholder="Search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button
            onClick={onRefresh}
            icon={<ReloadOutlined />}
            loading={isRefreshing}
          >
            Refresh
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="_id"
        scroll={{
          x: true
        }}
        pagination={{
          total,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
      {ipInfo?.visible && <IPInfo />}
    </PageWrapper>
  );
};

export default MeetingsLogs;
