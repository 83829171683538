import { APPOINTMENT_STATUSES } from 'constants/appointments';
import { ReactNode } from 'react';

export const ActionTypes = {
  TOGGLE_MODAL: 'TOGGLE_MODAL'
} as const;

export interface IUIProps {
  children: ReactNode;
}

export type ModalTypeItems<T = void> = {
  visible: boolean;
  params?: T;
};

type ConfirmParams = {
  onConfirm: () => void;
  title?: string;
  message?: string;
  confirmText?: string;
  type?: 'success' | 'danger';
};

type BlogDetailsParams = {
  blogId: number;
};

type SpecialtyDetailsParams = {
  specialtyId: number;
};

type DetailsParams = {
  id?: string | null;
};

type RejectParams = {
  id: string;
  status:
    | typeof APPOINTMENT_STATUSES.canceled
    | typeof APPOINTMENT_STATUSES.rejected;
};

type StatusLabels = {
  [key: number]: string;
};

type UpdataeStatusParams = {
  id: string;
  currentStatus: string;
  statusList: StatusLabels;
  onSubmit: (values: { note: string; status: number }) => void | Promise<void>;
};

type DateParams = {
  id: string;
  dateId: string;
};

type IPParams = {
  ip: string;
};

type StaticElementsParams = {
  type: string;
};

export type ModalParams =
  | ConfirmParams
  | BlogDetailsParams
  | SpecialtyDetailsParams
  | DetailsParams
  | RejectParams
  | DateParams
  | IPParams
  | UpdataeStatusParams
  | StaticElementsParams;

export type ModalType = {
  confirm: ModalTypeItems<ConfirmParams>;
  addTranslator: ModalTypeItems<DetailsParams>;
  translationPlans: ModalTypeItems<DetailsParams>;
  sendNotification: ModalTypeItems<DetailsParams>;
  education: ModalTypeItems<DetailsParams>;
  experience: ModalTypeItems<DetailsParams>;
  certificate: ModalTypeItems<DetailsParams>;
  membership: ModalTypeItems<DetailsParams>;
  publication: ModalTypeItems<DetailsParams>;
  plans: ModalTypeItems<DetailsParams>;
  doctorDetails: ModalTypeItems<DetailsParams>;
  profile: ModalTypeItems;
  password: ModalTypeItems;
  services: ModalTypeItems;
  sessionPay: ModalTypeItems;
  // specialties
  specialtyModal: ModalTypeItems<DetailsParams>;
  // blog news
  blogNewsModal: ModalTypeItems<DetailsParams>;
  // appointments
  rejectAppointment: ModalTypeItems<RejectParams>;
  paymentStatus: ModalTypeItems<DetailsParams>;
  dateModal: ModalTypeItems<DateParams>;
  appointmentState: ModalTypeItems<DateParams>;
  ipInfo: ModalTypeItems<IPParams>;
  settings: ModalTypeItems;
  updateStatus: ModalTypeItems<UpdataeStatusParams>;
  staticElements: ModalTypeItems<StaticElementsParams>;
};

type ToggleModal = {
  type: typeof ActionTypes.TOGGLE_MODAL;
  data: { key: keyof ModalType; value: boolean; params?: ModalParams };
};

export type ModalState = ModalType;

export type State = {
  modal: ModalState;
};

export type Action = ToggleModal;

export type DispatchContext = {
  toggleModal: (
    key: keyof ModalType,
    value: boolean,
    params?: ModalParams
  ) => void;
};

export type Dispatch = (action: Action) => void;
