import { FC, useEffect } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { DashboardTourProvider } from 'providers/Tour';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTour } from 'store/tour/slice';
import { selectShowTourItem } from 'store/tour/selectors';

import { IPrivateLayoutProps } from './types';
import { StyledMainContent, StyledMainLayout } from './styled';
import { Header, Sidebar } from './components';

const ROUTES_TO_HIDE_ALL = ['/pdf-viewer'];

const PrivateLayout: FC<IPrivateLayoutProps> = ({ sidebarMenu, children }) => {
  const { pathname } = useLocation();
  const shownTour = useAppSelector(selectShowTourItem('dashboard'));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTour({ key: 'dashboard', value: !shownTour }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pathname]);

  if (ROUTES_TO_HIDE_ALL.find(item => pathname.includes(item))) {
    return <>{children}</>;
  }

  return (
    <StyledMainLayout>
      <Header />
      <Layout>
        <DashboardTourProvider>
          <Sidebar sidebarMenu={sidebarMenu} />
          <StyledMainContent>{children}</StyledMainContent>
        </DashboardTourProvider>
      </Layout>
    </StyledMainLayout>
  );
};

export default PrivateLayout;
