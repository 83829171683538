import en from 'antd/lib/locale/en_US';
import de from 'antd/lib/locale/de_DE';
import { LocaleType } from 'constants/languages';
import { RTL_LANGUAGES } from 'constants/translations';

export const getFormLang = (lang: string) => {
  switch (lang) {
    case 'en':
      return en;
    case 'de':
      return de;
    default:
      return en;
  }
};

export const isRTL = (language: LocaleType) => {
  return RTL_LANGUAGES.includes(language);
};

export const getInputDir = (language: LocaleType) =>
  isRTL(language) ? 'rtl' : 'ltr';
