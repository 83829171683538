/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { useOrganizationsStateContext } from 'providers/Organizations';
import { App, Typography } from 'antd';

interface IProps {
  children: ReactNode;
}

const OrganizationAlert: FC<IProps> = ({ children }) => {
  const { modal } = App.useApp();
  const { parentSelected } = useOrganizationsStateContext();

  const showAlert = () => {
    modal.confirm({
      title: 'Warning',
      content: (
        <Typography.Text>
          Please choose an organization to proceed with this action
        </Typography.Text>
      ),
      type: 'warning',
      cancelButtonProps: {
        style: {
          display: 'none'
        }
      }
    });
  };

  const onClick: MouseEventHandler<HTMLElement> = e => {
    if (parentSelected === 'all') {
      return showAlert();
    }

    // @ts-ignore
    return children.props?.onClick?.(e);
  };

  return (
    <>
      {React.Children.map(children, child => {
        // @ts-ignore
        if (child?.props?.to) {
          if (parentSelected === 'all') {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { to, ...rest } = child.props || {};

            // @ts-ignore
            return React.createElement('a', {
              // @ts-ignore
              ...rest,
              onClick: showAlert
            });
          }

          return child;
        }

        // @ts-ignore
        return React.cloneElement(child, {
          // @ts-ignore
          ...child.props,
          onClick
        });
      })}
    </>
  );
};

export default OrganizationAlert;
