import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Space, Select, Button, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PLAN_TYPES } from 'constants/users';
import { ADMIN_PAID, APPOINTMENT_STATUSES } from 'constants/appointments';

import { FormFilters, IFiltersProps } from './types';

const defaultValues = {
  name: '',
  type: '',
  status: '',
  admin_paid: ''
};

const Filters: FC<IFiltersProps> = ({ onSubmit, initialValues }) => {
  // translations
  const { t } = useTranslation();
  // form
  const [form] = Form.useForm<FormFilters>();

  const onFinish = (values: FormFilters) => {
    onSubmit(values);
  };

  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish}>
      <Space size={8}>
        <Form.Item name="name">
          <Input
            placeholder={t('filter.search')}
            prefix={<SearchOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item name="type">
          <Select
            size="large"
            placeholder={t('filter.type')}
            style={{ width: 200 }}
            options={PLAN_TYPES.map(item => ({
              value: `${item.value}`,
              label: t(`appointments.${item.label}`)
            }))}
          />
        </Form.Item>
        <Form.Item name="status">
          <Select
            size="large"
            placeholder="Status"
            style={{ width: 200 }}
            options={Object.entries(APPOINTMENT_STATUSES).map(
              ([key, value]) => ({
                value: `${value}`,
                label: t(`appointments.${key}`)
              })
            )}
          />
        </Form.Item>
        <Form.Item name="admin_paid">
          <Select
            size="large"
            placeholder="Admin Paid"
            style={{ width: 200 }}
            options={ADMIN_PAID.map(item => ({
              value: item.value,
              label: item.label
            }))}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" onClick={form.submit}>
            Apply
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            onClick={() => {
              form.setFieldsValue({
                name: undefined,
                type: undefined,
                status: undefined,
                admin_paid: undefined
              });
              onSubmit({ ...defaultValues });
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default memo(Filters);
