import {
  FC,
  createContext,
  useReducer,
  useContext,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { GET_USER_PERMISSIONS } from 'gql/roles/queries';
import { ADMIN_USER_ID } from 'constants/permissions';
import { UserPermissions } from 'gql/roles/__generated__/UserPermissions';
import instance from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserIsLoading } from 'store/user/selectors';
import { setIsLoggedIn, setUser, setUserLoading } from 'store/user/slice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  State,
  Dispatch,
  ActionTypes,
  DispatchContext,
  IAuthProps,
  PermissionsState,
  LoginForm
} from './types';
import reducer from './reducers';
import { Loading } from 'components/ui';
import {
  parentOrgStorage,
  refreshTokenStorage,
  tokenStorage
} from 'utils/storage';
import { BlockedModal } from 'components/shared';

const initialState: State = {
  isBlockedVisible: false,
  tmpToken: ''
};

const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<
  { dispatch: Dispatch; getUserData(): void; signOut(): void } | undefined
>(undefined);

const PermissionsStateContext = createContext<PermissionsState>({});

const AuthProvider: FC<IAuthProps> = ({ children }) => {
  const client = useApolloClient();
  const isLoading = useAppSelector(selectUserIsLoading);
  const dispatchRedux = useAppDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const signOut = useCallback(async () => {
    instance.get('/auth/logout', {
      headers: {
        Authorization: `Bearer ${tokenStorage.get()}`
      }
    });
    tokenStorage.remove();
    refreshTokenStorage.remove();
    parentOrgStorage.remove();
    dispatchRedux(setUserLoading(false));
    dispatchRedux(setIsLoggedIn(false));
    dispatchRedux(setUser(null));
    client.clearStore();
  }, [client, dispatchRedux]);

  const [getUserPermissions, { data }] = useLazyQuery<UserPermissions>(
    GET_USER_PERMISSIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted() {
        dispatchRedux(setIsLoggedIn(true));
        dispatchRedux(setUserLoading(false));
      },
      onError() {
        signOut();
      }
    }
  );

  const getUserData = useCallback(async () => {
    try {
      dispatchRedux(setUserLoading(true));
      const res = await instance.get('/auth/me');

      if (res?.data?.data) {
        dispatchRedux(setUser(res.data.data));
      }

      if (`${res?.data?.data?.id}` !== ADMIN_USER_ID) {
        getUserPermissions();
      } else {
        dispatchRedux(setIsLoggedIn(true));
        dispatchRedux(setUserLoading(false));
      }
    } catch {
      signOut();
    }
  }, [dispatchRedux, getUserPermissions, signOut]);

  useEffect(() => {
    if (tokenStorage.get()) {
      getUserData();
    }
  }, [getUserData]);

  const permissionValues = useMemo(
    () =>
      data?.userPermissions?.data?.reduce<Record<string, number>>(
        (acc, val) => ({
          ...acc,
          [`${val.section}`]: val.value
        }),
        {}
      ),
    [data?.userPermissions?.data]
  );

  const closeModal = () => {
    dispatch({
      type: ActionTypes.SET_BLOCKED_VISIBLE,
      data: false
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={{ signOut, getUserData, dispatch }}>
        <PermissionsStateContext.Provider value={permissionValues || {}}>
          {children}
          <BlockedModal open={state.isBlockedVisible} onClose={closeModal} />
        </PermissionsStateContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthStateContext = (): State => {
  const context = useContext(AuthStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAuthStateContext must be used within a useAuthStateContext'
    );
  }

  return context;
};

const useAuthDispatchContext = (): DispatchContext => {
  const context = useContext(AuthDispatchContext);
  const dispatchRedux = useAppDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  if (typeof context === 'undefined') {
    throw new Error(
      'useAuthDispatchContext must be used within a useAuthDispatchContext'
    );
  }

  const { dispatch, signOut, getUserData } = context;

  const refetchUserData = useCallback(async () => {
    try {
      const res = await instance.get('/auth/me');

      if (res?.data?.data) {
        dispatchRedux(setUser(res.data.data));
      }
    } catch {
      //
    }
  }, [dispatchRedux]);

  const signIn = useCallback(
    async (params: LoginForm) => {
      try {
        if (!executeRecaptcha) {
          dispatch({
            type: ActionTypes.SET_BLOCKED_VISIBLE,
            data: true
          });

          return Promise.reject('');
        }

        const recaptchaToken = await executeRecaptcha('login');

        const { data } = await instance.post(
          '/auth/sign-in',
          {
            ...params,
            recaptchaToken,
            role: 'admin'
          },
          {
            withCredentials: true
          }
        );

        dispatch({
          type: ActionTypes.SET_TMP_TOKEN,
          data: data.data.tmpToken
        });

        return data.data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.response?.status === 429) {
          dispatch({
            type: ActionTypes.SET_BLOCKED_VISIBLE,
            data: true
          });

          return Promise.reject(err);
        }

        return Promise.reject(err);
      }
    },
    [dispatch, executeRecaptcha]
  );

  const verify2FA = useCallback(async (otp: string, tmpToken: string) => {
    try {
      const data = await instance.post('/auth/otp/verify', {
        otp,
        token: tmpToken
      });

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  return { signOut, signIn, verify2FA, getUserData, refetchUserData };
};

const usePermissionsStateContext = (): PermissionsState => {
  const context = useContext(PermissionsStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'usePermissionsStateContext must be used within a PermissionsStateContext'
    );
  }

  return context;
};

export default AuthProvider;
export {
  useAuthDispatchContext,
  useAuthStateContext,
  usePermissionsStateContext
};
