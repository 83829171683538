import { useQuery } from '@apollo/client';
import { Progress, Typography } from 'antd';
import { FetchAttributeGroups } from 'gql/attributes/__generated__/FetchAttributeGroups';
import { GET_ATTRIBUTE_GROUPS } from 'gql/attributes/queries';
import { DoctorById } from 'gql/doctors/__generated__/DoctorById';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { FC, useMemo } from 'react';

interface IProps {
  id: string;
  lang: string;
}

const VALUES_PER_SECTION = {
  personalInfo: 20,
  about: 10,
  plans: 20,
  rest: 50
};

const ProfileCompleteness: FC<IProps> = ({ id, lang }) => {
  const { data: profileData } = useQuery<DoctorById>(GET_DOCTOR_PROFILE, {
    fetchPolicy: 'cache-only',
    variables: {
      id,
      lang
    }
  });

  const { data: attributeGroupsData } = useQuery<FetchAttributeGroups>(
    GET_ATTRIBUTE_GROUPS,
    {
      fetchPolicy: 'cache-only'
    }
  );

  const profile = profileData?.doctorById.data;
  const attributeGroups = attributeGroupsData?.fetchAttributeGroups?.data;

  const percent = useMemo(() => {
    let percent = 0;
    const personalInfoCompleted = [
      profile?.avatar,
      profile?.first_name,
      profile?.last_name,
      profile?.gender,
      profile?.timezone,
      profile?.phone,
      profile?.email,
      profile?.address,
      profile?.languages?.length
    ].every(item => item);

    if (personalInfoCompleted) {
      percent += VALUES_PER_SECTION.personalInfo;
    }

    if (profile?.about) {
      percent += VALUES_PER_SECTION.about;
    }

    if (profile?.plans?.length) {
      percent += VALUES_PER_SECTION.plans;
    }

    const eachItemPercent = attributeGroups?.length
      ? VALUES_PER_SECTION.rest / Number(attributeGroups?.length)
      : 0;

    const groupsLength = profile?.groups.length || 0;

    return Math.round(percent + eachItemPercent * groupsLength);
  }, [profile, attributeGroups?.length]);

  const color = useMemo(() => {
    if (percent >= 90) {
      return {
        strokeColor: '#35b678',
        trailColor: 'rgba(53, 182, 120, 0.1)'
      };
    }

    if (percent >= 50) {
      return {
        strokeColor: '#FF7F4E',
        trailColor: 'rgba(255, 127, 78, 0.1)'
      };
    }

    return {
      strokeColor: '#ff4b4e',
      trailColor: 'rgba(255, 75, 78, 0.1)'
    };
  }, [percent]);

  return (
    <div style={{ marginTop: 40 }}>
      <Typography.Paragraph style={{ margin: 0 }} strong>
        Profile {percent}% completed
      </Typography.Paragraph>
      <Progress
        percent={percent}
        trailColor={color.trailColor}
        strokeColor={color.strokeColor}
      />
    </div>
  );
};

export default ProfileCompleteness;
