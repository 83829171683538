import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Space, Button, Form, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { FormFilters, IFiltersProps } from './types';

const defaultValues = {
  name: undefined,
  patientPaid: ''
};

const Filters: FC<IFiltersProps> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormFilters>();

  const onFinish = (values: FormFilters) => {
    onSubmit(values);
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      size="large"
      onFinish={onFinish}
    >
      <Space size={8}>
        <Form.Item name="name">
          <Input placeholder={t('filter.search')} prefix={<SearchOutlined />} />
        </Form.Item>
        <Form.Item name="patientPaid">
          <Select
            placeholder="Patient paid"
            style={{ width: 221 }}
            options={[
              {
                label: 'Not paid',
                value: '0'
              },
              {
                label: 'Paid',
                value: '1'
              }
            ]}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={form.submit}>
            Apply
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            onClick={() => {
              form.setFieldsValue({ ...defaultValues });
              onSubmit({ ...defaultValues });
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default memo(Filters);
