export const PAYMENT_BEHAVIOR_BY_ORGANIZATION = {
  NO_PAYMENT: 1,
  FULL_PAYMENT: 2,
  PERCENT_PAY: 3
  // OTHER_PAY: 4,
  // UNKNOWN: 5
} as const;

export const PAYMENT_BEHAVIOR_LABEL = {
  [PAYMENT_BEHAVIOR_BY_ORGANIZATION.NO_PAYMENT]: 'No Payment',
  [PAYMENT_BEHAVIOR_BY_ORGANIZATION.FULL_PAYMENT]: 'Full Payment',
  [PAYMENT_BEHAVIOR_BY_ORGANIZATION.PERCENT_PAY]: 'Percent Pay'
  // [PAYMENT_BEHAVIOR_BY_ORGANIZATION.OTHER_PAY]: 'Other Pay',
  // [PAYMENT_BEHAVIOR_BY_ORGANIZATION.UNKNOWN]: 'Unknown'
} as const;
