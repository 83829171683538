// STATUSES

export const APPOINTMENT_STATUS_AWAITING_CONFIRMATION = 1;
export const APPOINTMENT_STATUS_APPROVED = 2;
export const APPOINTMENT_STATUS_STARTED = 3;
export const APPOINTMENT_STATUS_COMPlETED = 4;
export const APPOINTMENT_STATUS_CANCELED = 5;
export const APPOINTMENT_STATUS_REJECTED = 6;
export const APPOINTMENT_STATUS_FINISHED = 7;

export const APPOINTMENT_STATUSES = {
  awaiting_confirmation: APPOINTMENT_STATUS_AWAITING_CONFIRMATION,
  approved: APPOINTMENT_STATUS_APPROVED,
  started: APPOINTMENT_STATUS_STARTED,
  completed: APPOINTMENT_STATUS_COMPlETED,
  canceled: APPOINTMENT_STATUS_CANCELED,
  rejected: APPOINTMENT_STATUS_REJECTED,
  finished: APPOINTMENT_STATUS_FINISHED
} as const;

export const APPOINTMENT_STATUS_VALUES = {
  [APPOINTMENT_STATUS_AWAITING_CONFIRMATION]: 'awaiting_confirmation',
  [APPOINTMENT_STATUS_APPROVED]: 'approved',
  [APPOINTMENT_STATUS_STARTED]: 'started',
  [APPOINTMENT_STATUS_COMPlETED]: 'completed',
  [APPOINTMENT_STATUS_CANCELED]: 'canceled',
  [APPOINTMENT_STATUS_REJECTED]: 'rejected',
  [APPOINTMENT_STATUS_FINISHED]: 'finished'
};

export type AppointmentStatusType = keyof typeof APPOINTMENT_STATUS_VALUES;

// CREATORS

export const APPOINTMENT_CREATOR_ADMIN = 1;
export const APPOINTMENT_CREATOR_PATIENT = 2;
export const APPOINTMENT_CREATOR_DOCTOR = 3;

export const APPOINTMENT_CREATOR = {
  admin: APPOINTMENT_CREATOR_ADMIN,
  patient: APPOINTMENT_CREATOR_PATIENT,
  doctor: APPOINTMENT_CREATOR_DOCTOR
} as const;

export const ADMIN_PAID = [
  {
    value: '0',
    label: 'Not Paid'
  },
  {
    value: '1',
    label: 'Paid'
  }
];

// STATE

export const APPOINTMENT_STATE_PENDING_VALUE = 1;
export const APPOINTMENT_STATE_PENDING_LABEL = 'Pending';
export const APPOINTMENT_STATE_APPROVED_VALUE = 2;
export const APPOINTMENT_STATE_APPROVED_LABEL = 'Approved';
export const APPOINTMENT_STATE_REJECTED_VALUE = 3;
export const APPOINTMENT_STATE_REJECTED_LABEL = 'Rejected';
export const APPOINTMENT_STATE_MODERATE_VALUE = 4;
export const APPOINTMENT_STATE_MODERATE_LABEL = 'Moderate';

export const APPOINTMENT_STATES = {
  [APPOINTMENT_STATE_PENDING_VALUE]: APPOINTMENT_STATE_PENDING_LABEL,
  [APPOINTMENT_STATE_APPROVED_VALUE]: APPOINTMENT_STATE_APPROVED_LABEL,
  [APPOINTMENT_STATE_REJECTED_VALUE]: APPOINTMENT_STATE_REJECTED_LABEL,
  [APPOINTMENT_STATE_MODERATE_VALUE]: APPOINTMENT_STATE_MODERATE_LABEL
} as const;

export const APPOINTMENT_STATES_COLOR = {
  [APPOINTMENT_STATE_PENDING_VALUE]: 'yellow',
  [APPOINTMENT_STATE_APPROVED_VALUE]: 'green',
  [APPOINTMENT_STATE_REJECTED_VALUE]: 'red',
  [APPOINTMENT_STATE_MODERATE_VALUE]: 'yellow'
} as const;

export type AppointmentStateType = keyof typeof APPOINTMENT_STATES;

export const FAST_APPOINTMENTS_STATUS = {
  new: 1,
  replied: 2,
  in_process: 6,
  rejected: 3,
  succeed: 4,
  canceled: 5
} as const;

export const FAST_APPOINTMENTS_COLOR = {
  [FAST_APPOINTMENTS_STATUS.new]: '#FF5733',
  [FAST_APPOINTMENTS_STATUS.replied]: '#2196F3',
  [FAST_APPOINTMENTS_STATUS.rejected]: '#E71D36',
  [FAST_APPOINTMENTS_STATUS.succeed]: '#36A64F',
  [FAST_APPOINTMENTS_STATUS.canceled]: '#777777',
  [FAST_APPOINTMENTS_STATUS.in_process]: '#faab1e'
} as const;

export const FAST_APPOINTMENTS_LABEL = {
  [FAST_APPOINTMENTS_STATUS.new]: 'New',
  [FAST_APPOINTMENTS_STATUS.replied]: 'Replied',
  [FAST_APPOINTMENTS_STATUS.rejected]: 'Rejected',
  [FAST_APPOINTMENTS_STATUS.succeed]: 'Succeed',
  [FAST_APPOINTMENTS_STATUS.canceled]: 'Canceled',
  [FAST_APPOINTMENTS_STATUS.in_process]: 'In process'
} as const;

export type FastAppointmentValues = keyof typeof FAST_APPOINTMENTS_LABEL;

export const MEDICAL_TREATMENT_STATUS = {
  new: 1,
  contacted: 2,
  in_process: 6,
  visa_invitation_sent: 3,
  completed: 4,
  canceled: 5,
  waiting_for_arrival: 7
} as const;

export const MEDICAL_TREATMENT_COLOR = {
  [MEDICAL_TREATMENT_STATUS.new]: '#FF5733',
  [MEDICAL_TREATMENT_STATUS.contacted]: '#2196F3',
  [MEDICAL_TREATMENT_STATUS.visa_invitation_sent]: '#E71D36',
  [MEDICAL_TREATMENT_STATUS.completed]: '#36A64F',
  [MEDICAL_TREATMENT_STATUS.canceled]: '#777777',
  [MEDICAL_TREATMENT_STATUS.in_process]: '#faab1e',
  [MEDICAL_TREATMENT_STATUS.waiting_for_arrival]: '#E633FF'
} as const;

export const MEDICAL_TREATMENT_LABEL = {
  [MEDICAL_TREATMENT_STATUS.new]: 'New',
  [MEDICAL_TREATMENT_STATUS.contacted]: 'Replied',
  [MEDICAL_TREATMENT_STATUS.visa_invitation_sent]: 'Visa Invitation Sent',
  [MEDICAL_TREATMENT_STATUS.completed]: 'Completed',
  [MEDICAL_TREATMENT_STATUS.canceled]: 'Canceled',
  [MEDICAL_TREATMENT_STATUS.in_process]: 'In process',
  [MEDICAL_TREATMENT_STATUS.waiting_for_arrival]: 'Waiting for Arrival'
} as const;

export type MedicalTreatmentValues = keyof typeof MEDICAL_TREATMENT_LABEL;
