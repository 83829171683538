import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  SetTour,
  UpdateToursVersion,
  ShowToursType,
  ToursStepsType,
  ToursType
} from './types';

const initialState: ToursType = {
  shownTours: {
    dashboard: false
  },
  toursSteps: {
    dashboard: 0
  },
  toursVersion: {
    dashboard: ''
  }
};

const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setTour: (state, action: PayloadAction<SetTour>) => {
      const { key, value } = action.payload;

      if (value) {
        if (state.toursSteps[key] === -1) return;
        Object.keys(state.shownTours).forEach(item => {
          state.shownTours[item as keyof ShowToursType] = false;
        });
      }

      state.shownTours[key] = value;
    },

    setTourStep: (state, action: PayloadAction<Partial<ToursStepsType>>) => {
      state.toursSteps = { ...state.toursSteps, ...action.payload };
    },

    setFinishedTours: (state, action: PayloadAction<keyof ToursStepsType>) => {
      state.toursSteps[action.payload] = -1;
    },
    updateToursVersion: (state, action: PayloadAction<UpdateToursVersion>) => {
      state.toursVersion[action.payload.key] = action.payload.value;
    }
  }
});

export const { setTour, setTourStep, setFinishedTours, updateToursVersion } =
  toursSlice.actions;
export default toursSlice.reducer;
