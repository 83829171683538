import { gql } from '@apollo/client';

export const ORGANIZATION_MINI_FRAGMENT = gql`
  fragment OrganizationMiniFragment on Organization {
    id
    name
    image
    created_at
  }
`;

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    ...OrganizationMiniFragment
    additional_info
    settings
  }
  ${ORGANIZATION_MINI_FRAGMENT}
`;
