import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Row, Col } from 'antd';
import { ReactComponent as LogoDark } from 'assets/img/logo_dark.svg';

import { AuthWrapper, AuthContent } from './styled';
import useIsMobile from 'hooks/useIsMobile';

const Auth: FC = () => {
  const isMobile = useIsMobile();

  return (
    <AuthWrapper $leftSiderHidden={isMobile}>
      <AuthContent $leftSiderHidden>
        <Row justify="center">
          <Col xs={20} md={8}>
            <LogoDark style={{ marginBottom: 32 }} />
            <Outlet />
          </Col>
        </Row>
      </AuthContent>
    </AuthWrapper>
  );
};

export default Auth;
