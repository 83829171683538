import { Modal, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NotificationModal: FC = () => {
  // translations
  const { t } = useTranslation();
  // form
  const [form] = Form.useForm();
  // context
  const { sendNotification } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();

  const closeModal = () => {
    form.resetFields();
    toggleModal('sendNotification', false);
  };

  const onFinish = (values: unknown) => {
    console.log('value', values);
  };

  return (
    <Modal
      title={t('messages.send_notification')}
      open={sendNotification.visible}
      onCancel={closeModal}
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="title"
          label={t('messages.notification_title')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="message"
          label={t('messages.notification_message')}
          rules={[{ required: true }]}
        >
          <TextArea style={{ height: 130 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotificationModal;
