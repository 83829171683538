import { Tabs } from 'antd';
import styled from 'styled-components';

export const CustomTabs = styled(Tabs)`
  margin-top: 44px;
  width: 100%;
  .ant-tabs-nav {
    margin-bottom: 24px;
  }
  .ant-tabs-tab-btn {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  height: 90%;
  width: 100%;
  .chartdiv {
    width: 100%;
    height: 100%;
  }
  .remove_logo {
    position: absolute;
    width: 100%;
    height: 19px;
    background: white;
    bottom: 0;
  }
`;
