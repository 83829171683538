import { FC, useState } from 'react';
import { App, Image, Space, Spin } from 'antd';
import axios from 'axios';
import instance from 'services/api';

import { downloadFileFromUrl, getFileIcon, getFileTypeName } from 'utils/file';

interface IProps {
  value: string;
  patientId?: string | null;
}

const DownloadFile: FC<IProps> = ({ value, patientId }) => {
  const { message } = App.useApp();
  const [isLoading, setLoading] = useState(false);

  const downloadFile = async () => {
    try {
      setLoading(true);
      const res = await instance.get(
        `storage/file/url/${value}?user_id=${patientId}`
      );

      const { url } = res.data.data;

      downloadFileFromUrl(url, value);
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message.error(error.response.data.message || 'Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
      <Space>
        <Image
          src={getFileIcon(getFileTypeName(value))}
          width={32}
          preview={false}
          style={{ paddingRight: 8 }}
        />
        {isLoading ? (
          <Spin size="small" />
        ) : (
          <a onClick={downloadFile} style={{ marginLeft: 4 }}>
            {value}
          </a>
        )}
      </Space>
    </Space>
  );
};

export default DownloadFile;
