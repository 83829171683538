import { FC } from 'react';
import { App, Card, Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useUIDispatchContext } from 'providers/UI';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { DELETE_PLAN } from 'gql/plans/mutations';
import { PLAN_TYPE_LABELS } from 'constants/users';
import client from 'apolloClient';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';

import { IPlanItemProps } from './types';
import { StyledList, StyledPrice } from './styled';
import { IconButton } from 'components/ui';
import { money } from 'utils/price';
import { parseJson } from 'utils/json';
import { showErrorMessage } from 'utils/showErrorMessage';
import { Private } from 'components/shared';

const { Paragraph } = Typography;
const { Meta } = Card;

const PlanItem: FC<IPlanItemProps> = ({
  id,
  name,
  type,
  duration,
  description,
  price,
  features
}) => {
  // ui
  const { toggleModal } = useUIDispatchContext();
  const { modal } = App.useApp();
  // graphql
  const [deletePlan] = useMutation(DELETE_PLAN, {
    onCompleted() {
      client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
    },
    onError: err => showErrorMessage(err)
  });

  const onDeleteClick = () => {
    modal.confirm({
      title: <>{t('common.confirm')}</>,
      content: <>{t('common.sure')}</>,
      okText: <>{t('common.yes')}</>,
      cancelText: <>{t('common.no')}</>,
      onOk: () => {
        deletePlan({ variables: { id } });
      }
    });
  };

  const featuresArr = parseJson(features) || [];
  const subtitle = `${t(`doctor_profile.${PLAN_TYPE_LABELS[type]}`)}${
    duration ? ` | ${duration}h` : ''
  }`;

  return (
    <Card
      title={name}
      style={{ height: '100%' }}
      extra={[
        <Private
          route={PERMISSION_ROUTES.plan}
          action={PERMISSION_ACTIONS.update}
          key={`plan-item-edit-${id}`}
        >
          <IconButton
            mode="transparent"
            onClick={() => toggleModal('plans', true, { id })}
          >
            <EditOutlined />
          </IconButton>
        </Private>,
        <Private
          route={PERMISSION_ROUTES.plan}
          action={PERMISSION_ACTIONS.delete}
          key={`plan-item-delete-${id}`}
        >
          <IconButton
            key={`plan-item-delete-${id}`}
            mode="transparent"
            onClick={onDeleteClick}
          >
            <DeleteOutlined style={{ color: 'var(--danger-color)' }} />
          </IconButton>
        </Private>
      ]}
    >
      <Meta description={subtitle} />
      <Paragraph style={{ marginTop: 16 }}>{description}</Paragraph>
      <StyledPrice>{money(price)}</StyledPrice>
      {!!featuresArr.length && (
        <StyledList>
          {featuresArr.map(
            (item: string, index: number) =>
              !!item.length && <li key={`feature-item-${index}`}>{item}</li>
          )}
        </StyledList>
      )}
    </Card>
  );
};

export default PlanItem;
