import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Col,
  Collapse,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
  UploadFile
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { LANGUAGES } from 'constants/languages';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';
import { GET_STORY_BY_ID } from 'gql/stories/queries';
import { CREATE_STORY, UPDATE_STORY } from 'gql/stories/mutation';
import { STORIES_STATUSES_ARRAY, STORIES_TYPES_ARRAY } from 'constants/stories';
import { useNavigate } from 'react-router-dom';

import { CKEditor } from 'components/shared';
import { Loading, PageWrapper } from 'components/ui';
import Dragger from 'components/shared/Dragger';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';
import { getInputDir } from 'utils/helpers';

import {
  GetStoryByIdForAdminQuery,
  MutationCreateStoryArgs
} from '@/generated/graphql';

const { Title, Paragraph, Text } = Typography;

const itemValues = {
  title: '',
  content: '',
  thumbnail: '',
  img: '',
  type: '',
  status: '',
  link: ''
};

type ValuesType = typeof itemValues;
type BodyType = Record<string, ValuesType>;

const StoriesDetails: FC = () => {
  const [errorKeys, setErrorKeys] = useState<string[]>([]);

  const [image, setImage] =
    useState<UploadChangeParam<UploadFile<{ data: string }>>>();

  const [thumbnail, setThumbnail] =
    useState<UploadChangeParam<UploadFile<{ data: string }>>>();

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  const storyId = params?.id;

  const { data, loading: isFetching } = useQuery<GetStoryByIdForAdminQuery>(
    GET_STORY_BY_ID,
    {
      fetchPolicy: 'no-cache',
      skip: !storyId,
      variables: {
        getStoryByIdForAdminId: storyId
      }
    }
  );

  const result = data?.getStoryByIdForAdmin?.data;

  const [createStory, { loading: isCreating }] =
    useMutation<MutationCreateStoryArgs>(CREATE_STORY, {
      onError: err => showErrorMessage(err),
      onCompleted: () => navigate('/stories')
    });

  const [updateStory, { loading: isUpdating }] = useMutation(UPDATE_STORY, {
    onError: err => showErrorMessage(err),
    onCompleted: () => navigate('/stories')
  });

  const onFinish = useCallback(
    async (values: BodyType) => {
      delete values.doctors;

      setErrorKeys([]);
      const input = {
        ...values,
        img: image?.file?.response?.data || result?.img,
        thumbnail: thumbnail?.file?.response?.data || result?.thumbnail,
        type: +values.type,
        status: +values.status
      };

      if (storyId) {
        await updateStory({
          variables: {
            updateStoryId: storyId,
            input
          }
        });
      } else {
        await createStory({
          variables: {
            input
          }
        });
      }
    },
    [
      image?.file?.response?.data,
      result?.img,
      result?.thumbnail,
      thumbnail?.file?.response?.data,
      storyId,
      updateStory,
      createStory
    ]
  );

  const onFinishError = (errorInfo: ValidateErrorEntity<BodyType>) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  return (
    <PageWrapper
      title={storyId ? t('permissions.UPDATE') : t('permissions.CREATE')}
      extra={[
        <Button
          type="primary"
          key="save-service"
          loading={isCreating || isUpdating}
          onClick={form.submit}
          size="large"
        >
          {t('common.save')}
        </Button>
      ]}
    >
      {isFetching ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishError}
          layout="vertical"
          size="large"
          initialValues={result}
          scrollToFirstError
        >
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                label={t('stories.image')}
                name="img"
                valuePropName="img"
                rules={[{ required: true }]}
              >
                <Dragger
                  listType="picture"
                  maxCount={1}
                  multiple={false}
                  accept={'image/jpeg, image/png'}
                  onChange={info => {
                    setImage(info);
                  }}
                  defaultFileList={
                    result?.img
                      ? [
                          {
                            name: result.img || '',
                            uid: result.img || '',
                            thumbUrl: getFileUrl(result?.img)
                          }
                        ]
                      : []
                  }
                >
                  <Flex vertical align="center">
                    <CloudUploadOutlined
                      style={{
                        color: 'var(--primary-color-100)',
                        fontSize: 45
                      }}
                    />
                    <Title level={4}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('stories.drag_drop_files_or_browse')
                        }}
                      />
                    </Title>
                    <Paragraph style={{ color: 'var(--text-color-80)' }}>
                      {t('stories.supported_formats')}
                    </Paragraph>
                  </Flex>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }} gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name="link"
                label={t('stories.link')}
                rules={[{ required: true }]}
              >
                <Input addonBefore="https://" type="link" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label={t('stories.type')}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  options={STORIES_TYPES_ARRAY.map(item => ({
                    ...item,
                    label: t(`stories.${item.label}`)
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                label={t('stories.status')}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  options={STORIES_STATUSES_ARRAY.map(item => ({
                    ...item,
                    label: t(`stories.${item.label}`)
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="thumbnail"
                label={t('stories.upload_thumbnail')}
                rules={[{ required: true }]}
                valuePropName="thumbnail"
              >
                <Dragger
                  style={{ padding: 0, border: 'none' }}
                  listType="picture"
                  maxCount={1}
                  multiple={false}
                  accept={'image/jpeg, image/png'}
                  onChange={info => {
                    setThumbnail(info);
                  }}
                  defaultFileList={
                    result?.thumbnail
                      ? [
                          {
                            name: result.thumbnail || '',
                            uid: result?.thumbnail || '',
                            thumbUrl: getFileUrl(result?.thumbnail)
                          }
                        ]
                      : []
                  }
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ width: '100%', overflow: 'hidden' }}
                  >
                    <Text ellipsis>{t('stories.upload_thumbnail')}</Text>
                  </Button>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Collapse
            accordion
            destroyInactivePanel={false}
            defaultActiveKey={LANGUAGES[0].locale}
            items={LANGUAGES.map(item => {
              const hasError = errorKeys.includes(item.locale);

              return {
                key: item.locale,
                forceRender: true,
                label: (
                  <Text
                    style={{
                      color: `var(--${hasError ? 'error-color' : 'text-color'})`
                    }}
                  >
                    {item.label}
                  </Text>
                ),
                children: (
                  <>
                    <Form.Item
                      name={['title', item.locale]}
                      label={t('stories.title')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={['content', item.locale]}
                      label={t('stories.content')}
                      rules={[{ required: true }]}
                    >
                      <CKEditor language={item.locale} />
                    </Form.Item>
                  </>
                )
              };
            })}
          />
        </Form>
      )}
    </PageWrapper>
  );
};

export default StoriesDetails;
