import styled from 'styled-components';
import { Typography, Card, Button } from 'antd';

const { Text } = Typography;

export const StyledCard = styled(Card)`
  .ant-card-cover {
    border-radius: 2px 2px 0 0;
    overflow: hidden;
  }

  .ant-card-body {
    padding-bottom: 0;
  }
`;

export const Cover = styled.div<{ onClick?: () => void }>`
  background-image: url('/images/card-cover.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f3f4f5; // secondary-color
  height: 0;
  padding-bottom: 62.5%;
  border-radius: 2px 2px 0 0;
  will-change: transform;
  transition: transform 0.3s;

  ${({ onClick }) =>
    !!onClick &&
    `
    cursor: pointer;

    &:hover {
      transform: scale(1.01);
    }
  `}
`;

export const CardName = styled(Text)`
  font-weight: 400;
`;

export const RatingBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 8px;
`;

export const CardActionAvatar = styled.div`
  line-height: 1;
  border-top: 1px solid var(--border-color-base);
  padding: 12px 24px 10px;
  margin: 0 -24px;
`;

export const BtnAvatarAction = styled(Button)`
  border: 0;
  box-shadow: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  margin-left: -18px;
  && {
    transition: opacity 0.3s;
    color: var(--text-color);
    &:hover {
      color: var(--text-color);
      opacity: 0.85;
    }
  }
`;
