import { Button, Card, Flex } from 'antd';
import styled from 'styled-components';

export const CardItem = styled(Card)`
  .ant-card-body {
    padding: 16px;
  }
`;

export const Status = styled.span<{ $color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${props => props.$color};
  border-radius: 50%;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardBottom = styled(Flex)`
  width: 100%;
  height: 32px;
`;
