import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { ATTRIBUTE_GROUP_FRAGMENT } from 'gql/attributes/fragments';
import { AttributeGroupFragment } from 'gql/attributes/__generated__/AttributeGroupFragment';
import {
  App,
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Flex,
  Form,
  Input,
  Row,
  Upload
} from 'antd';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { useFileUpload, usePermission } from 'hooks';
import { AttributeFragment } from 'gql/attributes/__generated__/AttributeFragment';
import dayjs, { Dayjs } from 'dayjs';
import {
  CloseCircleOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DOCTOR_ATTRIBUTE_GROUPS_FRAGMENT } from 'gql/doctors/fragments';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { CREATE_USER_GROUP_ATTRIBUTES } from 'gql/attributes/mutations';
import { DoctorAttributeGroupsFragment } from 'gql/doctors/__generated__/DoctorAttributeGroupsFragment';
import { RTL_LANGUAGES } from 'constants/translations';

import { CKEditor, Private } from 'components/shared';
import { StyledPage } from './styled';
import { showErrorMessage } from 'utils/showErrorMessage';

interface IProps {
  id: string;
  lang: string;
  pageId: string | null;
  doctorGroupId?: string;
}

const AdditionalFields: FC<IProps> = ({ id, lang, pageId, doctorGroupId }) => {
  const { checkUserPermission } = usePermission();
  const client = useApolloClient();
  const { message, modal } = App.useApp();
  const { upload } = useFileUpload({
    progress: true
  });

  const [createUserGroupAttributes, { loading: isCreating }] = useMutation(
    CREATE_USER_GROUP_ATTRIBUTES,
    {
      onCompleted() {
        message.success('Successfully saved');
        client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [form] = Form.useForm();

  const doctorAttributeGroups =
    client.readFragment<DoctorAttributeGroupsFragment>({
      fragment: DOCTOR_ATTRIBUTE_GROUPS_FRAGMENT,
      fragmentName: 'DoctorAttributeGroupsFragment',
      id: `UserGroupAttributes:${doctorGroupId}`
    });

  const attributeGroup = client.readFragment<AttributeGroupFragment>({
    fragment: ATTRIBUTE_GROUP_FRAGMENT,
    fragmentName: 'AttributeGroupFragment',
    id: `Group:${pageId}`
  });

  const hasProfileUpdatePermission = checkUserPermission(
    PERMISSION_ROUTES.doctors,
    PERMISSION_ACTIONS.update
  );

  const resetValues = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    if (pageId) {
      resetValues();
    }
  }, [pageId, resetValues]);

  const renderField = (item: AttributeFragment, name: number) => {
    const fieldName = [name, item.id];

    switch (item.type) {
      case 'date':
        return (
          <Form.Item name={fieldName} label={item.name}>
            <DatePicker
              style={{ width: '100%' }}
              direction={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
            />
          </Form.Item>
        );
      case 'editor':
        return (
          <Form.Item name={fieldName} label={item.name}>
            <CKEditor language={lang} />
          </Form.Item>
        );
      case 'file':
        return (
          <Form.Item
            name={fieldName}
            label={item.name}
            valuePropName="fileList"
            getValueFromEvent={e => e?.fileList || []}
          >
            <Upload
              disabled={!hasProfileUpdatePermission}
              maxCount={1}
              customRequest={e =>
                upload(e.file as File, {
                  onUploadProgress: e.onProgress
                })
                  .then(e.onSuccess)
                  .catch(e.onError)
              }
            >
              <Button block icon={<UploadOutlined />}>
                Upload
              </Button>
            </Upload>
          </Form.Item>
        );
      default:
        return (
          <Form.Item name={fieldName} label={item.name}>
            <Input dir={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'} />
          </Form.Item>
        );
    }
  };

  const onFinish = (data: { values: Record<string, string | Dayjs>[] }) => {
    const isOneFilled = data.values.every(item =>
      Object.values(item).some(value => !!value)
    );

    if (!isOneFilled) {
      return modal.error({
        title: 'Error',
        content:
          'Please ensure that at least one field is filled in each row or remove the row'
      });
    }

    const transformed = data.values.map(item => {
      const values: Record<string, string | undefined> = {};
      Object.entries(item).forEach(([key, value]) => {
        if (dayjs.isDayjs(value)) {
          values[key] = value.format();

          return;
        }

        if (Array.isArray(value)) {
          values[key] = value?.[0]?.response || undefined;

          return;
        }

        values[key] = value || undefined;
      });

      return values;
    });

    const input = {
      user_id: id,
      group_id: attributeGroup?.id,
      lang,
      values: transformed
    };

    createUserGroupAttributes({
      variables: {
        input
      }
    });
  };

  const initialValues = useMemo(() => {
    if (doctorAttributeGroups?.attributes?.length) {
      const values = doctorAttributeGroups.attributes.reduce<
        Record<string, string | Dayjs | unknown[]>[]
      >((acc, item) => {
        const index = item.row_index - 1;

        if (!item.value) {
          return acc;
        }

        if (!acc[index]) {
          acc[index] = {};
        }

        switch (item.type) {
          case 'date':
            acc[index][item.attribute_id] = dayjs(item.value);
            break;
          case 'file':
            acc[index][item.attribute_id] = [
              {
                uid: item.value,
                name: item.value,
                response: item.value
              }
            ];
            break;
          default:
            acc[index][item.attribute_id] = item.value;
            break;
        }

        return [...acc];
      }, []);

      return values.filter(item => item);
    }

    return [];
  }, [doctorAttributeGroups]);

  return (
    <StyledPage>
      <Form
        form={form}
        layout="vertical"
        size="large"
        disabled={!hasProfileUpdatePermission}
        onFinish={onFinish}
        initialValues={{
          values: initialValues
        }}
      >
        <Form.List name="values">
          {(fields, { add, move, remove }) =>
            fields.length ? (
              <>
                <DragDropContext
                  onDragEnd={r =>
                    move(r.source.index, r.destination?.index || 0)
                  }
                >
                  <Droppable droppableId="droppable">
                    {dropped => (
                      <div {...dropped.droppableProps} ref={dropped.innerRef}>
                        {fields.map(({ key, name }, index) => (
                          <Draggable
                            key={`${pageId}-${name}-${key}`}
                            draggableId={`${pageId}-${name}-${key}`}
                            index={index}
                          >
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom: 24,
                                  userSelect: 'none'
                                }}
                              >
                                <Card title={`#${index + 1}`}>
                                  <Button
                                    danger
                                    type="text"
                                    className="page-row-remove-button"
                                    onClick={() => remove(index)}
                                    icon={
                                      <CloseCircleOutlined
                                        style={{ fontSize: 24 }}
                                      />
                                    }
                                  />
                                  <Row gutter={[16, 0]}>
                                    {attributeGroup?.attributes.map(
                                      (item, attributeIndex, arr) => {
                                        const attributeNumber =
                                          attributeIndex + 1;

                                        const isLast =
                                          attributeNumber === arr.length;

                                        const isLastOdd =
                                          isLast && attributeNumber % 2 !== 0;

                                        return (
                                          <Col
                                            span={isLastOdd ? 24 : 12}
                                            key={`${pageId}-${item.id}`}
                                          >
                                            {renderField(item, name)}
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {dropped.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Flex justify="end" gap={12}>
                  <Private
                    route={PERMISSION_ROUTES.doctors}
                    action={PERMISSION_ACTIONS.update}
                  >
                    <Button
                      size="large"
                      type="default"
                      htmlType="button"
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      disabled={isCreating}
                    >
                      Add Row
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      onClick={form.submit}
                      loading={isCreating}
                    >
                      Save
                    </Button>
                  </Private>
                </Flex>
              </>
            ) : (
              <Empty description="There are no fields in this section. Click the button below to add a row and start filling in the data">
                <Button
                  size="large"
                  type="default"
                  htmlType="button"
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  disabled={isCreating}
                >
                  Add Row
                </Button>
              </Empty>
            )
          }
        </Form.List>
      </Form>
    </StyledPage>
  );
};

export default memo(AdditionalFields);
