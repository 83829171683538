import { gql } from '@apollo/client';

export const CREATE_SERVICE = gql`
  mutation CreateService($input: ServiceInput!) {
    createService(input: $input) {
      data {
        id
      }
      message
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($updateServiceId: ID!, $input: ServiceInput!) {
    updateService(id: $updateServiceId, input: $input) {
      message
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation DeleteService($deleteServiceId: ID!) {
    deleteService(id: $deleteServiceId) {
      message
    }
  }
`;

export const ATTACH_SERVICE_TO_DOCTORS = gql`
  mutation AttachServiceToDoctors($input: ServiceDoctors!) {
    attachServiceToDoctors(input: $input) {
      message
    }
  }
`;
