import { FC, useMemo, useState } from 'react';
import { Button, Result, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_INVOICE_TEMPLATES } from 'gql/financial/queries';
import { ColumnProps } from 'antd/lib/table';
import invoiceIcon from 'assets/img/doctor_invoice.png';
import {
  GetInvoiceTemplatesQuery,
  GetInvoiceTemplatesQuery_getInvoiceTemplates_data_results
} from 'gql/financial/__generated__/GetInvoiceTemplatesQuery';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { useOrganizationsStateContext } from 'providers/Organizations';
import { PlusOutlined } from '@ant-design/icons';

import { Loading, PageWrapper } from 'components/ui';
import { StyledImage, StyledResult } from './styled';

const PAGE_LIMIT = 20;

const InvoiceTemplates: FC = () => {
  const navigate = useNavigate();
  const { selected } = useOrganizationsStateContext();
  const [page, setPage] = useState(0);
  const { data, loading } = useQuery<GetInvoiceTemplatesQuery>(
    GET_INVOICE_TEMPLATES,
    {
      variables: {
        query: {
          pagination: {
            limit: PAGE_LIMIT,
            page
          }
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const results = data?.getInvoiceTemplates.data?.results;

  const columns: ColumnProps<GetInvoiceTemplatesQuery_getInvoiceTemplates_data_results>[] =
    useMemo(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Organization ID',
          dataIndex: 'organization_id',
          key: 'organization_id'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'id',
          width: '5%',
          align: 'right',
          render: id => <Button onClick={() => navigate(`${id}`)}>Edit</Button>
        }
      ],
      [navigate]
    );

  const renderContent = () => {
    if (selected === 'all') {
      return (
        <Table
          dataSource={results}
          columns={columns}
          loading={loading}
          rowKey="id"
          pagination={{
            total: data?.getInvoiceTemplates.data?.total || 0,
            pageSize: PAGE_LIMIT,
            onChange: p => setPage(p - 1),
            showSizeChanger: false
          }}
        />
      );
    }

    if (results?.[0]) {
      return (
        <Result
          icon={<StyledImage src={invoiceIcon} />}
          style={{ padding: 0 }}
          title={`Invoice template #${results?.[0]?.id}`}
          extra={
            <>
              <StyledResult>
                <li>
                  <b>Name: </b> {results[0].name}
                </li>
                <li>
                  <b>Organization ID: </b> {results[0].organization_id}
                </li>
                <li>
                  <b>Created at: </b>{' '}
                  {dayjs(results[0].created_at).format(FULL_DATE_FORMAT)}
                </li>
              </StyledResult>
              <br />
              <Button
                type="primary"
                onClick={() => navigate(`${results[0].id}`)}
              >
                Edit
              </Button>
            </>
          }
        />
      );
    }

    return (
      <Result
        icon={<StyledImage src={invoiceIcon} />}
        title="You have not yet created a template for this organization"
        extra={
          <>
            <Button type="primary" onClick={() => navigate('create')}>
              <PlusOutlined />
              Create
            </Button>
          </>
        }
      />
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper title="Invoice templates" color="white">
      {renderContent()}
    </PageWrapper>
  );
};

export default InvoiceTemplates;
