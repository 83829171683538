import { Tabs } from 'antd';
import {
  CalendarOutlined,
  FormOutlined,
  IdcardOutlined,
  TranslationOutlined
} from '@ant-design/icons';
import { usePermission } from 'hooks';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { Container, TitleStyled } from './styled';
import { TranslatorRequests, AiRequest } from 'components/shared';
import AppointmentRequest from '../AppointmentRequest';
import FastAppointmentRequest from '../FastAppointmentRequest';

const NewRequests = () => {
  const { checkUserPermission } = usePermission();

  const hasAppointmentsPermission = checkUserPermission(
    PERMISSION_ROUTES.appointments,
    PERMISSION_ACTIONS.read
  );

  const hasTranslatorRequestsPermission = checkUserPermission(
    PERMISSION_ROUTES.dashboard,
    PERMISSION_ACTIONS.viewTranslatorRequest
  );

  const hasPatientRequestsPermission = checkUserPermission(
    PERMISSION_ROUTES.patientRequests,
    PERMISSION_ACTIONS.read
  );

  const hasAiPermission = checkUserPermission(
    PERMISSION_ROUTES.ai,
    PERMISSION_ACTIONS.read
  );

  const TabsData = [
    ...(hasAppointmentsPermission
      ? [
          {
            key: 'appointment_request',
            label: 'Appointment Requests',
            children: <AppointmentRequest />,
            icon: <FormOutlined />
          }
        ]
      : []),
    ...(hasTranslatorRequestsPermission
      ? [
          {
            key: 'translator_request',
            label: 'Translator Requests',
            children: (
              <TranslatorRequests
                limit={5}
                className="table_head"
                pagination={false}
              />
            ),
            icon: <TranslationOutlined />
          }
        ]
      : []),
    ...(hasPatientRequestsPermission
      ? [
          {
            key: 'fast_appointment_request',
            label: 'Fast Appointment Requests',
            children: <FastAppointmentRequest />,
            icon: <CalendarOutlined />
          }
        ]
      : []),
    ...(hasAiPermission
      ? [
          {
            key: 'ai_assistant_request',
            label: 'AI Assistant Requests',
            children: (
              <AiRequest limit={5} className="table_head" pagination={false} />
            ),
            icon: <IdcardOutlined />
          }
        ]
      : [])
  ];

  if (
    !hasAppointmentsPermission &&
    !hasPatientRequestsPermission &&
    !hasAiPermission
  ) {
    return null;
  }

  return (
    <>
      <TitleStyled level={4} color="#18212C">
        New Requests
      </TitleStyled>
      <Container>
        <Tabs
          style={{ width: '100%' }}
          items={TabsData}
          size="large"
          destroyInactiveTabPane
        />
      </Container>
    </>
  );
};

export default NewRequests;
