import { FC, useCallback, useState } from 'react';
import { Button, Image, Input, Space, Switch, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from 'config';
import { useMutation } from '@apollo/client';
import { UPDATE_SETTINGS } from 'gql/profile/mutations';
import { SettingsKeys, parseJson } from '@medicusunion/front-utils';
import instance from 'services/api';
import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/selectors';
import { useAuthDispatchContext } from 'providers/Auth';

import { showErrorMessage } from 'utils/showErrorMessage';
import { StyledList, StyledToken } from './styled';
import { jwt } from 'utils/jwt';
import { tokenStorage } from 'utils/storage';

const { Title, Paragraph } = Typography;

const Authentication: FC = () => {
  const { t } = useTranslation();
  const { refetchUserData } = useAuthDispatchContext();
  const user = useAppSelector(selectUserData());
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [active, setActive] = useState(user?.settings?.auth2fa || false);

  const [result, setResult] = useState<{
    qr: string;
    secretCode: string;
  } | null>(null);

  // graphql
  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    onCompleted() {
      refetchUserData();
    },
    onError: err => showErrorMessage(err)
  });

  const onConfirm = useCallback(async () => {
    if (code) {
      await axios
        .post(`${config.base_url}/auth/otp/verify`, {
          otp: code,
          token: tokenStorage.get()
        })
        .then(() => {
          updateSettings({
            variables: {
              input: {
                settings: {
                  ...(parseJson(user?.settings) || {}),
                  [SettingsKeys.auth2fa]: true
                }
              }
            }
          });
          message.success(t('profile.2fa.success'));
          setResult(null);
        })
        .catch(err => {
          if (err?.response?.data?.status === 401) {
            jwt.refreshToken().then(newToken => {
              if (newToken) {
                onConfirm();
              }
            });
          } else {
            message.error(err?.response?.data?.message);
          }
        });
    } else {
      setError(true);
    }
  }, [code, t, updateSettings, user?.settings]);

  const onToggleSwitch = useCallback(
    async (val: boolean) => {
      if (val) {
        const response = await instance.post('auth/qr');

        setResult({
          qr: response?.data?.data?.qr,
          secretCode: response?.data?.data?.secretKey
        });
      } else {
        updateSettings({
          variables: {
            input: {
              settings: {
                ...(parseJson(user?.settings) || {}),
                [SettingsKeys.auth2fa]: false
              }
            }
          }
        });

        setResult(null);
      }

      setActive(val);
    },
    [updateSettings, user?.settings]
  );

  return (
    <Space direction="vertical" size={16} style={{ maxWidth: '100%' }}>
      <Space>
        <Switch checked={active} onChange={onToggleSwitch} />
        <Typography.Text>{t('profile.2fa.title')}</Typography.Text>
      </Space>
      {!!result && (
        <>
          <div>
            <Title level={4}>{t('profile.2fa.configuring')}</Title>
            <StyledList>
              <li>{t('profile.2fa.install_app')}</li>
              <li>{t('profile.2fa.add')}</li>
              <li>{t('profile.2fa.select_scan')}</li>
            </StyledList>
          </div>
          <div>
            <Title level={4}>{t('profile.2fa.scan_code')}</Title>
            <Image width={200} height={200} preview={false} src={result.qr} />
          </div>
          <div>
            <Title level={4}>{t('profile.2fa.enter_code')}</Title>
            <StyledToken copyable code>
              {result.secretCode}
            </StyledToken>
          </div>
          <div>
            <Title level={4}>{t('profile.2fa.verify_code')}</Title>
            <Paragraph>{t('profile.2fa.verify')}</Paragraph>
            <Input
              value={code}
              onChange={e => setCode(e.target.value)}
              status={error ? 'error' : ''}
            />
          </div>
          <Button onClick={onConfirm} block type="primary">
            Confirm
          </Button>
        </>
      )}
    </Space>
  );
};

export default Authentication;
