import styled, { css } from 'styled-components';

export const Message = styled.div<{ type: 'answer' | 'question' }>`
  padding: 12px;
  border-radius: 8px;
  margin-top: 4px;
  width: fit-content;
  max-width: 80%;
  margin-bottom: 10px;
  display: flex;
  ${({ type }) => css`
    background-color: ${type === 'question' ? '#EFF3FF' : '#FBFBFB'};
    border: 1px solid ${type === 'question' ? '#C8D2E6' : '#F4F4F4'};
    margin-left: ${type === 'question' ? 'auto' : 0};
    margin-right: ${type === 'answer' ? 'auto' : 0};
  `}
`;

export const MessageContainer = styled.div<{ type: 'answer' | 'question' }>`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ type }) =>
    type === 'answer' ? 'flex-end' : 'flex-start'};
`;
