import { FC, useCallback, useEffect, useState } from 'react';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { App, Button, Collapse, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CONFIGS } from 'gql/configs/queries';
import { GetConfig } from 'gql/configs/__generated__/GetConfig';
import { CREATE_CONFIG } from 'gql/configs/mutations';
import client from 'apolloClient';
import { LANGUAGES } from 'constants/languages';
import { CollapseProps } from 'antd/lib';

import { CKEditor, Private } from 'components/shared';
import { PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';

type DataType = {
  [key: string]: string;
};

const { Panel } = Collapse;
const { Text } = Typography;

const TITLES = {
  '1': 'General Conditions of Use',
  '2': 'Privacy policy',
  '4': 'Legal Notice'
};

interface IProps {
  type: '1' | '2' | '4';
}

const StaticPage: FC<IProps> = ({ type }) => {
  const { modal } = App.useApp();
  const { data } = useQuery<GetConfig>(GET_CONFIGS, {
    fetchPolicy: 'no-cache',
    variables: {
      query: {
        type
      }
    }
  });

  const [createConfig, { loading }] = useMutation(CREATE_CONFIG, {
    onCompleted() {
      client.refetchQueries({ include: [GET_CONFIGS] });
      modal.success({
        title: 'Success',
        content: `${TITLES[type]} page updated successfully!`
      });
    },
    onError: err => showErrorMessage(err)
  });

  // states
  const [values, setValues] = useState<DataType>();

  useEffect(() => {
    if (data?.getConfig?.data.results[0]?.body) {
      setValues(data.getConfig.data.results[0].body);
    }

    return () => {
      setValues(undefined);
    };
  }, [data?.getConfig?.data.results]);

  const [error, setError] = useState<string[]>([]);

  const hasEmptyKey = (obj: DataType) => {
    const languages = LANGUAGES.map(lang => lang.locale);

    const emptyKeys = languages.filter(key => !obj[key]);

    return emptyKeys;
  };

  const onFinish = useCallback(() => {
    if (values) {
      const emptyKeys = hasEmptyKey(values);
      setError(emptyKeys);

      if (emptyKeys.length) {
        return;
      }
    }

    if (!error.length) {
      createConfig({
        variables: {
          input: {
            body: JSON.stringify(values),
            type
          }
        }
      });
    }
  }, [createConfig, error.length, type, values]);

  const items: CollapseProps['items'] = LANGUAGES.map(item => {
    const hasError = error.includes(item.locale);

    return {
      key: item.key,
      label: item.label,
      forceRender: true,
      children: (
        <Panel
          header={
            <Text
              style={{
                color: `var(--${hasError ? 'error-color' : 'text-color'})`
              }}
            >
              {item.label}
              {hasError ? ' (error)' : ''}
            </Text>
          }
          key={item.locale}
          forceRender
        >
          <CKEditor
            language={item.locale}
            onChange={val =>
              setValues(prev => ({
                ...prev,
                [item.locale]: val
              }))
            }
            value={values?.[item.locale] || ''}
          />
        </Panel>
      )
    };
  });

  return (
    <PageWrapper
      title={TITLES[type]}
      color="white"
      extra={[
        <Private
          key="privacy-policy"
          route={PERMISSION_ROUTES.super}
          action={PERMISSION_ACTIONS.update}
        >
          <Button type="primary" onClick={onFinish} loading={loading}>
            Save
          </Button>
        </Private>
      ]}
    >
      <Collapse
        accordion
        defaultActiveKey={LANGUAGES[0].locale}
        destroyInactivePanel
        items={items}
      />
    </PageWrapper>
  );
};

export default StaticPage;
