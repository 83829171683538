import { FC } from 'react';
import { App, Button, Flex, Form, Input, Typography } from 'antd';
import { PAYMENT_BEHAVIOR_BY_ORGANIZATION } from 'constants/organization';

import { StyledPage } from './styled';
import useOrganizationSettings from 'pages/OrganizationSettings/hooks/useUpdateSettigns';

enum MeetingServicesEnum {
  medicus,
  zoom
}

type UiFormValues = {
  translatorByDefault: boolean;
  meeting: MeetingServicesEnum;
  email: string;
  paymentBehavior: (typeof PAYMENT_BEHAVIOR_BY_ORGANIZATION)[keyof typeof PAYMENT_BEHAVIOR_BY_ORGANIZATION];
  initialPercent: string;
};

const defaultValues = {
  translatorByDefault: false,
  meeting: MeetingServicesEnum.medicus,
  paymentBehavior: PAYMENT_BEHAVIOR_BY_ORGANIZATION.NO_PAYMENT,
  initialPercent: '',
  email: ''
};

interface IProps {
  id: string;
}

const AppointmentSettings: FC<IProps> = ({ id }) => {
  const { modal } = App.useApp();
  const [form] = Form.useForm<UiFormValues>();

  const { loading, settings, onUpdateSettings } = useOrganizationSettings(id);

  const onFinish = (values: UiFormValues) => {
    onUpdateSettings({
      email: values.email
    });
  };

  const onReset = () => {
    modal.confirm({
      title: 'Waring',
      content: (
        <Typography.Text>
          Are you sure you want to reset settings to default?{' '}
          <b>Changes will be automatically saved.</b>
        </Typography.Text>
      ),
      onOk() {
        form.setFieldsValue({
          ...defaultValues
        });
        form.submit();
      }
    });
  };

  return (
    <StyledPage>
      <Form
        initialValues={{
          email: settings?.email || defaultValues.email
        }}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size="large"
      >
        <Form.Item name="email" label="Email" rules={[{ type: 'email' }]}>
          <Input />
        </Form.Item>
      </Form>
      <Flex justify="end" gap={12}>
        <Button size="large" onClick={onReset} disabled={loading}>
          Reset to default
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={form.submit}
          loading={loading}
        >
          Save
        </Button>
      </Flex>
    </StyledPage>
  );
};

export default AppointmentSettings;
