import { State, Action, ActionTypes } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SELECT_PARENT_ORGANIZATION:
      return {
        ...state,
        parentSelected: action.data
      };
    case ActionTypes.SELECT_SUB_ORGANIZATION:
      return {
        ...state,
        subSelected: action.data
      };
    case ActionTypes.SET_ORGANIZATIONS:
      return {
        ...state,
        results: action.data
      };
    case ActionTypes.SET_SUB_ORGANIZATIONS:
      return {
        ...state,
        subResults: action.data
      };

    default:
      return state;
  }
};

export default reducer;
