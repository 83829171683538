import { FC } from 'react';
import { Select } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { LANGUAGES } from 'constants/languages';

const { Option } = Select;

interface IProps {
  lang: string;
}

const LanguageSwitcher: FC<IProps> = ({ lang }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const changeLocale = (val: string) => {
    searchParams.set('lang', val);
    setSearchParams(searchParams);
  };

  return (
    <Select
      placeholder="Select Language"
      size="large"
      value={lang}
      onChange={val => changeLocale(val)}
      style={{ width: 139 }}
    >
      {LANGUAGES.map(item => (
        <Option key={`profile-lang-selector-${item.key}`} value={item.locale}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSwitcher;
