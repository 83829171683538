import { useQuery } from '@apollo/client';
import { getIdStart } from '@medicusunion/front-utils';
import { Table, Tag, Button } from 'antd';
import { DATE_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { GET_CHAT_HISTORY } from 'gql/ai_health/queries';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ChatModal from 'components/shared/AiRequest/ChatModal';

import { GetChatHistoryAdminQuery } from '@/generated/graphql';

interface IProps {
  limit?: number;
  pagination?: boolean;
  status?: number;
  className?: string;
}

const AiRequest: FC<IProps> = ({
  limit = 10,
  pagination = true,
  status,
  className
}) => {
  const [chatId, setChatId] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page') || 0);

  const { data, loading } = useQuery<GetChatHistoryAdminQuery>(
    GET_CHAT_HISTORY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          pagination: {
            page,
            limit
          },
          finished: typeof status === 'undefined' ? undefined : !!status,
          date: ''
        }
      }
    }
  );

  const historyData = data?.getChatHistoryAdmin.data?.results;
  const total = data?.getChatHistoryAdmin.data?.total;

  return (
    <>
      <Table
        dataSource={historyData}
        loading={loading}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: id => getIdStart(id)
          },
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
          },
          {
            title: 'Email',
            dataIndex: 'user',
            key: 'user',
            render: user =>
              user?.email ? (
                <a href={`mailto:${user.email}`}>{user.email}</a>
              ) : (
                '-'
              )
          },
          {
            title: 'Status',
            dataIndex: 'finished',
            key: 'finished',
            render: value =>
              value ? (
                <Tag color="success">Submitted</Tag>
              ) : (
                <Tag color="orange">Not Submitted</Tag>
              )
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: value => dayjs(value).format(DATE_FORMAT)
          },
          {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: id => (
              <Button type="link" onClick={() => setChatId(id)}>
                Show Chat
              </Button>
            )
          }
        ]}
        pagination={
          pagination
            ? {
                total: total || 0,
                current: page + 1,
                onChange: p => {
                  searchParams.set('page', `${p - 1}`);
                  setSearchParams(searchParams);
                },
                showSizeChanger: false
              }
            : false
        }
        className={className}
        rowKey="id"
      />
      {!!chatId && <ChatModal id={chatId} onClose={() => setChatId('')} />}
    </>
  );
};

export default AiRequest;
