import { gql } from '@apollo/client';

export const CREATE_INVOICE_TEMPLATE = gql`
  mutation CreateInvoiceTemplate($input: InvoiceTemplateInput!) {
    createInvoiceTemplate(input: $input) {
      message
    }
  }
`;

export const UPDATE_INVOICE_TEMPLATE = gql`
  mutation UpdateInvoiceTemplate(
    $id: ID!
    $input: UpdateInvoiceTemplateInput!
  ) {
    updateInvoiceTemplate(id: $id, input: $input) {
      message
    }
  }
`;
