import { FC, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Empty } from 'antd';

import { ChartContainer } from '../Charts/styled';

interface IProps {
  data: {
    country: string;
    value: number;
  }[];
}

const OrganizationChart: FC<IProps> = ({ data }) => {
  useEffect(() => {
    if (!data?.length) {
      return;
    }

    const root = am5.Root.new('organizationChart');

    const myTheme = am5.Theme.new(root);

    myTheme.rule('Grid', ['base']).setAll({
      strokeOpacity: 0.1
    });

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingLeft: 0
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });

    yRenderer.grid.template.set('location', 1);

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'country',
        renderer: yRenderer
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        renderer: am5xy.AxisRendererX.new(root, {
          visible: true,
          strokeOpacity: 0.1,
          minGridDistance: 80
        })
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: 'value',
        sequencedInterpolation: true,
        categoryYField: 'country'
      })
    );

    series.set('fill', am5.color(0xffa25c));
    const columnTemplate = series.columns.template;

    columnTemplate.setAll({
      draggable: false,
      tooltipText: ' {country}: {value}',
      cornerRadiusBR: 10,
      cornerRadiusTR: 10,
      strokeOpacity: 0
    });

    yAxis.data.setAll(data);
    series.data.setAll(data);
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return data ? (
    <ChartContainer style={{ height: 450 }}>
      <div id="organizationChart" className="chartdiv" />
      <div className="remove_logo" />
    </ChartContainer>
  ) : (
    <Empty className="chartdiv" />
  );
};

export default OrganizationChart;
