import { Button, Form, Input, Modal, Result, Upload } from 'antd';
import { FC, useCallback, useState } from 'react';
import dicomIcon from 'assets/img/dicom.png';
import { useMutation } from '@apollo/client';
import { CREATE_DICOM_LINK } from 'gql/sessions/mutations';
import client from 'apolloClient';
import { GET_DICOM } from 'gql/sessions/queries';
import { CreateDicomLink } from 'gql/sessions/__generated__/CreateDicomLink';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useFileUpload } from 'hooks';
import { InboxOutlined } from '@ant-design/icons';

import { StyledImage, StyledResult } from './styled';
import useCopyText from 'hooks/useCopyText';
import { showErrorMessage } from 'utils/showErrorMessage';
import { Loading } from 'components/ui';

interface IProps {
  onClose: () => void;
}

const LinkPassword: FC<IProps> = ({ onClose }) => {
  const { copyText } = useCopyText();
  const [showResult, setShowResult] = useState(false);
  const [form] = Form.useForm();
  const {
    result: file,
    upload,
    setResult
  } = useFileUpload({
    handleResult: true
  });

  const [createDicomLink, { data, loading }] = useMutation<CreateDicomLink>(
    CREATE_DICOM_LINK,
    {
      onError: err => showErrorMessage(err),
      onCompleted() {
        setShowResult(true);
        form.resetFields();
        client.refetchQueries({ include: [GET_DICOM] });
      }
    }
  );

  const handleSubmit = useCallback(
    (values: { name: string }) => {
      createDicomLink({
        variables: {
          input: {
            ...values,
            file
          }
        }
      });
    },
    [createDicomLink, file]
  );

  const onUploadFile = async (e: UploadRequestOption) => {
    try {
      const res = await upload(e.file as File);

      e.onSuccess?.(res);
    } catch (err) {
      e.onError?.(err as ProgressEvent<EventTarget>);
    }
  };

  const result = data?.createDicomLink.data;

  const link = result
    ? `${result.redirect_url}?sessionId=${result.session_id}`
    : '';

  return (
    <Modal onCancel={onClose} footer={null} closable open>
      {loading ? (
        <Loading />
      ) : showResult && result ? (
        <Result
          icon={<StyledImage src={dicomIcon} />}
          style={{ padding: 0 }}
          title="Click to copy link and password"
          extra={
            <>
              <StyledResult>
                <li>
                  <b>URL: </b>{' '}
                  <span onClick={() => copyText(link)}>{link}</span>
                </li>
                <li>
                  <b>Password: </b>{' '}
                  <span onClick={() => copyText(result.password)}>
                    {result.password}
                  </span>
                </li>
              </StyledResult>
              <br />
              <Button
                type="primary"
                style={{ width: 250 }}
                onClick={() => setShowResult(false)}
                loading={loading}
              >
                Create new
              </Button>
            </>
          }
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          size="large"
          onFinish={handleSubmit}
        >
          <Form.Item name="name" label="Title" rules={[{ required: true }]}>
            <Input maxLength={255} />
          </Form.Item>
          <Form.Item
            valuePropName="fileList"
            getValueProps={value => value}
            rules={[{ required: false }]}
          >
            <Upload.Dragger
              name="file"
              accept="application/zip"
              customRequest={onUploadFile}
              onRemove={() => setResult('')}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={form.submit} block>
              Create
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default LinkPassword;
