import { swapObject } from 'utils/object';

export const NOTIFICATION_TYPES = {
  patient: '1',
  doctor: '2',
  both: '3'
} as const;

export const NOTIFICATION_TYPES_LABELS = {
  '1': 'Patient',
  '2': 'Doctor',
  '3': 'Both'
} as const;

export type NotificationType = keyof typeof NOTIFICATION_TYPES_LABELS;

export const NOTIFICATION_ACTIONS = {
  new_date: 1,
  approved_date: 2,
  new_date_after_1: 3,
  new_date_after_2: 4,
  payment_before_1_day: 5,
  payment_before_3_hours: 6,
  doctor_note: 7,
  doctor_report: 8,
  // doctor_radiology_report: 9,
  cancel_appointment: 10,
  new_appointment: 11,
  new_appointment_after_3: 12,
  appointment_confirm: 13,
  patient_note: 14,
  upload_file: 15,
  send_report: 16,
  appointment_start_1_day: 17,
  appointment_start_1_hour: 18,
  appointment_start_10_min: 29,
  admin_update_date: 20,
  admin_payment: 21,
  new_review: 22,
  moderate_appointment: 23
} as const;

export const NOTIFICATION_ACTIONS_LABELS = swapObject(NOTIFICATION_ACTIONS);

export type NotificationAction = keyof typeof NOTIFICATION_ACTIONS;

export const NOTIFICATION_TRIGGERS = {
  email: 2,
  push: 4,
  sms: 8
} as const;

export type NotificationTrigger = keyof typeof NOTIFICATION_TRIGGERS;

export const NOTIFICATION_LABELS = {
  email: 'Email',
  push: 'Push',
  sms: 'SMS'
} as const;

export type NotificationLabel = keyof typeof NOTIFICATION_LABELS;

export enum EmailTemplatesFields {
  title = 'title',
  body = 'body',
  subTitle = 'subTitle',
  btnTitle = 'btnTitle',
  btnLink = 'btnLink'
}

export const EMAIL_TEMPLATES = {
  '5236435': {
    title: 'Email Verification',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body
    ]
  },
  '5236454': {
    title: 'Successfully Registered',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.body,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.btnTitle,
      EmailTemplatesFields.btnLink
    ]
  },
  '5236468': {
    title: 'Your request was successfully Sent',
    fields: [EmailTemplatesFields.title, EmailTemplatesFields.subTitle]
  },
  '5236477': {
    title: 'Confirm the appointment',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body,
      EmailTemplatesFields.btnTitle,
      EmailTemplatesFields.btnLink
    ]
  },
  '5236511': {
    title: 'Your appointment is incomplete (appointment state moderate)',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body,
      EmailTemplatesFields.btnTitle,
      EmailTemplatesFields.btnLink
    ]
  },
  '5236533': {
    title: 'Your Appointment is confirmed by a doctor',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body
    ]
  },
  '5236574': {
    title: 'Payment completed (invoice pdf file as well)',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body
    ]
  },
  '5236622': {
    title: 'Request canceled',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body
    ]
  },
  '5236642': {
    title: 'Reset password',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body,
      EmailTemplatesFields.btnTitle,
      EmailTemplatesFields.btnLink
    ]
  },
  '5236659': {
    title: 'Set password',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body,
      EmailTemplatesFields.btnTitle,
      EmailTemplatesFields.btnLink
    ]
  },
  '5236678': {
    title: 'New file uploaded',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body,
      EmailTemplatesFields.btnTitle,
      EmailTemplatesFields.btnLink
    ]
  },
  '5236744': {
    title: 'For doctors when a patient cancels the appointment',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body
    ]
  },
  '5236726': {
    title: 'For admin about appointment changes',
    fields: [
      EmailTemplatesFields.title,
      EmailTemplatesFields.subTitle,
      EmailTemplatesFields.body
    ]
  }
} as const;

export type EmailTemplateId = keyof typeof EMAIL_TEMPLATES;
