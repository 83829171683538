import {
  ADMIN_USER_ID,
  PERMISSIONS,
  SECTIONS,
  PERMISSION_ACTIONS
} from 'constants/permissions';
import { USER_ROLES } from 'constants/users';
import { usePermissionsStateContext } from 'providers/Auth';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/selectors';

import { checkPermission } from 'utils/permission';

const ADMIN_SECTIONS: Record<string, number> = SECTIONS[USER_ROLES.admin];
const ADMIN_PERMISSIONS: Record<number, Record<string, number>> = PERMISSIONS[
  USER_ROLES.admin
];

const usePermission = () => {
  // context
  const permissions = usePermissionsStateContext();
  const user = useAppSelector(selectUserData());

  const isSuperAdmin = useMemo(
    () => `${user?.id}` === ADMIN_USER_ID,
    [user?.id]
  );

  const checkUserPermission = useCallback(
    (routeKey: string, action = PERMISSION_ACTIONS.read) => {
      if (!routeKey) {
        return true;
      }

      const routeId = ADMIN_SECTIONS[routeKey];

      if (isSuperAdmin) return true;

      if (routeId === ADMIN_SECTIONS.super) {
        return false;
      }

      const value = ADMIN_PERMISSIONS[routeId][action];

      const permission = permissions[routeId];

      return !!permission && checkPermission(value, permission);
    },
    [isSuperAdmin, permissions]
  );

  const checkUserMultiPermission = useCallback(
    (key: string | string[], action = PERMISSION_ACTIONS.read) =>
      Array.isArray(key)
        ? key.some(item => checkUserPermission(item, action))
        : checkUserPermission(key, action),
    [checkUserPermission]
  );

  return { checkUserPermission, checkUserMultiPermission, isSuperAdmin };
};

export default usePermission;
