import { useCallback, useMemo, useRef, useState } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import instance from 'services/api';

import { getFileUrl } from 'utils/file';

type Options = {
  progress?: boolean;
  loading?: boolean;
  handleError?: AxiosError;
  handleResult?: boolean;
};

const useFileUpload = (_options?: Options) => {
  const abortRef = useRef<Record<string, AbortController>>({});
  const [uploading, setUploading] = useState<Record<string, boolean>>({});
  const [result, setResult] = useState<string>('');

  const options: Options = useMemo(
    () => ({
      ..._options,
      progress: _options?.progress || false,
      handleError: _options?.handleError,
      handleResult: _options?.handleResult || false
    }),
    [_options]
  );

  const upload = useCallback(
    async (file: File, config: AxiosRequestConfig<FormData> = {}) => {
      if (options.loading) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setUploading(prev => ({ ...prev, [file?.uid]: true }));
      }

      const { onUploadProgress, ...rest } = config;

      const formData = new FormData();
      formData.append('file', file);

      const controller = new AbortController();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      abortRef.current[file?.uid] = controller;

      const res = await instance.post(`/aws-s3`, formData, {
        onUploadProgress(e) {
          const percent = Math.ceil((e.loaded / e.total) * 100);

          onUploadProgress?.({ percent: percent > 100 ? 100 : percent });
        },
        signal: controller.signal,
        ...rest
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete abortRef.current[file?.uid];

      if (options.handleResult) {
        setResult(res.data.data);
      }

      if (options.loading) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setUploading(prev => ({ ...prev, [file?.uid]: false }));
      }

      return res.data.data;
    },
    [options.handleResult, options.loading]
  );

  const fileUrl = useMemo(() => getFileUrl(result), [result]);

  const onCancel = useCallback((uid?: string) => {
    if (uid) {
      abortRef.current[uid]?.abort();
      delete abortRef.current[uid];
      setUploading(prev => ({ ...prev, [uid]: false }));
    }

    setResult('');
  }, []);

  const loading = useMemo(
    () => Object.values(uploading).some(item => item),
    [uploading]
  );

  return {
    result,
    fileUrl,
    loading,
    setResult,
    onCancel,
    upload
  };
};

export default useFileUpload;
