import { Button, Empty } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StyledNotFound } from './styled';

const NotFound: FC = () => {
  // translations
  const { t } = useTranslation();
  // navigation
  const navigate = useNavigate();

  return (
    <StyledNotFound>
      <Empty description={t('not_found.text')}>
        <Button type="primary" onClick={() => navigate('/')}>
          {t('not_found.go_home')}
        </Button>
      </Empty>
    </StyledNotFound>
  );
};

export default NotFound;
