export const TRANSLATIONS_FILES = [
  {
    key: 'doctor_dashboard',
    title: 'Doctor Web',
    folderKey: 'doctor_dashboard',
    fileNames: 'doctor_translations',
    languages: ['en', 'de']
  },
  // {
  //   key: 'patient_web',
  //   title: 'Patient Web',
  //   folderKey: 'patient_web',
  //   fileNames: [
  //     'about_us',
  //     'account',
  //     'ai_health',
  //     'appointments',
  //     'benefits',
  //     'blog_news',
  //     'common',
  //     'contact_us',
  //     'doctors_products',
  //     'doctors',
  //     'faq',
  //     'footer',
  //     'get_started',
  //     'header',
  //     'home',
  //     'how_it_works',
  //     'join_doctor',
  //     'legal_notice',
  //     'medical_treatment',
  //     'patients_products',
  //     'payment',
  //     'privacy',
  //     'services',
  //     'specialties',
  //     'terms'
  //   ],
  //   languages: ['en', 'de', 'ru', 'ar']
  // },
  {
    key: 'doctor_app',
    title: 'Doctor Mobile App',
    folderKey: 'doctor_app',
    fileNames: 'doctor_app_translations',
    languages: ['en', 'de']
  },
  {
    key: 'patient_app',
    title: 'Patient Mobile App',
    folderKey: 'patient_app',
    fileNames: 'patient_app_translations',
    languages: ['en', 'de', 'ru']
  }
];

export const RTL_LANGUAGES = ['ar'];
