export type Languages = 'en' | 'ru' | 'de' | 'ar';

export type TranslationSelectedTab = {
  key: string;
  title: string;
  folderKey: string;
  fileNames: string | string[];
  languages: string[];
};

export type ResultType = {
  section?: string;
  key?: string;
  name?: string;
  en?: string;
  prev_en?: string;
  has_value_en?: string;
  ru?: string;
  prev_ru?: string;
  has_value_ru?: string;
  de?: string;
  prev_de?: string;
  has_value_de?: string;
  ar?: string;
  prev_ar?: string;
  has_value_ar?: string;
  children?: ResultType[];
};

export interface DataItem {
  parent: string;
  key: string;
  children?: DataItem[];
  [key: string]: string | DataItem[] | undefined;
}

export type FormattedTranslation = {
  parent: string;
  key: string;
  value_en?: string;
  value_de?: string;
  value_ru?: string;
};

export type TranslationsDataSectionType = {
  [parent: string]: {
    [key: string]:
      | string
      | {
          [key: string]: string;
        };
  };
};

export type TranslationsDataType = {
  [language: string]: TranslationsDataSectionType;
};

export type TranslationsSingleJsonDataSingleLanguageType = {
  [key: string]:
    | string
    | {
        [key: string]: string;
      };
};

export type TranslationsSingleJsonDataType = {
  [language: string]: TranslationsSingleJsonDataSingleLanguageType;
};

export const transformSingleLanguage = (
  items: ResultType[],
  language: Languages
) => {
  const result: TranslationsDataSectionType = {};

  items.forEach(item => {
    if (!item.section) {
      return;
    }

    if (!result[item.section]) {
      result[item.section] = {};
    }

    if (item.children && item.children.length > 0) {
      item.children.forEach(child => {
        if (!child.key || !item.section) {
          return;
        }

        const value = child[language];

        if (typeof value !== 'undefined') {
          const noValue = !child[language];

          if (noValue) {
            throw new Error(
              `value is empty for '${item.section}.${child.key}')`
            );
          }

          result[item.section][child.key] = value;

          return;
        }

        if (child.children && child.children.length > 0) {
          child.children.forEach(nested => {
            if (!child.key || !item.section) {
              return;
            }

            const nestedValue = nested[language];

            if (!result[item.section][child.key]) {
              result[item.section][child.key] = {};
            }

            if (typeof nestedValue !== 'undefined' && nested.key) {
              const noNestedValue = !nested?.[language];

              if (noNestedValue) {
                const prevValue = nested[`prev_${language}`] || '-';

                throw new Error(
                  `value is empty for '${item.section}.${child.key}.${nested.key}': (Language: ${language}, Previous value: ${prevValue})`
                );
              }

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              result[item.section][child.key][nested.key] = nestedValue;
            }
          });
        }
      });
    }
  });

  return result;
};

// transform the data to send to back end
export const transformData = (
  items: ResultType[],
  languages: Languages[]
): TranslationsDataType => {
  const result: TranslationsDataType = {};

  languages.forEach(lang => {
    result[lang] = {};

    items.forEach(item => {
      if (!item.section) {
        return;
      }

      if (!result[lang][item.section]) {
        result[lang][item.section] = {};
      }

      if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
          if (!child.key || !item.section) {
            return;
          }

          const value = child[lang];

          if (typeof value !== 'undefined') {
            const noValueForAll = languages.every(l => !child?.[l]);

            if (noValueForAll) {
              throw new Error(
                `value is empty for '${item.section}.${child.key}')`
              );
            }

            result[lang][item.section][child.key] = value;

            return;
          }

          if (child.children && child.children.length > 0) {
            child.children.forEach(nested => {
              if (!child.key || !item.section) {
                return;
              }

              const nestedValue = nested[lang];

              if (!result[lang][item.section][child.key]) {
                result[lang][item.section][child.key] = {};
              }

              if (typeof nestedValue !== 'undefined' && nested.key) {
                const noNestedValueForAll = languages.every(l => !nested?.[l]);

                if (noNestedValueForAll) {
                  const prevValue = nested[`prev_${lang}`] || '-';

                  throw new Error(
                    `value is empty for '${item.section}.${child.key}.${nested.key}': (Language: ${lang}, Previous value: ${prevValue})`
                  );
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result[lang][item.section][child.key][nested.key] = nestedValue;
              }
            });
          }
        });
      }
    });
  });

  return result;
};

export const transformDataSingleJsonSingleLanguage = (
  item: ResultType,
  language: Languages
) => {
  const result: TranslationsSingleJsonDataSingleLanguageType = {};

  if (item.children && item.children.length > 0) {
    item.children.forEach(child => {
      if (!child.key) {
        return;
      }

      const value = child[language];

      if (value) {
        result[child.key] = value;

        return;
      }

      if (child.children && child.children.length > 0) {
        child.children.forEach(nested => {
          if (!child.key) {
            return;
          }

          const nestedValue = nested[language];

          if (!result[child.key]) {
            result[child.key] = {};
          }

          if (nestedValue && nested.key) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result[child.key][nested.key] = nestedValue;
          }
        });
      }
    });
  }

  return result;
};

export const transformDataSingleJson = (
  item: ResultType,
  languages: Languages[]
): TranslationsSingleJsonDataType => {
  const result: TranslationsSingleJsonDataType = {};

  languages.forEach(lang => {
    result[lang] = {};

    if (!result[lang]) {
      result[lang] = {};
    }

    if (item.children && item.children.length > 0) {
      item.children.forEach(child => {
        if (!child.key) {
          return;
        }

        const value = child[lang];

        if (value) {
          result[lang][child.key] = value;

          return;
        }

        if (child.children && child.children.length > 0) {
          child.children.forEach(nested => {
            if (!child.key) {
              return;
            }

            const nestedValue = nested[lang];

            if (!result[lang][child.key]) {
              result[lang][child.key] = {};
            }

            if (nestedValue && nested.key) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              result[lang][child.key][nested.key] = nestedValue;
            }
          });
        }
      });
    }
  });

  return result;
};
