import { Typography } from 'antd';
import styled from 'styled-components';

export const StyledList = styled.ul`
  padding-left: 16px;
`;

export const StyledToken = styled(Typography.Paragraph)`
  max-width: 100%;
  code {
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    word-break: break-all;
  }
`;
