import { Form, Input, Modal, message } from 'antd';
import { FC, memo, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from 'gql/users/mutations';

import { showErrorMessage } from 'utils/showErrorMessage';

interface IProps {
  onClose(): void;
  id?: string;
}

const ChangeUserPassword: FC<IProps> = ({ id, onClose }) => {
  const [form] = Form.useForm();

  const closeModal = () => {
    onClose();
    form.resetFields();
  };

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onError: err => showErrorMessage(err),
    onCompleted() {
      closeModal();
      message.success('Successfully updated');
    }
  });

  const onFinish = useCallback(
    (values: Record<string, string>) => {
      changePassword({
        variables: {
          id,
          input: values
        }
      });
    },
    [changePassword, id]
  );

  return (
    <Modal
      open
      title="Change password"
      onCancel={closeModal}
      width={600}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} layout="vertical" size="large">
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(ChangeUserPassword);
