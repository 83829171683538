import { useQuery, useMutation } from '@apollo/client';
import { parseJson } from '@medicusunion/front-utils';
import { App } from 'antd';
import client from 'apolloClient';
import { OrganizationById } from 'gql/organizations/__generated__/OrganizationById';
import { UPDATE_ORGANIZATION_SETTINGS } from 'gql/organizations/mutations';
import { GET_ORGANIZATION_BY_ID } from 'gql/organizations/queries';
import { useCallback, useMemo } from 'react';

import { showErrorMessage } from 'utils/showErrorMessage';

const useOrganizationSettings = (id: string) => {
  const { message } = App.useApp();

  const { data } = useQuery<OrganizationById>(GET_ORGANIZATION_BY_ID, {
    fetchPolicy: 'cache-only',
    variables: {
      id: id
    }
  });

  const settings = useMemo(
    () => parseJson(data?.organizationById.data?.settings) || {},
    [data?.organizationById.data?.settings]
  );

  const [updateSettings, { loading }] = useMutation(
    UPDATE_ORGANIZATION_SETTINGS,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_ORGANIZATION_BY_ID] });
        message.success('Successfully saved');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onUpdateSettings = useCallback(
    (values: Record<string, unknown>) => {
      updateSettings({
        variables: {
          id,
          input: {
            settings: JSON.stringify({
              ...settings,
              ...values
            })
          }
        }
      });
    },
    [id, settings, updateSettings]
  );

  return {
    loading,
    settings,
    onUpdateSettings
  };
};

export default useOrganizationSettings;
