export const STORIES_STATUS_DRAFT = 1;
export const STORIES_STATUS_PUBLISHED = 2;
export const STORIES_STATUS_ARCHIVED = 3;

export const STORIES_TYPE_PATIENT = '1';
export const STORIES_TYPE_DOCTOR = '2';
export const STORIES_TYPE_BOTH = '3';

export const STORIES_STATUSES: { [key: number]: string } = {
  [STORIES_STATUS_DRAFT]: 'draft',
  [STORIES_STATUS_PUBLISHED]: 'published',
  [STORIES_STATUS_ARCHIVED]: 'archived'
} as const;

export const STORIES_TYPES: { [key: number]: string } = {
  [STORIES_TYPE_PATIENT]: 'patient',
  [STORIES_TYPE_DOCTOR]: 'doctor',
  [STORIES_TYPE_BOTH]: 'both'
} as const;

export const STORIES_TYPES_ARRAY = [
  {
    value: STORIES_TYPE_PATIENT,
    label: STORIES_TYPES[STORIES_TYPE_PATIENT]
  },
  {
    value: STORIES_TYPE_DOCTOR,
    label: STORIES_TYPES[STORIES_TYPE_DOCTOR]
  },
  {
    value: STORIES_TYPE_BOTH,
    label: STORIES_TYPES[STORIES_TYPE_BOTH]
  }
];

export const STORIES_STATUSES_ARRAY = [
  {
    value: STORIES_STATUS_DRAFT,
    label: STORIES_STATUSES[STORIES_STATUS_DRAFT]
  },
  {
    value: STORIES_STATUS_PUBLISHED,
    label: STORIES_STATUSES[STORIES_STATUS_PUBLISHED]
  },
  {
    value: STORIES_STATUS_ARCHIVED,
    label: STORIES_STATUSES[STORIES_STATUS_ARCHIVED]
  }
];
