import { gql } from '@apollo/client';

export const ROLES_FRAGMENT = gql`
  fragment RolesFragment on Role {
    id
    name
    value
    created_at
  }
`;

export const ROLES_BY_PARENT_FRAGMENT = gql`
  fragment RolesByParentFragment on Role {
    ...RolesFragment
  }
  ${ROLES_FRAGMENT}
`;
