export const parseJson = (data: unknown) => {
  try {
    if (typeof data !== 'string') {
      throw new Error();
    }

    const res = JSON.parse(data);

    return res;
  } catch (_) {
    return data;
  }
};

export const makeJson = (data: unknown, replacer = null, space?: number) => {
  try {
    if (!data || typeof data === 'string') {
      throw new Error();
    }

    const res = JSON.stringify(data, replacer, space);

    return res;
  } catch (_) {
    return '';
  }
};
