import { gql } from '@apollo/client';

export const UPDATE_WORKING_HOURS = gql`
  mutation UpdateWorkingHours($input: WorkingHoursInput) {
    updateWorkingHours(input: $input) {
      message
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateDoctor($input: DoctorUserInput!) {
    updateDoctor(input: $input) {
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      message
    }
  }
`;

export const ATTACH_DOCTOR_TO_SERVICES = gql`
  mutation AttachDoctorToServices($input: DoctorServices!) {
    attachDoctorToServices(input: $input) {
      message
    }
  }
`;
