import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { CMS_FIELDS } from 'constants/cms';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';

import { PageWrapper } from 'components/ui';
import { EditStaticElements } from './components';

const StaticElements: FC = () => {
  const { t } = useTranslation();
  const { toggleModal } = useUIDispatchContext();
  const { staticElements } = useModalStateContext();

  return (
    <PageWrapper title={t('common.static_elements')} color="white">
      <Table
        dataSource={CMS_FIELDS}
        columns={[
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'key',
            render(value: string) {
              return value;
            }
          },
          {
            title: '',
            dataIndex: 'config_type',
            key: 'config_type',
            width: '5%',
            render(value: string) {
              return (
                <Button
                  onClick={() =>
                    toggleModal('staticElements', true, {
                      type: value
                    })
                  }
                >
                  Edit
                </Button>
              );
            }
          }
        ]}
      />
      {staticElements?.visible && <EditStaticElements />}
    </PageWrapper>
  );
};

export default StaticElements;
