import {
  InMemoryCache,
  ApolloClient,
  HttpLink,
  from,
  Observable
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import config from 'config';
import { store } from 'store';
import { setIsLoggedIn, setUserLoading } from 'store/user/slice';

import { parentOrgStorage, subOrgStorage, tokenStorage } from 'utils/storage';
import { jwt } from 'utils/jwt';

export type ServerError = {
  message: string;
};

const cache = new InMemoryCache({
  typePolicies: {
    Specialty: {
      fields: {
        children: {
          merge(_, incoming) {
            return incoming?.length ? incoming : null;
          }
        }
      }
    }
  }
});

const graphql_uri = config.graphql_url + '/graphql';

const authMiddleware = setContext(async (_, { headers: initialHeaders }) => {
  const token = tokenStorage.get();
  const parentOrg = parentOrgStorage.get();
  const subOrg = subOrgStorage.get();

  const organization = subOrg || parentOrg;
  const organizationId = initialHeaders?.organization || organization;

  const headers = {
    ...(initialHeaders || {}),
    organization: organizationId
  };

  return {
    headers: token
      ? {
          authorization: `Bearer ${token}`,
          ...headers
        }
      : {
          ...headers
        }
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, extensions } = err;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (extensions.response?.status === 401) {
          return new Observable(observer => {
            jwt.refreshToken().then(newToken => {
              if (newToken) {
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${newToken}`
                  }
                }));

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };

                forward(operation).subscribe(subscriber);
              } else {
                store.dispatch(setUserLoading(false));
                store.dispatch(setIsLoggedIn(false));
              }
            });
          });
        } else {
          console.error(`GraphQL Error: Message: ${message}`, extensions);
        }
      }
    }

    console.log('NETWORK_ERROR__', networkError, '__NETWORK_ERROR');
  }
);

const link = from([
  errorLink,
  authMiddleware,
  new HttpLink({ uri: graphql_uri })
]);

const client = new ApolloClient({
  cache,
  link
});

export default client;
