import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_IP_CARTS_DATA } from 'gql/analytics/queries';
import { GetIpData } from 'gql/analytics/__generated__/GetIpData';
import { Card, Col, Progress, Row, Table, Tabs, Typography } from 'antd';
import { COUNTRIES } from '@medicusunion/front-utils';

import { Loading } from 'components/ui';
import MapChart from '../MapChart';

const { Title } = Typography;

export enum MapChartsKeys {
  map = 'map',
  globe = 'globe'
}

const AnalyticsMap: FC = () => {
  const { data, loading } = useQuery<GetIpData>(GET_IP_CARTS_DATA, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const results = useMemo(
    () => data?.getIpData?.data?.ip_country_data || {},
    [data?.getIpData?.data?.ip_country_data]
  );

  const sorted = Object.entries(results).map(([key, value]) => ({
    country: key,
    users: value as number
  }));

  sorted.sort((a, b) => b.users - a.users);

  const allValue = (Object.values(results) as number[]).reduce(
    (acc, val) => acc + val,
    0
  );

  if (loading || !results) {
    return <Loading />;
  }

  return (
    <Card
      bodyStyle={{
        padding: '20px 24px 16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      style={{ height: '100%' }}
      className="chart-card-with-logo"
    >
      <Title level={4} style={{ paddingBottom: 24 }}>
        Registrations by country
      </Title>
      <Row gutter={[16, 0]}>
        <Col sm={18}>
          <Tabs
            destroyInactiveTabPane
            defaultActiveKey={MapChartsKeys.map}
            items={[
              {
                label: 'Map',
                key: MapChartsKeys.map,
                children: (
                  <MapChart results={results} type={MapChartsKeys.map} />
                )
              },
              {
                label: 'Globe',
                key: MapChartsKeys.globe,
                children: (
                  <MapChart results={results} type={MapChartsKeys.globe} />
                )
              }
            ]}
          />
        </Col>
        <Col sm={6}>
          <Table
            columns={[
              {
                key: 'country',
                dataIndex: 'country',
                title: 'Country',
                render(value, record) {
                  const country = COUNTRIES.find(item => item.value === value);

                  return (
                    <>
                      <Typography.Paragraph style={{ margin: 0 }}>
                        {country?.label || value}
                      </Typography.Paragraph>
                      <Progress
                        percent={Math.round((record.users / allValue) * 100)}
                        size="small"
                        status="normal"
                      />
                    </>
                  );
                }
              },
              {
                key: 'users',
                dataIndex: 'users',
                title: `Users (${allValue})`,
                align: 'right'
              }
            ]}
            dataSource={sorted}
            pagination={false}
            rowKey="country"
            scroll={{
              y: 500
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AnalyticsMap;
