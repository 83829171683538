import { Space } from 'antd';
import styled, { css } from 'styled-components';

export const AuthWrapper = styled.div<{ $leftSiderHidden: boolean }>`
  display: flex;

  ${({ $leftSiderHidden }) =>
    $leftSiderHidden
      ? css`
          justify-content: center;
          min-height: 100vh;
        `
      : 'height: 100vh;'}
`;

export const AuthSider = styled.div`
  flex: 0 1 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
`;

export const AuthContent = styled.div<{ $leftSiderHidden: boolean }>`
  flex: 0 1 100%;
  padding: 16px 0;
  text-align: center;
  display: flex;
  align-items: center;
  ${({ $leftSiderHidden }) =>
    !$leftSiderHidden &&
    css`
      max-height: 100vh;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 44px;
        right: 100%;
        margin-right: -1px;
        top: 0;
        border-radius: 30px 0 0 30px;
        background-color: #ffffff;
      }
    `}
  > div {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div<{ image: string }>`
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex: 1;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledCarousel = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledCarouselItem = styled.div`
  height: 100vh;
  width: 100%;
`;

export const StyledCarouselContent = styled.div`
  width: 85%;
  padding: 0 70px 70px;
  h3 {
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.276;
    margin-bottom: 10px;
    margin-top: 25px;
    max-width: 466px;
  }
  p {
    font-size: 18px;
    color: #ffffff;
    line-height: 1.3;
  }
`;

export const StyledLogo = styled.div`
  position: absolute;
  top: 50px;
  left: 70px;
  z-index: 999;
`;

export const LoginContent = styled.div`
  width: 100%;
`;

export const LinkWrapper = styled.div`
  text-align: right;
  margin-bottom: 24px;

  a {
    color: var(--text-color);
  }
`;

export const SocialAuthSpace = styled(Space)`
  width: 100%;

  .ant-space-item {
    flex: 1;

    a {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(87, 86, 112, 0.2);
      border-radius: 10px;
    }
  }
`;
