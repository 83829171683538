import { StorageKeys } from 'constants/storage';

class LazyStorage {
  key: StorageKeys;
  value: string;
  constructor(key: StorageKeys) {
    this.key = key;
    this.value = '';
  }

  set(value: string) {
    this.value = value;

    return localStorage.setItem(this.key, value);
  }

  get() {
    if (this.value) {
      return this.value;
    }

    const value = localStorage.getItem(this.key);

    return value;
  }

  remove() {
    this.value = '';

    return localStorage.removeItem(this.key);
  }
}

export const tokenStorage = new LazyStorage(StorageKeys.token);
export const refreshTokenStorage = new LazyStorage(StorageKeys.refreshToken);
export const parentOrgStorage = new LazyStorage(
  StorageKeys.parentOrganizationId
);
export const subOrgStorage = new LazyStorage(StorageKeys.subOrganizationId);
export const sidebarOpenedStorage = new LazyStorage(StorageKeys.sidebarOpened);
