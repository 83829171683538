import ModalProvider from 'providers/UI';
import AuthProvider from 'providers/Auth';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider, App as AntApp } from 'antd';
import { store, persistor } from 'store';
import Routes from 'routes';
import { useTranslation } from 'react-i18next';

import {
  ConfirmModal,
  ErrorBoundary,
  NotificationModal,
  UpdateStatusModal,
  SettingsModal,
  AddTranslator
} from 'components/shared';
import { PaymentStatusModal } from 'pages/AppointmentsDetails/components';
import { Loading } from 'components/ui';
import { getFormLang } from 'utils/helpers';
import { validateMessages } from 'utils/validation';

const App = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <ConfigProvider
            form={{ validateMessages: validateMessages(t) }}
            locale={getFormLang(i18n.language)}
            theme={{
              components: {
                Layout: {
                  headerHeight: 48,
                  headerPadding: '0 24px',
                  siderBg: '#F5F7FF',
                  lightSiderBg: '#F5F7FF'
                },
                Button: {
                  borderRadius: 2,
                  borderRadiusLG: 2,
                  borderRadiusSM: 2,
                  borderRadiusXS: 2,
                  borderRadiusOuter: 2
                },
                Input: {
                  borderRadius: 2,
                  borderRadiusLG: 2,
                  borderRadiusSM: 2,
                  borderRadiusXS: 2,
                  borderRadiusOuter: 2
                },
                Select: {
                  borderRadius: 2,
                  borderRadiusLG: 2,
                  borderRadiusSM: 2,
                  borderRadiusXS: 2,
                  borderRadiusOuter: 2
                },
                DatePicker: {
                  borderRadius: 2,
                  borderRadiusLG: 2,
                  borderRadiusSM: 2,
                  borderRadiusXS: 2,
                  borderRadiusOuter: 2
                },
                Segmented: {
                  itemSelectedBg: '#2552F3',
                  itemSelectedColor: '#ffffff',
                  itemHoverBg: 'transparent',
                  borderRadius: 2,
                  borderRadiusLG: 2,
                  borderRadiusSM: 2,
                  borderRadiusXS: 2,
                  borderRadiusOuter: 2
                }
              }
            }}
          >
            <AntApp>
              <ErrorBoundary>
                <AuthProvider>
                  <ModalProvider>
                    <Routes />
                    <NotificationModal />
                    <ConfirmModal />
                    <SettingsModal />
                    <UpdateStatusModal />
                    <PaymentStatusModal />
                    <AddTranslator />
                  </ModalProvider>
                </AuthProvider>
              </ErrorBoundary>
            </AntApp>
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
