// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Form,
  Input,
  Typography,
  Collapse,
  Button,
  Card,
  Tabs,
  App
} from 'antd';
import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { LANGUAGES } from 'constants/languages';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useMutation, useQuery } from '@apollo/client';
import { CONFIG_PAGES } from 'constants/configs';
import { GetConfig } from 'gql/configs/__generated__/GetConfig';
import { GET_CONFIGS } from 'gql/configs/queries';
import { CREATE_CONFIG } from 'gql/configs/mutations';
import client from 'apolloClient';
import { useNavigate, useParams } from 'react-router-dom';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { CloseOutlined } from '@ant-design/icons';

import { showErrorMessage } from 'utils/showErrorMessage';
import { Loading, PageWrapper } from 'components/ui';
import { CKEditor, Private } from 'components/shared';
import { getInputDir } from 'utils/helpers';

const { Text } = Typography;

const itemValues = {
  title: '',
  description: ''
};

type ValuesType = typeof itemValues;
type ItemType = ValuesType & { id: string };
type BodyType = Record<string, ValuesType>;

enum DeviceTypes {
  desktop = 'desktop',
  mobile = 'mobile'
}

const TABS_DATA = [
  {
    key: DeviceTypes.desktop,
    tab: 'Desktop'
  },
  {
    key: DeviceTypes.mobile,
    tab: 'Mobile'
  }
];

const { Panel } = Collapse;

const HowItWorksContent: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { modal } = App.useApp();

  const [form] = Form.useForm();

  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const [selected, setSelected] = useState(DeviceTypes.desktop);

  const [createConfig, { loading: isCreating }] = useMutation(CREATE_CONFIG, {
    onCompleted() {
      client.refetchQueries({ include: [GET_CONFIGS] });
      navigate('/static-pages/how-it-works');
    },
    onError: err => showErrorMessage(err)
  });

  const { data, loading } = useQuery<GetConfig>(GET_CONFIGS, {
    fetchPolicy: 'cache-first',
    variables: {
      query: {
        type: CONFIG_PAGES.HOW_IT_WORKS
      }
    }
  });

  const results = useMemo(
    () => data?.getConfig?.data.results?.[0]?.body?.data || [],
    [data?.getConfig?.data.results]
  );

  const current = useMemo(
    () => results.find((item: ItemType) => item.id === id),
    [id, results]
  );

  const setFormData = useCallback(() => {
    const { content } = results.find((item: ItemType) => item.id === id);

    form.setFieldsValue(content);
  }, [form, id, results]);

  useEffect(() => {
    if (results?.length) {
      setFormData();
    }
  }, [results?.length, setFormData]);

  const onFinish = useCallback(
    (values: BodyType) => {
      setErrorKeys([]);

      const isDesktopError =
        !values[DeviceTypes.desktop] ||
        Object.values(values[DeviceTypes.desktop]).some(item => !item);

      const isMobileError =
        !values[DeviceTypes.mobile] ||
        Object.values(values[DeviceTypes.mobile]).some(item => !item);

      if (isDesktopError) {
        setSelected(DeviceTypes.desktop);

        return modal.error({
          title: 'Error',
          content: (
            <p>
              Please ensure that the <b>desktop</b> version contains content in
              all languages
            </p>
          )
        });
      }

      if (isMobileError) {
        setSelected(DeviceTypes.mobile);

        return modal.error({
          title: 'Error',
          content: (
            <p>
              Please ensure that the <b>mobile</b> version contains content in
              all languages
            </p>
          )
        });
      }

      const body = results.map((item: ItemType) => {
        if (item.id === id) {
          return {
            ...item,
            content: values
          };
        }

        return item;
      });

      return createConfig({
        variables: {
          input: {
            body: JSON.stringify({ data: body }),
            type: CONFIG_PAGES.HOW_IT_WORKS
          }
        }
      });
    },
    [createConfig, id, modal, results]
  );

  const onFinishError = (
    errorInfo: ValidateErrorEntity<
      {
        visible: boolean;
      } & BodyType
    >
  ) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  const onDeviceTypeChange = (key: DeviceTypes) => {
    setSelected(key);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper
      back
      title={`How It Works: ${current?.values?.en?.title}`}
      extra={[
        <Private
          route={PERMISSION_ROUTES.staticContent}
          action={PERMISSION_ACTIONS.update}
          key="save-content"
        >
          <Button type="primary" onClick={form.submit} loading={isCreating}>
            Save
          </Button>
        </Private>
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishError}
        layout="vertical"
        size="large"
        disabled={isCreating}
      >
        <Tabs
          activeKey={selected}
          onChange={key => onDeviceTypeChange(key as DeviceTypes)}
          items={TABS_DATA.map(tab => ({
            label: tab.tab,
            key: tab.key,
            forceRender: true,
            children: (
              <Collapse
                accordion
                defaultActiveKey={LANGUAGES[0].locale}
                items={LANGUAGES.map(item => {
                  const hasError = errorKeys.includes(item.locale);

                  return {
                    key: item.key,
                    label: item.label,
                    forceRender: true,
                    children: (
                      <Panel
                        header={
                          <Text
                            style={{
                              color: `var(--${
                                hasError ? 'error-color' : 'text-color'
                              })`
                            }}
                          >
                            {item.label}
                            {hasError ? ' (error)' : ''}
                          </Text>
                        }
                        key={item.locale}
                        forceRender
                      >
                        <Form.List name={[tab.key, item.locale]}>
                          {(fields, { add, remove }) => (
                            <div
                              style={{
                                display: 'flex',
                                rowGap: 16,
                                flexDirection: 'column'
                              }}
                            >
                              {fields.map(field => (
                                <Card
                                  size="small"
                                  title={`Menu item ${field.name + 1}`}
                                  key={field.key}
                                  extra={
                                    <CloseOutlined
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                    />
                                  }
                                >
                                  <Form.Item
                                    label="Title"
                                    name={[field.name, 'title']}
                                    rules={[{ required: true }]}
                                  >
                                    <Input dir={getInputDir(item.locale)} />
                                  </Form.Item>
                                  <Form.Item
                                    label="Content"
                                    name={[field.name, 'content']}
                                    rules={[{ required: true }]}
                                  >
                                    <CKEditor language={item.locale} />
                                  </Form.Item>
                                </Card>
                              ))}

                              <Button type="dashed" onClick={() => add()} block>
                                + Add Item
                              </Button>
                            </div>
                          )}
                        </Form.List>
                      </Panel>
                    )
                  };
                })}
              />
            )
          }))}
        />
      </Form>
    </PageWrapper>
  );
};

export default HowItWorksContent;
