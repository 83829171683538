import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { RolesGroup } from 'gql/roles/__generated__/RolesGroup';
import { GET_ROLES_GROUP } from 'gql/roles/queries';
import { FC, memo } from 'react';

interface IProps {
  value: string;
  onChange(value: string): void;
}

const RolesGroupSelect: FC<IProps> = ({ value, onChange }) => {
  const roleGroups = useQuery<RolesGroup>(GET_ROLES_GROUP, {
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      const firstValue = data.rolesGroup.data[0]?.id;

      if (!value && firstValue) {
        onChange(firstValue);
      }
    }
  });

  const results = roleGroups.data?.rolesGroup.data || [];

  return (
    <Select
      options={results.map(item => ({
        label: item.name,
        value: item.id
      }))}
      value={value}
      onChange={onChange}
    />
  );
};

export default memo(RolesGroupSelect);
