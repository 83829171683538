import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Descriptions,
  Flex,
  message,
  Select,
  Tag,
  Timeline,
  Typography,
  Image
} from 'antd';
import { useTranslation } from 'react-i18next';
import { GENDERS_VALUES } from 'constants/users';
import dayjs from 'dayjs';
import { DATE_FORMAT, FULL_DATE_FORMAT } from 'constants/dates';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_PATIENT_REQUESTS,
  GET_PATIENT_REQUESTS_COUNT,
  GET_PATIENT_REQUEST_BY_ID
} from 'gql/appointments/queries';
import {
  FAST_APPOINTMENTS_COLOR,
  FAST_APPOINTMENTS_LABEL,
  FAST_APPOINTMENTS_STATUS,
  FastAppointmentValues
} from 'constants/appointments';
import { useUIDispatchContext } from 'providers/UI';
import { UPDATE_FAST_APPOINTMENT } from 'gql/appointments/mutations';
import client from 'apolloClient';
import { OrganizationsAdmin as OrganizationsType } from 'gql/organizations/__generated__/OrganizationsAdmin';
import { GET_ORGANIZATIONS } from 'gql/organizations/queries';
import { UPDATE_FAST_APPOINTMENT_ORGANIZATION } from 'gql/organizations/mutations';

import { Loading, PageWrapper } from 'components/ui';
import { parseJson } from 'utils/json';
import { getFileUrl } from 'utils/file';
import { showErrorMessage } from 'utils/showErrorMessage';

import { GetPatientRequestByIdQuery } from '@/generated/graphql';

const { Text } = Typography;

const PatientRequestsDetails: FC = () => {
  const { toggleModal } = useUIDispatchContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // graphql
  const { data, loading, refetch } = useQuery<GetPatientRequestByIdQuery>(
    GET_PATIENT_REQUEST_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: params.id
      }
    }
  );

  const { data: organizationsData } = useQuery<OrganizationsType>(
    GET_ORGANIZATIONS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [updateDoctorPayment] = useMutation(UPDATE_FAST_APPOINTMENT, {
    onCompleted() {
      toggleModal('updateStatus', false);
      client.refetchQueries({
        include: [
          GET_PATIENT_REQUESTS,
          GET_PATIENT_REQUESTS_COUNT,
          GET_PATIENT_REQUEST_BY_ID
        ]
      });
    },
    onError: err => showErrorMessage(err)
  });

  const [updateAppointmentOrganization, { loading: isUpdating }] = useMutation(
    UPDATE_FAST_APPOINTMENT_ORGANIZATION,
    {
      onCompleted() {
        message.success(t('Organization updated successfully'));
        refetch();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const patientRequestId = data?.fastAppointmentById?.data;
  const files = parseJson(patientRequestId?.files) || [];

  if (!patientRequestId || loading) {
    return <Loading />;
  }

  const selectedOrganization =
    organizationsData?.organizationsAdmin.data.filter(item =>
      patientRequestId?.organization_info?.includes(item.id)
    );

  const patientOrganization = organizationsData?.organizationsAdmin.data.find(
    item => item.id === String(patientRequestId?.organization_id)
  );

  return (
    <PageWrapper
      back
      onClickBack={() => navigate(-1)}
      title={`Patient Request: #${params.id}`}
      extra={[
        <Button
          key="change-status-button"
          type="primary"
          onClick={() =>
            toggleModal('updateStatus', true, {
              id: params.id,
              currentStatus: `${patientRequestId.status}`,
              statusList: FAST_APPOINTMENTS_LABEL,
              onSubmit: values => {
                updateDoctorPayment({
                  variables: {
                    id: params.id,
                    input: {
                      note: values.note,
                      status: values.status
                    }
                  }
                });
              }
            })
          }
        >
          Change status
        </Button>
      ]}
    >
      <Card title="Personal Information" style={{ marginBottom: 24 }}>
        <Descriptions labelStyle={{ fontWeight: 'bold' }}>
          <Descriptions.Item label="User Name">
            {patientRequestId.first_name} {patientRequestId.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Phone number">
            <a href={`tel:${patientRequestId.phone}`}>
              {patientRequestId.phone}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <a href={`mailto:${patientRequestId.email}`}>
              {patientRequestId.email}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Gender">
            {t(
              `appointments_details.${
                GENDERS_VALUES[
                  patientRequestId.gender as keyof typeof GENDERS_VALUES
                ]
              }`
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Date of birth">
            {dayjs(patientRequestId.date_of_birth).format(DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label="Created at">
            {dayjs(patientRequestId.created_at).format(FULL_DATE_FORMAT)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="Appointment Details" style={{ marginBottom: 24 }}>
        <Descriptions
          layout="vertical"
          column={2}
          contentStyle={{ marginBottom: 12 }}
          labelStyle={{ fontWeight: 'bold' }}
        >
          <Descriptions.Item label="Health Concerns">
            {patientRequestId.note}
          </Descriptions.Item>
          {!!selectedOrganization?.length && (
            <Descriptions.Item label="Selected Organizations">
              <Flex gap={24}>
                {selectedOrganization.map(item => {
                  return (
                    <Flex gap={6} key={item.id}>
                      <Image
                        src={getFileUrl(item.image || '')}
                        width={24}
                        height={24}
                        preview={false}
                        style={{
                          borderRadius: '50%',
                          objectFit: 'cover'
                        }}
                      />
                      <Text>{item.name}</Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Files" span={1}>
            {files.length ? (
              <ul>
                {files.map((item: string, key: number) => (
                  <li key={`file-${item}-${key}`}>
                    <a
                      href={getFileUrl(item)}
                      download={item}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Organization">
            <Select
              labelInValue
              placeholder="Choose organization"
              style={{ width: 200 }}
              allowClear
              loading={isUpdating}
              value={
                patientOrganization
                  ? {
                      value: patientOrganization.id,
                      label: patientOrganization.name
                    }
                  : null
              }
              onChange={e => {
                const organizationId = e ? Number(e.value) : null;
                updateAppointmentOrganization({
                  variables: {
                    updateOrganizationFastAppointmentsId: Number(params.id),
                    input: {
                      organization_id: organizationId
                    }
                  }
                });
              }}
              options={
                selectedOrganization?.length
                  ? selectedOrganization?.map(item => ({
                      value: item.id,
                      label: item.name
                    }))
                  : organizationsData?.organizationsAdmin.data?.map(item => ({
                      value: item.id,
                      label: item.name
                    }))
              }
            />
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="History">
        <Timeline
          items={[
            ...(Array.isArray(patientRequestId.notes)
              ? patientRequestId.notes.map(item => ({
                  children: (
                    <>
                      <p key={`history-${item.id}`}>
                        Status changed to{' '}
                        <Tag
                          color={
                            FAST_APPOINTMENTS_COLOR[
                              item.status as FastAppointmentValues
                            ]
                          }
                        >
                          {
                            FAST_APPOINTMENTS_LABEL[
                              item.status as FastAppointmentValues
                            ]
                          }
                        </Tag>
                        on{' '}
                        <b>{dayjs(item.created_at).format(FULL_DATE_FORMAT)}</b>
                      </p>
                      <p style={{ fontWeight: 500 }}>{item.note}</p>
                    </>
                  )
                }))
              : []),
            {
              children: (
                <p>
                  Request created with a status{' '}
                  <Tag
                    color={
                      FAST_APPOINTMENTS_COLOR[FAST_APPOINTMENTS_STATUS.new]
                    }
                  >
                    {FAST_APPOINTMENTS_LABEL[FAST_APPOINTMENTS_STATUS.new]}
                  </Tag>
                  on{' '}
                  <b>
                    {dayjs(patientRequestId.created_at).format(
                      FULL_DATE_FORMAT
                    )}
                  </b>
                </p>
              )
            }
          ]}
        />
      </Card>
    </PageWrapper>
  );
};

export default PatientRequestsDetails;
