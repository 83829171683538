import { gql } from '@apollo/client';

export const DICOM_LINK_FRAGMENT = gql`
  fragment DicomLinkShareFragment on DicomLinkShare {
    file
    name
    password
    redirect_url
    session_id
  }
`;
