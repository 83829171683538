import {
  FC,
  useState,
  // useRef,
  // ChangeEventHandler,
  // useCallback,
  useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Space,
  Form,
  Table,
  // Input,
  Tooltip,
  Typography,
  Button
} from 'antd';
import { useQuery } from '@apollo/client';
import { GET_ACTIVITY_LOGS } from 'gql/logs/queries';
import {
  ActivityLogs as ActivityLogsType,
  ActivityLogs_activityLogs_data_results as ActivityLogsResults
} from 'gql/logs/__generated__/ActivityLogs';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { USER_ROLES } from 'constants/users';
import { Link } from 'react-router-dom';

import { PageWrapper } from 'components/ui';
import { IPInfo } from '../components';

// const { Search } = Input;
const { Text } = Typography;
const PAGE_LIMIT = 10;

const ActivityLogs: FC = () => {
  const { t } = useTranslation();
  // const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const { toggleModal } = useUIDispatchContext();
  const { ipInfo } = useModalStateContext();
  // const timer = useRef<NodeJS.Timeout | null>(null);

  const { data, loading } = useQuery<ActivityLogsType>(GET_ACTIVITY_LOGS, {
    fetchPolicy: 'no-cache',
    variables: {
      query: {
        pagination: {
          limit: PAGE_LIMIT,
          page: page
        }
      }
    }
  });

  const results = data?.activityLogs?.data.results || [];
  const total = data?.activityLogs?.data.total || 0;

  // const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
  //   e => {
  //     if (timer.current) {
  //       clearTimeout(timer.current);
  //     }

  //     timer.current = setTimeout(() => {
  //       setSearch(e.target.value);
  //     }, 500);
  //   },
  //   []
  // );

  const columns: ColumnProps<ActivityLogsResults>[] = useMemo(
    () => [
      {
        title: t('activity_logs.id'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('activity_logs.ip'),
        dataIndex: 'ip',
        key: 'ip',
        // onFilter: (value: number, item) => item.ip.startsWith(value),
        render(item) {
          return (
            <Button
              type="text"
              onClick={() => toggleModal('ipInfo', true, { ip: item })}
            >
              {item}
            </Button>
          );
        }
      },
      {
        title: t('activity_logs.user'),
        dataIndex: 'user',
        key: 'user.id',
        render(item) {
          if (!item) {
            return '-';
          }

          const name = `${item.first_name} ${item.last_name}`;

          switch (item.role?.name) {
            case USER_ROLES.doctor:
              return (
                <Link
                  to={`/access/doctors/${item.id}/personal-information/?lang=en`}
                >
                  {name}
                </Link>
              );
            default:
              return name;
          }
        }
      },
      {
        title: 'User role',
        dataIndex: 'user',
        key: 'role',
        render(item) {
          if (!item?.role) {
            return '-';
          }

          return item.role.name;
        }
      },
      {
        title: t('activity_logs.activity'),
        key: 'activity',
        dataIndex: 'activity'
      },
      {
        title: t('activity_logs.device'),
        dataIndex: 'device',
        key: 'device',
        render(item) {
          return (
            <Tooltip placement="top" title={item}>
              <Text style={{ width: 100 }} ellipsis>
                {item}
              </Text>
            </Tooltip>
          );
        }
      },
      {
        title: t('activity_logs.date'),
        dataIndex: 'date',
        key: 'date',
        render(date) {
          return dayjs(date).format(FULL_DATE_FORMAT);
        }
      }
    ],
    [t, toggleModal]
  );

  return (
    <PageWrapper title={t('activity_logs.page_title')} color="white">
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              {/* <Search
                placeholder={t('common.search')}
                onChange={onSearchChange}
              /> */}
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        rowClassName="specialty-row"
        pagination={{
          total,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
      {ipInfo?.visible && <IPInfo />}
    </PageWrapper>
  );
};

export default ActivityLogs;
