import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Spin, Empty } from 'antd';
import { useQuery } from '@apollo/client';
import { Users } from 'gql/users/__generated__/Users';
import { GET_USERS } from 'gql/users/queries';
import { USER_ROLES } from 'constants/users';
import { _debounce } from '@medicusunion/front-utils';

const PAGE_LIMIT = 20;

const debounce = _debounce();

const PatientSelect: FC = () => {
  const { t } = useTranslation();
  const [patientName, setPatientName] = useState('');
  const [patientSearchLoading, setPatientSearchLoading] = useState(false);

  const { data, loading } = useQuery<Users>(GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      query: {
        page: 0,
        limit: PAGE_LIMIT,
        role: USER_ROLES.patient,
        name: patientName
      }
    }
  });

  const patients =
    data?.users?.data.results.map(item => ({
      label: `${item.first_name} ${item.last_name} (${item.email})`,
      value: item.id as string
    })) || [];

  return (
    <Form.Item
      name="patient_id"
      label={t('appointments.patient')}
      rules={[{ required: true }]}
    >
      <Select
        size="large"
        filterOption={false}
        options={patients}
        loading={loading}
        notFoundContent={
          patientSearchLoading || loading ? (
            <Spin
              size="small"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 134
              }}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        onSearch={input => {
          if (!patientSearchLoading) {
            setPatientSearchLoading(true);
          }

          debounce(() => {
            setPatientName(input);
            setPatientSearchLoading(false);
          });
        }}
        showSearch
        placeholder={t('appointments.patient_placeholder')}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
};

export default PatientSelect;
