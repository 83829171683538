import { gql } from '@apollo/client';

export const PLAN_FRAGMENT = gql`
  fragment Plan on Plan {
    id
    name
    type
    duration
    description
    price
    features
  }
`;
