import { gql } from '@apollo/client';

import { ATTRIBUTE_GROUP_FRAGMENT } from './fragments';

export const GET_ATTRIBUTE_GROUPS = gql`
  query FetchAttributeGroups {
    fetchAttributeGroups {
      data {
        ...AttributeGroupFragment
      }
    }
  }
  ${ATTRIBUTE_GROUP_FRAGMENT}
`;

export const GET_DOCTOR_ATTRIBUTES = gql`
  query AttributesPlaceUse($query: PlaceUseQuery!) {
    attributesPlaceUse(query: $query) {
      data {
        id
        name
        slug
        type
      }
    }
  }
`;
