import { gql } from '@apollo/client';

import {
  SERVICE_LIST_FRAGMENT,
  SERVICE_LIST_FRAGMENT_BY_ID
} from './fragments';

export const GET_SERVICES = gql`
  query GetServicesForAdmin($query: getService!) {
    getServicesForAdmin(query: $query) {
      data {
        results {
          ...ServiceList
        }
        total
      }
    }
  }
  ${SERVICE_LIST_FRAGMENT}
`;

export const GET_SERVICES_BY_ID = gql`
  query GetServiceId($getServiceIdId: ID!) {
    getServiceId(id: $getServiceIdId) {
      data {
        ...ServiceTranslation
      }
    }
  }
  ${SERVICE_LIST_FRAGMENT_BY_ID}
`;
