import { SearchOutlined } from '@ant-design/icons';
import { _debounce } from '@medicusunion/front-utils';
import { Flex, Input, Select } from 'antd';
import { PLAN_DURATIONS } from 'constants/plans';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const debounce = _debounce();

const Filters: FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = PLAN_DURATIONS.find(
    item => item.value === Number(searchParams.get('duration'))
  )?.label;

  useEffect(() => {
    const value = search.trim();
    const current = searchParams.get('search');

    if (value && value !== current) {
      debounce(() => {
        searchParams.set('page', '0');
        searchParams.set('search', value);
        setSearchParams(searchParams);
      });
    } else if (current && !value) {
      searchParams.set('search', '');
      searchParams.set('page', '0');
      setSearchParams(searchParams);
    }
  }, [search, searchParams, setSearchParams]);

  return (
    <Flex gap={16}>
      <Input
        style={{ width: 537, borderRadius: 4, marginBottom: 16 }}
        size="large"
        placeholder={t('translation_plans.input_placeholder')}
        value={search}
        onChange={e => setSearch(e.target.value)}
        prefix={<SearchOutlined style={{ color: 'var(--primary)' }} />}
      />
      <Select
        allowClear
        size="large"
        placeholder={t('translation_plans.duration')}
        onChange={e => {
          if (e) {
            searchParams.set('duration', e);
            searchParams.set('page', '0');
            setSearchParams(searchParams);
          } else {
            searchParams.delete('duration');
            searchParams.set('page', '0');
            setSearchParams(searchParams);
          }
        }}
        style={{ width: 200, borderRadius: 4, marginBottom: 16 }}
        defaultValue={defaultValue}
        options={PLAN_DURATIONS}
      />
    </Flex>
  );
};

export default Filters;
