import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { Col, Form, Input, Row, Table } from 'antd';

import { StyledContent } from './styled';
import {
  TranslationsDataType,
  ResultType,
  Languages
} from 'utils/translations';
import { Loading } from 'components/ui';

export type TranslationsContentRef = {
  getData: (onlyChanged?: boolean) => ResultType[];
  reset: () => void;
};

interface IProps {
  data: TranslationsDataType;
  languages: string[];
  isFetching: boolean;
  onChange(): void;
}

const Content: ForwardRefRenderFunction<TranslationsContentRef, IProps> = (
  { data, languages, isFetching, onChange },
  ref
) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [results, setResults] = useState<ResultType[]>([]);
  const [search, setSearch] = useState('');

  useImperativeHandle(ref, () => ({
    getData: onlyChanged => {
      if (onlyChanged) {
        return results.filter(item =>
          item.children?.some(child => {
            if (child.children) {
              return child.children.some(nested =>
                languages.some(
                  lang =>
                    nested[`prev_${lang as Languages}`] &&
                    nested[`prev_${lang as Languages}`] !==
                      nested[lang as Languages]
                )
              );
            }

            return languages.some(
              lang =>
                child[`prev_${lang as Languages}`] &&
                child[`prev_${lang as Languages}`] !== child[lang as Languages]
            );
          })
        );
      }

      return results;
    },
    reset() {
      setSearch('');
      setExpandedRowKeys([]);
    }
  }));

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    lang: Languages
  ) => {
    const { name, value } = e.target;

    const [i1, i2, i3] = name.split(':').map(i => +i);

    setResults(prev => {
      if (i3 >= 0) {
        const nestedChildren = prev[i1].children?.[i2].children;

        if (nestedChildren) {
          if (!nestedChildren[i3][`prev_${lang}`]) {
            nestedChildren[i3][`prev_${lang}`] = nestedChildren[i3][lang];
          }

          nestedChildren[i3][lang] = value;
        }
      } else {
        const children = prev[i1].children;

        if (children) {
          if (!children[i2][`prev_${lang}`]) {
            children[i2][`prev_${lang}`] = children[i2][lang];
          }

          children[i2][lang] = value;
        }
      }

      return [...prev];
    });
    onChange();
  };

  useEffect(() => {
    const values: ResultType[] = [];

    if (!data.en) {
      return;
    }

    const sections = Object.keys(data.en);

    languages.forEach(lang => {
      const value = data[lang];
      sections.forEach((section, i1) => {
        const texts = value[section] || {};
        const index = values.findIndex(item => item.section === section);

        if (index > -1) {
          const children = values[index].children?.map(prevChild => {
            if (!prevChild.key) {
              throw new Error(`Error with json: ${prevChild}`);
            }

            if (prevChild.children) {
              const childValue = texts[prevChild.key] || {};

              if (typeof childValue === 'string') {
                throw new Error(`Error with json: invalid child ${childValue}`);
              }

              return {
                ...prevChild,
                children: prevChild.children.map(prevNested => {
                  if (typeof prevNested.key !== 'string') {
                    throw new Error(`Error with json: ${prevNested}`);
                  }

                  const nestedValue = childValue[prevNested.key] || '';

                  return {
                    ...prevNested,
                    [lang]: nestedValue,
                    [`has_value_${lang}`]: !!nestedValue
                  };
                })
              };
            }

            const childValue = texts[prevChild.key] || '';

            if (typeof childValue !== 'string') {
              throw new Error(`Error with json: invalid child ${childValue}`);
            }

            return {
              ...prevChild,
              [lang]: childValue,
              [`has_value_${lang}`]: !!childValue
            };
          });

          values[index] = {
            ...values[index],
            children
          };

          return;
        }

        const children: ResultType[] = Object.entries(texts).map(
          ([key, value], i2) => {
            if (typeof value !== 'string') {
              return {
                section,
                key,
                children: Object.entries(value).map(
                  ([nestedKey, nestedValue], i3) => ({
                    name: `${i1}:${i2}:${i3}`,
                    key: nestedKey,
                    [lang]: nestedValue || '',
                    [`has_value_${lang}`]: !!nestedValue
                  })
                )
              };
            }

            return {
              key,
              name: `${i1}:${i2}`,
              [lang]: value || '',
              [`has_value_${lang}`]: !!value
            };
          }
        );

        values.push({
          section,
          children
        });
      });
    });

    setResults(values);
  }, [data, languages]);

  const onExpandRowKeys = (keys: string[]) => {
    if (keys.length) {
      const first = keys[0];
      const last = keys[keys.length - 1];

      const [firstBeginning] = first.split('-');
      const [lastBeginning] = last.split('-');

      if (firstBeginning !== lastBeginning) {
        setExpandedRowKeys([last]);

        return;
      }
    }

    setExpandedRowKeys(keys);
  };

  const dataSource = useMemo(() => {
    const value = search.toLowerCase().trim();

    if (!value) {
      return results;
    }

    return results
      .map(item => {
        if (item.children) {
          return {
            ...item,
            children: item.children
              ?.map(child => {
                if (child.children) {
                  return {
                    ...child,
                    children: child.children.filter(nested =>
                      languages.some(lang => {
                        return (
                          nested?.[lang as Languages]
                            ?.toLowerCase()
                            .includes(value) ||
                          nested?.[`prev_${lang as Languages}`]
                            ?.toLowerCase()
                            .includes(value)
                        );
                      })
                    )
                  };
                }

                return child;
              })
              .filter(child => {
                if (typeof child.children !== 'undefined') {
                  return !!child.children.length;
                }

                return languages.some(
                  lang =>
                    child?.[lang as Languages]?.toLowerCase().includes(value) ||
                    child?.[`prev_${lang as Languages}`]
                      ?.toLowerCase()
                      .includes(value)
                );
              })
          };
        }

        return item;
      })
      .filter(item => item.children?.length);
  }, [languages, results, search]);

  useEffect(() => {
    if (dataSource.length === 1) {
      const item = dataSource[0];
      const keys = [`${item.section}-${item.key}-${item.name}`];

      if (item.children?.length) {
        const child = item.children[0];

        keys.push(`${child.section}-${child.key}-${child.name}`);
      }

      setExpandedRowKeys(keys);
    }
  }, [dataSource]);

  if (!results.length && isFetching) {
    return <Loading />;
  }

  return (
    <StyledContent direction="vertical">
      <Row>
        <Col span={8}>
          <Form.Item>
            <Input.Search
              size="large"
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Search by text"
            />
          </Form.Item>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={[
          {
            title: 'Section',
            dataIndex: 'section',
            key: 'section',
            width: '20%',
            render(section, record) {
              if (record.key) {
                return null;
              }

              return section;
            }
          },
          {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            width: '20%'
          },
          ...languages.map(item => ({
            title: item,
            dataIndex: item,
            key: item,
            width: `${60 / languages.length}%`,
            render: (text: string, record: ResultType) => {
              if (record.children) {
                return null;
              }

              const lang = item as Languages;

              return (
                <>
                  {record[`has_value_${lang}`] &&
                    record[`prev_${lang}`] &&
                    record[`prev_${lang}`] !== record[lang] && (
                      <p>Previous value: {record[`prev_${lang}`]}</p>
                    )}
                  <Input.TextArea
                    value={text}
                    name={record.name}
                    onChange={e => handleInputChange(e, lang)}
                    dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  />
                </>
              );
            }
          }))
        ]}
        pagination={{
          showSizeChanger: true
        }}
        rowKey={record => `${record.section}-${record.key}-${record.name}`}
        expandable={{
          onExpandedRowsChange(e) {
            onExpandRowKeys(e as string[]);
          },
          expandedRowKeys
        }}
      />
    </StyledContent>
  );
};

export default forwardRef(Content);
