import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { FC, useState } from 'react';

interface IProps {
  text: string;
}

const Password: FC<IProps> = ({ text }) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Button
        onClick={() => setVisible(!isVisible)}
        type="text"
        icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      />
      <Typography.Paragraph style={{ margin: 0 }} copyable={isVisible}>
        {isVisible ? text : new Array(text.length).fill('*')}
      </Typography.Paragraph>
    </div>
  );
};

export default Password;
