import { useMutation, useQuery } from '@apollo/client';
import {
  App,
  Button,
  Col,
  Collapse,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
  UploadFile
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { LANGUAGES } from 'constants/languages';
import {
  SERVICE_STATUSES,
  SERVICE_TYPES,
  SERVICE_TYPES_VALUES
} from 'constants/service';
import { CreateService } from 'gql/services/__generated__/CreateService';
import { GetServiceId } from 'gql/services/__generated__/GetServiceId';
import {
  ATTACH_SERVICE_TO_DOCTORS,
  CREATE_SERVICE,
  UPDATE_SERVICE
} from 'gql/services/mutations';
import { GET_SERVICES_BY_ID } from 'gql/services/queries';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';
import { GET_DOCTORS_BY_SERVICE } from 'gql/doctors/queries';
import { INFINITE_PAGE_SIZE } from 'constants/pagination';
import { GetServiceBySlugDoctors } from 'gql/doctors/__generated__/GetServiceBySlugDoctors';

import { CKEditor } from 'components/shared';
import { Loading, PageWrapper } from 'components/ui';
import Dragger from 'components/shared/Dragger';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';
import DoctorsSelect from './components/DoctorsSelect';
import { getInputDir } from 'utils/helpers';

const { Title, Paragraph, Text } = Typography;

const itemValues = {
  title: '',
  description: '',
  short_desc: '',
  meta_title: '',
  meta_description: '',
  meta_keywords: '',
  img: '',
  type: '',
  status: '',
  link: '',
  text: ''
};

type ValuesType = typeof itemValues;
type BodyType = Record<string, ValuesType>;

const ServicesDetails: FC = () => {
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const [file, setFile] =
    useState<UploadChangeParam<UploadFile<{ data: string }>>>();

  const [secondaryImg, setSecondaryImg] =
    useState<UploadChangeParam<UploadFile<{ data: string }>>>();

  const [icon, setIcon] =
    useState<UploadChangeParam<UploadFile<{ data: string }>>>();

  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();
  const [serviceType, setServiceType] = useState<number | undefined>();

  const serviceId = params?.id;

  const { data, loading: isFetching } = useQuery<GetServiceId>(
    GET_SERVICES_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        getServiceIdId: serviceId
      },
      skip: !serviceId,
      onCompleted(res) {
        setServiceType(res.getServiceId?.data.type);
      }
    }
  );

  const isImageRequired =
    serviceType === SERVICE_TYPES_VALUES['radiology'] ||
    serviceType === SERVICE_TYPES_VALUES['video_call'];

  const result = data?.getServiceId?.data;

  const { data: doctorsForServiceData, loading: isDoctorsFetching } =
    useQuery<GetServiceBySlugDoctors>(GET_DOCTORS_BY_SERVICE, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !result?.slug,
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: INFINITE_PAGE_SIZE
          },
          slug: result?.slug,
          lang: 'en'
        }
      }
    });

  const [createService, { loading: isCreating }] = useMutation<CreateService>(
    CREATE_SERVICE,
    {
      onError: err => showErrorMessage(err)
    }
  );

  const [updateService, { loading: isUpdating }] = useMutation(UPDATE_SERVICE, {
    onError: err => showErrorMessage(err)
  });

  const [attachDoctors, { loading: isAttaching }] = useMutation(
    ATTACH_SERVICE_TO_DOCTORS,
    {
      onCompleted() {
        message.success('Successfully saved!');
        navigate('/services');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onFinish = useCallback(
    async (values: BodyType) => {
      const doctors = values.doctors;
      delete values.doctors;

      setErrorKeys([]);
      const input = {
        ...values,
        main_image: file?.file?.response?.data || result?.main_image,
        img: secondaryImg?.file?.response?.data || result?.img,
        icon: icon?.file?.response?.data || result?.icon,
        type: values.type,
        status: +values.status
      };

      if (serviceId) {
        await updateService({
          variables: {
            updateServiceId: serviceId,
            input
          }
        });

        attachDoctors({
          variables: {
            input: {
              service_id: serviceId,
              doctor_ids: doctors || []
            }
          }
        });
      } else {
        const res = await createService({
          variables: {
            input
          }
        });

        if (res.data?.createService?.data?.id) {
          attachDoctors({
            variables: {
              input: {
                service_id: res.data?.createService?.data?.id,
                doctor_ids: doctors || []
              }
            }
          });
        }
      }
    },
    [
      file?.file?.response?.data,
      result?.main_image,
      result?.img,
      result?.icon,
      secondaryImg?.file?.response?.data,
      icon?.file?.response?.data,
      serviceId,
      attachDoctors,
      updateService,
      createService
    ]
  );

  const onFinishError = (errorInfo: ValidateErrorEntity<BodyType>) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  return (
    <PageWrapper
      title={serviceId ? t('permissions.UPDATE') : t('permissions.CREATE')}
      extra={[
        <Button
          type="primary"
          key="save-service"
          loading={isCreating || isUpdating || isAttaching}
          onClick={form.submit}
          size="large"
        >
          {t('common.save')}
        </Button>
      ]}
    >
      {isFetching || isDoctorsFetching ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishError}
          layout="vertical"
          size="large"
          initialValues={{
            ...result,
            doctors:
              doctorsForServiceData?.getServiceBySlugDoctors.data.results.map(
                item => item.id
              )
          }}
          scrollToFirstError
        >
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                label={t('service.main_image')}
                name="main_image"
                valuePropName="main_image"
                rules={[{ required: true }]}
              >
                <Dragger
                  listType="picture"
                  maxCount={1}
                  multiple={false}
                  accept={'image/jpeg, image/png'}
                  onChange={info => {
                    setFile(info);
                  }}
                  defaultFileList={
                    result?.main_image
                      ? [
                          {
                            name: result.main_image || '',
                            uid: result.main_image || '',
                            thumbUrl: getFileUrl(result?.main_image)
                          }
                        ]
                      : []
                  }
                >
                  <Flex vertical align="center">
                    <CloudUploadOutlined
                      style={{
                        color: 'var(--primary-color-100)',
                        fontSize: 45
                      }}
                    />
                    <Title level={4}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('service.drag_drop_files_or_browse')
                        }}
                      />
                    </Title>
                    <Paragraph style={{ color: 'var(--text-color-80)' }}>
                      {t('service.supported_formats')}
                    </Paragraph>
                  </Flex>
                </Dragger>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('service.secondary_image')}
                name="img"
                valuePropName="img"
                rules={[
                  {
                    required: isImageRequired
                  }
                ]}
              >
                <Dragger
                  listType="picture"
                  maxCount={1}
                  multiple={false}
                  accept={'image/jpeg, image/png'}
                  onChange={info => {
                    setSecondaryImg(info);
                  }}
                  defaultFileList={
                    result?.img
                      ? [
                          {
                            name: result.img || '',
                            uid: result?.img || '',
                            thumbUrl: getFileUrl(result?.img)
                          }
                        ]
                      : []
                  }
                >
                  <Flex vertical align="center">
                    <CloudUploadOutlined
                      style={{
                        color: 'var(--primary-color-100)',
                        fontSize: 45
                      }}
                    />
                    <Title level={4}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('service.drag_drop_files_or_browse')
                        }}
                      />
                    </Title>
                    <Paragraph style={{ color: 'var(--text-color-80)' }}>
                      {t('service.supported_formats')}
                    </Paragraph>
                  </Flex>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }} gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name="link"
                label={t('service.link')}
                rules={[{ required: true }]}
              >
                <Input addonBefore="https://" type="link" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label={t('service.type')}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  options={SERVICE_TYPES}
                  onChange={e => setServiceType(e)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                label={t('service.status')}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  options={SERVICE_STATUSES}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="icon"
                label={t('service.upload_icon')}
                rules={[{ required: true }]}
                valuePropName="icon"
              >
                <Dragger
                  style={{ padding: 0, border: 'none' }}
                  listType="picture"
                  maxCount={1}
                  multiple={false}
                  accept={'image/jpeg, image/png'}
                  onChange={info => {
                    setIcon(info);
                  }}
                  defaultFileList={
                    result?.icon
                      ? [
                          {
                            name: result.icon || '',
                            uid: result?.icon || '',
                            thumbUrl: getFileUrl(result?.icon)
                          }
                        ]
                      : []
                  }
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ width: '100%', overflow: 'hidden' }}
                  >
                    <Text ellipsis>{t('service.upload_icon')}</Text>
                  </Button>
                </Dragger>
              </Form.Item>
            </Col>
            <Col span={24}>
              <DoctorsSelect />
            </Col>
          </Row>
          <Collapse
            accordion
            destroyInactivePanel={false}
            defaultActiveKey={LANGUAGES[0].locale}
            items={LANGUAGES.map(item => {
              const hasError = errorKeys.includes(item.locale);

              return {
                key: item.locale,
                forceRender: true,
                label: (
                  <Text
                    style={{
                      color: `var(--${hasError ? 'error-color' : 'text-color'})`
                    }}
                  >
                    {item.label}
                  </Text>
                ),
                children: (
                  <>
                    <Form.Item
                      name={['keywords', item.locale, 'meta_title']}
                      label={t('service.meta_title')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={['keywords', item.locale, 'meta_description']}
                      label={t('service.meta_description')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={['keywords', item.locale, 'meta_keywords']}
                      label={t('service.meta_keywords')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={['title', item.locale]}
                      label={t('service.title')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={['subtitle', item.locale]}
                      label={t('service.subtitle')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={['short_desc', item.locale]}
                      label={t('service.short_desc')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={['description', item.locale]}
                      label={t('service.description')}
                      rules={[{ required: true }]}
                    >
                      <CKEditor language={item.locale} />
                    </Form.Item>
                    <Form.Item
                      name={['text', item.locale]}
                      label={t('service.benefit')}
                      rules={[{ required: true }]}
                    >
                      <CKEditor language={item.locale} />
                    </Form.Item>
                  </>
                )
              };
            })}
          />
        </Form>
      )}
    </PageWrapper>
  );
};

export default ServicesDetails;
