import { FC } from 'react';
import { Typography } from 'antd';

import { ICardNote } from './types';
import { StyledCard, NoteWrapper, Content, NoteInfo } from './styled';

const { Title, Text, Paragraph } = Typography;

const CardNote: FC<ICardNote> = ({ title, content, date, authorName }) => {
  // translations
  // const { t } = useTranslation();

  return (
    <StyledCard bordered={false}>
      <Title level={5} style={{ marginBottom: 24 }}>
        {title}
      </Title>
      <NoteWrapper>
        <Content>
          <Text>{content}</Text>
        </Content>
      </NoteWrapper>
      <NoteInfo>
        <Text style={{ color: 'rgba(45, 44, 76, 0.85)' }}>{authorName}</Text>
        <Paragraph
          style={{
            color: 'rgba(45, 44, 76, 0.45)'
          }}
        >
          {date}
        </Paragraph>
      </NoteInfo>
    </StyledCard>
  );
};

export default CardNote;
