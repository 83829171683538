import {
  App,
  Checkbox,
  Col,
  Collapse,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  message
} from 'antd';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { LANGUAGES } from 'constants/languages';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import {
  NOTIFICATION_ACTIONS,
  NotificationAction,
  NOTIFICATION_TYPES_LABELS,
  NOTIFICATION_TRIGGERS,
  NotificationType,
  NotificationTrigger,
  EMAIL_TEMPLATES,
  EmailTemplateId
} from 'constants/notifications';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION
} from 'gql/notifications/mutations';
import client from 'apolloClient';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_BY_ID
} from 'gql/notifications/queries';
import { NotificationById } from 'gql/notifications/__generated__/NotificationById';
import { useTranslation } from 'react-i18next';
import { EMAIL_FIELD_VARIABLES } from 'constants/email';

import { IDetailsProps } from './types';
import { Loading, VariablesTextarea } from 'components/ui';
import { checkRequiredValue } from 'utils/checkRequiredValue';
import { parseJson } from 'utils/json';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getInputDir } from 'utils/helpers';

const { Panel } = Collapse;
const { Text } = Typography;

const FIELD_VARIABLES = ['{{first_name}}', '{{last_name}}', '{{phone}}'];
const REGULAR_FIELDS = ['title', 'body'];

const itemValues = {
  title: '',
  body: ''
};

type ValuesType = typeof itemValues;
type BodyType = Record<string, ValuesType>;

const Details: FC<IDetailsProps> = ({ open, id, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const [template, setTemplate] = useState('');
  const [trigger, setTrigger] = useState(0);

  const handleClose = () => {
    form.resetFields();
    setTrigger(0);
    setErrorKeys([]);
    onClose();
  };

  const { loading } = useQuery<NotificationById>(GET_NOTIFICATION_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !id,
    variables: {
      id
    },
    onCompleted(data) {
      const notification = data.notificationById?.data;

      if (notification) {
        setTrigger(notification.trigger);
        setTemplate(notification.template || '');

        const translations = Object.keys(notification.title).reduce<BodyType>(
          (acc, key) => ({
            ...acc,
            [key]: {
              title: notification.title[key],
              body: notification.body[key],
              ...(notification.fields
                ? Object.keys(notification.fields[key]).reduce<
                    Record<string, string>
                  >(
                    (fields, name) => ({
                      ...fields,
                      [`field-${name}`]: notification.fields[key][name]
                    }),
                    {}
                  )
                : {})
            }
          }),
          {}
        );

        form.setFieldsValue({
          action: notification.action,
          type: notification.type,
          ...translations
        });
      }
    }
  });

  const [createNotification] = useMutation(CREATE_NOTIFICATION, {
    onError: err => showErrorMessage(err),
    onCompleted() {
      message.success('Successfully created');
      client.refetchQueries({ include: [GET_NOTIFICATIONS] });
      handleClose();
    }
  });

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    onError: err => showErrorMessage(err),
    onCompleted() {
      message.success('Successfully created');
      client.refetchQueries({ include: [GET_NOTIFICATIONS] });
      handleClose();
    }
  });

  const isEmailSelected = useMemo(
    () => checkRequiredValue(trigger, NOTIFICATION_TRIGGERS.email),
    [trigger]
  );

  const onFinish = useCallback(
    (values: BodyType) => {
      if (!trigger) {
        return modal.error({
          title: 'Error',
          content: 'Please select at least 1 trigger'
        });
      }

      const { action, type, ...rest } = values;

      const translations = Object.keys(rest).reduce<Record<string, object>>(
        (acc, key) => {
          const { title, body, fields } = acc;
          const value = rest[key];

          const titleValues = parseJson(title) || {};
          const bodyValues = parseJson(body) || {};
          const fieldsValues = parseJson(fields) || {};

          const restFields = Object.entries(rest[key])
            .filter(([field]) => !REGULAR_FIELDS.includes(field))
            .reduce((prev, field) => {
              return {
                ...prev,
                [field[0].replace('field-', '')]: field[1]
              };
            }, {});

          return {
            title: {
              ...titleValues,
              [key]: value.title
            },
            body: {
              ...bodyValues,
              [key]: value.body
            },
            fields: {
              ...fieldsValues,
              [key]: restFields
            }
          };
        },
        {}
      );

      const input = {
        action: Number(action),
        trigger,
        type,
        template: template ? template : undefined,
        ...translations,
        // TODO: figure out why we need this
        data: undefined,
        keywords: ''
      };

      if (id) {
        return updateNotification({
          variables: {
            id,
            input
          }
        });
      }

      createNotification({
        variables: {
          input
        }
      });
    },
    [trigger, template, id, createNotification, modal, updateNotification]
  );

  const onFinishError = (errorInfo: ValidateErrorEntity<BodyType>) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  const onAddVariable = (locale: string, field: string, variable: string) => {
    const current = form.getFieldValue([locale, field]);

    form.setFieldsValue({
      [locale]: { [field]: `${current || ''}${variable}` }
    });
  };

  const onChangeCheckbox = (value: 2 | 4 | 8, isChecked: boolean) => {
    if (value === NOTIFICATION_TRIGGERS.email) {
      form.resetFields();
      setTemplate('');

      setTrigger(isEmailSelected ? 0 : NOTIFICATION_TRIGGERS.email);
    } else {
      const result = trigger + (isChecked ? -value : value);

      const includesEmail = checkRequiredValue(
        result,
        NOTIFICATION_TRIGGERS.email
      );

      if (includesEmail) {
        form.resetFields();
        setTemplate('');
      }

      setTrigger(includesEmail ? result - NOTIFICATION_TRIGGERS.email : result);
    }
  };

  const actions = NOTIFICATION_ACTIONS;

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title="Notifications"
      okText={id ? 'Save' : 'Create'}
      onOk={form.submit}
      width={1000}
      destroyOnClose
    >
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishError}
          layout="vertical"
          size="large"
        >
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label="Trigger"
                tooltip="Notification methods: Multiselect for SMS and push, but not for email"
                rules={[{ required: true }]}
              >
                {Object.keys(NOTIFICATION_TRIGGERS).map(key => {
                  const value =
                    NOTIFICATION_TRIGGERS[key as NotificationTrigger];

                  const isChecked = checkRequiredValue(trigger, value);

                  return (
                    <Checkbox
                      key={key}
                      value={value}
                      checked={isChecked}
                      onChange={() => onChangeCheckbox(value, isChecked)}
                    >
                      <p style={{ userSelect: 'none', margin: 0 }}>{key}</p>
                    </Checkbox>
                  );
                })}
              </Form.Item>
            </Col>
            {isEmailSelected && (
              <>
                <Col span={24}>
                  <Form.Item
                    label="Template ID"
                    tooltip="This field is only for the email"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={Object.keys(EMAIL_TEMPLATES).map(id => ({
                        label: `${id} - ${
                          EMAIL_TEMPLATES[id as EmailTemplateId]?.title
                        }`,
                        value: id
                      }))}
                      value={template}
                      onChange={e => {
                        form.resetFields();
                        setTemplate(e);
                      }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={12}>
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Select
                  options={Object.keys(NOTIFICATION_TYPES_LABELS).map(key => ({
                    label: NOTIFICATION_TYPES_LABELS[key as NotificationType],
                    value: key
                  }))}
                  onChange={() => form.setFieldsValue({ action: undefined })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="action"
                label="Action"
                rules={[{ required: true }]}
              >
                <Select
                  options={Object.keys(actions).map(key => ({
                    label: t(`notifications.${key}`),
                    value: actions[key as NotificationAction]
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Collapse
            accordion
            defaultActiveKey={LANGUAGES[0].locale}
            items={LANGUAGES.map(item => {
              const hasError = errorKeys.includes(item.locale);

              return {
                key: item.key,
                label: item.label,
                forceRender: true,
                children: (
                  <Panel
                    header={
                      <Text
                        style={{
                          color: `var(--${
                            hasError ? 'error-color' : 'text-color'
                          })`
                        }}
                      >
                        {item.label}
                        {hasError ? ' (error)' : ''}
                      </Text>
                    }
                    key={item.locale}
                    forceRender
                  >
                    {isEmailSelected ? (
                      <>
                        {template ? (
                          EMAIL_TEMPLATES[
                            template as EmailTemplateId
                          ]?.fields.map(field => {
                            const fieldName = REGULAR_FIELDS.includes(field)
                              ? field
                              : `field-${field}`;

                            return (
                              <VariablesTextarea
                                key={`email-field-${field}`}
                                name={[item.locale, fieldName]}
                                label={t(`notifications.${field}`)}
                                language={item.locale}
                                variables={EMAIL_FIELD_VARIABLES}
                                onAddVariable={variable => {
                                  onAddVariable(
                                    item.locale,
                                    fieldName,
                                    variable
                                  );
                                }}
                              />
                            );
                          })
                        ) : (
                          <Empty description="Please select template to continue" />
                        )}
                      </>
                    ) : (
                      <>
                        <Form.Item
                          name={[item.locale, 'title']}
                          label="Title"
                          rules={[{ required: true }]}
                        >
                          <Input dir={getInputDir(item.locale)} />
                        </Form.Item>
                        <VariablesTextarea
                          name={[item.locale, 'body']}
                          label="Body"
                          language={item.locale}
                          variables={FIELD_VARIABLES}
                          onAddVariable={variable => {
                            onAddVariable(item.locale, 'body', variable);
                          }}
                        />
                      </>
                    )}
                  </Panel>
                )
              };
            })}
          />
        </Form>
      )}
    </Modal>
  );
};

export default memo(Details);
