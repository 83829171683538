/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import styled from 'styled-components';
import { Empty } from 'antd';

type Props = {
  id: string;
  results: {
    name: string;
    value: number;
    pictureSettings: {
      src: string;
    };
  }[];
};

const StyledRoot = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 70px;
    height: 25px;
    background-color: #ffffff;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
`;

const BarsMovingBullets: FC<Props> = ({ id, results }) => {
  useEffect(() => {
    if (!results.length) {
      return;
    }

    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        paddingRight: 30,
        wheelX: 'none',
        wheelY: 'none'
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, {});

    yRenderer.grid.template.set('visible', false);

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'name',
        renderer: yRenderer,
        paddingRight: 40
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 80
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: xRenderer
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Income',
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: 'value',
        categoryYField: 'name',
        sequencedInterpolation: true,
        calculateAggregates: true,
        maskBullets: false,
        tooltip: am5.Tooltip.new(root, {
          dy: -30,
          pointerOrientation: 'vertical',
          labelText: '{valueX}'
        })
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      cornerRadiusBR: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusTL: 10,
      maxHeight: 50,
      fillOpacity: 0.8
    });

    let currentlyHovered: any;

    series.columns.template.events.on('pointerover', function (e) {
      handleHover(e.target.dataItem);
    });

    series.columns.template.events.on('pointerout', function () {
      handleOut();
    });

    function handleHover(dataItem: any) {
      if (dataItem && currentlyHovered != dataItem) {
        handleOut();
        currentlyHovered = dataItem;
        const bullet = dataItem.bullets[0];
        bullet.animate({
          key: 'locationX',
          to: 1,
          duration: 600,
          easing: am5.ease.out(am5.ease.cubic)
        });
      }
    }

    function handleOut() {
      if (currentlyHovered) {
        const bullet = currentlyHovered.bullets[0];
        bullet.animate({
          key: 'locationX',
          to: 0,
          duration: 600,
          easing: am5.ease.out(am5.ease.cubic)
        });
      }
    }

    const circleTemplate = am5.Template.new({});

    series.bullets.push(function (root) {
      const bulletContainer = am5.Container.new(root, {});
      bulletContainer.children.push(
        am5.Circle.new(
          root,
          {
            radius: 34
          },
          // @ts-ignore
          circleTemplate
        )
      );

      const maskCircle = bulletContainer.children.push(
        am5.Circle.new(root, { radius: 27 })
      );

      const imageContainer = bulletContainer.children.push(
        am5.Container.new(root, {
          mask: maskCircle
        })
      );

      imageContainer.children.push(
        am5.Picture.new(root, {
          templateField: 'pictureSettings',
          centerX: am5.p50,
          centerY: am5.p50,
          width: 60,
          height: 60
        })
      );

      return am5.Bullet.new(root, {
        locationX: 0,
        sprite: bulletContainer
      });
    });

    // heatrule
    series.set('heatRules', [
      {
        dataField: 'valueX',
        min: am5.color(0xe5dc36),
        max: am5.color(0x5faa46),
        target: series.columns.template,
        key: 'fill'
      },
      {
        dataField: 'valueX',
        min: am5.color(0xe5dc36),
        max: am5.color(0x5faa46),
        target: circleTemplate,
        key: 'fill'
      }
    ]);

    series.data.setAll(results);
    yAxis.data.setAll(results);

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineX.set('visible', false);
    cursor.lineY.set('visible', false);

    cursor.events.on('cursormoved', function () {
      const dataItem = series.get('tooltip')?.dataItem;

      if (dataItem) {
        handleHover(dataItem);
      } else {
        handleOut();
      }
    });

    series.appear();
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id, results]);

  if (!results.length) {
    return (
      <StyledRoot>
        <Empty description="No data to show, please adjust filters" />
      </StyledRoot>
    );
  }

  return <StyledRoot id={id} />;
};

export default memo(BarsMovingBullets);
