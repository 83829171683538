/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import styled from 'styled-components';
import { Empty } from 'antd';

type Props = {
  id: string;
  results: {
    value: number;
    category: string;
  }[];
};

const StyledRoot = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 70px;
    height: 25px;
    background-color: #ffffff;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
`;

const PieChart: FC<Props> = ({ id, results }) => {
  useEffect(() => {
    if (!results.length) {
      return;
    }

    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        endAngle: 270
      })
    );

    series.states.create('hidden', {
      endAngle: -90
    });

    series.data.setAll(results);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id, results]);

  if (!results.length) {
    return (
      <StyledRoot>
        <Empty description="No data to show, please adjust filters" />
      </StyledRoot>
    );
  }

  return <StyledRoot id={id} />;
};

export default memo(PieChart);
