import { gql } from '@apollo/client';

export const GET_CHAT_HISTORY = gql`
  query getChatHistoryAdmin($query: GetChatHistoryQuery!) {
    getChatHistoryAdmin(query: $query) {
      data {
        results {
          created_at
          finished
          id
          session_id
          title
          user {
            id
            email
          }
        }
        total
      }
    }
  }
`;

export const GET_CHAT_BY_ID = gql`
  query getChatById($id: ID!) {
    getChatById(id: $id) {
      data {
        title
        messages {
          answer
          question
          doctors {
            id
            first_name
            last_name
            avatar
            organization {
              id
              name
              image
            }
            slug
          }
        }
      }
    }
  }
`;
