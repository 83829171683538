import { useQuery } from '@apollo/client';
import { Button, Col, Empty, Flex, Row } from 'antd';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { DoctorById } from 'gql/doctors/__generated__/DoctorById';
import { useUIDispatchContext } from 'providers/UI';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { OrganizationResult, Private } from 'components/shared';
import { PlanItem } from 'pages/DoctorProfile/components';

const Plans: FC = () => {
  // translations
  const { t } = useTranslation();
  // ui
  const { toggleModal } = useUIDispatchContext();
  // navigation
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  // graphql
  const lang = useMemo(() => searchParams.get('lang'), [searchParams]);

  const { data: profileData } = useQuery<DoctorById>(GET_DOCTOR_PROFILE, {
    fetchPolicy: 'cache-only',
    variables: {
      id,
      lang
    }
  });

  const results = profileData?.doctorById?.data.plans || [];

  return (
    <OrganizationResult>
      {results.length ? (
        <Row gutter={[16, 16]} justify="start" wrap>
          {results.map(item => (
            <Col sm={12} key={`plan-item-${item.id}`}>
              <PlanItem
                id={item.id}
                name={item.name}
                type={item.type as 1 | 2}
                duration={item.duration}
                description={item.description}
                price={item.price}
                features={item.features}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Row justify="center">
          <Empty />
        </Row>
      )}
      <Private
        route={PERMISSION_ROUTES.plan}
        action={PERMISSION_ACTIONS.create}
      >
        <Flex justify="flex-end" style={{ paddingTop: 24 }}>
          <Button
            type="primary"
            size="large"
            onClick={() => toggleModal('plans', true)}
          >
            {t('doctor_profile.add_plan')}
          </Button>
        </Flex>
      </Private>
    </OrganizationResult>
  );
};

export default memo(Plans);
