export const EMAIL_FIELD_VARIABLES = [
  '{{name}}',
  '{{code}}',
  '{{orderNumber}}',
  '{{totalPrice}}',
  '{{date}}',
  '{{time}}',
  '{{timezone}}',
  '{{btn_name}}',
  '{{btn_link}}'
];
