import { gql } from '@apollo/client';

export const SPECIALTY_LIST_FRAGMENT = gql`
  fragment SpecialtyList on Specialty {
    id
    name
    visible
    short_description
    children {
      id
      name
      visible
      short_description
    }
  }
`;

export const SPECIALTY_ITEM_FRAGMENT = gql`
  fragment SpecialtyItem on SpecialtyTranslationsType {
    id
    icon
    image
    slug
    visible
    symptom
    translates {
      id
      lang
      name
      description
      short_description
    }
    parents {
      parent_id
      specialty_id
    }
  }
`;
