import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_CARTS_DATA,
  GET_ORGANIZATION_CARTS_DATA
} from 'gql/analytics/queries';
import { Row, Col, Card, Typography } from 'antd';
import { GetChartsData } from 'gql/analytics/__generated__/GetChartsData';
import { GetOrganizationCharts } from 'gql/analytics/__generated__/GetOrganizationCharts';
import { usePermission } from 'hooks';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { Loading } from 'components/ui';
import CountCard from '../CountCard';
import OrganizationChart from '../OrganizationChart';
import PieChart from '../PieChart';

const DoctorsChart: FC = () => {
  const { checkUserPermission } = usePermission();
  const { data, loading } = useQuery<GetChartsData>(GET_CARTS_DATA, {
    fetchPolicy: 'cache-first'
  });

  const { data: organizationsData, loading: organizationLoading } =
    useQuery<GetOrganizationCharts>(GET_ORGANIZATION_CARTS_DATA, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });

  const results = useMemo(
    () => data?.getChartsData?.data,
    [data?.getChartsData?.data]
  );

  const organizationsResults = useMemo(
    () => organizationsData?.getOrganizationCharts?.data,
    [organizationsData?.getOrganizationCharts?.data]
  );

  const hasDoctorReadPermission = checkUserPermission(
    PERMISSION_ROUTES.doctors,
    PERMISSION_ACTIONS.read
  );

  if (loading || organizationLoading || !results) {
    return <Loading />;
  }

  return (
    <>
      <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
        <Col lg={{ span: 16 }}>
          <Card
            bodyStyle={{ padding: '20px 24px 16px' }}
            style={{ height: '100%' }}
          >
            <Typography.Title level={4} style={{ marginBottom: 20 }}>
              Doctors from organizations
            </Typography.Title>
            <OrganizationChart
              data={Object.entries(
                organizationsResults?.organization_count || {}
              )?.map(([key, value]) => {
                return {
                  country: key,
                  value: (value as number) || 0
                };
              })}
            />
          </Card>
        </Col>
        <Col lg={{ span: 8 }}>
          <Card
            bodyStyle={{
              padding: '20px 24px 16px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
            style={{ height: '100%' }}
          >
            <CountCard
              title="Total doctors"
              to={hasDoctorReadPermission ? '/access/doctors' : null}
              count={results.doctors?.total || 0}
            >
              <PieChart
                id="doctorsPieChart"
                data={[
                  {
                    status: `Active`,
                    value: results.doctors?.active || 0,
                    color: '#4F84E8'
                  },
                  {
                    status: `Inactive`,
                    value: results.doctors?.disabled || 0,
                    color: '#C6C9D1'
                  }
                ]}
              />
            </CountCard>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DoctorsChart;
