import { FC, useEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Empty } from 'antd';

import { ChartContainer } from '../Charts/styled';

interface IProps {
  innerRadius?: boolean;
  id: string;
  data: {
    status: string;
    value: number;
    color: string;
  }[];
}

const PieChart: FC<IProps> = ({ id, data, innerRadius = true }) => {
  const chartRef = useRef<am5percent.PieChart>();

  useEffect(() => {
    if (!data?.length) {
      return;
    }

    if (chartRef.current) {
      chartRef.current.dispose();
    }

    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: innerRadius ? am5.percent(50) : am5.percent(0.1)
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'status',
        legendLabelText: '{category}',
        legendValueText: '{value}'
      })
    );

    const colorSet = series.get('colors');

    if (colorSet) {
      const colors = data.map(item => am5.color(item.color));
      colorSet.set('colors', colors);
    }

    series.labels.template.setAll({
      visible: false
    });

    series.ticks.template.setAll({
      visible: false
    });

    series.data.setAll(data);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 20
      })
    );

    legend.data.setAll(series.dataItems);
    series.appear(1000, 100);

    chartRef.current = chart;

    return () => {
      root.dispose();
    };
  }, [data, id, innerRadius]);

  return data ? (
    <ChartContainer>
      <div id={id} className="chartdiv" />
      <div className="remove_logo" />
    </ChartContainer>
  ) : (
    <Empty className="chartdiv" />
  );
};

export default PieChart;
