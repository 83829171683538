import {
  Form,
  Image,
  Input,
  Modal,
  Typography,
  Upload,
  Collapse,
  Row
} from 'antd';
import { FC, memo, useState, useCallback, useMemo } from 'react';
import { LANGUAGES } from 'constants/languages';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useFileUpload } from 'hooks';
import { useMutation, useQuery } from '@apollo/client';
import { CONFIG_PAGES } from 'constants/configs';
import { GetConfig } from 'gql/configs/__generated__/GetConfig';
import { GET_CONFIGS } from 'gql/configs/queries';
import { CREATE_CONFIG } from 'gql/configs/mutations';
import client from 'apolloClient';
import { generateUniqueString } from '@medicusunion/front-utils';
import { CollapseProps } from 'antd/lib';

import { getFileUrl } from 'utils/file';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getInputDir } from 'utils/helpers';

const { TextArea } = Input;
const { Text } = Typography;

const itemValues = {
  title: '',
  content: '',
  description: ''
};

type ValuesType = typeof itemValues;
type ItemType = ValuesType & { id: string };
type BodyType = Record<string, ValuesType>;

const { Panel } = Collapse;

interface IProps {
  id?: string;
  onClose(): void;
}

const HowItWorksModal: FC<IProps> = ({ id, onClose }) => {
  const [form] = Form.useForm();

  const [icon, setIcon] = useState('');
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const { upload } = useFileUpload();

  const [createConfig, { loading }] = useMutation(CREATE_CONFIG, {
    onCompleted() {
      client.refetchQueries({ include: [GET_CONFIGS] });
      onClose();
    },
    onError: err => showErrorMessage(err)
  });

  const { data } = useQuery<GetConfig>(GET_CONFIGS, {
    fetchPolicy: 'cache-only',
    variables: {
      query: {
        type: CONFIG_PAGES.HOW_IT_WORKS
      }
    },
    onCompleted(data) {
      if (id) {
        const list = data?.getConfig?.data.results?.[0]?.body?.data || [];
        const { icon, values } = list.find((item: ItemType) => item.id === id);

        setIcon(icon);
        form.setFieldsValue(values);
      }
    }
  });

  const results = useMemo(
    () => data?.getConfig?.data.results?.[0]?.body?.data || [],
    [data?.getConfig?.data.results]
  );

  const onFinish = useCallback(
    (values: BodyType) => {
      setErrorKeys([]);
      const input = {
        icon,
        values
      };

      if (id) {
        const body = results.map((item: ItemType) => {
          if (item.id === id) {
            return {
              ...item,
              ...input
            };
          }

          return item;
        });

        return createConfig({
          variables: {
            input: {
              body: JSON.stringify({ data: body }),
              type: CONFIG_PAGES.HOW_IT_WORKS
            }
          }
        });
      }

      createConfig({
        variables: {
          input: {
            body: JSON.stringify({
              data: [
                ...results,
                {
                  id: generateUniqueString(),
                  ...input
                }
              ]
            }),
            type: CONFIG_PAGES.HOW_IT_WORKS
          }
        }
      });
    },
    [createConfig, icon, id, results]
  );

  const onFinishError = (
    errorInfo: ValidateErrorEntity<
      {
        visible: boolean;
      } & BodyType
    >
  ) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  const items: CollapseProps['items'] = LANGUAGES.map(item => {
    const hasError = errorKeys.includes(item.locale);

    return {
      key: item.key,
      label: item.label,
      forceRender: true,
      children: (
        <Panel
          header={
            <Text
              style={{
                color: `var(--${hasError ? 'error-color' : 'text-color'})`
              }}
            >
              {item.label}
              {hasError ? ' (error)' : ''}
            </Text>
          }
          key={item.locale}
          forceRender
        >
          <Form.Item
            name={[item.locale, 'title']}
            label="Title"
            rules={[{ required: true }]}
          >
            <Input dir={getInputDir(item.locale)} />
          </Form.Item>
          <Form.Item
            name={[item.locale, 'description']}
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea dir={getInputDir(item.locale)} />
          </Form.Item>
        </Panel>
      )
    };
  });

  return (
    <Modal
      open
      title={id ? 'Edit' : 'Create'}
      onCancel={onClose}
      width={600}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      okText="Save"
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishError}
        layout="vertical"
        size="large"
      >
        <Row justify="center">
          <Form.Item>
            <Upload
              customRequest={e =>
                upload(e.file as File).then(val => setIcon(val || ''))
              }
              multiple={false}
              maxCount={1}
              showUploadList={false}
            >
              <Image
                src={getFileUrl(icon)}
                width={100}
                height={100}
                preview={false}
                style={{ borderRadius: '50%' }}
              />
            </Upload>
          </Form.Item>
        </Row>
        <Collapse
          items={items}
          accordion
          defaultActiveKey={LANGUAGES[0].locale}
        />
      </Form>
    </Modal>
  );
};

export default memo(HowItWorksModal);
