import { FC, useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { PageWrapper } from 'components/ui';
import { DicomList, LinkPassword } from '../components';

const DicomViewer: FC = () => {
  const [isVisible, setVisible] = useState(false);

  return (
    <PageWrapper
      title="DICOM Viewer"
      color="white"
      extra={[
        <Button
          type="primary"
          style={{ width: 250 }}
          onClick={() => setVisible(true)}
          key="generate_link_password"
        >
          <PlusOutlined />
          Generate Link & Password
        </Button>
      ]}
    >
      <DicomList />
      {isVisible && <LinkPassword onClose={() => setVisible(false)} />}
    </PageWrapper>
  );
};

export default DicomViewer;
