import { FC, useCallback, useEffect } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_INVOICE_TEMPLATE,
  UPDATE_INVOICE_TEMPLATE
} from 'gql/financial/mutations';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GET_INVOICE_TEMPLATE,
  GET_INVOICE_TEMPLATES
} from 'gql/financial/queries';
import { GetInvoiceTemplate } from 'gql/financial/__generated__/GetInvoiceTemplate';
import client from 'apolloClient';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Loading, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { FieldItem } from '../components';

type FieldValues = {
  name: string;
  keys: {
    key: string;
    label: string;
    type: string;
    required: boolean;
  }[];
};

const InvoiceTemplateDetails: FC = () => {
  // navigation
  const params = useParams();
  const navigate = useNavigate();
  // form
  const [form] = Form.useForm();
  // graphql
  const { data, loading } = useQuery<GetInvoiceTemplate>(GET_INVOICE_TEMPLATE, {
    variables: {
      id: params.id
    },
    skip: !params.id
  });

  const [createTemplate, { loading: createLoading }] = useMutation(
    CREATE_INVOICE_TEMPLATE,
    {
      onCompleted() {
        navigate('/financial/templates');
        client.refetchQueries({ include: [GET_INVOICE_TEMPLATES] });
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [updateTemplate, { loading: updateLoading }] = useMutation(
    UPDATE_INVOICE_TEMPLATE,
    {
      onCompleted() {
        navigate('/financial/templates');
        client.refetchQueries({ include: [GET_INVOICE_TEMPLATES] });
      },
      onError: err => showErrorMessage(err)
    }
  );

  const result = data?.getInvoiceTemplate.data;

  useEffect(() => {
    if (result) {
      const keys = result.keys?.map(item => ({
        key: item?.key,
        required: item?.required,
        label: item?.label.en,
        type: item?.type
      }));

      form.setFieldsValue({
        name: result.name,
        keys
      });
    }
  }, [form, result]);

  const onFinish = useCallback(
    (values: FieldValues) => {
      const keys = values.keys.map((item, index) => {
        const label = { en: item.label };

        return {
          key: item.key,
          label,
          type: item.type,
          required: item.required,
          sort_index: index
        };
      });

      const input = {
        name: values.name,
        keys
      };

      if (params.id) {
        updateTemplate({
          variables: {
            id: params.id,
            input
          }
        });
      } else {
        createTemplate({
          variables: {
            input
          }
        });
      }
    },
    [createTemplate, params.id, updateTemplate]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper
      back
      onClickBack={() => navigate('/financial/templates')}
      title={params?.id ? 'Update template' : 'Create template'}
      extra={[
        <Button
          key="create-button"
          type="primary"
          onClick={form.submit}
          loading={createLoading || updateLoading}
        >
          {params?.id ? 'Save' : 'Create'}
        </Button>
      ]}
    >
      <Form
        name="object-form"
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={{
          keys: [
            {
              key: '',
              required: false,
              label: '',
              type: ''
            }
          ]
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input placeholder="Name" />
            </Form.Item>
            <Form.List name="keys">
              {(fields, { add, remove, move }) => (
                <>
                  <DragDropContext
                    onDragEnd={r =>
                      move(r.source.index, r.destination?.index || 0)
                    }
                  >
                    <Droppable droppableId="droppable">
                      {dropped => (
                        <div
                          {...dropped.droppableProps}
                          style={{ height: 240 * fields.length }}
                          ref={dropped.innerRef}
                        >
                          {fields.map(({ key, name }, index) => {
                            return (
                              <Draggable
                                key={`field-${key}`}
                                draggableId={`field-${key}`}
                                index={index}
                              >
                                {provided => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: 'none',
                                      marginBottom: 24
                                    }}
                                  >
                                    <FieldItem
                                      itemKey={key}
                                      index={index}
                                      name={name}
                                      length={fields.length}
                                      remove={remove}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Form.Item>
                    <Button onClick={() => add()} block>
                      Add Field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </PageWrapper>
  );
};

export default InvoiceTemplateDetails;
