import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from 'config';

import Login from 'pages/Auth/Login';
import Auth2FA from 'pages/Auth/Auth2FA';
import Auth from 'pages/Auth';

const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_key!}>
        <Auth />
      </GoogleReCaptchaProvider>
    ),
    children: [
      {
        path: '',
        element: <Login />
      },
      {
        path: 'auth2fa',
        element: <Auth2FA />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" />
  }
];

const PublicRoutes = () => {
  const routers = useRoutes(publicRoutes);

  return routers;
};

export default PublicRoutes;
