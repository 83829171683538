import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Button, message, Table } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ADMIN_APPOINTMENT,
  // DELETE_APPOINTMENT,
  OPEN_ROOM
} from 'gql/appointments/mutations';
import { GET_APPOINTMENT_BY_CREATOR } from 'gql/appointments/queries';
import {
  AppointmentByCreator,
  AppointmentByCreator_appointmentByCreator_data_results as AppointmentType
} from 'gql/appointments/__generated__/AppointmentByCreator';
import { OpenRoom } from 'gql/appointments/__generated__/OpenRoom';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import instance from 'services/api';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, PageWrapper } from 'components/ui';
import useCopyText from 'hooks/useCopyText';
import { StyledLink } from './styled';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getIdStart } from 'utils/string';
import { Private, OrganizationAlert } from 'components/shared';

const PAGE_LIMIT = 10;

const Sessions: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { copyText } = useCopyText();
  const [links, setLinks] = useState<Record<string, string>>({});
  const [passwords, setPasswords] = useState<Record<string, string>>({});
  const { data, loading, refetch } = useQuery<AppointmentByCreator>(
    GET_APPOINTMENT_BY_CREATOR,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  );

  const [createAppointment, { loading: isCreating }] = useMutation(
    CREATE_ADMIN_APPOINTMENT,
    {
      onCompleted() {
        message.success(t('messages.successfully_created'));
        setPage(0);
        refetch();
      },
      onError: err => showErrorMessage(err)
    }
  );

  // const [deleteAppointment] = useMutation(DELETE_APPOINTMENT, {
  //   onCompleted() {
  //     message.success(t('messages.successfully_deleted'));
  //     setPage(0);
  //     refetch();
  //   },
  //   onError: err => showErrorMessage(err)
  // });

  const [openRoom] = useMutation<OpenRoom>(OPEN_ROOM, {
    onError: err => showErrorMessage(err)
  });

  const onCopyRoom = useCallback(
    (id: string) => {
      openRoom({ variables: { input: { id } } })
        .then(({ data }) => {
          const url = data?.openRoom.data.redirect_url;
          const roomId = data?.openRoom.data.meetUrl;

          if (url) {
            const link = `${url}/home/${roomId}`;

            setLinks(prev => ({ ...prev, [id]: link }));
            copyText(link);

            return instance.post('meetings/create-room', {
              appointmentId: id
            });
          }
        })
        .then(res => {
          setPasswords(prev => ({
            ...prev,
            [id]: res?.data?.data?.password
          }));
        });
    },
    [copyText, openRoom]
  );

  const columns: ColumnProps<AppointmentType>[] = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: id => getIdStart(id)
      },
      {
        title: t('sessions.link'),
        dataIndex: 'id',
        key: 'link',
        width: '30%',
        render: id =>
          links[id] ? (
            <StyledLink onClick={() => copyText(links[id])}>
              {links[id]}
            </StyledLink>
          ) : (
            '-'
          )
      },
      {
        title: 'Password',
        dataIndex: 'id',
        key: 'password',
        render: id =>
          passwords[id] ? (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => copyText(passwords[id])}
            >
              {passwords[id]}
            </span>
          ) : (
            '-'
          )
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        width: '25%',
        render: createdAt => dayjs(createdAt).format(FULL_DATE_FORMAT)
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'actions',
        width: '5%',
        align: 'right',
        render: id => (
          <ActionMenu
            data={[
              {
                title: 'Generate link and password',
                permission: {
                  route: PERMISSION_ROUTES.sessions,
                  action: PERMISSION_ACTIONS.generateUrl
                },
                action() {
                  onCopyRoom(id);
                }
              },
              {
                title: 'Logs',
                permission: {
                  route: PERMISSION_ROUTES.super,
                  action: PERMISSION_ACTIONS.read
                },
                action() {
                  navigate(`/logs/meetings?search=${id}`);
                }
              }
              // {
              //   title: t('table.delete'),
              //   permission: {
              //     route: PERMISSION_ROUTES.sessions,
              //     action: PERMISSION_ACTIONS.delete
              //   },
              //   action() {
              //     deleteAppointment({ variables: { id } });
              //   },
              //   menuProps: {
              //     style: {
              //       color: 'var(--danger-color)'
              //     }
              //   }
              // }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [copyText, links, navigate, onCopyRoom, passwords, t]
  );

  const results = data?.appointmentByCreator?.data.results || [];

  return (
    <PageWrapper
      title="Free Calls"
      color="white"
      extra={[
        <Private
          key="add-sessions-button"
          route={PERMISSION_ROUTES.sessions}
          action={PERMISSION_ACTIONS.create}
        >
          <OrganizationAlert>
            <Button
              type="primary"
              onClick={() => createAppointment({ variables: { input: {} } })}
              loading={isCreating}
            >
              <PlusOutlined />
              {t('sessions.create')}
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.appointmentByCreator?.data.total || 0,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default Sessions;
