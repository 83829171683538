import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { App, message, Select } from 'antd';
import { useMutation } from '@apollo/client';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { APPOINTMENT_STATUS_VALUES } from 'constants/appointments';
import {
  // OPEN_ROOM,
  UPDATE_APPOINTMENT_STATUS
} from 'gql/appointments/mutations';
import client from 'apolloClient';
import { UpdateAppointmentStatus } from 'gql/appointments/__generated__/UpdateAppointmentStatus';

import { showErrorMessage } from 'utils/showErrorMessage';

interface IProps {
  appointmentId: string;
  status: number;
}

const StatusSelect: FC<IProps> = ({ appointmentId, status }) => {
  // translations
  const { t } = useTranslation();
  const { modal } = App.useApp();
  // graphql
  const [updateStatus, { loading }] = useMutation<UpdateAppointmentStatus>(
    UPDATE_APPOINTMENT_STATUS,
    {
      onCompleted() {
        message.success(t(`messages.successfully_updated`));
        client.refetchQueries({ include: [GET_APPOINTMENT_ITEM] });
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onChange = (value: string) => {
    modal.confirm({
      title: 'Warning',
      content: (
        <p>
          Changing the status may potentially introduce some inconsistencies.
          Please take a dayjs to carefully review and confirm that everything is
          in order
        </p>
      ),
      okText: 'Yes',
      onOk() {
        updateStatus({
          variables: {
            id: appointmentId,
            input: {
              status: Number(value)
            }
          }
        });
      }
    });
  };

  return (
    <Select
      style={{ width: 200, marginLeft: 16, marginRight: 16 }}
      options={Object.entries(APPOINTMENT_STATUS_VALUES).map(([id, label]) => ({
        label: t(`appointments.${label}`),
        value: id
      }))}
      value={`${status}`}
      loading={loading}
      onChange={onChange}
    />
  );
};

export default StatusSelect;
