import { Row } from 'antd';
import styled, { css } from 'styled-components';

import { StyledTimeType } from './types';

export const UsersWrapper = styled(Row)`
  padding: var(--padding-lg);
  background-color: var(--layout-body-background);
  gap: 24px;
`;

export const StyledTime = styled.button<StyledTimeType>`
  background: #ffffff;
  box-shadow: 0px 2px 40px rgba(23, 28, 146, 0.1);
  border-radius: 14px;
  padding: 14px;
  transition: all 0.4s;
  font-size: 14px;
  line-height: 21px;
  color: #0e2f5c;
  cursor: pointer;
  border: none;
  ${({ active }) =>
    active &&
    css`
      background-color: #0b1a30;
      color: #ffffff;
    `}
`;
