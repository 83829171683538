import styled, { css } from 'styled-components';

import { IWeekDay } from './types';

export const WeekDay = styled.button<IWeekDay>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  text-transform: uppercase;
  ${({ isSelected }) =>
    `${
      isSelected
        ? css`
            background-color: var(--primary);
            color: var(--white);
          `
        : css`
            background-color: var(--layout-body-background);
            color: var(--text-color);
          `
    }`}
`;
