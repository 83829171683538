import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Table } from 'antd';
import { GetDicom } from 'gql/sessions/__generated__/GetDicom';
import { GET_DICOM } from 'gql/sessions/queries';
import { FC, memo, useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { DELETE_DICOM_LINK } from 'gql/sessions/mutations';

import { Password } from 'components/shared';
import useCopyText from 'hooks/useCopyText';
import { ActionMenu } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';

const PAGE_LIMIT = 10;

const DicomList: FC = () => {
  const [page, setPage] = useState(0);
  const { copyText } = useCopyText();
  const { modal } = App.useApp();
  const { data, loading, refetch } = useQuery<GetDicom>(GET_DICOM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        pagination: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const [deleteDicomLink] = useMutation(DELETE_DICOM_LINK, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  const onClickDelete = (id: string) => {
    modal.confirm({
      title: 'Warning',
      content: <p>Are you sure?</p>,
      onOk: () => {
        deleteDicomLink({
          variables: {
            id
          }
        });
      }
    });
  };

  const results = data?.getDicom.data.results || [];
  const total = data?.getDicom.data.total || 0;

  return (
    <Table
      dataSource={results}
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Title',
          dataIndex: 'name',
          key: 'name',
          render(value) {
            return value || '-';
          }
        },
        {
          title: 'File',
          dataIndex: 'file',
          key: 'file',
          render(value) {
            return value ? (
              <a
                download={value}
                target="_blank"
                rel="noreferrer"
                href={getFileUrl(value)}
              >
                {value}
              </a>
            ) : (
              '-'
            );
          }
        },
        {
          title: 'Password',
          dataIndex: 'password',
          key: 'password',
          width: '15%',
          render(value) {
            return <Password text={value} />;
          }
        },
        {
          title: 'URL',
          dataIndex: 'redirect_url',
          key: 'redirect_url',
          width: '30%',
          render(_, record) {
            const link = `${record.redirect_url}?sessionId=${record.session_id}`;

            return (
              <div
                style={{
                  overflowX: 'auto'
                }}
              >
                <Button type="link" onClick={() => copyText(link)}>
                  Copy URL
                </Button>
              </div>
            );
          }
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'actions',
          width: '5%',
          align: 'right',
          render: id => (
            <ActionMenu
              data={[
                {
                  title: 'Delete',
                  action() {
                    onClickDelete(id);
                  },
                  menuProps: {
                    style: {
                      color: 'var(--danger-color)'
                    }
                  }
                }
              ]}
              placement="bottomRight"
              icon={<EllipsisOutlined />}
            />
          )
        }
      ]}
      loading={loading}
      rowKey="id"
      pagination={{
        total,
        pageSize: PAGE_LIMIT,
        onChange: p => setPage(p - 1),
        showSizeChanger: false
      }}
    />
  );
};

export default memo(DicomList);
