import { FC, useState } from 'react';
import { Button, List, Modal, Space, Typography } from 'antd';
import { useUIDispatchContext, useModalStateContext } from 'providers/UI';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';

import { Authentication } from 'components/shared';

const LIST_DATA = [
  {
    key: '2fa',
    label: 'Two-Factor Authentication'
  }
];

const SettingsModal: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const [page, setPage] = useState('');
  const { settings } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();

  const render = () => {
    switch (page) {
      case '2fa':
        return <Authentication />;
      default:
        return (
          <List
            bordered
            dataSource={LIST_DATA}
            renderItem={item => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => setPage(item.key)}
              >
                <Space
                  align="center"
                  style={{ width: '100%', justifyContent: 'space-between' }}
                >
                  <Typography.Text>{item.label}</Typography.Text>
                  <RightOutlined />
                </Space>
              </List.Item>
            )}
          />
        );
    }
  };

  const title = LIST_DATA.find(item => item.key === page)?.label || 'Settings';

  return (
    <Modal
      title={
        <Space align="center">
          {!!page && (
            <Button
              icon={<ArrowLeftOutlined />}
              type="text"
              onClick={() => setPage('')}
            />
          )}
          <Typography.Title level={3} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        </Space>
      }
      onCancel={() => toggleModal('settings', false)}
      open={settings.visible}
      okText={t('common.ok')}
      footer={null}
    >
      <div style={{ minHeight: 300 }}>{render()}</div>
    </Modal>
  );
};

export default SettingsModal;
