import axios from 'axios';
import config from 'config';

import { jwt } from 'utils/jwt';
import { parentOrgStorage, subOrgStorage, tokenStorage } from 'utils/storage';

type ServerError = {
  [key: string]: {
    [key: string]: {
      message: string;
    };
  };
};

type FieldsData = {
  name: string;
  errors: string[];
};

export const getFieldsDataFromServerError: (
  errors: ServerError
) => FieldsData[] = (errors: ServerError) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = Object.keys(errors).reduce((acc: any[], key: string) => {
    return [
      ...acc,
      { name: key, errors: [errors[key].message || errors[key].msg] }
    ];
  }, []);

  return error as FieldsData[];
};

const instance = axios.create({
  baseURL: config.base_url
});

instance.interceptors.request.use(
  request => {
    const token = tokenStorage.get();

    if (token && request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }

    const parentOrg = parentOrgStorage.get();
    const subOrg = subOrgStorage.get();

    const organization = subOrg || parentOrg;

    if (organization && request.headers) {
      request.headers['organization'] = organization;
    }

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await jwt.refreshToken();

        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

          return instance(originalRequest);
        }

        return Promise.reject();
      } catch (refreshError) {
        // Handle failure (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }

    if (error.response?.status === 422) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorData: any = error?.response?.data;

      return new Promise((_, reject) => {
        if (errorData) {
          if (errorData.errors) {
            const errors = getFieldsDataFromServerError(errorData.errors);

            return reject({ ...errorData, errors });
          }

          return reject({ ...errorData, status: error?.response.status });
        }

        return reject(error);
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
