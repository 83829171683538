import { useApolloClient, useMutation } from '@apollo/client';
import { App, Button, Collapse, Form, Input, Modal, Typography } from 'antd';
import { LANGUAGES } from 'constants/languages';
import { CREATE_FAQ } from 'gql/faq/mutations';
import { GET_FAQ } from 'gql/faq/queries';
import { FC, memo, useState } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useSearchParams } from 'react-router-dom';

import { showErrorMessage } from 'utils/showErrorMessage';
import { Private } from 'components/shared';
import { getInputDir } from 'utils/helpers';

interface IProps {
  maxSortIndex: number;
}

const CreateFaq: FC<IProps> = ({ maxSortIndex }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [isVisible, setVisible] = useState(false);
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('service');

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  };

  const [createFaq, { loading: isCreating }] = useMutation(CREATE_FAQ, {
    onCompleted() {
      message.success('Successfully created');
      client.refetchQueries({ include: [GET_FAQ] });
      closeModal();
    },
    onError: err => showErrorMessage(err)
  });

  const onFinish = (values: Record<string, string>) => {
    setErrorKeys([]);

    createFaq({
      variables: {
        input: {
          ...values,
          sort_index: values.sort_index
            ? Number(values.sort_index)
            : maxSortIndex,
          service_id: Number(serviceId) || null
        }
      }
    });
  };

  const onFinishError = (
    errorInfo: ValidateErrorEntity<Record<string, string>>
  ) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  return (
    <>
      <Private route={PERMISSION_ROUTES.faq} action={PERMISSION_ACTIONS.create}>
        <Button type="primary" size="large" onClick={() => setVisible(true)}>
          Create
        </Button>
      </Private>
      {isVisible && (
        <Modal
          open
          onCancel={closeModal}
          confirmLoading={isCreating}
          onOk={() => form.submit()}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishError}
          >
            <Form.Item
              name="sort_index"
              label="Display Order"
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: 'Please enter a valid number'
                },
                {
                  validator: (_, value) =>
                    value && value > maxSortIndex
                      ? Promise.reject(
                          `Number must be less than or equal to ${maxSortIndex}`
                        )
                      : Promise.resolve()
                }
              ]}
            >
              <Input defaultValue={maxSortIndex} />
            </Form.Item>
            <Collapse
              accordion
              defaultActiveKey={LANGUAGES[0].locale}
              items={LANGUAGES.map(item => {
                const hasError = errorKeys.includes(item.locale);

                return {
                  key: item.locale,
                  forceRender: true,
                  label: (
                    <Typography.Text
                      style={{
                        color: `var(--${
                          hasError ? 'error-color' : 'text-color'
                        })`
                      }}
                    >
                      {item.label}
                      {hasError ? ' (error)' : ''}
                    </Typography.Text>
                  ),
                  children: (
                    <>
                      <Form.Item
                        name={['question', item.locale]}
                        label="Question"
                        rules={[{ required: true }]}
                      >
                        <Input dir={getInputDir(item.locale)} />
                      </Form.Item>
                      <Form.Item
                        name={['answer', item.locale]}
                        label="Answer"
                        rules={[{ required: true }]}
                      >
                        <Input.TextArea dir={getInputDir(item.locale)} />
                      </Form.Item>
                    </>
                  )
                };
              })}
            />
          </Form>
        </Modal>
      )}
    </>
  );
};

export default memo(CreateFaq);
