import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  margin-bottom: 24px;
  .ant-card-body {
    padding: 24px;
    border-radius: 10px;
    background-color: var(--layout-sider-background);
  }
`;

export const NoteWrapper = styled.div`
  padding: 14px 0;
`;

export const Content = styled.div`
  max-width: 80%;
`;

export const NoteInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
