import { gql } from '@apollo/client';

import { USER_FRAGMENT } from './fragments';

export const BLOCK_USER = gql`
  mutation BlockUser($id: ID!) {
    blockUser(id: $id) {
      message
    }
  }
`;

export const UPDATE_DOCTOR_STATUS = gql`
  mutation UpdateStatus($id: ID!, $input: DoctorProfileStatusInput) {
    updateStatus(id: $id, input: $input) {
      message
      data {
        status
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      message
    }
  }
`;

export const CREATE_DOCTOR = gql`
  mutation CreateDoctor($input: CreateDoctorUserInput!) {
    createDoctor(input: $input) {
      message
    }
  }
`;

export const SEND_DOCTOR_EMAIL = gql`
  mutation SendDoctorEmail($input: DoctorEmailInput) {
    sendDoctorEmail(input: $input) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        ...UserFragment
      }
      message
    }
  }
  ${USER_FRAGMENT}
`;

export const CHANGE_PASSWORD = gql`
  mutation UpdatePasswordAdmin($id: ID!, $input: UpdatePasswordAdminInput!) {
    updatePasswordAdmin(id: $id, input: $input) {
      message
    }
  }
`;
