import { gql } from '@apollo/client';

export const CREATE_USER_GROUP_ATTRIBUTES = gql`
  mutation CreateUserGroupAttributes($input: UserAttributeGroupInput!) {
    createUserGroupAttributes(input: $input) {
      message
    }
  }
`;

export const CREATE_USER_ATTRIBUTES = gql`
  mutation CreateUserAttributes($input: DoctorAttributeInput!) {
    createUserAttributes(input: $input) {
      message
    }
  }
`;
