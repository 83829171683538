import { gql } from '@apollo/client';

export const GET_STORIES = gql`
  query GetStoriesForAdmin($query: getStoryForAdmin!) {
    getStoriesForAdmin(query: $query) {
      data {
        total
        results {
          id
          title
          status
          thumbnail
          type
          views
          created_at
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_STORY_BY_ID = gql`
  query GetStoryByIdForAdmin($getStoryByIdForAdminId: ID!) {
    getStoryByIdForAdmin(id: $getStoryByIdForAdminId) {
      message
      data {
        id
        title
        status
        thumbnail
        type
        created_at
        img
        link
        content
        organization {
          id
          name
        }
      }
    }
  }
`;
