import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  .ant-card-head {
    border: 0;

    .ant-card-head-title {
      padding: 24px 0 0;

      .ant-typography {
        display: flex;
        align-items: center;
      }
    }
  }

  .ant-card-actions {
    display: flex;
    align-items: cneter;
    justify-content: flex-end;
    border: 0;
    border-radius: 0 0 8px 8px;
    padding: 0 24px 24px;

    > li {
      width: auto !important;
      float: none;
      border: 0;
      margin: 0;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    button {
      min-width: 115px;
    }
  }
`;
