import { Button, Tour, TourProps } from 'antd';
import { DASHBOARD_TOUR_DATA } from 'constants/tours';
import { usePermission } from 'hooks';
import {
  FC,
  MutableRefObject,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectShowTourItem,
  selectTourItemStep,
  selectTourVersion
} from 'store/tour/selectors';
import { setFinishedTours, setTourStep } from 'store/tour/slice';

const DashboardTourStateContext = createContext<MutableRefObject<
  Record<string, HTMLElement>
> | null>(null);

interface IProps {
  children: ReactNode;
}

const DashboardTourProvider: FC<IProps> = ({ children }) => {
  const { t } = useTranslation();
  const { checkUserMultiPermission } = usePermission();
  const showTour = useAppSelector(selectShowTourItem('dashboard'));
  const tourStep = useAppSelector(selectTourItemStep('dashboard'));
  const tourVersion = useAppSelector(selectTourVersion('dashboard'));
  const dispatch = useAppDispatch();

  const refs = useRef<Record<string, HTMLElement>>({});

  const steps: TourProps['steps'] = useMemo(() => {
    return DASHBOARD_TOUR_DATA.filter(item =>
      checkUserMultiPermission(item.permissions)
    ).map(item => ({
      title: t(`tours.dashboard.step_${item.step}_title`),
      description: t(`tours.dashboard.step_${item.step}_desricption`),
      placement: 'rightTop',
      target: () => refs.current[item.step]
    }));
  }, [checkUserMultiPermission, t]);

  const handleFinish = () => {
    dispatch(setFinishedTours('dashboard'));
  };

  return (
    <DashboardTourStateContext.Provider value={refs}>
      <Tour
        current={tourStep}
        onChange={step => dispatch(setTourStep({ dashboard: step }))}
        open={showTour}
        onClose={handleFinish}
        steps={steps}
        key={tourVersion}
        onFinish={handleFinish}
        disabledInteraction
        closeIcon={
          <Button
            style={{ backgroundColor: 'var(--white)' }}
            onClick={handleFinish}
            size="small"
            type="link"
          >
            {t('tours.skip')}
          </Button>
        }
      />
      {children}
    </DashboardTourStateContext.Provider>
  );
};

const useDashboardTourStateContext = () => {
  const refs = useContext(DashboardTourStateContext);
  const showTour = useAppSelector(selectShowTourItem('dashboard'));

  if (typeof refs === 'undefined') {
    throw new Error(
      'useDashboardTourStateContext must be used within a DashboardTourStateContext'
    );
  }

  const findTourStepByKey = useCallback((key: string | undefined) => {
    return DASHBOARD_TOUR_DATA.find(tour => tour.key === key)?.step;
  }, []);

  return { refs, findTourStepByKey, showTour };
};

export { useDashboardTourStateContext };

export default DashboardTourProvider;
