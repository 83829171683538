import { Button, Card, Space, Table } from 'antd';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { GenderKeyType, GENDERS_VALUES } from 'constants/users';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { useUIDispatchContext } from 'providers/UI';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/dates';
import { APPOINTMENT_CREATOR } from 'constants/appointments';
import { EditOutlined } from '@ant-design/icons';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { IDetailsProps } from './types';
import { getFileUrl } from 'utils/file';
import { money } from 'utils/price';
import { UsersWrapper } from './styled';
import { InfoItem } from './components';
import { Private, UserItem } from 'components/shared';

const AppointmentDetailsTab: FC<IDetailsProps> = ({ date, isVideoCall }) => {
  const { t } = useTranslation();
  const { toggleModal } = useUIDispatchContext();
  const params = useParams();
  const navigate = useNavigate();
  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-only'
  });

  const appointment = data?.appointmentForAdmin?.data;

  const appointmentTranslatorInfo = appointment?.appointment_translators?.[0];

  const dates = useMemo(() => {
    const values = [...(appointment?.dates || [])];

    values.sort((a, b) => {
      if (a.accepted === true) {
        return -1;
      } else if (b.accepted === true) {
        return 1;
      } else {
        return 0;
      }
    });

    return values;
  }, [appointment?.dates]);

  if (!appointment) return null;

  return (
    <Space direction="vertical" size={32} style={{ width: '100%' }}>
      <Space size={48}>
        <InfoItem
          title={t('appointments_details.price')}
          subtitle={money(appointment.price || 0)}
          icon="tag"
        />
        <InfoItem
          title={t('appointments_details.created_at')}
          subtitle={dayjs(appointment?.created_at).format('DD-MM-YYYY HH:mm')}
          icon="calendar"
        />
        <InfoItem
          title={t('appointments_details.appointment_date')}
          subtitle={date}
          icon="calendar"
        />
        <InfoItem
          title={t('appointments_details.service')}
          subtitle={appointment.plan.name}
          icon="heart"
        />
      </Space>
      <UsersWrapper>
        <UserItem
          url={getFileUrl(appointment.doctor.avatar || '')}
          name={`Doctor - ${appointment.doctor.first_name} ${appointment.doctor.last_name}`}
          onClick={() =>
            navigate(
              `/access/doctors/${appointment.doctor.id}/personal-information?lang=en`
            )
          }
          info={[
            `${t('appointments_details.doctor_id')} ${appointment.doctor.id}`,
            appointment.doctor?.date_of_birth &&
              `${t('appointments_details.years', {
                num: dayjs().diff(
                  dayjs(appointment.doctor.date_of_birth),
                  'years'
                )
              })}`,
            appointment.doctor?.gender &&
              `${t(
                `appointments_details.${
                  GENDERS_VALUES[appointment.doctor.gender as GenderKeyType]
                }`
              )}`
          ]}
          circle
        />
        <UserItem
          url={getFileUrl(appointment.patient.avatar || '')}
          name={`Patient - ${appointment.patient.first_name} ${appointment.patient.last_name}`}
          info={[
            `${t('appointments_details.patient_id')} ${appointment.patient.id}`,
            appointment.patient?.date_of_birth &&
              `${t('appointments_details.years', {
                num: dayjs().diff(
                  dayjs(appointment.patient.date_of_birth),
                  'years'
                )
              })}`,
            appointment.patient?.gender &&
              `${t(
                `appointments_details.${
                  GENDERS_VALUES[appointment.patient.gender as GenderKeyType]
                }`
              )}`
          ]}
          circle
        />
        {appointmentTranslatorInfo?.translator?.first_name &&
          appointmentTranslatorInfo.translator.last_name && (
            <UserItem
              url={getFileUrl(appointmentTranslatorInfo.translator.avatar)}
              name={`${appointmentTranslatorInfo.translator.first_name} ${appointmentTranslatorInfo.translator.last_name}`}
              info={[
                `${t('appointments_details.translator_id')} ${
                  appointmentTranslatorInfo.translator.id
                }`,
                `${appointmentTranslatorInfo.translator_plan?.lang_1} / ${appointmentTranslatorInfo.translator_plan?.lang_2}`
              ]}
              circle
            />
          )}
      </UsersWrapper>
      {isVideoCall && !!appointment?.dates.length && (
        <Card bordered title="Appointment dates">
          <Table
            rowKey="id"
            dataSource={dates}
            rowClassName={row => (row.accepted ? 'table-bg-green' : '')}
            columns={[
              {
                title: 'Requested by',
                dataIndex: 'sender',
                key: 'sender',
                render: value =>
                  value === APPOINTMENT_CREATOR.doctor ? 'Doctor' : 'Patient'
              },
              {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: value =>
                  dayjs(value).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
              },
              {
                title: 'Accepted',
                dataIndex: 'accepted',
                key: 'accepted',
                render: value => (value ? 'Yes' : 'No')
              },
              {
                title: '',
                dataIndex: 'id',
                key: 'actions',
                align: 'right',
                render: (value: string) => (
                  <Private
                    route={PERMISSION_ROUTES.appointments}
                    action={PERMISSION_ACTIONS.changeDate}
                  >
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        toggleModal('dateModal', true, {
                          id: params?.id || '',
                          dateId: value
                        });
                      }}
                    />
                  </Private>
                )
              }
            ]}
          />
        </Card>
      )}
    </Space>
  );
};

export default memo(AppointmentDetailsTab);
