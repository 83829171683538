import { FC } from 'react';
import {
  useOrganizationsDispatchContext,
  useOrganizationsStateContext
} from 'providers/Organizations';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { SelectItem, StyledSelect } from './styled';

interface IProps {
  isDisabled: boolean;
  onClickDisabled(): void;
}

const { Text } = Typography;

const SubOrganizationSelect: FC<IProps> = ({ isDisabled, onClickDisabled }) => {
  const { t } = useTranslation();
  const { onChangeSub } = useOrganizationsDispatchContext();
  const { subResults, subSelected } = useOrganizationsStateContext();

  const options = subResults?.map(item => ({
    label: <Text style={{ fontWeight: 600, fontSize: 16 }}>{item.label}</Text>,
    value: item.value
  }));

  if (isDisabled) {
    const selected = options.find(item => item.value === subSelected);

    if (!selected) {
      return null;
    }

    return <SelectItem onClick={onClickDisabled}>{selected.label}</SelectItem>;
  }

  if (!subResults?.length) {
    return null;
  }

  return (
    <StyledSelect
      style={{ width: 200 }}
      options={options}
      value={subSelected}
      onChange={e => onChangeSub((e || '') as string)}
      placeholder={t('common.select_sub_organizations')}
      allowClear={!!subSelected}
    />
  );
};

export default SubOrganizationSelect;
