import { gql } from '@apollo/client';

import { DOCTOR_LIST_FRAGMENT, DOCTOR_PROFILE_FRAGMENT } from './fragments';

export const GET_DOCTOR_PROFILE = gql`
  query DoctorById($id: ID!, $lang: String) {
    doctorById(id: $id, lang: $lang) {
      data {
        ...DoctorProfile
      }
    }
  }
  ${DOCTOR_PROFILE_FRAGMENT}
`;

export const GET_DOCTORS_BY_STATUS = gql`
  query DoctorsByStatus($query: DoctorStatusInput!) {
    doctorsByStatus(query: $query) {
      data {
        results {
          ...DoctorListFragment
        }
        total
      }
    }
  }
  ${DOCTOR_LIST_FRAGMENT}
`;

export const GET_DOCTORS_BY_SERVICE = gql`
  query GetServiceBySlugDoctors($query: GetServiceBySlug!) {
    getServiceBySlugDoctors(query: $query) {
      data {
        results {
          id
        }
      }
    }
  }
`;

export const GET_DOCTORS = gql`
  query Doctors($query: DoctorSearchInput!) {
    doctors(query: $query) {
      data {
        results {
          id
          first_name
          last_name
          avatar
        }
        total
      }
    }
  }
`;
