import { gql } from '@apollo/client';

export const CREATE_BLOG = gql`
  mutation CreateBlog($input: BlogsLangInput) {
    createBlog(input: $input) {
      message
    }
  }
`;

export const UPDATE_BLOG = gql`
  mutation UpdateBlog($id: ID!, $input: BlogsLangInput) {
    updateBlog(id: $id, input: $input) {
      message
    }
  }
`;

export const DELETE_BLOG = gql`
  mutation DeleteBlog($id: ID!) {
    deleteBlog(id: $id) {
      message
    }
  }
`;
