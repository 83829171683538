import React, { FC, memo, useEffect, useMemo, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import styled from 'styled-components';

import { MapChartsKeys } from '../AnalyticsMap';

const ID_PREFIX = 'chartdiv';

type Props = {
  results: Record<string, number>;
  type: MapChartsKeys;
};

const StyledRoot = styled.div`
  position: relative;
  height: 600px;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    background-color: #ffffff;
    z-index: 999;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
  }
`;

const MapChart: FC<Props> = ({ results, type }) => {
  const root = useRef<am5.Root | null>(null);
  const id = useMemo(() => `${ID_PREFIX}${type}`, [type]);

  const showMap = React.useCallback(
    (data: Record<string, number>) => {
      if (root.current) {
        return;
      }

      const numbers = Object.entries(data);
      const colorMap = [
        '#174ae6',
        '#1967d2',
        '#4385f4',
        '#6fa3ef',
        '#8ebdff',
        '#a8c9ff'
      ];

      numbers.sort((a, b) => b[1] - a[1]);

      function getValueColor(index: number) {
        switch (index) {
          case 0:
          case 1:
            return colorMap[0];
          case 2:
          case 3:
            return colorMap[1];
          case 4:
          case 5:
            return colorMap[2];
          case 6:
          case 7:
            return colorMap[3];
          case 9:
          case 10:
            return colorMap[4];
          default:
            return colorMap[5];
        }
      }

      const countriesWithColors = numbers.reduce<Record<string, string>>(
        (acc, val, index) => {
          const [country] = val;

          const color = getValueColor(index);

          return {
            ...acc,
            [country]: color
          };
        },
        {}
      );

      am5.ready(function () {
        root.current = am5.Root.new(id);

        root.current.setThemes([am5themes_Animated.new(root.current)]);

        const chart = root.current.container.children.push(
          am5map.MapChart.new(
            root.current,
            type === MapChartsKeys.map
              ? {
                  panX: 'translateX',
                  panY: 'translateY',
                  paddingBottom: 100,
                  projection: am5map.geoMercator()
                }
              : {
                  panX: 'rotateX',
                  panY: 'rotateY',
                  paddingBottom: 100,
                  projection: am5map.geoOrthographic()
                }
          )
        );

        const backgroundSeries = chart.series.push(
          am5map.MapPolygonSeries.new(root.current, {})
        );

        if (type === MapChartsKeys.globe) {
          backgroundSeries.mapPolygons.template.setAll({
            fill: root.current.interfaceColors.get('alternativeBackground'),
            fillOpacity: 0.01,
            strokeOpacity: 0
          });
          backgroundSeries.data.push({
            geometry: am5map.getGeoRectangle(90, 180, -90, -180)
          });

          const graticuleSeries = chart.series.push(
            am5map.GraticuleSeries.new(root.current, {})
          );

          graticuleSeries.mapLines.template.setAll({
            strokeOpacity: 0.1,
            stroke: root.current.interfaceColors.get('alternativeBackground')
          });

          chart.animate({
            key: 'rotationX',
            from: 0,
            to: 360,
            duration: 30000,
            loops: Infinity
          });
        }

        const polygonSeries = chart.series.push(
          am5map.MapPolygonSeries.new(root.current, {
            geoJSON: {
              ...am5geodata_worldLow,
              features: am5geodata_worldLow.features.map(item => ({
                ...item,
                properties: {
                  ...item.properties,
                  value: data?.[item?.properties?.id] || ''
                }
              }))
            },
            exclude: type === MapChartsKeys.map ? ['AZ', 'AQ'] : ['AZ']
          })
        );

        polygonSeries.mapPolygons.template.setAll({
          tooltipText: '{name} {value}',
          toggleKey: 'active',
          interactive: true
        });

        polygonSeries.mapPolygons.template.adapters.add(
          'fill',
          function (_, target) {
            const color =
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              countriesWithColors[target?.dataItem?.dataContext?.id] ||
              '#DDDDDD';

            return am5.color(color);
          }
        );

        chart.set('zoomControl', am5map.ZoomControl.new(root.current, {}));

        chart.chartContainer.get('background')?.events.on('click', function () {
          chart.goHome();
        });

        chart.appear(1000, 100);
      });

      return () => {
        root.current?.dispose();
      };
    },
    [id, type]
  );

  useEffect(() => {
    if (Object.values(results).length) {
      setTimeout(() => showMap(results), 500);
    }
  }, [results, showMap]);

  return <StyledRoot id={id} />;
};

export default memo(MapChart);
