import { gql } from '@apollo/client';
import { PLAN_FRAGMENT } from 'gql/plans/fragments';

export const DOCTOR_ATTRIBUTE_GROUPS_FRAGMENT = gql`
  fragment DoctorAttributeGroupsFragment on UserGroupAttributes {
    id
    name
    group_id
    sort_index
    attributes {
      id
      name
      type
      value
      row_index
      attribute_id
    }
  }
`;

export const DOCTOR_PROFILE_FRAGMENT = gql`
  fragment DoctorProfile on DoctorType {
    id
    first_name
    last_name
    phone
    email
    avatar
    address
    cover
    removed
    about
    slug
    gender
    status
    cover
    languages
    gender
    working_hours
    status
    timezone
    info
    specialties {
      id
      name
    }
    service {
      id
      title
    }
    tags {
      id
    }
    plans {
      ...Plan
    }
    groups {
      ...DoctorAttributeGroupsFragment
    }
  }
  ${PLAN_FRAGMENT}
  ${DOCTOR_ATTRIBUTE_GROUPS_FRAGMENT}
`;

export const DOCTOR_LIST_FRAGMENT = gql`
  fragment DoctorListFragment on DoctorType {
    id
    avatar
    phone
    email
    status
    first_name
    last_name
  }
`;
