import { ActionTypes, State, Action } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_BLOCKED_VISIBLE:
      return { ...state, isBlockedVisible: action.data as boolean };
    case ActionTypes.SET_TMP_TOKEN:
      return { ...state, tmpToken: action.data as string };
    default:
      return state;
  }
};

export default reducer;
