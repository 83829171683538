import { State, Action, ActionTypes } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL: {
      const { key, value, params } = action.data;

      return {
        ...state,
        modal: { ...state.modal, [key]: { visible: value, params } }
      };
    }

    default:
      return state;
  }
};

export default reducer;
