import { FC, useCallback, useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useAuthDispatchContext } from 'providers/Auth';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from 'providers/Auth/types';

import { LoginContent } from './styled';
import { refreshTokenStorage, tokenStorage } from 'utils/storage';

const { Title } = Typography;

const Login: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { signIn, getUserData } = useAuthDispatchContext();
  const [isLoading, setLoading] = useState(false);

  const onFinish = useCallback(
    (values: LoginForm) => {
      setLoading(true);
      signIn(values)
        .then(data => {
          if (data.settings?.auth2fa) {
            return navigate('/auth2fa');
          }

          if (data.token) {
            tokenStorage.set(data.token);
          }

          if (data.refreshToken) {
            refreshTokenStorage.set(data.refreshToken);
          }

          getUserData();
        })
        .catch(e => {
          e?.errors && form.setFields(e.errors);
          setLoading(false);
        });
    },
    [signIn, getUserData, navigate, form]
  );

  return (
    <LoginContent>
      <Title level={2} style={{ marginBottom: 24 }}>
        Welcome back!
      </Title>
      <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
          style={{ marginBottom: 16 }}
          messageVariables={{ label: 'Email' }}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true }]}
          style={{ marginBottom: 8 }}
          messageVariables={{ label: 'Password' }}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            Login
          </Button>
        </Form.Item>
      </Form>
    </LoginContent>
  );
};

export default Login;
