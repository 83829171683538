import { Col, Form, Row, Table, Input, Button } from 'antd';
import { t } from 'i18next';
import { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { useUIDispatchContext } from 'providers/UI';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ROLES_BY_PARENT } from 'gql/roles/queries';
import { DELETE_ROLE } from 'gql/roles/mutations';
import { useNavigate } from 'react-router-dom';
import { RoleByParent } from 'gql/roles/__generated__/RoleByParent';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { RolesGroupSelect } from '../components';
import { Private } from 'components/shared';

const Roles: FC = () => {
  const navigate = useNavigate();
  const [roleGroup, setRoleGroup] = useState('');
  const [search, setSearch] = useState('');
  const timer = useRef<NodeJS.Timeout | null>(null);
  const { toggleModal } = useUIDispatchContext();

  const { data, loading, refetch } = useQuery<RoleByParent>(
    GET_ROLES_BY_PARENT,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !roleGroup,
      variables: {
        parentId: roleGroup
      }
    }
  );

  const [deleteRole] = useMutation(DELETE_ROLE, {
    onCompleted() {
      refetch();
    },
    onError(err) {
      showErrorMessage(err);
    }
  });

  const results = data?.roleByParent.data.filter(item =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    },
    []
  );

  const onDeleteRole = useCallback(
    (id: string) => {
      toggleModal('confirm', true, {
        onConfirm() {
          deleteRole({
            variables: {
              id
            }
          });
        }
      });
    },
    [deleteRole, toggleModal]
  );

  return (
    <PageWrapper
      title="Roles"
      color="white"
      extra={[
        <Private
          key="create-role"
          route={PERMISSION_ROUTES.roles}
          action={PERMISSION_ACTIONS.create}
        >
          <Button
            type="primary"
            onClick={() => navigate('details')}
            disabled={!roleGroup}
          >
            <PlusOutlined />
            Create role
          </Button>
        </Private>
      ]}
    >
      <Row gutter={[16, 0]}>
        <Col sm={6}>
          <Form.Item>
            <Input.Search
              placeholder={t('common.search')}
              onChange={onSearchChange}
            />
          </Form.Item>
        </Col>
        <Col sm={6}>
          <Form.Item>
            <RolesGroupSelect value={roleGroup} onChange={setRoleGroup} />
          </Form.Item>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
          },
          {
            title: '',
            dataIndex: 'id',
            key: 'x',
            width: '5%',
            align: 'right',
            render: id => (
              <ActionMenu
                data={[
                  {
                    title: t('table.edit'),
                    permission: {
                      route: PERMISSION_ROUTES.roles,
                      action: PERMISSION_ACTIONS.update
                    },
                    action() {
                      navigate(`details/${id}`);
                    }
                  },
                  {
                    title: t('table.delete'),
                    permission: {
                      route: PERMISSION_ROUTES.roles,
                      action: PERMISSION_ACTIONS.delete
                    },
                    action: () => onDeleteRole(id),
                    menuProps: {
                      style: {
                        color: 'var(--danger-color)'
                      }
                    }
                  }
                ]}
                placement="bottomRight"
                icon={<EllipsisOutlined />}
              />
            )
          }
        ]}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 10,
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default Roles;
