import { EditOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Space, Tag, Typography } from 'antd';
import {
  APPOINTMENT_STATES,
  APPOINTMENT_STATES_COLOR,
  AppointmentStateType
} from 'constants/appointments';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import dayjs from 'dayjs';
import { useUIDispatchContext } from 'providers/UI';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Private } from 'components/shared';

const PaymentInfo: FC = () => {
  // navigation
  const params = useParams();
  // context
  const { toggleModal } = useUIDispatchContext();
  // graphql
  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-only'
  });

  const appointment = data?.appointmentForAdmin?.data;
  const adminNote = appointment?.admin_notes?.[0];

  if (!appointment) {
    return null;
  }

  return (
    <div>
      <Card
        title="Appointment state"
        extra={
          <Private
            route={PERMISSION_ROUTES.appointments}
            action={PERMISSION_ACTIONS.updateState}
          >
            <Button
              icon={<EditOutlined />}
              onClick={() =>
                toggleModal('appointmentState', true, { id: params?.id })
              }
            />
          </Private>
        }
        style={{ marginBottom: 24 }}
      >
        <Space>
          <Typography.Title level={5} style={{ margin: 0 }}>
            State:{' '}
          </Typography.Title>
          <Tag
            color={
              APPOINTMENT_STATES_COLOR[
                appointment.state as AppointmentStateType
              ]
            }
          >
            {APPOINTMENT_STATES[appointment.state as AppointmentStateType]}
          </Tag>
        </Space>
        <div style={{ marginTop: 16 }}>
          <Typography.Title level={5}>Notes</Typography.Title>
          <Space>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Date:{' '}
            </Typography.Title>
            {adminNote?.created_at && (
              <Typography.Text>
                {dayjs(adminNote.created_at).format('YYYY-MM-DD HH:mm')}
              </Typography.Text>
            )}
          </Space>
          <Typography.Paragraph>{adminNote?.note || '-'}</Typography.Paragraph>
        </div>
      </Card>
    </div>
  );
};

export default PaymentInfo;
