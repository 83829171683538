import { FC } from 'react';
import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { INVOICE_KEYS } from 'constants/invoice';
import { CloseCircleFilled } from '@ant-design/icons';

import { StyledCard, StyledRemove } from './styled';

const { Option } = Select;

interface IProps {
  itemKey: number;
  index: number;
  name: number;
  length: number;
  remove(index: number): void;
}

const FieldItem: FC<IProps> = ({ itemKey, index, name, length, remove }) => {
  return (
    <StyledCard
      title={`#${index + 1}`}
      extra={[
        <Form.Item
          name={[name, 'required']}
          label="Mandatory"
          valuePropName="checked"
          key={`required-${itemKey}`}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 8,
            margin: 0
          }}
        >
          <Switch style={{ marginBottom: 8 }} />
        </Form.Item>
      ]}
    >
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item
            name={[name, 'key']}
            label="Key"
            rules={[{ required: true }]}
            tooltip="Unique name of the field (please use lowercase and avoid spaces)"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={[name, 'label']}
            label="Label"
            rules={[{ required: true }]}
            tooltip="Field Label Visible to Doctor"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={[name, 'type']}
            label="Type"
            rules={[{ required: true }]}
            tooltip="Field Value Type"
          >
            <Select>
              {INVOICE_KEYS.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {length > 1 && (
        <StyledRemove
          onClick={() => {
            remove(index);
          }}
          icon={
            <CloseCircleFilled style={{ color: '#FF5733', fontSize: 24 }} />
          }
          type="text"
        />
      )}
    </StyledCard>
  );
};

export default FieldItem;
