import { Button, Form, Input, Modal, Select } from 'antd';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { useMutation } from '@apollo/client';
import client from 'apolloClient';
import { CREATE_DOCTOR, UPDATE_USER } from 'gql/users/mutations';
import { USER_FRAGMENT } from 'gql/users/fragments';
import { Users_users_data_results as UserType } from 'gql/users/__generated__/Users';
import { CreateDoctorUserInput } from '__generated__/globalTypes';
import { GET_DOCTORS_BY_STATUS } from 'gql/doctors/queries';
import { useOrganizationsStateContext } from 'providers/Organizations';
import { PlusOutlined } from '@ant-design/icons';

import { showErrorMessage } from 'utils/showErrorMessage';
import { OrganizationAlert } from 'components/shared';

const AddDoctor: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { toggleModal } = useUIDispatchContext();
  const { doctorDetails } = useModalStateContext();
  const { selected, results } = useOrganizationsStateContext();

  const doctorId = useMemo(
    () => doctorDetails.params?.id,
    [doctorDetails.params?.id]
  );

  const closeModal = () => {
    form.resetFields();
    toggleModal('doctorDetails', false);
  };

  const user: UserType | null = client.readFragment({
    id: `User:${doctorId}`,
    fragment: USER_FRAGMENT
  });

  const [createUser, { loading: isCreating }] = useMutation(CREATE_DOCTOR, {
    onCompleted() {
      client.refetchQueries({ include: [GET_DOCTORS_BY_STATUS] });
      closeModal();
    },
    onError: err => showErrorMessage(err)
  });

  const [updateUser, { loading: isUpdating }] = useMutation(UPDATE_USER, {
    onCompleted() {
      closeModal();
    },
    onError: err => showErrorMessage(err)
  });

  const onFinish = useCallback(
    (values: CreateDoctorUserInput & { organization_id?: string }) => {
      const headers =
        selected === 'all'
          ? {
              organization: values.organization_id
            }
          : {};

      delete values.organization_id;

      if (doctorId) {
        updateUser({
          variables: {
            input: {
              ...values,
              user_id: doctorId
            }
          },
          context: {
            headers
          }
        });
      } else {
        createUser({
          variables: {
            input: values
          },
          context: {
            headers
          }
        });
      }
    },
    [createUser, doctorId, selected, updateUser]
  );

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email
      });
    }
  }, [form, user]);

  return (
    <>
      <OrganizationAlert>
        <Button
          type="primary"
          onClick={() => toggleModal('doctorDetails', true)}
        >
          <PlusOutlined />
          {t('doctors.add')}
        </Button>
      </OrganizationAlert>
      <Modal
        title={t(doctorId ? 'doctors.edit' : 'doctors.add')}
        onCancel={closeModal}
        open={doctorDetails.visible}
        width={600}
        onOk={form.submit}
        confirmLoading={isCreating || isUpdating}
        destroyOnClose
      >
        <Form form={form} onFinish={onFinish} layout="vertical" size="large">
          <Form.Item
            name="first_name"
            label={t('doctors.first_name')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label={t('doctors.last_name')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('doctors.email')}
            rules={[{ required: true }, { type: 'email' }]}
          >
            <Input />
          </Form.Item>
          {selected === 'all' && (
            <Form.Item
              name="organization_id"
              label="Organization"
              rules={[{ required: true }]}
            >
              <Select options={results.filter(item => item.value !== 'all')} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default memo(AddDoctor);
