import { ChangeEventHandler, FC, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Form, Input, Row, Space, Table, Tag } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_MEDICAL_TREATMENT,
  GET_MEDICAL_TREATMENT_BY_ID
} from 'gql/appointments/queries';
import { EllipsisOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DATE_FORMAT, FULL_DATE_FORMAT } from 'constants/dates';
import { GENDERS_VALUES } from 'constants/users';
import { useTranslation } from 'react-i18next';
import {
  MedicalTreatmentValues,
  MEDICAL_TREATMENT_COLOR,
  MEDICAL_TREATMENT_LABEL
} from 'constants/appointments';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useUIDispatchContext } from 'providers/UI';
import { GetTreatmentForms } from 'gql/appointments/__generated__/GetTreatmentForms';
import { UPDATE_TREATMENT } from 'gql/appointments/mutations';
import client from 'apolloClient';

import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';

const PAGE_LIMIT = 10;

const MedicalTreatment: FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const timer = useRef<NodeJS.Timeout>();
  const page = Number(searchParams.get('page') || 0);
  const { toggleModal } = useUIDispatchContext();
  const { data, loading } = useQuery<GetTreatmentForms>(GET_MEDICAL_TREATMENT, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        search: search,
        pagination: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const [updateMedicalTreatment] = useMutation(UPDATE_TREATMENT, {
    onCompleted() {
      toggleModal('updateStatus', false);
      client.refetchQueries({
        include: [GET_MEDICAL_TREATMENT, GET_MEDICAL_TREATMENT_BY_ID]
      });
    },
    onError: err => showErrorMessage(err)
  });

  const results = data?.getTreatmentForms?.data?.results || [];
  const total = data?.getTreatmentForms?.data?.total || 0;

  const onSearch: ChangeEventHandler<HTMLInputElement> = e => {
    searchParams.set('page', '0');
    setSearchParams(searchParams);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setSearch(e.target.value);
    }, 700);
  };

  return (
    <PageWrapper title="Medical Treatment" color="white">
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              <Input.Search
                placeholder={t('common.search')}
                onChange={onSearch}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Name',
            dataIndex: 'first_name',
            key: 'name',
            render(_, record) {
              return `${record.first_name} ${record.last_name}`;
            }
          },
          {
            title: 'Date of birth',
            dataIndex: 'date_of_birth',
            key: 'date_of_birth',
            render(dateOfBirth: string) {
              return dayjs(dateOfBirth).format(DATE_FORMAT);
            }
          },
          {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            render(gender: keyof typeof GENDERS_VALUES) {
              return gender
                ? t(`appointments_details.${GENDERS_VALUES[gender]}`)
                : '-';
            }
          },
          {
            title: 'Contact data',
            dataIndex: 'email',
            key: 'email',
            render(_, record) {
              return (
                <>
                  <b>Phone:</b>{' '}
                  <a href={`tel:${record.phone}`}>{record.phone}</a>
                  <br />
                  <b>Email:</b>{' '}
                  <a href={`mailto:${record.email}`}>{record.email}</a>
                </>
              );
            }
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render(value: MedicalTreatmentValues) {
              return (
                <Tag color={MEDICAL_TREATMENT_COLOR[value]}>
                  {MEDICAL_TREATMENT_LABEL[value]}
                </Tag>
              );
            }
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render(createdAt: string) {
              return dayjs(createdAt).format(FULL_DATE_FORMAT);
            }
          },
          {
            title: '',
            dataIndex: 'id',
            key: 'actions',
            align: 'right',
            render(id: string, record) {
              return (
                <ActionMenu
                  data={[
                    {
                      title: 'Change status',
                      permission: {
                        route: PERMISSION_ROUTES.medicalTreatment,
                        action: PERMISSION_ACTIONS.update
                      },
                      action() {
                        toggleModal('updateStatus', true, {
                          id,
                          currentStatus: `${record.status}`,
                          statusList: MEDICAL_TREATMENT_LABEL,
                          onSubmit: values => {
                            updateMedicalTreatment({
                              variables: {
                                id: id,
                                input: {
                                  note: values.note,
                                  status: Number(values.status)
                                }
                              }
                            });
                          }
                        });
                      }
                    },
                    {
                      title: 'View',
                      action() {
                        navigate(id);
                      }
                    }
                  ]}
                  placement="bottomRight"
                  icon={<EllipsisOutlined />}
                />
              );
            }
          }
        ]}
        loading={loading}
        rowKey="id"
        pagination={{
          total: total || 0,
          current: page + 1,
          onChange: p => {
            searchParams.set('page', `${p - 1}`);
            setSearchParams(searchParams);
          },
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default MedicalTreatment;
