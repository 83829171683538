import { FC } from 'react';
import { Button, Card, Typography, Row, Col, Avatar } from 'antd';
import { StarFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Add } from 'assets/img/add-button.svg';

import { ICardDoctor } from './types';
import {
  Cover,
  CardName,
  RatingBox,
  StyledCard,
  CardActionAvatar,
  BtnAvatarAction
} from './styled';

const { Meta } = Card;
const { Text } = Typography;

const CardDoctor: FC<ICardDoctor> = ({
  name,
  profession,
  rate,
  cardType,
  actionAvatars,
  hoverable,
  coverAction
}) => {
  // translations
  const { t } = useTranslation();

  return (
    <StyledCard
      cover={<Cover onClick={coverAction} />}
      actions={
        cardType === 'legacy'
          ? [<EditOutlined key="edit" />, <DeleteOutlined key="delete" />]
          : []
      }
      hoverable={hoverable}
    >
      <Meta
        title={<CardName>{name}</CardName>}
        description={
          <>
            <Text type="secondary">{profession}</Text>
            <RatingBox>
              <StarFilled
                style={{ fontSize: 16, color: '#FFC200', marginRight: 8 }}
              />
              <Text>{rate}</Text>
            </RatingBox>
          </>
        }
        style={{
          marginBottom: 24
          // paddingBottom: cardType === 'actions' && !actionAvatars ? 24 : 0
        }}
      />
      {actionAvatars ? ( // can check with Avatar array
        <CardActionAvatar>
          <Avatar.Group style={{ alignItems: 'center' }}>
            <Avatar size={34} />
            <Avatar size={34} />
            <BtnAvatarAction shape="circle" ghost icon={<Add />} />
          </Avatar.Group>
        </CardActionAvatar>
      ) : null}
      {cardType === 'request' ? (
        <Row gutter={8} style={{ paddingBottom: 24 }}>
          <Col span={12}>
            <Button shape="round" style={{ width: '100%' }}>
              {t('doctors.reject_button')}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              shape="round"
              style={{
                width: '100%'
              }}
            >
              {t('doctors.check_button')}
            </Button>
          </Col>
        </Row>
      ) : null}
    </StyledCard>
  );
};

export default CardDoctor;
