import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENTS_CARTS_DATA } from 'gql/analytics/queries';
import { GetAppointmentCharts } from 'gql/analytics/__generated__/GetAppointmentCharts';
import { Row, Col, Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'hooks';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { APPOINTMENT_STATUSES } from 'constants/appointments';

import { Loading } from 'components/ui';
import CountCard from '../CountCard';
import { getStatusColor } from 'utils/appointments';
import AppointmentsDateChart from '../AnalyticDateChart';
import PieChart from '../PieChart';

const AnalyticsAppointments: FC = () => {
  const { t } = useTranslation();
  const { checkUserPermission } = usePermission();
  const { data, loading } = useQuery<GetAppointmentCharts>(
    GET_APPOINTMENTS_CARTS_DATA,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const results = useMemo(
    () => data?.getAppointmentCharts?.data,
    [data?.getAppointmentCharts?.data]
  );

  const hasAppointmentReadPermission = checkUserPermission(
    PERMISSION_ROUTES.appointments,
    PERMISSION_ACTIONS.read
  );

  if (loading || !results) {
    return <Loading />;
  }

  return (
    <>
      <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
        <Col lg={{ span: 16 }}>
          <Card
            bodyStyle={{ padding: '20px 24px 16px' }}
            style={{ height: '100%' }}
          >
            <Typography.Title level={4} style={{ marginBottom: 20 }}>
              Appointment by dates
            </Typography.Title>
            <AppointmentsDateChart
              color="#4F84E8"
              data={Object.entries(results.appointments_month || {})?.map(
                ([key, value]) => ({
                  Date: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
                  Count: (value as number) || 0
                })
              )}
            />
          </Card>
        </Col>
        <Col lg={{ span: 8 }}>
          <Card
            bodyStyle={{
              padding: '20px 24px 16px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
            style={{ height: '100%' }}
          >
            <CountCard
              title="Total appointments"
              count={+(results.appointment_count || 0)}
              to={hasAppointmentReadPermission ? '/appointments' : null}
            >
              <PieChart
                id="appoitmentsPieChart"
                data={Object.entries(results?.appointments_status)?.map(
                  ([key, value]) => {
                    return {
                      status: t(`dashboard.${key}`),
                      value: Number(value),
                      color:
                        getStatusColor(
                          APPOINTMENT_STATUSES[
                            key as keyof typeof APPOINTMENT_STATUSES
                          ] as number
                        ) || '#808080'
                    };
                  }
                )}
              />
            </CountCard>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AnalyticsAppointments;
