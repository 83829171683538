import { gql } from '@apollo/client';

import { TRANSLATION_PLAN_FRAGMENT } from './fragments';

export const GET_TRANSLATION_PLANS = gql`
  query TranslatorPlans($query: IPlan) {
    translatorPlans(query: $query) {
      data {
        total
        results {
          ...TranslatorPlanFragment
        }
      }
    }
  }
  ${TRANSLATION_PLAN_FRAGMENT}
`;

export const GET_LANGUAGES = gql`
  query Languages($query: Pagination!) {
    languages(query: $query) {
      data {
        results {
          code
          id
          name
          nativeName
        }
      }
    }
  }
`;
