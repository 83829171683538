import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Button, Card, List, Typography, Table } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { useQuery } from '@apollo/client';
import {
  GET_APPOINTMENT_ITEM,
  GET_PREVIOUS_FILES
} from 'gql/appointments/queries';
import {
  PreviousFiles,
  PreviousFiles_previousFiles_data as PreviousFileType
} from 'gql/appointments/__generated__/PreviousFiles';

import { parseJson } from 'utils/json';
import { validateLink } from 'utils/link';
import { getIdStart } from 'utils/string';
import DownloadFile from './DownloadFile';

const { Title } = Typography;

const Files: FC = () => {
  // translations
  const { t } = useTranslation();
  // navigation
  const params = useParams();
  // graphql
  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-only'
  });

  const { data: filesData } = useQuery<PreviousFiles>(GET_PREVIOUS_FILES, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const appointment = data?.appointmentForAdmin?.data;

  const columns: ColumnProps<PreviousFileType>[] = useMemo(
    () => [
      {
        title: 'Appt ID',
        dataIndex: 'appointment_id',
        key: 'appointment_id',
        render: (id: string) => (
          <Link to={`/appointments/${id}`}>{getIdStart(id)}</Link>
        )
      },
      {
        title: 'Creation date',
        dataIndex: 'created_at',
        key: 'created_at'
      },
      {
        title: 'Files',
        dataIndex: 'file',
        key: 'file',
        render: (value: string) => {
          return (
            <DownloadFile value={value} patientId={appointment?.patient.id} />
          );
        }
      },
      {
        title: '',
        dataIndex: 'fileSize',
        key: 'fileSize',
        align: 'center'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text: React.ReactNode) => (
          <Button
            type="text"
            style={{ fontSize: 20, lineHeight: 1, padding: 0 }}
          >
            {text}
          </Button>
        )
      }
    ],
    [appointment?.patient.id]
  );

  const previousFiles = filesData?.previousFiles?.data;

  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{
          padding: '24px 0 0 40px',
          marginBottom: 32,
          boxShadow: '0px 4px 20px #E7EAF6'
        }}
      >
        <Title level={5} style={{ marginBottom: 16 }}>
          {t('appointments_details.files_documents')}
        </Title>
        <List
          itemLayout="horizontal"
          dataSource={appointment?.files || []}
          style={{
            maxHeight: 200,
            overflowY: 'auto'
          }}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <DownloadFile
                    value={item.file}
                    patientId={appointment?.patient.id}
                  />
                }
              />
            </List.Item>
          )}
        />
      </Card>
      <Card
        bordered={false}
        bodyStyle={{
          padding: '24px 0 0 40px',
          marginBottom: 32,
          boxShadow: '0px 4px 20px #E7EAF6'
        }}
      >
        <Title level={5} style={{ marginBottom: 16 }}>
          {t('appointments_details.attached_links')}
        </Title>
        <List
          itemLayout="horizontal"
          dataSource={parseJson(appointment?.links || [])}
          style={{
            maxHeight: 200,
            overflowY: 'auto'
          }}
          renderItem={(item: string, index: number) => (
            <List.Item key={`link-item-${index}`}>
              <List.Item.Meta
                title={
                  <a href={validateLink(item)} target="_blank" rel="noreferrer">
                    {item}
                  </a>
                }
              />
            </List.Item>
          )}
        />
      </Card>
      <Title level={5} style={{ paddingLeft: 8, marginBottom: 24 }}>
        {t('appointments_details.previous_appointments_files')}
      </Title>
      <Table dataSource={previousFiles} columns={columns} rowKey="id" />
    </>
  );
};

export default Files;
