import { useQuery } from '@apollo/client';
import { parseJson } from '@medicusunion/front-utils';
import { App } from 'antd';
import { DoctorById } from 'gql/doctors/__generated__/DoctorById';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type Params = {
  id: string;
  lang: string;
};

const REQUIRED_FIELDS = [
  'first_name',
  'last_name',
  'gender',
  'timezone',
  'phone',
  'address',
  'specialties',
  'languages'
] as const;

const useDoctorProfileCache = ({ id, lang }: Params) => {
  const { modal } = App.useApp();
  const navigate = useNavigate();

  const { data } = useQuery<DoctorById>(GET_DOCTOR_PROFILE, {
    nextFetchPolicy: 'cache-only',
    variables: {
      id,
      lang
    }
  });

  const profile = useMemo(() => data?.doctorById.data, [data?.doctorById.data]);

  const validateProfile = useCallback(
    (cb: () => void) => {
      const isProfileCompleted = REQUIRED_FIELDS.every(key => {
        const field = profile?.[key];

        if (typeof field === 'string') {
          const parsed = parseJson(field);

          if (typeof parsed === 'number') {
            return true;
          }

          return parseJson(field)?.length;
        }

        if (Array.isArray(field)) {
          return field.length;
        }

        if (typeof field === 'number') {
          return true;
        }

        return !!field;
      });

      if (isProfileCompleted) {
        return cb();
      }

      modal.error({
        title: 'Error',
        content:
          'Please complete all profile information fields as a first step',
        onOk() {
          navigate(`/access/doctors/${id}/personal-information?lang=${lang}`);
        }
      });
    },
    [modal, profile, navigate, id, lang]
  );

  return { validateProfile, profile };
};

export default useDoctorProfileCache;
