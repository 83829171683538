import styled, { css } from 'styled-components';

import { IStyledButtonProps } from './types';

export const StyledIconButton = styled.button<IStyledButtonProps>`
  ${({ mode }) =>
    mode === 'filled'
      ? css`
          background-color: var(--layout-body-background);
          width: 44px;
          height: 44px;
          border-radius: 50%;
        `
      : css`
          background-color: transparent;
        `}
  border: none;
  cursor: pointer;
`;
