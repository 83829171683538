/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from 'services/api';

import { getFileUrl } from './file';

export class CustomUploadAdapter {
  loader: any;
  abortController?: AbortController;
  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);
          this.abortController = new AbortController();

          instance
            .post('/aws-s3', data, {
              signal: this.abortController.signal
            })
            .then(result => {
              resolve({
                default: getFileUrl(result.data.data)
              });
            })
            .catch(() => {
              reject('Upload failed');
            });
        })
    );
  }

  abort() {
    this.abortController?.abort();
  }
}
