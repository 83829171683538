import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useApolloClient, useMutation } from '@apollo/client';
import { App, Button, Flex, Typography } from 'antd';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { PLAN_DURATIONS } from 'constants/plans';
import { DELETE_TRANSLATION_PLAN } from 'gql/translationPlans/mutations';
import { useUIDispatchContext } from 'providers/UI';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_TRANSLATION_PLANS } from 'gql/translationPlans/queries';

import { Private } from 'components/shared';
import { money } from 'utils/price';
import { showErrorMessage } from 'utils/showErrorMessage';
import { StyledCard, StyledParagraph } from './styled';
import { IPlanItemProps } from './types';

const { Paragraph } = Typography;

const PlanItem: FC<IPlanItemProps> = ({
  id,
  name,
  duration,
  price,
  translation
}) => {
  const client = useApolloClient();
  const { modal } = App.useApp();
  // translation
  const { t } = useTranslation();
  // ui
  const { toggleModal } = useUIDispatchContext();

  const durationLabel = PLAN_DURATIONS.find(
    item => item.value === duration
  )?.label;

  // graphql
  const [deletePlan] = useMutation(DELETE_TRANSLATION_PLAN, {
    onCompleted() {
      client.refetchQueries({ include: [GET_TRANSLATION_PLANS] });
    },
    onError: err => showErrorMessage(err)
  });

  const onDeleteClick = () => {
    modal.confirm({
      title: t('common.confirm'),
      content: t('common.sure'),
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: () => {
        deletePlan({ variables: { id } });
      }
    });
  };

  return (
    <StyledCard title={name}>
      <div>
        <Flex gap={8} justify="space-between">
          <StyledParagraph>{t('translation_plans.translete')}</StyledParagraph>
          <StyledParagraph $strong>{translation || '-'}</StyledParagraph>
        </Flex>
        <Flex gap={8} justify="space-between">
          <StyledParagraph>{t('translation_plans.price')}</StyledParagraph>
          <StyledParagraph $strong>{money(price)}</StyledParagraph>
        </Flex>
        <Flex gap={8} justify="space-between">
          <StyledParagraph>{t('translation_plans.duration')}</StyledParagraph>
          <StyledParagraph $strong>{durationLabel}</StyledParagraph>
        </Flex>
      </div>
      <Flex justify="space-between" gap={8} style={{ marginTop: 12 }}>
        <Private
          route={PERMISSION_ROUTES.translatorPlans}
          action={PERMISSION_ACTIONS.delete}
          key={`plan-item-delete-${id}`}
        >
          <Button
            size="large"
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={onDeleteClick}
            icon={<DeleteOutlined style={{ color: 'var(--text-color-80)' }} />}
          >
            <Paragraph style={{ margin: 0 }}>
              {t('translation_plans.delete_plan')}
            </Paragraph>
          </Button>
        </Private>
        <Private
          route={PERMISSION_ROUTES.translatorPlans}
          action={PERMISSION_ACTIONS.update}
          key={`plan-item-edit-${id}`}
        >
          <Button
            type="text"
            size="large"
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'var(--primary-color-10)'
            }}
            onClick={() => toggleModal('translationPlans', true, { id })}
            icon={<EditOutlined style={{ color: 'var(--primary)' }} />}
          >
            <Paragraph strong style={{ margin: 0, color: 'var(--primary)' }}>
              {t('translation_plans.edit_plan')}
            </Paragraph>
          </Button>
        </Private>
      </Flex>
    </StyledCard>
  );
};

export default PlanItem;
