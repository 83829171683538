import { FC } from 'react';
import { message, Switch, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import { UPDATE_DOCTOR_STATUS } from 'gql/users/mutations';
import { UpdateStatus } from 'gql/users/__generated__/UpdateStatus';
import { PROFILE_STATUS } from 'constants/users';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { showErrorMessage } from 'utils/showErrorMessage';
import { Private } from 'components/shared';
import useDoctorProfileCache from 'hooks/useDoctorProfileCache';

interface IProps {
  id: string;
  lang: string;
}

const ChangeDoctorStatus: FC<IProps> = ({ id, lang }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { profile, validateProfile } = useDoctorProfileCache({ id, lang });

  const [updateDoctorStatus, { loading: isUpdating }] =
    useMutation<UpdateStatus>(UPDATE_DOCTOR_STATUS, {
      onCompleted() {
        client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
        message.success(t('messages.successfully_updated'));
      },
      onError: err => showErrorMessage(err)
    });

  const onChange = (value: boolean) => {
    validateProfile(() => {
      if (value) {
        return updateDoctorStatus({
          variables: {
            id,
            input: {
              status: PROFILE_STATUS.active,
              lang
            }
          }
        });
      }

      updateDoctorStatus({
        variables: {
          id,
          input: {
            status: PROFILE_STATUS.disabled,
            lang
          }
        }
      });
    });
  };

  return (
    <Private
      route={PERMISSION_ROUTES.doctors}
      action={PERMISSION_ACTIONS.disableDoctor}
    >
      <Space align="center">
        Activate Doctor
        <Switch
          value={profile?.status === PROFILE_STATUS.active}
          loading={isUpdating}
          onChange={onChange}
        />
      </Space>
    </Private>
  );
};

export default ChangeDoctorStatus;
