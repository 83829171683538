import { gql } from '@apollo/client';

export const CREATE_FAQ = gql`
  mutation CreateFAQ($input: FAQInput!) {
    createFAQ(input: $input) {
      message
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation DeleteFAQ($id: ID!) {
    deleteFAQ(id: $id) {
      message
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation UpdateFAQ($id: ID!, $input: FAQInput!) {
    updateFAQ(id: $id, input: $input) {
      message
    }
  }
`;
