import { FC, memo, useEffect, useState } from 'react';
import { Button, Descriptions, Empty, Modal, Row } from 'antd';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { IP_INFO_KEY } from 'constants/ip';

type IProps = {
  country: string;
  city: string;
  org: string;
  timezone: string;
};

const IPInfo: FC = () => {
  const { t } = useTranslation();
  const [info, setIpInfo] = useState<IProps>();
  const [isLoading, setLoading] = useState(true);
  const { ipInfo } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `https://ipinfo.io//${ipInfo.params?.ip}?token=${IP_INFO_KEY}`
        );

        setIpInfo(res.data);
      } catch (err) {
        console.error('Error fetching IP information:', err);
      } finally {
        setLoading(false);
      }
    })();
  }, [ipInfo?.params?.ip]);

  return (
    <Modal
      title={t('activity_logs.ip_info')}
      open={ipInfo?.visible}
      closable={false}
      footer={<Button onClick={() => toggleModal('ipInfo', false)}>OK</Button>}
    >
      {isLoading ? (
        <Row justify="center">
          <LoadingOutlined style={{ fontSize: 50 }} />
        </Row>
      ) : info ? (
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item
            label={t('activity_logs.location')}
          >{`${info?.country} ${info?.city}`}</Descriptions.Item>
          <Descriptions.Item label={t('activity_logs.provider')}>
            {info?.org}
          </Descriptions.Item>
          <Descriptions.Item label={t('activity_logs.timezone')}>
            {info?.timezone}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Empty />
      )}
    </Modal>
  );
};

export default memo(IPInfo);
