import { Dropdown, Form, Typography } from 'antd';
import { ChangeEventHandler, FC, memo, useRef } from 'react';

import { IVariablesTextareaProps } from './types';
import { StyledButton } from './styled';
import { getInputDir } from 'utils/helpers';

const VariablesTextarea: FC<IVariablesTextareaProps> = ({
  name,
  label,
  variables,
  language,
  onAddVariable
}) => {
  const form = Form.useFormInstance();
  const prevValue = useRef('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    const textarea = textareaRef.current;

    if (!textarea) return;

    const currentContent = prevValue.current;
    let { value } = event.target;

    const isDeleted = currentContent.length > value.length;
    prevValue.current = value;

    if (!isDeleted) {
      return;
    }

    const deletedCharacter = currentContent[currentContent.length - 1];

    if (deletedCharacter === '}') {
      const lastOpeningBracePos = currentContent.lastIndexOf('{') - 1;

      if (lastOpeningBracePos >= 0) {
        const variable = value.substring(lastOpeningBracePos);

        value = value.replace(variable, '');
      }
    }

    if (Array.isArray(name)) {
      form.setFieldsValue({ [name[0]]: { [name[1]]: value } });
    } else {
      form.setFieldsValue({ [name]: value });
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        { required: true },
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject();
            }

            const allVariables: string[] = value.match(/{{[^}]*}}/g) || [];

            const isWrongVariable = allVariables.some(
              item => !variables.includes(item)
            );

            if (isWrongVariable) {
              return Promise.reject();
            }

            return Promise.resolve();
          },
          message: 'Please select variables exclusively from the provided list'
        }
      ]}
      extra={
        <Dropdown
          trigger={['click']}
          menu={{
            items: variables.map(variable => ({
              key: variable,
              label: (
                <Typography.Text
                  onClick={() => {
                    textareaRef.current?.focus();
                    onAddVariable(variable);
                  }}
                  key={variable}
                >
                  {variable}
                </Typography.Text>
              )
            }))
          }}
        >
          <StyledButton type="link">Add variable</StyledButton>
        </Dropdown>
      }
    >
      <textarea
        ref={textareaRef}
        dir={language && getInputDir(language)}
        className="ant-input ant-input-lg"
        onChange={handleChange}
        style={{ width: '100%', minHeight: 100 }}
      />
    </Form.Item>
  );
};

export default memo(VariablesTextarea);
