import { Layout, Typography } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;
const { Text } = Typography;

export const StyledMainHeader = styled(Header)`
  box-shadow: 0px 20px 40px rgba(218, 218, 218, 0.2);
  line-height: inherit;
  height: 80px;
  padding: 0;
  display: flex;
`;

export const StyledLogo = styled.div`
  background-color: var(--secondary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  max-width: 256px;
  height: 100%;
  display: grid;
  place-content: center;
  flex: 1;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  padding-inline: 24px;
  background-color: var(--white);
  border: 1px solid rgba(244, 244, 244, 1);
  box-shadow: 0px 5px 10px 0px rgba(203, 204, 211, 0.1);
`;

export const StyledUserName = styled(Text)`
  color: #200e32;
  font-weight: 700;
`;
