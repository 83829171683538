import { FC } from 'react';
import { Empty } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { APPOINTMENT_CREATOR } from 'constants/appointments';
import { FULL_DATE_FORMAT } from 'constants/dates';

import { CardNote } from 'components/ui';

const Notes: FC = () => {
  // navigation
  const params = useParams();

  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-only'
  });

  const appointment = data?.appointmentForAdmin?.data;

  return (
    <>
      {appointment?.notes?.length ? (
        appointment?.notes.map(item => (
          <CardNote
            key={`card-item-${item.id}`}
            title={
              item.sender === APPOINTMENT_CREATOR.doctor
                ? `${appointment?.doctor?.first_name} ${appointment?.doctor?.last_name}`
                : `${appointment.patient.first_name} ${appointment.patient.last_name}`
            }
            content={item?.note || ''}
            date={dayjs(item?.created_at).format(FULL_DATE_FORMAT)}
          />
        ))
      ) : (
        <Empty />
      )}
    </>
  );
};

export default Notes;
