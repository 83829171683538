import { gql } from '@apollo/client';

import { NOTIFICATIONS_FRAGMENT } from './fragments';

export const GET_NOTIFICATIONS = gql`
  query Notifications($query: GetNotifications!) {
    notifications(query: $query) {
      data {
        results {
          ...NotificationsFragment
        }
        total
      }
    }
  }
  ${NOTIFICATIONS_FRAGMENT}
`;

export const GET_NOTIFICATION_BY_ID = gql`
  query NotificationById($id: ID!) {
    notificationById(id: $id) {
      data {
        ...NotificationsFragment
        fields
        body
        data
        keywords
      }
    }
  }
  ${NOTIFICATIONS_FRAGMENT}
`;
