import { AxiosResponse } from 'axios';
import { ReactNode } from 'react';
import { ValueOf } from 'types/custom';

export const ActionTypes = {
  SET_BLOCKED_VISIBLE: 'SET_BLOCKED_VISIBLE',
  SET_TMP_TOKEN: 'SET_TMP_TOKEN',
  SIGN_OUT: 'SIGN_OUT'
};

export type LoginForm = {
  email: string;
  password: string;
};

export interface IAuthProps {
  children: ReactNode;
}

export type Action = {
  type: ValueOf<typeof ActionTypes>;
  data: boolean | string;
};

export type State = {
  isBlockedVisible: boolean;
  tmpToken: string;
};

export type PermissionsState = Record<string, number>;

export type SignInReturnType = {
  redirectUrl: string;
  verified?: boolean;
  token?: string;
  email?: string;
  phone?: string;
  settings: {
    auth2fa: boolean;
  };
  tmpToken: string;
  refreshToken: string;
};

export type DispatchContext = {
  signOut: () => void;
  signIn: (data: LoginForm) => Promise<SignInReturnType>;
  verify2FA: (
    otp: string,
    tmpToken: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<AxiosResponse<any, any>>;
  getUserData(): void;
  refetchUserData(): void;
};

export type Dispatch = (action: Action) => void;
