import { gql } from '@apollo/client';

export const APPOINTMENT_FILE_FRAGMENT = gql`
  fragment AppointmentFileFragment on AppointmentFile {
    file
    id
    sender
    created_at
  }
`;

export const APPOINTMENT_NOTE_FRAGMENT = gql`
  fragment AppointmentNoteFragment on AppointmentNote {
    id
    note
    sender
    created_at
  }
`;

export const APPOINTMENT_DATE_FRAGMENT = gql`
  fragment AppointmentDateFragment on AppointmentDate {
    id
    date
    sender
    accepted
  }
`;

export const APPOINTMENT_REPORT_FRAGMENT = gql`
  fragment AppointmentReportFragment on Report {
    id
    report
    date_report
  }
`;

export const APPOINTMENT_REPORT_FILE_FRAGMENT = gql`
  fragment AppointmentReportFileFragment on ReportsFile {
    id
    file
    created_at
  }
`;

export const APPOINTMENTS_FRAGMENT = gql`
  fragment AppointmentsFragment on Appointment {
    id
    status
    created_at
    price
    paid
    admin_paid
    state
    dates {
      ...AppointmentDateFragment
    }
    doctor {
      id
      first_name
      last_name
      avatar
    }
    patient {
      id
      first_name
      last_name
      avatar
      email
    }
    plan {
      id
      name
      price
      type
      duration
    }
    appointment_translators {
      id
      translator_plan {
        id
        price
        lang_1
        lang_2
      }
      translator {
        id
        first_name
        last_name
        avatar
      }
    }
    translator
  }
  ${APPOINTMENT_DATE_FRAGMENT}
`;

export const APPOINTMENT_ITEM_FRAGMENT = gql`
  fragment AppointmentItem on Appointment {
    id
    reason
    creator
    price
    status
    created_at
    paid
    links
    state
    admin_notes {
      id
      note
      created_at
    }
    patient {
      id
      avatar
      last_name
      first_name
      date_of_birth
      languages
      gender
    }
    doctor {
      id
      avatar
      last_name
      first_name
      date_of_birth
      languages
      gender
    }
    appointment_translators {
      translator_plan {
        id
        price
        lang_1
        lang_2
      }
      translator {
        id
        first_name
        last_name
        avatar
      }
    }
    admin_payment {
      id
      note
      paid
      transfer
      payed_at
      file
    }
    dates {
      ...AppointmentDateFragment
    }
    files {
      ...AppointmentFileFragment
    }
    notes {
      ...AppointmentNoteFragment
    }
    report {
      ...AppointmentReportFragment
    }
    reports_file {
      ...AppointmentReportFileFragment
    }
    plan {
      id
      name
      description
      features
      price
      type
      duration
    }
    patient_payment_info {
      id
      payment_info
      reason
      source
      created_at
    }
  }
  ${APPOINTMENT_DATE_FRAGMENT}
  ${APPOINTMENT_NOTE_FRAGMENT}
  ${APPOINTMENT_REPORT_FRAGMENT}
  ${APPOINTMENT_REPORT_FILE_FRAGMENT}
  ${APPOINTMENT_FILE_FRAGMENT}
`;

export const FAST_APPOINTMENT_FRAGMENT = gql`
  fragment FastAppointmentFragment on FastAppointment {
    id
    first_name
    last_name
    email
    organization_id
    phone
    status
    date_of_birth
    gender
    created_at
  }
`;

export const FAST_TREATMENT_FRAGMENT = gql`
  fragment FastTreatmentFragment on TreatmentForm {
    id
    first_name
    last_name
    email
    phone
    status
    date_of_birth
    gender
    created_at
    way_of_communication
  }
`;
