import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_PATIENT_REQUESTS } from 'gql/appointments/queries';
import { GetPatientRequests } from 'gql/appointments/__generated__/GetPatientRequests';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import {
  FAST_APPOINTMENTS_COLOR,
  FAST_APPOINTMENTS_LABEL,
  FAST_APPOINTMENTS_STATUS,
  FastAppointmentValues
} from 'constants/appointments';

const PAGE_LIMIT = 5;
const { Text } = Typography;

const FastAppointmentRequest: FC = () => {
  const { data, loading } = useQuery<GetPatientRequests>(GET_PATIENT_REQUESTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        pagination: {
          page: 0,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const results = data?.getFastAppointments?.data?.results || [];

  return (
    <Table
      dataSource={results}
      columns={[
        {
          title: 'Patient Name',
          dataIndex: 'first_name',
          key: 'name',
          render(_, record) {
            return `${record.first_name} ${record.last_name}`;
          }
        },
        {
          title: 'Contact',
          dataIndex: 'email',
          key: 'email',
          render(_, record) {
            return (
              <>
                <Text>
                  Phone: <a href={`tel:${record.phone}`}>{record.phone}</a>
                  <span>, </span>
                  Email: <a href={`mailto:${record.email}`}>{record.email}</a>
                </Text>
              </>
            );
          }
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render(value: FastAppointmentValues) {
            return (
              <Tag color={FAST_APPOINTMENTS_COLOR[value]}>
                {FAST_APPOINTMENTS_LABEL[value]}
              </Tag>
            );
          }
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          render(createdAt: string) {
            return dayjs(createdAt).format(FULL_DATE_FORMAT);
          }
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'id',
          align: 'right',
          render: id => (
            <Link
              style={{ cursor: 'pointer', color: '#2552F3' }}
              to={`/appointments/patient-requests/${id}`}
            >
              <b>View Request</b>
            </Link>
          )
        }
      ]}
      loading={loading}
      rowKey="id"
      className="table_head"
      pagination={false}
      rowClassName={record =>
        record.status && +record.status === FAST_APPOINTMENTS_STATUS.new
          ? 'table-row-new'
          : ''
      }
    />
  );
};

export default FastAppointmentRequest;
