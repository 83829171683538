import { gql } from '@apollo/client';

export const ATTRIBUTE_FRAGMENT = gql`
  fragment AttributeFragment on Attribute {
    id
    name
    slug
    type
  }
`;

export const ATTRIBUTE_GROUP_FRAGMENT = gql`
  fragment AttributeGroupFragment on Group {
    id
    slug
    name
    place_use
    is_editable
    attributes {
      ...AttributeFragment
    }
  }
  ${ATTRIBUTE_FRAGMENT}
`;
