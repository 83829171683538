import { Col, Space, Form, Row, Table, Input, Button } from 'antd';
import { t } from 'i18next';
import { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { useUIDispatchContext } from 'providers/UI';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ORGANIZATIONS } from 'gql/organizations/queries';
import { OrganizationsAdmin as OrganizationsType } from 'gql/organizations/__generated__/OrganizationsAdmin';
import { DELETE_ORGANIZATION } from 'gql/organizations/mutations';
import {
  useOrganizationsDispatchContext,
  useOrganizationsStateContext
} from 'providers/Organizations';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, PageWrapper } from 'components/ui';
import { OrganizationsModal } from './components';
import { showErrorMessage } from 'utils/showErrorMessage';
import { Private } from 'components/shared';

const Organizations: FC = () => {
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [detailsId, setDetailsId] = useState('');
  const [search, setSearch] = useState('');
  const idToDelete = useRef('');
  const timer = useRef<NodeJS.Timeout | null>(null);
  const { toggleModal } = useUIDispatchContext();
  const { results: organizations, selected } = useOrganizationsStateContext();
  const { onChangeParent } = useOrganizationsDispatchContext();
  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery<OrganizationsType>(
    GET_ORGANIZATIONS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION, {
    onCompleted() {
      if (selected === idToDelete.current) {
        onChangeParent(organizations?.[0].value || '');
      }

      idToDelete.current = '';

      refetch();
    },
    onError(err) {
      idToDelete.current = '';
      showErrorMessage(err);
    }
  });

  const results = data?.organizationsAdmin.data.filter(item =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    },
    []
  );

  const onDetailsClose = useCallback(() => {
    setDetailsId('');
    setDetailsVisible(false);
  }, []);

  const onDeleteOrganization = useCallback(
    (id: string) => {
      toggleModal('confirm', true, {
        onConfirm() {
          idToDelete.current = id;
          deleteOrganization({
            variables: {
              id
            }
          });
        }
      });
    },
    [deleteOrganization, toggleModal]
  );

  return (
    <PageWrapper
      title="Organizations"
      color="white"
      extra={[
        <Private
          key="create-role"
          route={PERMISSION_ROUTES.organization}
          action={PERMISSION_ACTIONS.create}
        >
          <Button type="primary" onClick={() => setDetailsVisible(true)}>
            <PlusOutlined />
            Create organization
          </Button>
        </Private>
      ]}
    >
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              <Input.Search
                placeholder={t('common.search')}
                onChange={onSearchChange}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
          },
          {
            title: '',
            dataIndex: 'id',
            key: 'x',
            width: '5%',
            align: 'right',
            render: id => (
              <ActionMenu
                data={[
                  {
                    title: t('table.edit'),
                    permission: {
                      route: PERMISSION_ROUTES.organization,
                      action: PERMISSION_ACTIONS.update
                    },
                    action() {
                      setDetailsId(id);
                      setDetailsVisible(true);
                    }
                  },
                  {
                    title: 'Settings',
                    permission: {
                      route: PERMISSION_ROUTES.organization,
                      action: PERMISSION_ACTIONS.update
                    },
                    action() {
                      navigate(`settings/${id}/ui`);
                    }
                  },
                  {
                    title: t('table.delete'),
                    permission: {
                      route: PERMISSION_ROUTES.organization,
                      action: PERMISSION_ACTIONS.delete
                    },
                    action: () => onDeleteOrganization(id),
                    menuProps: {
                      style: {
                        color: 'var(--danger-color)'
                      }
                    }
                  }
                ]}
                placement="bottomRight"
                icon={<EllipsisOutlined />}
              />
            )
          }
        ]}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 10,
          showSizeChanger: false
        }}
      />
      {isDetailsVisible && (
        <OrganizationsModal
          onClose={onDetailsClose}
          id={detailsId}
          refetch={refetch}
        />
      )}
    </PageWrapper>
  );
};

export default Organizations;
