import { gql } from '@apollo/client';

import {
  APPOINTMENT_DATE_FRAGMENT,
  APPOINTMENT_NOTE_FRAGMENT,
  APPOINTMENT_FILE_FRAGMENT
} from './fragments';

export const APPROVE_DATE = gql`
  mutation ApproveDate($appointmentId: ID!, $dateId: ID!) {
    approveDate(appointment: $appointmentId, id: $dateId) {
      data {
        status
      }
      message
    }
  }
`;

export const REQUEST_APPOINTMENT_DATES = gql`
  mutation RequestAppointmentDates($id: ID!, $input: AppointmentDateInput!) {
    requestAppointmentDates(id: $id, input: $input) {
      data {
        ...AppointmentDateFragment
      }
    }
  }
  ${APPOINTMENT_DATE_FRAGMENT}
`;

export const CREATE_APPOINTMENT_NOTE = gql`
  mutation CreateAppointmentNote($id: ID!, $input: AppointmentNoteInput!) {
    createAppointmentNote(id: $id, input: $input) {
      data {
        ...AppointmentNoteFragment
      }
    }
  }
  ${APPOINTMENT_NOTE_FRAGMENT}
`;

export const CREATE_APPOINTMENT_FILE = gql`
  mutation CreateAppointmentFile($id: ID!, $input: AppointmentFileInput!) {
    createAppointmentFile(id: $id, input: $input) {
      data {
        ...AppointmentFileFragment
      }
    }
  }
  ${APPOINTMENT_FILE_FRAGMENT}
`;

export const REJECT_APPOINTMENT = gql`
  mutation RejectStatus($id: ID!, $input: RejectStatusInput) {
    rejectStatus(id: $id, input: $input) {
      message
    }
  }
`;

export const ADD_TRANSLATOR = gql`
  mutation AddTranslator($id: ID!, $input: AppointmentTranslatorInput) {
    addTranslator(id: $id, input: $input) {
      message
    }
  }
`;

export const CREATE_ADMIN_APPOINTMENT = gql`
  mutation CreateAppointmentByAdmin {
    createAppointmentByAdmin {
      message
    }
  }
`;

export const CREATE_ADMIN_PAID_APPOINTMENT = gql`
  mutation CreateAppointmentByAdminPay($input: AppointmentSessionPayInput) {
    createAppointmentByAdminPay(input: $input) {
      message
    }
  }
`;

export const OPEN_ROOM = gql`
  mutation OpenRoom($input: AppointmentIdInput!) {
    openRoom(input: $input) {
      data {
        meetUrl
        redirect_url
      }
    }
  }
`;

export const DELETE_APPOINTMENT = gql`
  mutation DeleteAppointment($id: ID!) {
    deleteAppointment(id: $id) {
      message
    }
  }
`;

export const UPDATE_APPOINTMENT_STATUS = gql`
  mutation UpdateAppointmentStatus($id: ID!, $input: UpdateStatusInput) {
    updateAppointmentStatus(id: $id, input: $input) {
      data {
        status
      }
    }
  }
`;

export const UPDATE_DOCTOR_PAYMENT = gql`
  mutation CreateAppointmentPayment($input: AppointmentPaymentInput!) {
    createAppointmentPayment(input: $input) {
      message
    }
  }
`;

export const UPDATE_APPROVED_DATE = gql`
  mutation UpdateApprovedDate($id: ID!, $input: ApprovedDateInput!) {
    updateApprovedDate(id: $id, input: $input) {
      message
    }
  }
`;

export const UPDATE_APPOINTMENT_STATE = gql`
  mutation UpdateAppointmentState($id: ID!, $input: UpdateStateInput) {
    updateAppointmentState(id: $id, input: $input) {
      message
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation CreateAppointmentAdmin($input: AppointmentAdminInput!) {
    createAppointmentAdmin(input: $input) {
      message
      data {
        id
      }
    }
  }
`;

export const UPDATE_FAST_APPOINTMENT = gql`
  mutation UpdateFastAppointment(
    $id: ID!
    $input: IUpdateFastAppointmentInput
  ) {
    updateFastAppointment(id: $id, input: $input) {
      message
    }
  }
`;

export const UPDATE_TREATMENT = gql`
  mutation UpdateTreatmentForm($id: ID!, $input: UpdateTreatmentFormInput) {
    updateTreatmentForm(id: $id, input: $input) {
      message
    }
  }
`;
