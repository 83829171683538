import { FC } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import {
  CheckCircleFilled,
  DeleteOutlined,
  EditOutlined,
  StarFilled
} from '@ant-design/icons';

import {
  CardRow,
  Cover,
  // InfoWrapper,
  // CardActionWrapper,
  ActionButton
} from './styled';
import { ICardMainRow } from './types';

const { Title, Paragraph, Text } = Typography;

const CardMainRow: FC<ICardMainRow> = ({ title, verified }) => {
  return (
    <CardRow justify="start" wrap={false}>
      <Col style={{ marginRight: 24 }}>
        <Cover src="/images/card-cover.png" />
      </Col>
      <Col flex={1}>
        <Row align="middle" style={{ marginBottom: 8 }}>
          <Title
            level={2}
            style={{ textTransform: 'capitalize', marginBottom: 0 }}
          >
            {title}
            {verified && (
              <CheckCircleFilled
                style={{
                  fontSize: 20,
                  color: '#2AACD0', // primary-color
                  marginLeft: 10,
                  position: 'relative',
                  top: '-2px'
                }}
              />
            )}
          </Title>
          <Space size={16} style={{ marginLeft: 24 }}>
            <ActionButton
              type="text"
              size="large"
              shape="circle"
              icon={<EditOutlined />}
            />
            <ActionButton
              type="text"
              size="large"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Space>
        </Row>
        <Row>
          <Col span={8}>
            <Paragraph type="secondary" style={{ marginBottom: 8 }}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </Paragraph>
            <Text>
              {/* warning-color */}
              <StarFilled style={{ color: '#FFC200', marginRight: 8 }} />
              4,8 (17)
            </Text>
          </Col>
        </Row>
      </Col>
    </CardRow>
  );
};

export default CardMainRow;
