export const LANGUAGES = [
  {
    label: 'English',
    key: 'english',
    locale: 'en'
  },
  {
    label: 'Russian',
    key: 'russian',
    locale: 'ru'
  },
  {
    label: 'German',
    key: 'german',
    locale: 'de'
  },
  {
    label: 'Arabic',
    key: 'arabic',
    locale: 'ar'
  }
  // {
  //   label: 'Ukraine',
  //   key: 'ukraine',
  //   locale: 'ua'
  // }
] as const;

export type LocaleType = 'en' | 'ru' | 'de' | 'ua' | 'ar';

export const DOCTOR_LANGUAGES = {
  en: 'en',
  de: 'de'
};
