export enum WayOfCommunicationValues {
  email = 1,
  phoneCall = 2,
  whatsapp = 3,
  noPreferences = 4
}

export const WAY_OF_COMMUNICATION = [
  { value: WayOfCommunicationValues.email, label: 'Email' },
  { value: WayOfCommunicationValues.phoneCall, label: 'Phone Call' },
  { value: WayOfCommunicationValues.whatsapp, label: 'Whatsapp' },
  { value: WayOfCommunicationValues.noPreferences, label: 'No Preferences' }
];
