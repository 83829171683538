import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, Empty, Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { useAppDispatch } from 'store/hooks';
import { setViewerPdf } from 'store/file/slice';
import { DownloadOutlined } from '@ant-design/icons';

import { getFileUrl } from 'utils/file';

const { Paragraph } = Typography;

const Reports: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-only'
  });

  const appointment = data?.appointmentForAdmin?.data;

  const onDownloadReport = (id: string) => {
    dispatch(setViewerPdf({ id, url: `appointments-reports/pdf/${id}` }));
  };

  const renderContent = () => {
    if (appointment?.reports_file?.file) {
      return (
        <div>
          <a
            href={getFileUrl(appointment.reports_file.file)}
            download={appointment.reports_file.file}
          >
            {appointment.reports_file.file}
          </a>
        </div>
      );
    }

    if (appointment?.report) {
      return (
        <Link
          to={`/pdf-viewer/${appointment.report.id}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => onDownloadReport(`${appointment.report?.id}`)}
        >
          <Button icon={<DownloadOutlined />} type="primary" ghost>
            Download
          </Button>
        </Link>
      );
    }

    return (
      <Empty
        imageStyle={{
          height: 60
        }}
        description={<span>{t('appointments_details.no_report')}</span>}
      />
    );
  };

  return (
    <Card
      bordered={false}
      bodyStyle={{
        paddingBottom: 56,
        marginBottom: 32,
        boxShadow: '0px 4px 20px #E7EAF6'
      }}
    >
      <Paragraph style={{ marginBottom: 16 }}>
        {t('appointments_details.reports')}
      </Paragraph>
      {renderContent()}
    </Card>
  );
};

export default Reports;
