import { gql } from '@apollo/client';

import { PLAN_FRAGMENT } from './fragments';

export const CREATE_PLAN = gql`
  mutation CreatePlan($input: PlanInput!) {
    createPlan(input: $input) {
      data {
        ...Plan
      }
    }
  }
  ${PLAN_FRAGMENT}
`;

export const UPDATE_PLAN = gql`
  mutation UpdatePlan($id: ID!, $input: PlanInput!) {
    updatePlan(id: $id, input: $input) {
      data {
        ...Plan
      }
    }
  }
  ${PLAN_FRAGMENT}
`;

export const DELETE_PLAN = gql`
  mutation DeletePlan($id: ID!) {
    deletePlan(id: $id) {
      message
    }
  }
`;
