import { useMutation, useQuery } from '@apollo/client';
import { Modal, Form, Input, Select } from 'antd';
import client from 'apolloClient';
import { APPOINTMENT_STATES } from 'constants/appointments';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { UPDATE_APPOINTMENT_STATE } from 'gql/appointments/mutations';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC, memo, useEffect, useMemo } from 'react';

import { showErrorMessage } from 'utils/showErrorMessage';

const { TextArea } = Input;

const StateModal: FC = () => {
  // form
  const [form] = Form.useForm();
  // context
  const { appointmentState } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // graphql
  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: appointmentState.params?.id
    },
    skip: !appointmentState.params?.id,
    fetchPolicy: 'cache-only'
  });

  const closeModal = () => {
    toggleModal('appointmentState', false);
    form.resetFields();
  };

  const [updateAppointmentState, { loading }] = useMutation(
    UPDATE_APPOINTMENT_STATE,
    {
      onCompleted() {
        closeModal();
        client.refetchQueries({ include: [GET_APPOINTMENT_ITEM] });
      },
      onError: err => showErrorMessage(err)
    }
  );

  const appointment = useMemo(() => data?.appointmentForAdmin?.data, [data]);

  const adminNote = useMemo(
    () => appointment?.admin_notes?.[0]?.note,
    [appointment?.admin_notes]
  );

  useEffect(() => {
    if (appointmentState.visible) {
      form.setFieldsValue({
        state: `${appointment?.state}`,
        note: adminNote
      });
    }
  }, [adminNote, appointment, appointmentState.visible, form]);

  const onFinish = (values: { note: string; state: number }) => {
    updateAppointmentState({
      variables: {
        id: appointmentState.params?.id,
        input: {
          state: +values.state,
          admin_note: values.note || ''
        }
      }
    });
  };

  return (
    <Modal
      title="Appointment State"
      open={appointmentState.visible}
      onOk={form.submit}
      onCancel={closeModal}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="State" name="state" rules={[{ required: true }]}>
          <Select
            options={Object.entries(APPOINTMENT_STATES).map(
              ([value, label]) => ({
                label,
                value
              })
            )}
          />
        </Form.Item>
        <Form.Item label="Notes" name="note" rules={[{ required: false }]}>
          <TextArea style={{ height: 130 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(StateModal);
