import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Result,
  Row,
  Select,
  Space,
  Typography,
  Input,
  Upload
} from 'antd';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DoctorsByStatus } from 'gql/doctors/__generated__/DoctorsByStatus';
import { GET_DOCTORS_BY_STATUS, GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { DOCTOR_STATUSES } from 'constants/doctors';
import { DoctorById } from 'gql/doctors/__generated__/DoctorById';
import { useNavigate } from 'react-router-dom';
import { TranslatorPlans } from 'gql/translationPlans/__generated__/TranslatorPlans';
import { GET_TRANSLATION_PLANS } from 'gql/translationPlans/queries';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useFileUpload } from 'hooks';
import { CREATE_APPOINTMENT } from 'gql/appointments/mutations';
import client from 'apolloClient';
import { GET_APPOINTMENTS } from 'gql/appointments/queries';
import { CreateAppointmentAdmin } from 'gql/appointments/__generated__/CreateAppointmentAdmin';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { URL_VALIDATION } from '@medicusunion/front-utils';

import { PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { validateLink } from 'utils/link';
import { PatientSelect } from './components';

const { Text, Link } = Typography;
const { TextArea } = Input;

type FieldValues = {
  link?: string;
};

const CreateAppointment: FC = () => {
  // translations
  const { t } = useTranslation();
  // navigation
  const navigate = useNavigate();
  // Form
  const [form] = Form.useForm();
  // states
  const [doctorID, setDoctorId] = useState('');
  const [links, setLinks] = useState<string[]>([]);
  const [files, setFiles] = useState<string[]>([]);
  //refs
  const uploadQueue = useRef<UploadRequestOption[]>([]);
  const uploadQueueTimer = useRef<NodeJS.Timeout | null>(null);

  //AWS
  const { upload } = useFileUpload({
    handleResult: true,
    loading: true
  });

  const { data: doctorsData, loading: doctorsLoading } =
    useQuery<DoctorsByStatus>(GET_DOCTORS_BY_STATUS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 999
          },
          status: DOCTOR_STATUSES.active
        }
      }
    });

  // graphql
  const { data: translatorPlansData, loading: translatorPlansLoading } =
    useQuery<TranslatorPlans>(GET_TRANSLATION_PLANS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 999
          }
        }
      }
    });

  const [
    getDoctorProfile,
    { data: doctorProfile, loading: doctorProfileLoading }
  ] = useLazyQuery<DoctorById>(GET_DOCTOR_PROFILE);

  const [createAppointment, { loading: createLoading }] =
    useMutation<CreateAppointmentAdmin>(CREATE_APPOINTMENT, {
      onCompleted(data) {
        client.refetchQueries({ include: [GET_APPOINTMENTS] });
        navigate(`/appointments/${data.createAppointmentAdmin.data.id}`);
      },
      onError: err => showErrorMessage(err)
    });

  useEffect(() => {
    if (doctorID) {
      getDoctorProfile({
        variables: {
          id: doctorID,
          lang: 'en'
        }
      });
    }
  }, [doctorID, getDoctorProfile]);

  const onAddLinkClick = useCallback(async () => {
    try {
      const field = await form.validateFields(['link']);

      if (field.link) {
        const link = form.getFieldValue('link');

        form.setFieldsValue({
          link: ''
        });
        setLinks(prev => [...prev, link]);
      }
    } catch (_) {
      //
    }
  }, [form]);

  const doctors = doctorsData?.doctorsByStatus?.data.results.map(item => ({
    label: `${item.first_name} ${item.last_name} (${item.email})`,
    value: item.id as string
  }));

  const doctorPlans = doctorProfile?.doctorById?.data.plans?.map(item => ({
    label: item.name,
    value: item.id
  }));

  const translatorPlans =
    translatorPlansData?.translatorPlans.data?.results.map(item => ({
      label: item.name,
      value: item.id
    }));

  useEffect(() => {
    if (!doctorPlans?.length) {
      form.resetFields(['plan_id']);
    }
  }, [doctorPlans?.length, form]);

  const onUploadFile = (e: UploadRequestOption) => {
    uploadQueue.current.push(e);

    if (uploadQueueTimer.current) {
      clearTimeout(uploadQueueTimer.current);
    }

    uploadQueueTimer.current = setTimeout(async () => {
      for (let i = 0; i < uploadQueue.current.length; i++) {
        const item = uploadQueue.current[i];

        try {
          const res = await upload(item.file as File);

          if (res) {
            setFiles(prev => [...prev, res]);
          }

          item.onSuccess?.(res);
        } catch (err) {
          item.onError?.(err as ProgressEvent<EventTarget>);
        }
      }

      uploadQueue.current = [];
    }, 100);
  };

  const onFinish = (values: FieldValues) => {
    delete values.link;
    createAppointment({
      variables: {
        input: {
          ...values,
          files: files,
          links: links
        }
      }
    });
  };

  return (
    <PageWrapper
      title={t('appointments.create')}
      color="white"
      extra={[
        <Button
          type="primary"
          onClick={form.submit}
          key="save"
          loading={createLoading}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row style={{ width: '100%' }} gutter={[24, 0]}>
          <Col span={12}>
            <PatientSelect />
          </Col>
          <Col span={12}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => {
                setDoctorId(curValues.doctor_id);

                return prevValues.additional !== curValues.additional;
              }}
            >
              {() => {
                return (
                  <Form.Item
                    name="doctor_id"
                    label={t('appointments.doctor')}
                    rules={[{ required: true }]}
                  >
                    <Select
                      size="large"
                      allowClear
                      showSearch
                      filterOption={(
                        input: string,
                        option?: { label: string; value: string }
                      ) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={doctorsLoading}
                      options={doctors}
                      placeholder={t('appointments.doctor_placeholder')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          {!doctorID || (!!doctorID && !!doctorPlans?.length) ? (
            <>
              <Col span={12}>
                <Form.Item
                  name="plan_id"
                  label={t('appointments.plan')}
                  rules={[{ required: true }]}
                >
                  <Select
                    size="large"
                    loading={doctorProfileLoading}
                    placeholder={t('appointments.plan_placeholder')}
                    options={doctorPlans}
                    disabled={!doctorID || !doctorPlans?.length}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="translator_plan_id"
                  label={t('appointments.translator')}
                >
                  <Select
                    size="large"
                    loading={translatorPlansLoading}
                    placeholder={t('appointments.translator_placeholder')}
                    options={translatorPlans}
                    disabled={!translatorPlans?.length}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('appointments.status')}
                  name="paid"
                  rules={[{ required: true }]}
                >
                  <Select
                    size="large"
                    placeholder={t('appointments.status_placeholder')}
                    options={[
                      {
                        label: t('appointments.not_paid'),
                        value: false
                      },
                      {
                        label: t('appointments.paid'),
                        value: true
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="link"
                  label={t('appointments.links')}
                  rules={[
                    {
                      pattern: URL_VALIDATION,
                      message: t('appointments.link_is_not_valid')
                    }
                  ]}
                >
                  <Input
                    placeholder={t('appointments.link_here')}
                    size="large"
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        onAddLinkClick();
                      }
                    }}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={onAddLinkClick}
                >
                  {t('appointments.add')}
                </Button>
                <ul style={{ marginTop: 16 }}>
                  {links.map((item, index) => (
                    <li key={`selected-link-${index}`}>
                      <a
                        href={validateLink(item)}
                        target="_blank"
                        rel="noreferrer"
                        style={{ wordBreak: 'break-all' }}
                      >
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('appointments.select_date')}
                  name="date"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    suffixIcon={
                      <CalendarOutlined
                        style={{ fontSize: 14, color: '#09121F' }}
                      />
                    }
                    style={{ width: '100%' }}
                    showTime
                    size="large"
                    format={FULL_DATE_FORMAT}
                    showSecond={false}
                    placement={'bottomLeft'}
                    disabledDate={current => dayjs().diff(current, 'days') > 0}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="notes" label={t('appointments.notes')}>
                  <TextArea />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Space direction="vertical">
                  <Text>{t('appointments.documents')}</Text>
                  <Upload customRequest={onUploadFile} multiple>
                    <Button>{t('appointments.upload_file')}</Button>
                  </Upload>
                </Space>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <Result
                status="error"
                title={t('appointments.plan_empty')}
                subTitle={
                  <Text>
                    {t('appointments.create_plan')}
                    {'  '}
                    <Link
                      onClick={() =>
                        navigate(
                          `/access/doctors/${doctorID}/personal-information`
                        )
                      }
                    >
                      {t('appointments.click')}
                    </Link>
                  </Text>
                }
              />
            </Col>
          )}
        </Row>
      </Form>
    </PageWrapper>
  );
};

export default CreateAppointment;
