import { Form, Input, Modal, Select } from 'antd';
import { FC, memo, useCallback, useEffect } from 'react';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { UPDATE_ORGANIZATION } from 'gql/organizations/mutations';
import { GET_ORGANIZATION_BY_ID } from 'gql/organizations/queries';
import { OrganizationById } from 'gql/organizations/__generated__/OrganizationById';
import { Users } from 'gql/users/__generated__/Users';
import { CREATE_USER } from 'gql/users/mutations';
import { GET_ROLES_BY_PARENT, GET_ROLES_GROUP } from 'gql/roles/queries';
import { RolesGroup } from 'gql/roles/__generated__/RolesGroup';
import { RoleByParent } from 'gql/roles/__generated__/RoleByParent';
import { GENDERS, USER_ROLES } from 'constants/users';
import { useOrganizationsStateContext } from 'providers/Organizations';

import { showErrorMessage } from 'utils/showErrorMessage';

interface IProps {
  refetch(): Promise<ApolloQueryResult<Users>>;
  onClose(): void;
  role?: string;
  id?: string;
}

const UsersModal: FC<IProps> = ({ id, role, refetch, onClose }) => {
  const [form] = Form.useForm();

  const closeModal = () => {
    onClose();
    form.resetFields();
  };

  const { results } = useOrganizationsStateContext();

  const roleGroups = useQuery<RolesGroup>(GET_ROLES_GROUP, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      if (role) {
        const parentId = data?.rolesGroup.data.find(
          item => item.name === role
        )?.id;

        form.setFieldsValue({ parent_id: parentId });
      }
    }
  });

  const parentId = Form.useWatch('parent_id', form);

  const parent = roleGroups.data?.rolesGroup.data.find(
    item => item.id === parentId
  );

  const roles = useQuery<RoleByParent>(GET_ROLES_BY_PARENT, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !parentId,
    variables: {
      parentId
    }
  });

  useQuery<OrganizationById>(GET_ORGANIZATION_BY_ID, {
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: {
      id
    },
    onCompleted(data) {
      const result = data.organizationById.data;

      if (result) {
        form.setFieldsValue({
          name: result.name,
          additional_info: result.additional_info || ''
        });
      }
    }
  });

  const [createUser, { loading: isCreating }] = useMutation(CREATE_USER, {
    onCompleted() {
      refetch();
      closeModal();
    },
    onError: err => showErrorMessage(err)
  });

  const [updateOrganization, { loading: isUpdating }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      onCompleted() {
        refetch();
        closeModal();
      },
      onError: err => showErrorMessage(err)
    }
  );

  useEffect(() => {
    if (parent?.name !== USER_ROLES.admin) {
      form.setFieldsValue({
        organizationIds: undefined
      });
    }
  }, [form, parent?.name]);

  const onFinish = useCallback(
    (values: Record<string, string>) => {
      if (id) {
        return updateOrganization({
          variables: {
            id: id,
            input: values
          }
        });
      }

      createUser({
        variables: {
          input: values
        }
      });
    },
    [id, createUser, updateOrganization]
  );

  return (
    <Modal
      open
      title={id ? 'Edit' : 'Create'}
      onCancel={closeModal}
      width={600}
      onOk={form.submit}
      confirmLoading={isCreating || isUpdating}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} layout="vertical" size="large">
        <Form.Item
          name="first_name"
          label="First name"
          rules={[
            {
              required: true
            },
            {
              max: 25,
              min: 3
            },
            {
              message: 'Only latin letters are allowed',
              pattern: /^[A-Za-z][A-Za-z\s-]*$/
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last name"
          rules={[
            {
              required: true
            },
            {
              max: 25,
              min: 3
            },
            {
              message: 'Only latin letters are allowed',
              pattern: /^[A-Za-z][A-Za-z\s-]*$/
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
          <Select options={GENDERS} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone number"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="parent_id"
          label="Role group"
          rules={[{ required: true }]}
          hidden={!!role}
        >
          <Select
            options={roleGroups.data?.rolesGroup.data.map(item => ({
              value: item.id,
              label: item.name
            }))}
          />
        </Form.Item>
        <Form.Item name="role_id" label="Role" rules={[{ required: true }]}>
          <Select
            options={roles.data?.roleByParent.data.map(item => ({
              value: item.id,
              label: item.name
            }))}
          />
        </Form.Item>
        {parent?.name === USER_ROLES.admin && (
          <Form.Item
            name="organizations"
            label="Organizations"
            rules={[{ required: true }]}
          >
            <Select
              options={results.filter(item => item.value !== 'all')}
              mode="multiple"
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default memo(UsersModal);
