import { CONFIG_PAGES } from './configs';

export const CMS_FIELDS = [
  {
    title: 'Patient mobile',
    key: 'patient_mobile',
    config_type: CONFIG_PAGES.PATIENT_MOBILE,
    banners: ['about_us', 'our_services']
  },
  {
    title: 'Doctor mobile',
    key: 'doctor_mobile',
    config_type: CONFIG_PAGES.DOCTOR_MOBILE,
    banners: ['how_it_works']
  }
];
