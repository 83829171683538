import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUserIsLoggedIn } from 'store/user/selectors';
import OrganizationsProvider from 'providers/Organizations';

import PrivateRoutes from './Private';
import PublicRoutes from './Public';

const Routes: FC = () => {
  const isLoggedIn = useAppSelector(selectUserIsLoggedIn);

  if (isLoggedIn) {
    return (
      <OrganizationsProvider>
        <PrivateRoutes />
      </OrganizationsProvider>
    );
  }

  return <PublicRoutes />;
};

export default Routes;
