import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

export const StyledMainLayout = styled(Layout)`
  min-height: 100vh;
`;

export const StyledMainContent = styled(Content)`
  /* padding: ${({ theme }) =>
    `${theme['padding-lg']} ${theme['padding-md']}`}; */
`;
