import { gql } from '@apollo/client';

import { ROLES_FRAGMENT, ROLES_BY_PARENT_FRAGMENT } from './fragments';

export const GET_ROLES_GROUP = gql`
  query RolesGroup {
    rolesGroup {
      data {
        ...RolesFragment
      }
    }
  }
  ${ROLES_FRAGMENT}
`;

export const GET_ROLES_BY_PARENT = gql`
  query RoleByParent($parentId: ID!) {
    roleByParent(parent_id: $parentId) {
      data {
        ...RolesByParentFragment
      }
    }
  }
  ${ROLES_BY_PARENT_FRAGMENT}
`;

export const GET_ROLE_BY_ID = gql`
  query RoleById($id: ID!) {
    roleById(id: $id) {
      data {
        id
        name
        parent_id
        role_permissions {
          id
          permission_id
          value
        }
      }
    }
  }
`;

export const GET_PERMISSIONS_BY_ROLE = gql`
  query PermissionsByRole($roleName: String!) {
    permissionsRole(roleName: $roleName) {
      data {
        id
        name
        section
        values {
          id
          name
          value
        }
      }
    }
  }
`;

export const GET_USER_PERMISSIONS = gql`
  query UserPermissions {
    userPermissions {
      data {
        id
        value
        section
      }
    }
  }
`;
