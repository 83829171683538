import { FC, memo, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  App,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Upload
} from 'antd';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { useFileUpload, usePermission } from 'hooks';
import dayjs, { Dayjs } from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { GET_DOCTOR_ATTRIBUTES } from 'gql/attributes/queries';
import {
  AttributesPlaceUse,
  AttributesPlaceUse_attributesPlaceUse_data
} from 'gql/attributes/__generated__/AttributesPlaceUse';
import { ATTRIBUTE_PLACE_USE } from 'constants/attributes';
import { CREATE_USER_ATTRIBUTES } from 'gql/attributes/mutations';
import { RTL_LANGUAGES } from 'constants/translations';

import { CKEditor, Private } from 'components/shared';
import { StyledPage } from './styled';
import { showErrorMessage } from 'utils/showErrorMessage';
import { Loading } from 'components/ui';

interface IProps {
  id: string;
  lang: string;
}

const DynamicPage: FC<IProps> = ({ id, lang }) => {
  const { message } = App.useApp();
  const { checkUserPermission } = usePermission();
  const { upload } = useFileUpload({
    progress: true
  });

  const [form] = Form.useForm();

  const { data, loading: isFetching } = useQuery<AttributesPlaceUse>(
    GET_DOCTOR_ATTRIBUTES,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          place_used: [
            ATTRIBUTE_PLACE_USE.report,
            ATTRIBUTE_PLACE_USE.doctorProfile,
            ATTRIBUTE_PLACE_USE.email
          ]
        }
      }
    }
  );

  const [createUserAttributes, { loading: isCreating }] = useMutation(
    CREATE_USER_ATTRIBUTES,
    {
      onCompleted() {
        message.success('Successfully saved');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const hasProfileUpdatePermission = checkUserPermission(
    PERMISSION_ROUTES.doctors,
    PERMISSION_ACTIONS.update
  );

  const renderField = (item: AttributesPlaceUse_attributesPlaceUse_data) => {
    const fieldName = item.id;

    switch (item.type) {
      case 'date':
        return (
          <Form.Item name={fieldName} label={item.name}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        );
      case 'editor':
        return (
          <Form.Item name={fieldName} label={item.name}>
            <CKEditor />
          </Form.Item>
        );
      case 'file':
        return (
          <Form.Item
            name={fieldName}
            label={item.name}
            valuePropName="fileList"
            getValueFromEvent={e => e?.fileList || []}
          >
            <Upload
              disabled={!hasProfileUpdatePermission}
              maxCount={1}
              customRequest={e =>
                upload(e.file as File, {
                  onUploadProgress: e.onProgress
                })
                  .then(e.onSuccess)
                  .catch(e.onError)
              }
            >
              <Button block icon={<UploadOutlined />}>
                Upload
              </Button>
            </Upload>
          </Form.Item>
        );
      default:
        return (
          <Form.Item name={fieldName} label={item.name}>
            <Input dir={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'} />
          </Form.Item>
        );
    }
  };

  const onFinish = (data: Record<string, string | Dayjs>) => {
    const values: Record<string, string> = {};

    Object.entries(data).forEach(([key, value]) => {
      if (dayjs.isDayjs(value)) {
        values[key] = value.format();

        return;
      }

      if (Array.isArray(value)) {
        values[key] = value?.[0]?.response || undefined;

        return;
      }

      values[key] = value;
    });

    const input = {
      user_id: id,
      lang,
      values
    };

    createUserAttributes({
      variables: {
        input
      }
    });
  };

  const initialValues = useMemo(() => {
    // if (doctorAttributeGroups?.attributes?.length) {
    //   const values = doctorAttributeGroups.attributes.reduce<
    //     Record<string, string | Dayjs | unknown[]>[]
    //   >((acc, item) => {
    //     const index = item.row_index - 1;

    //     acc[index] = {
    //       ...(acc[index] || {}),
    //       [item.attribute_id]:
    //         item.type === 'date' ? dayjs(item.value) : item.value
    //     };

    //     if (!acc[index]) {
    //       acc[index] = {};
    //     }

    //     switch (item.type) {
    //       case 'date':
    //         acc[index][item.attribute_id] = dayjs(item.value);
    //         break;
    //       case 'file':
    //         acc[index][item.attribute_id] = [
    //           {
    //             uid: item.value,
    //             name: item.value,
    //             response: item.value
    //           }
    //         ];
    //         break;
    //       default:
    //         acc[index][item.attribute_id] = item.value;
    //         break;
    //     }

    //     return [...acc];
    //   }, []);

    //   return values;
    // }

    return [{}];
  }, []);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <StyledPage>
      <Form
        form={form}
        layout="vertical"
        size="large"
        disabled={!hasProfileUpdatePermission}
        onFinish={onFinish}
        initialValues={{
          values: initialValues
        }}
      >
        <Row gutter={[16, 0]}>
          {data?.attributesPlaceUse?.data?.map(item => (
            <Col span={24} key={`${item.id}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>
        <Flex justify="end" gap={12}>
          <Private
            route={PERMISSION_ROUTES.doctors}
            action={PERMISSION_ACTIONS.update}
          >
            <Button
              size="large"
              type="primary"
              onClick={form.submit}
              loading={isCreating}
            >
              Save
            </Button>
          </Private>
        </Flex>
      </Form>
    </StyledPage>
  );
};

export default memo(DynamicPage);
