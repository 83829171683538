import { rgba } from 'polished';
import styled from 'styled-components';

export const StyledIcon = styled.div`
  // TODO: fix
  background-color: ${rgba('#2552F3', 0.1)};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
