import { Form, Input, Button, Row, Col, Card, Checkbox } from 'antd';
import { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CREATE_ROLE, UPDATE_ROLE } from 'gql/roles/mutations';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_PERMISSIONS_BY_ROLE,
  GET_ROLES_GROUP,
  GET_ROLE_BY_ID
} from 'gql/roles/queries';
import {
  PermissionsByRole,
  PermissionsByRole_permissionsRole_data
} from 'gql/roles/__generated__/PermissionsByRole';
import { RoleById } from 'gql/roles/__generated__/RoleById';
import { RolesGroup } from 'gql/roles/__generated__/RolesGroup';
import { useTranslation } from 'react-i18next';
import { SECTIONS_ALL_KEYS } from 'constants/permissions';

import { Loading, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { RolesGroupSelect } from '../components';
import { checkPermission } from 'utils/permission';

const RoleDetails: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [parentId, setParentId] = useState('');
  const [isLoading, setLoading] = useState(!!id);
  const [values, setValues] = useState<Record<string, number>>({});

  const roleGroups = useQuery<RolesGroup>(GET_ROLES_GROUP, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const parent = roleGroups.data?.rolesGroup.data.find(
    item => item.id === parentId
  );

  useQuery<RoleById>(GET_ROLE_BY_ID, {
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: {
      id
    },
    onCompleted(data) {
      const role = data.roleById.data;

      if (role?.parent_id) {
        setParentId(role.parent_id);
        form.setFieldsValue({
          name: role.name,
          parent_id: role.parent_id
        });
        const permissionValues = role.role_permissions.reduce<
          Record<string, number>
        >((acc, val) => {
          const id = val?.permission_id;

          if (!id) {
            return acc;
          }

          const current = acc[id] || 0;

          return {
            ...acc,
            [id]: current + val.value
          };
        }, {});

        setValues(permissionValues);
        setLoading(false);
      }
    }
  });

  const { data } = useQuery<PermissionsByRole>(GET_PERMISSIONS_BY_ROLE, {
    fetchPolicy: 'no-cache',
    skip: !parent?.name,
    variables: {
      roleName: parent?.name
    }
  });

  const [createRole, { loading: isCreating }] = useMutation(CREATE_ROLE, {
    onCompleted() {
      navigate('/access/roles');
    },
    onError: err => showErrorMessage(err)
  });

  const [updateRole, { loading: isUpdating }] = useMutation(UPDATE_ROLE, {
    onCompleted() {
      navigate('/access/roles');
    },
    onError: err => showErrorMessage(err)
  });

  const onFinish = useCallback(
    (data: Record<string, string>) => {
      const input = {
        ...data,
        values
      };

      if (id) {
        return updateRole({
          variables: {
            id: id,
            input
          }
        });
      }

      createRole({
        variables: {
          input
        }
      });
    },
    [id, values, createRole, updateRole]
  );

  const onChangeRoleGroup = (id: string) => {
    setParentId(id);
    setValues({});
  };

  const onChangePermission = (
    permission: PermissionsByRole_permissionsRole_data,
    checked: boolean,
    value: number
  ) => {
    const { id } = permission;

    setValues(prev => {
      const current = prev[id] || 0;
      const result = checked ? current + value : current - value;

      if (!result) {
        delete prev[id];

        return { ...prev };
      }

      return {
        ...prev,
        [id]: result
      };
    });
  };

  const permissions = data?.permissionsRole?.data || [];

  if (isLoading || roleGroups.loading) {
    return <Loading />;
  }

  return (
    <PageWrapper
      back
      title={id ? 'Edit role' : 'Create Role'}
      onClickBack={() => navigate('/access/roles')}
      extra={[
        <Button
          type="primary"
          key="create-role"
          loading={isCreating || isUpdating}
          onClick={() => form.submit()}
        >
          {id ? 'Edit' : 'Create'}
        </Button>
      ]}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" size="large">
        <Row gutter={16}>
          <Col sm={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              name="parent_id"
              label="Role group"
              rules={[{ required: true }]}
            >
              <RolesGroupSelect value={parentId} onChange={onChangeRoleGroup} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {!!parent && (
        <Row gutter={[16, 16]} align="stretch">
          {permissions.map(permission => (
            <Col span={8} key={`permission-${permission.id}`}>
              <Card
                title={t(`permissions.${SECTIONS_ALL_KEYS[permission.name]}`)}
                headStyle={{ fontSize: 18 }}
                style={{ height: '100%' }}
              >
                <Row gutter={[0, 12]}>
                  {permission.values.map(value => {
                    const checked = checkPermission(
                      value.value,
                      values[permission.id]
                    );

                    return (
                      <Col span={24} key={`${permission.id}-value-${value.id}`}>
                        <Checkbox
                          checked={checked}
                          onChange={e =>
                            onChangePermission(
                              permission,
                              e.target.checked,
                              value.value
                            )
                          }
                        >
                          {t(`permissions.${value.name}`)}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </PageWrapper>
  );
};

export default RoleDetails;
