import { FC } from 'react';
import { Typography, Rate, Space } from 'antd';
// import { useUIDispatchContext } from 'providers/UI';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { StyledCard, NoteWrapper, Content, NoteInfo } from './styled';

const { Text, Paragraph } = Typography;

type IProps = {
  rate: number;
  content: string;
  date: string;
  sender: {
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  receiver: {
    firstName: string;
    lastName: string;
    email: string;
  } | null;
};

const CardNote: FC<IProps> = ({ rate, content, date, sender, receiver }) => {
  // translations
  const { t } = useTranslation();

  return (
    <StyledCard bordered={false}>
      <Rate allowClear={false} disabled defaultValue={rate || 3} />
      <NoteWrapper>
        <Content>
          <Text>{content}</Text>
        </Content>
      </NoteWrapper>
      <NoteInfo>
        <Space direction="vertical">
          {sender && (
            <Paragraph style={{ color: '#0B1A30', margin: 0 }}>
              {t('doctor_reviews.sender')}:{' '}
              <span>
                {sender.firstName} {sender.lastName} |{' '}
                <a href={`mailto:${sender.email}`}>{sender.email}</a>
              </span>
            </Paragraph>
          )}
          {receiver && (
            <Paragraph style={{ color: '#0B1A30', margin: 0 }}>
              {t('doctor_reviews.receiver')}:{' '}
              <span>
                {receiver.firstName} {receiver.lastName} |{' '}
                <a href={`mailto:${receiver.email}`}>{receiver.email}</a>
              </span>
            </Paragraph>
          )}
          <Paragraph
            style={{
              color: '#0B1A30'
            }}
          >
            {dayjs(date).format('DD MMM YYYY | HH:mm')}
          </Paragraph>
        </Space>

        {/* <Button
          type="primary"
          shape="round"
          onClick={() => toggleModal('replyReview', true)}
        >
          Reply
        </Button> */}
      </NoteInfo>
    </StyledCard>
  );
};

export default CardNote;
