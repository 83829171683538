import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { Tag, Table, Typography, Flex, Space, Image, Button } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENTS } from 'gql/appointments/queries';
import { PLAN_TYPE_VALUES } from 'constants/users';
import {
  AppointmentStatusType,
  APPOINTMENT_STATUS_VALUES
} from 'constants/appointments';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import { PlusSquareOutlined } from '@ant-design/icons';
import { useUIDispatchContext } from 'providers/UI';

import { getFileUrl } from 'utils/file';
import { getStatusColor } from 'utils/appointments';

import {
  AppointmentsForAdminQuery,
  AppointmentsFragmentFragment
} from '@/generated/graphql';

const PAGE_LIMIT = 5;
const { Text } = Typography;

const AppointmentRequest: FC = () => {
  // translations
  const { t } = useTranslation();
  // navigation
  const navigate = useNavigate();
  const { toggleModal } = useUIDispatchContext();

  // graphql
  const { data, loading } = useQuery<AppointmentsForAdminQuery>(
    GET_APPOINTMENTS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: PAGE_LIMIT
          }
        }
      }
    }
  );

  const columns: ColumnProps<AppointmentsFragmentFragment>[] = [
    {
      title: 'Doctor Name',
      dataIndex: 'doctor',
      key: 'doctor',
      render: value => (
        <Flex gap={8}>
          <Image
            src={getFileUrl(value.avatar || '')}
            width={32}
            height={32}
            preview={false}
            style={{
              objectFit: 'cover',
              borderRadius: '50%',
              minHeight: 32,
              minWidth: 32
            }}
          />
          <Space>
            <Text>
              {value.first_name} {value.last_name}
            </Text>
          </Space>
        </Flex>
      )
    },
    {
      title: 'Patient Name',
      dataIndex: 'patient',
      key: 'patient',
      render: value => (
        <Flex gap={8}>
          <Image
            src={getFileUrl(value.avatar || '')}
            width={32}
            height={32}
            preview={false}
            style={{
              objectFit: 'cover',
              borderRadius: '50%',
              minHeight: 32,
              minWidth: 32
            }}
          />
          <Space>
            <Text>
              {value.first_name} {value.last_name}
            </Text>
          </Space>
        </Flex>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: AppointmentStatusType) => (
        <Tag color={getStatusColor(value)}>
          {t(`appointments.${APPOINTMENT_STATUS_VALUES[value]}`)}
        </Tag>
      )
    },
    {
      title: 'Type',
      dataIndex: 'plan',
      key: 'plan',
      render: (_, record) =>
        record.plan.type === PLAN_TYPE_VALUES.report ? 'Report' : 'Video call'
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
    },
    {
      title: 'Translator',
      dataIndex: 'appointment_translators',
      key: 'appointment_translators',
      render(
        value: AppointmentsFragmentFragment['appointment_translators'],
        record
      ) {
        if (value?.[0]?.translator) {
          const { first_name, last_name } = value[0].translator;

          return `${first_name || ''} ${last_name || ''}`;
        }

        if (record.translator) {
          return (
            <Button
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={() =>
                toggleModal('addTranslator', true, {
                  id: record.appointment_translators?.[0]?.id
                })
              }
            >
              Add translator
            </Button>
          );
        }

        return '-';
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: id => (
        <Text
          strong
          style={{ cursor: 'pointer', color: '#2552F3' }}
          onClick={() => navigate(`/appointments/${id}`)}
        >
          View Request
        </Text>
      )
    }
  ];

  const appointments = (data?.appointmentsForAdmin?.data.results ||
    []) as AppointmentsFragmentFragment[];

  return (
    <Table
      dataSource={appointments}
      columns={columns}
      loading={loading}
      rowKey="id"
      className="table_head"
      pagination={false}
    />
  );
};

export default AppointmentRequest;
