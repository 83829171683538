import { FC, ReactNode } from 'react';
import { useOrganizationsStateContext } from 'providers/Organizations';
import { Flex, Result } from 'antd';

interface IProps {
  children: ReactNode;
}

const OrganizationResult: FC<IProps> = ({ children }) => {
  const { selected } = useOrganizationsStateContext();

  if (selected === 'all') {
    return (
      <Flex style={{ height: 'calc(100vh - 48px)' }} justify="center" vertical>
        <Result
          status="warning"
          title="Please choose an organization to proceed with this action"
        />
      </Flex>
    );
  }

  return <>{children}</>;
};

export default OrganizationResult;
