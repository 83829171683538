import styled from 'styled-components';

export const Wrapper = styled.div<{ space?: number }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff; // component-background
  box-shadow: 0px 4px 20px #e7eaf6; // box-shadow
  padding: 16px 0;

  ${({ space }) =>
    space &&
    `
    margin-bottom: ${space}px;
  `}
`;

export const FilterCollapsingItem = styled.div<{ expand?: boolean }>`
  display: ${({ expand }) => (expand ? 'flex' : 'none')};
  flex-flow: row wrap;
`;
