import { gql } from '@apollo/client';

import { TAGS_FRAGMENT } from './fragments';

export const CREATE_TAGS = gql`
  mutation CreateTag($input: TagInput) {
    createTag(input: $input) {
      data {
        ...TagsFragment
      }
    }
  }
  ${TAGS_FRAGMENT}
`;

export const ATTACH_TAG = gql`
  mutation AttachTagToDoctor($input: TagsDoctorInput) {
    attachTagToDoctor(input: $input) {
      message
    }
  }
`;

export const DETACH_TAG = gql`
  mutation DetachTagFromDoctor($doctor_id: ID, $query: TagsIdsInput) {
    detachTagFromDoctor(doctorId: $doctor_id, query: $query) {
      message
    }
  }
`;
