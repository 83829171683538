import styled from 'styled-components';
import { Button, Typography } from 'antd';

const { Title } = Typography;

export const PageContainer = styled.div`
  padding: var(--padding-lg);
  flex: 1 0 auto;
`;

export const Wrapper = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ $color }) => ` var(--${$color})`};
`;

export const PageHeader = styled.div`
  padding: 24px 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

export const StyledTitle = styled(Title)`
  &&& {
    align-items: center;
    margin: 0;
  }
`;

export const StyledButton = styled(Button)`
  color: #172541;
`;
