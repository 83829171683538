import {
  FC,
  useCallback,
  useMemo,
  useState,
  useRef,
  ChangeEventHandler
} from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, Col, Form, Row, Space, Input } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useUIDispatchContext } from 'providers/UI';
import { GET_SPECIALTIES } from 'gql/specialties/queries';
import { DELETE_SPECIALTY } from 'gql/specialties/mutations';
import {
  Specialties as SpecialtiesType,
  Specialties_specialties_data as SpecialtyType
} from 'gql/specialties/__generated__/Specialties';
import { useMutation, useQuery } from '@apollo/client';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { SpecialtyModal } from './components';
import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { Private } from 'components/shared';

const PAGE_LIMIT = 10;

const { Search } = Input;

const Specialties: FC = () => {
  // translations
  const { t } = useTranslation();
  // states
  const [search, setSearch] = useState('');
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    },
    []
  );

  // gql
  const { data, loading, refetch } = useQuery<SpecialtiesType>(
    GET_SPECIALTIES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          lang: 'en',
          symptom: true,
          name: search
        }
      }
    }
  );

  const [deleteSpecialty] = useMutation(DELETE_SPECIALTY, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  // ui
  const { toggleModal } = useUIDispatchContext();

  const onDeleteSpecialty = useCallback(
    (id: number) => {
      toggleModal('confirm', true, {
        onConfirm: () =>
          deleteSpecialty({
            variables: {
              id
            }
          })
      });
    },
    [deleteSpecialty, toggleModal]
  );

  const rows = useMemo(
    () => data?.specialties?.data || [],
    [data?.specialties?.data]
  );

  const results = useMemo(
    () =>
      rows.map((item, index) => ({
        ...item,
        children: item.children?.length
          ? item.children.map(child => ({
              ...(child || {}),
              id: `${child?.id}-${item.id}-${index}`
            }))
          : null
      })),
    [rows]
  );

  const columns: ColumnProps<SpecialtyType>[] = useMemo(
    () => [
      {
        title: t('id'),
        dataIndex: 'id',
        key: `key-32`,
        render(item) {
          return item.split('-')[0];
        }
      },
      {
        title: t('table.name'),
        dataIndex: 'name',
        key: 'key'
      },
      {
        title: t('table.visible'),
        dataIndex: 'visible',
        render: value => (value ? t('common.yes') : t('common.no'))
      },
      {
        title: t('specialty.short_description'),
        dataIndex: 'short_description'
      },
      {
        title: '',
        dataIndex: 'id',
        width: '5%',
        align: 'right',
        render: id => (
          <ActionMenu
            data={[
              {
                title: t('table.edit'),
                permission: {
                  route: PERMISSION_ROUTES.specialties,
                  action: PERMISSION_ACTIONS.update
                },
                action() {
                  toggleModal('specialtyModal', true, { id: id.split('-')[0] });
                }
              },
              {
                title: t('table.delete'),
                permission: {
                  route: PERMISSION_ROUTES.specialties,
                  action: PERMISSION_ACTIONS.delete
                },
                action: () => onDeleteSpecialty(id.split('-')[0]),
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                }
              }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [onDeleteSpecialty, t, toggleModal]
  );

  return (
    <PageWrapper
      title={t('specialty.page_title')}
      color="white"
      extra={[
        <Private
          key="add-new-specialty"
          route={PERMISSION_ROUTES.specialties}
          action={PERMISSION_ACTIONS.create}
        >
          <Button
            type="primary"
            onClick={() => toggleModal('specialtyModal', true)}
          >
            <PlusOutlined />
            {t('specialty.add_new')}
          </Button>
        </Private>
      ]}
    >
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              <Search
                placeholder={t('common.search')}
                onChange={onSearchChange}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        rowClassName="specialty-row"
        pagination={{
          total: results.length || 0,
          pageSize: PAGE_LIMIT,
          showSizeChanger: false
        }}
      />
      <SpecialtyModal refetch={refetch} />
    </PageWrapper>
  );
};

export default Specialties;
