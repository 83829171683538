/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo, useEffect, useMemo } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import styled from 'styled-components';
import { Empty } from 'antd';

type Props = {
  id: string;
  remaining: {
    label: string;
    value: number;
  };
  done: {
    label: string;
    value: number;
  };
};

const StyledRoot = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 70px;
    height: 25px;
    background-color: #ffffff;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
`;

const TwoLevelPieChart: FC<Props> = ({ id, remaining, done }) => {
  const isEmpty = useMemo(
    () => !remaining.value && !done.value,
    [done.value, remaining.value]
  );

  useEffect(() => {
    if (isEmpty) {
      return;
    }

    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout
      })
    );

    const series0 = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false,
        radius: am5.percent(100),
        innerRadius: am5.percent(80)
      })
    );

    series0.states.create('hidden', {
      startAngle: 180,
      endAngle: 180
    });

    series0.slices.template.setAll({
      fillOpacity: 0.5,
      strokeOpacity: 0,
      templateField: 'settings'
    });

    series0.slices.template.states.create('hover', { scale: 1 });
    series0.slices.template.states.create('active', { shiftRadius: 0 });

    series0.labels.template.setAll({
      templateField: 'settings'
    });

    series0.ticks.template.setAll({
      templateField: 'settings'
    });

    series0.labels.template.setAll({
      textType: 'circular',
      radius: 30
    });

    series0.data.setAll([
      {
        category: done.label,
        value: done.value
      },
      {
        category: remaining.label,
        value: remaining.value,
        settings: { forceHidden: true }
      }
    ]);

    const series1 = chart.series.push(
      am5percent.PieSeries.new(root, {
        radius: am5.percent(95),
        innerRadius: am5.percent(85),
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false
      })
    );

    series1.states.create('hidden', {
      startAngle: 180,
      endAngle: 180
    });

    series1.slices.template.setAll({
      templateField: 'sliceSettings',
      strokeOpacity: 0
    });

    series1.labels.template.setAll({
      textType: 'circular'
    });

    series1.labels.template.adapters.add('radius', function (radius, target) {
      const dataItem = target.dataItem;
      // @ts-ignore
      const slice = dataItem?.get('slice');

      // @ts-ignore
      return -(slice.get('radius') - slice.get('innerRadius')) / 2 - 10;
    });

    series1.slices.template.states.create('hover', { scale: 1 });
    series1.slices.template.states.create('active', { shiftRadius: 0 });

    series1.ticks.template.setAll({
      forceHidden: true
    });

    series1.data.setAll([
      {
        category: done.label,
        value: done.value
      },
      {
        category: remaining.label,
        value: remaining.value,
        sliceSettings: { fill: am5.color(0xdedede) }
      }
    ]);

    return () => {
      root.dispose();
    };
  }, [id, remaining, done, isEmpty]);

  if (isEmpty) {
    return (
      <StyledRoot>
        <Empty description="No data to show, please adjust filters" />
      </StyledRoot>
    );
  }

  return <StyledRoot id={id} />;
};

export default memo(TwoLevelPieChart);
