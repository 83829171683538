import { useQuery } from '@apollo/client';
import { Avatar, Form, Select, Space, Typography } from 'antd';
import { INFINITE_PAGE_SIZE } from 'constants/pagination';
import { FC, memo } from 'react';
import { GET_DOCTORS } from 'gql/doctors/queries';

import { getFileUrl } from 'utils/file';

import { DoctorsQuery } from '@/generated/graphql';

const DoctorsSelect: FC = () => {
  const { data: doctorsData } = useQuery<DoctorsQuery>(GET_DOCTORS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        pagination: {
          page: 0,
          limit: INFINITE_PAGE_SIZE
        },
        name: ''
      }
    }
  });

  return (
    <Form.Item name="doctors" label="Doctors">
      <Select mode="multiple">
        {doctorsData?.doctors?.data.results.map(item => (
          <Select.Option key={`doctor-item-${item.id}`} value={item.id}>
            <Space align="center" size={8}>
              <Avatar src={getFileUrl(item.avatar)} size={20} />
              <Typography.Text>
                {item.first_name} {item.last_name}
              </Typography.Text>
            </Space>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default memo(DoctorsSelect);
