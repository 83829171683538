import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { message } from 'antd';
import copy from 'copy-to-clipboard';

const useCopyText = () => {
  const { t } = useTranslation();

  const copyText = useCallback(
    (text: string) => {
      message.success(t('messages.copied'));
      copy(String(text));
    },
    [t]
  );

  return { copyText };
};

export default useCopyText;
