import { Avatar, Input, List, message, Modal } from 'antd';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS } from 'gql/users/queries';
import { Users } from 'gql/users/__generated__/Users';
import { USER_ROLES } from 'constants/users';
import { ADD_TRANSLATOR } from 'gql/appointments/mutations';
import client from 'apolloClient';
import {
  GET_APPOINTMENTS,
  GET_TRANSLATION_REQUESTS
} from 'gql/appointments/queries';

import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';

const { Search } = Input;

const PAGE_LIMIT = 100;

const AddTranslatorModal: FC = () => {
  // translations
  const { t } = useTranslation();
  // ui
  const { toggleModal } = useUIDispatchContext();
  const { addTranslator } = useModalStateContext();
  // states
  const [selected, setSelected] = useState('');
  // graphql
  const { data, loading } = useQuery<Users>(GET_USERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !addTranslator.visible,
    variables: {
      query: {
        page: 0,
        limit: PAGE_LIMIT,
        role: USER_ROLES.translator
      }
    }
  });

  const [addTranslatorMutation] = useMutation(ADD_TRANSLATOR, {
    onCompleted() {
      closeModal();
      message.success(t('dashboard.translator_added'));
      client.refetchQueries({
        include: [GET_TRANSLATION_REQUESTS, GET_APPOINTMENTS]
      });
    },
    onError: err => showErrorMessage(err)
  });

  const closeModal = () => {
    setSelected('');
    toggleModal('addTranslator', false);
  };

  return (
    <Modal
      title={t('translator.add')}
      onCancel={closeModal}
      open={addTranslator.visible}
      width={450}
      destroyOnClose
      okText="Apply"
      styles={{ body: { maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' } }}
      okButtonProps={{
        disabled: !selected
      }}
      onOk={() => {
        addTranslatorMutation({
          variables: {
            id: addTranslator.params?.id,
            input: {
              translator_id: String(selected)
            }
          }
        });
      }}
    >
      <Search placeholder="Search" style={{ marginBottom: 16 }} />
      <List
        itemLayout="horizontal"
        loading={loading}
        dataSource={data?.users?.data.results || []}
        renderItem={item => (
          <List.Item
            onClick={() => item.id && setSelected(item.id)}
            style={{
              backgroundColor:
                selected === item.id ? 'rgba(41, 80, 243, 0.1)' : '#fff',
              cursor: 'pointer'
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item.avatar ? getFileUrl(item.avatar) : ''}>
                  {item.first_name?.[0]}
                </Avatar>
              }
              title={`${item.first_name} ${item.last_name}`}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default memo(AddTranslatorModal);
