import { FC } from 'react';
import {
  App,
  Button,
  Col,
  ColorPicker,
  Flex,
  Form,
  Row,
  Typography
} from 'antd';
import { useFileUpload } from 'hooks';
import { CloudUploadOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

import { StyledPage } from './styled';
import useOrganizationSettings from 'pages/OrganizationSettings/hooks/useUpdateSettigns';

type UiFormValues = {
  colors: {
    '--warning': string;
    '--success': string;
    '--danger': string;
    '--dark-gray': string;
    '--dark-green': string;
    '--border-base': string;
    '--orange': string;
    '--gray': string;
    '--white': string;
    '--text-inverse': string;
    '--card-title': string;
    '--divider': string;
    '--error-dark': string;
    '--error-100': string;
    '--error-50': string;
    '--error-5': string;
    '--primary-hover': string;
    '--primary-blue': string;
    '--primary-btn': string;
    '--primary-dark': string;
    '--primary-100': string;
    '--primary-80': string;
    '--primary-10': string;
    '--primary-5': string;
    '--primary': string;
    '--secondary': string;
    '--secondary-1': string;
    '--secondary-5': string;
    '--secondary-hover': string;
    '--layout-bg': string;
    '--text-100': string;
    '--text-80': string;
    '--text-60': string;
    '--text-40': string;
    '--text-20': string;
    '--text-10': string;
    '--text-5': string;
    '--elements-1': string;
    '--elements-2': string;
    '--elements-3': string;
    '--elements-4': string;
    '--elements-5': string;
    '--elements-light': string;
    '--elements-light-2': string;
  };
  image?: string;
};

const defaultValues: UiFormValues = {
  colors: {
    '--warning': ' #FFC200',
    '--success': ' #7BC67E',
    '--danger': ' #FF5B5B',
    '--dark-gray': ' #183B56',
    '--dark-green': ' #5A7184',
    '--border-base': ' #D8E0F0',
    '--orange': ' #FF7F4E',
    '--gray': ' #6C7A96',
    '--white': ' #ffffff',
    '--text-inverse': ' #A4B2CE',
    '--card-title': ' #002366',
    '--divider': ' rgba(37, 82, 243, 0.5)',
    '--error-dark': ' #D80000',
    '--error-100': ' #F50057',
    '--error-50': ' #FF7394',
    '--error-5': ' #FFF0F5',
    '--primary-hover': ' #25A467',
    '--primary-blue': ' #4385F5',
    '--primary-btn': ' #35b678',
    '--primary-dark': ' #0B1A30',
    '--primary-100': ' #35B678',
    '--primary-80': ' #48CA8C',
    '--primary-10': ' #D3F8E4',
    '--primary-5': ' #E4FAEF',
    '--primary': ' #4385F5',
    '--secondary': ' #4385F5',
    '--secondary-1': ' #4F84E8',
    '--secondary-5': ' #EEF4FF',
    '--secondary-hover': ' #3B70D7',
    '--layout-bg': ' #F9FAFD',
    '--text-100': ' #18212C',
    '--text-80': ' #63687A',
    '--text-60': ' #A4A8B5',
    '--text-40': ' #C6C9D1',
    '--text-20': ' #DBDDE2',
    '--text-10': ' #F5F6F8',
    '--text-5': ' #FBFBFB',
    '--elements-1': ' #FFA25C',
    '--elements-2': ' #78C3BF',
    '--elements-3': ' #1F2F44',
    '--elements-4': ' #D59315',
    '--elements-5': ' #599693',
    '--elements-light': ' #FFF5ED',
    '--elements-light-2': ' #EBFAF9'
  }
};

interface IProps {
  id: string;
}

const UISettings: FC<IProps> = ({ id }) => {
  const { modal } = App.useApp();
  const [form] = Form.useForm<UiFormValues>();
  const { upload } = useFileUpload({
    progress: true
  });

  const { loading, settings, onUpdateSettings } = useOrganizationSettings(id);

  const onFinish = (values: UiFormValues) => {
    onUpdateSettings({
      ...values,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image: values.image?.[0]?.response
    });
  };

  const onReset = () => {
    modal.confirm({
      title: 'Waring',
      content: (
        <Typography.Text>
          Are you sure you want to reset settings to default?{' '}
          <b>Changes will be automatically saved.</b>
        </Typography.Text>
      ),
      onOk() {
        form.setFieldsValue({
          ...defaultValues
        });
        form.submit();
      }
    });
  };

  return (
    <StyledPage>
      <Form
        initialValues={{
          colors: settings?.colors || defaultValues.colors,
          image: settings?.image
            ? [
                {
                  uid: settings.image,
                  name: settings.image,
                  response: settings.image
                }
              ]
            : []
        }}
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item label="Colors">
          <Row gutter={[16, 16]}>
            {Object.keys(defaultValues.colors).map(color => (
              <Col span={6} key={color}>
                <Form.Item
                  name={['colors', color]}
                  label={color}
                  getValueFromEvent={e => e.toHexString()}
                >
                  <ColorPicker showText />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form.Item>
        <Form.Item
          name="image"
          label="Hero Image"
          valuePropName="fileList"
          getValueFromEvent={e => e?.fileList || []}
        >
          <Dragger
            maxCount={1}
            customRequest={e =>
              upload(e.file as File, {
                onUploadProgress: e.onProgress
              })
                .then(e.onSuccess)
                .catch(e.onError)
            }
            accept="image/webp"
          >
            <Flex vertical align="center">
              <CloudUploadOutlined
                style={{
                  color: 'var(--primary-color-100)',
                  fontSize: 45
                }}
              />
              <Typography.Title level={4}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: 'Drag & Drop files or <span>Browse</span>'
                  }}
                />
              </Typography.Title>
              <Typography.Paragraph style={{ color: 'var(--text-color-80)' }}>
                Supported formats WEBP
              </Typography.Paragraph>
            </Flex>
          </Dragger>
        </Form.Item>
        <Flex justify="end" gap={12}>
          <Button size="large" onClick={onReset} disabled={loading}>
            Reset to default
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={form.submit}
            loading={loading}
          >
            Save
          </Button>
        </Flex>
      </Form>
    </StyledPage>
  );
};

export default UISettings;
