import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IFileState, ViewerPdf } from './types';

const initialState: IFileState = {
  viewerPdf: {}
};

const filesSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setViewerPdf: (state, action: PayloadAction<ViewerPdf>) => {
      const { id, url } = action.payload;
      state.viewerPdf[id] = url;
    }
  }
});

export const { setViewerPdf } = filesSlice.actions;
export default filesSlice.reducer;
