import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
  box-shadow: 0px 6px 12px 0px rgba(221, 221, 221, 0.06);
  border: 1px solid #f5f6f8;
  background-color: #ffffff;
  border-radius: 6px;
  height: 100%;
  overflow: hidden;
  .ant-layout-sider {
    background-color: #ffffff;
    padding: 40px 24px;
    border-right: 1px solid #f5f6f8;
    .ant-menu {
      border: none;
    }
  }
  .ant-layout-content {
    padding: 40px;
  }
`;
