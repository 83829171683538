import { FC, useEffect, useState } from 'react';
import { BadgeProps, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { MAIN_ROUTES, RouteObject } from 'constants/routes';
import { Link, useLocation } from 'react-router-dom';
import { usePermission } from 'hooks';
import { useQuery } from '@apollo/client';
import { GET_PATIENT_REQUESTS_COUNT } from 'gql/appointments/queries';
import { FAST_APPOINTMENTS_STATUS } from 'constants/appointments';
import { GetPatientRequests } from 'gql/appointments/__generated__/GetPatientRequests';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useDashboardTourStateContext } from 'providers/Tour/DashboardProvider';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateToursVersion } from 'store/tour/slice';
import { selectShowTourItem } from 'store/tour/selectors';

import { sidebarOpenedStorage } from 'utils/storage';
import { ISidebarProps } from './types';
import { StyledIcon, StyledLabel, StyledMenu, StyledSider } from './styled';

const Sidebar: FC<ISidebarProps> = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { checkUserMultiPermission, checkUserPermission } = usePermission();
  const [openedKeys, setOpenedKeys] = useState<string[]>(() => {
    const route = MAIN_ROUTES.find(item => {
      if (item.children?.length && !item.renderParent) {
        return pathname.includes(item.path);
      }
    });

    return route?.path ? [route.path] : [];
  });

  const [isOpen, setOpen] = useState(sidebarOpenedStorage.get() === '1');
  const { refs, findTourStepByKey } = useDashboardTourStateContext();
  const shownTour = useAppSelector(selectShowTourItem('dashboard'));
  const dispatch = useAppDispatch();

  const hasPatientRequestsViewPermission = checkUserPermission(
    PERMISSION_ROUTES.patientRequests,
    PERMISSION_ACTIONS.read
  );

  const patientRequests = useQuery<GetPatientRequests>(
    GET_PATIENT_REQUESTS_COUNT,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          status: FAST_APPOINTMENTS_STATUS.new?.toString(),
          pagination: {
            page: 0,
            limit: 1
          }
        }
      },
      skip: !hasPatientRequestsViewPermission
    }
  );

  const newPatientRequests =
    patientRequests.data?.getFastAppointments.data?.total || 0;

  const onSidebarChange = (value: boolean) => {
    if (value) {
      sidebarOpenedStorage.set('1');
    } else {
      sidebarOpenedStorage.remove();
    }

    setOpen(value);
  };

  const getBadgeProps = (path: string): BadgeProps => {
    switch (path) {
      case '/appointments':
        return { dot: !!newPatientRequests, offset: [5, 0] };
      case 'patient-requests':
        return { count: newPatientRequests, size: 'small', offset: [5, -5] };
      default:
        return {};
    }
  };

  const renderItemChildren = (hasChildren: boolean, item: RouteObject) => {
    if (!hasChildren) {
      return null;
    }

    return item.children
      ?.filter(
        child => !child.hideInSidebar && checkUserMultiPermission(child.key)
      )
      .map(child => ({
        key: `${child.path}-${child.key}`,
        label: (
          <Link to={`${item.path}/${child.path}`}>
            <StyledLabel {...getBadgeProps(child.path)}>
              {t(`sidebar.${child.label}`)}
            </StyledLabel>
          </Link>
        )
      }));
  };

  useEffect(() => {
    dispatch(
      updateToursVersion({ key: 'dashboard', value: openedKeys.join('-') })
    );
  }, [dispatch, openedKeys, openedKeys.length]);

  return (
    <StyledSider
      width={256}
      onCollapse={value => onSidebarChange(value)}
      collapsed={isOpen}
      collapsible
    >
      <StyledMenu
        defaultOpenKeys={openedKeys}
        onOpenChange={setOpenedKeys}
        items={MAIN_ROUTES?.map(item => {
          if (item.hideInSidebar) {
            return null;
          }

          const hasPermission = checkUserMultiPermission(item.key);

          if (!hasPermission) {
            return null;
          }

          const hasChildren = !item.renderParent && !!item.children?.length;
          const label = t(`sidebar.${item.label}`);
          const tourStep = Number(findTourStepByKey(item.label));

          return {
            key: item.path,
            icon: <StyledIcon>{item.icon}</StyledIcon>,
            label: (
              <>
                <Space>
                  {hasChildren ? (
                    <StyledLabel {...getBadgeProps(item.path)}>
                      {label}
                    </StyledLabel>
                  ) : (
                    <Link to={item.path}>
                      <StyledLabel {...getBadgeProps(item.path)}>
                        {label}
                      </StyledLabel>
                    </Link>
                  )}
                </Space>
                <div
                  ref={e => {
                    if (e && tourStep && refs) {
                      refs.current[tourStep] = e;
                    }
                  }}
                  onClick={e => shownTour && e.stopPropagation()}
                  style={{ position: 'absolute', inset: 0, zIndex: -1 }}
                />
              </>
            ),
            children: renderItemChildren(hasChildren, item)
          };
        })}
        mode="inline"
      />
    </StyledSider>
  );
};

export default Sidebar;
