import React, { FC, memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import styled from 'styled-components';
import { Empty } from 'antd';

type Props = {
  id: string;
  xKey: string;
  yKeys: string[];
  labels?: Record<string, string>;
  results: Record<string, number | string>[];
};

const StyledRoot = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 70px;
    height: 25px;
    background-color: #ffffff;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
`;

const ClusteredBarChart: FC<Props> = ({ id, xKey, yKeys, results, labels }) => {
  useEffect(() => {
    if (!results.length) {
      return;
    }

    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout
      })
    );

    const scrollbarX = chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, { orientation: 'horizontal' })
    );

    scrollbarX.startGrip.set('scale', 0.7);
    scrollbarX.endGrip.set('scale', 0.7);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: xKey,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xRenderer.labels.template.setAll({
      rotation: -90
    });

    xAxis.data.setAll(results);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      })
    );

    function makeSeries(name: string, fieldName: string) {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: xKey
        })
      );

      series.columns.template.setAll({
        tooltipText: '[bold]{name}[/]\n{date}: {valueY}€',
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0
      });

      series.data.setAll(results);

      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: '{valueY}',
            fill: root.interfaceColors.get('alternativeText'),
            centerY: 0,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      legend.data.push(series);
    }

    yKeys.forEach(key => {
      makeSeries(labels?.[key] || key, key);
    });

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id, labels, results, xKey, yKeys]);

  if (!results.length) {
    return (
      <StyledRoot>
        <Empty description="No data to show, please adjust filters" />
      </StyledRoot>
    );
  }

  return <StyledRoot id={id} />;
};

export default memo(ClusteredBarChart);
