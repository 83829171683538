import { useMutation, useQuery } from '@apollo/client';
import { TranslatorPlanInput } from '__generated__/globalTypes';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography
} from 'antd';
import client from 'apolloClient';
import { PLAN_DURATIONS } from 'constants/plans';
import { CreateTranslatorPlan } from 'gql/translationPlans/__generated__/CreateTranslatorPlan';
import { Languages } from 'gql/translationPlans/__generated__/Languages';
import { TranslatorPlanFragment } from 'gql/translationPlans/__generated__/TranslatorPlanFragment';
import { TRANSLATION_PLAN_FRAGMENT } from 'gql/translationPlans/fragments';
import {
  CREATE_TRANSLATION_PLAN,
  UPDATE_TRANSLATION_PLAN
} from 'gql/translationPlans/mutations';
import {
  GET_LANGUAGES,
  GET_TRANSLATION_PLANS
} from 'gql/translationPlans/queries';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorMessage } from 'utils/showErrorMessage';
import { PATTERNS } from 'utils/validation';

const { TextArea } = Input;
const { Title } = Typography;

const TranslationPlansDetails: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { translationPlans } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // form
  const [form] = Form.useForm();
  // graphql
  const planId = translationPlans.params?.id;

  const closeModal = useCallback(() => {
    form.resetFields();
    toggleModal('translationPlans', false);
  }, [form, toggleModal]);

  const { data: languagesData } = useQuery<Languages>(GET_LANGUAGES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        page: 0,
        limit: 200
      }
    }
  });

  const planData: TranslatorPlanFragment | null = client.readFragment({
    id: `TranslatorPlan:${planId}`,
    fragment: TRANSLATION_PLAN_FRAGMENT
  });

  const [createPlan, { loading: isCreating }] =
    useMutation<CreateTranslatorPlan>(CREATE_TRANSLATION_PLAN, {
      onCompleted() {
        closeModal();
        client.refetchQueries({ include: [GET_TRANSLATION_PLANS] });
      },
      onError: err => showErrorMessage(err)
    });

  const [updatePlan, { loading: isUpdating }] = useMutation(
    UPDATE_TRANSLATION_PLAN,
    {
      onCompleted() {
        closeModal();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const handleSubmit = useCallback(
    (values: TranslatorPlanInput) => {
      const input = {
        ...values,
        price: Number(values.price)
      };

      if (planId) {
        updatePlan({
          variables: {
            id: planId,
            input
          }
        });
      } else {
        createPlan({
          variables: {
            input
          }
        });
      }
    },
    [createPlan, planId, updatePlan]
  );

  useEffect(() => {
    if (translationPlans.visible && planData) {
      form.setFieldsValue({
        name: planData.name,
        price: planData.price,
        duration: planData.duration,
        lang_1: planData.lang_1,
        lang_2: planData.lang_2,
        description: planData.description
      });
    } else {
      form.resetFields();
    }
  }, [form, translationPlans.visible, planData]);

  return (
    <Modal
      open={translationPlans.visible}
      onCancel={closeModal}
      footer={
        <>
          <Divider />
          <Button onClick={closeModal}>{t('common.cancel')}</Button>
          <Button
            loading={isUpdating || isCreating}
            onClick={form.submit}
            type="primary"
          >
            {t('common.save')}
          </Button>
        </>
      }
      title={
        <Title level={3} style={{ textAlign: 'center', marginBottom: 20 }}>
          {t(
            planId
              ? 'translation_plans.edit_plan'
              : 'translation_plans.add_plan'
          )}
        </Title>
      }
    >
      <Form form={form} layout="vertical" size="large" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label={t('translation_plans.name')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label={t('translation_plans.price')}
          rules={[{ required: true }, { pattern: PATTERNS.price }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="duration"
          label={t('translation_plans.duration')}
          rules={[{ required: true }]}
        >
          <Select options={PLAN_DURATIONS} />
        </Form.Item>
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <Form.Item
              name="lang_1"
              label={t('translation_plans.lang_1')}
              rules={[{ required: true }]}
            >
              <Select
                options={
                  languagesData?.languages?.data.results.map(item => ({
                    label: item.name,
                    value: item.code
                  })) || []
                }
                optionFilterProp="label"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lang_2"
              label={t('translation_plans.lang_2')}
              rules={[{ required: true }]}
            >
              <Select
                options={
                  languagesData?.languages?.data.results.map(item => ({
                    label: item.name,
                    value: item.code
                  })) || []
                }
                optionFilterProp="label"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="description"
          label={t('translation_plans.description')}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(TranslationPlansDetails);
