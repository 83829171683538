import { Button, Modal, Space } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectPdfForViewer } from 'store/file/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import instance from 'services/api';

import { Loading } from 'components/ui';

const PdfViewer: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState('');
  const url = useAppSelector(selectPdfForViewer(id));

  const showError = useCallback(() => {
    Modal.error({
      title: 'Error',
      content: (
        <div>
          <p>File not found</p>
          <Space style={{ width: '100%', justifyContent: 'flex-end' }} size={4}>
            <Button
              onClick={() => {
                Modal.destroyAll();
                navigate('/');
              }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={() => window.location.reload()}>
              Try again
            </Button>
          </Space>
        </div>
      ),
      okButtonProps: {
        style: { display: 'none' }
      }
    });
  }, [navigate]);

  useEffect(() => {
    (async () => {
      try {
        if (!url) {
          throw new Error();
        }

        const res = await instance.get(url, {
          responseType: 'blob'
        });

        const dataUrl = URL.createObjectURL(res.data);

        setFile(dataUrl);
      } catch {
        showError();
      }
    })();
  }, [showError, url]);

  if (!file) {
    return <Loading />;
  }

  return (
    <iframe
      src={file}
      style={{ width: '100vw', height: '100vh', border: 'none' }}
    />
  );
};

export default PdfViewer;
