import { Card, Typography } from 'antd';
import { FC } from 'react';

interface IProps {
  title: string;
  value: string;
}

const TotalCard: FC<IProps> = ({ title, value }) => (
  <Card title={title}>
    <Typography.Paragraph style={{ fontSize: 24 }}>
      {value}
    </Typography.Paragraph>
  </Card>
);

export default TotalCard;
