import { FC } from 'react';
import { Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { useNavigate } from 'react-router-dom';
import { DOCTOR_HOW_IT_WORKS_PAGES } from '@medicusunion/front-utils';

import { ActionMenu, PageWrapper } from 'components/ui';

const DoctorHHowItWorks: FC = () => {
  const navigate = useNavigate();

  const dataSource = Object.values(DOCTOR_HOW_IT_WORKS_PAGES)
    .sort((a, b) => a.order - b.order)
    .map(item => ({
      id: item.key,
      slug: item.key,
      title: item.label
    }));

  return (
    <PageWrapper title="How It Works (Doctor)" color="white">
      <Table
        dataSource={dataSource}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug'
          },
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
          },
          {
            title: '',
            dataIndex: 'id',
            width: '5%',
            align: 'right',
            render: (_, record) => (
              <ActionMenu
                data={[
                  {
                    title: 'Update content',
                    permission: {
                      route: PERMISSION_ROUTES.staticContent,
                      action: PERMISSION_ACTIONS.update
                    },
                    action() {
                      navigate(record.id);
                    }
                  }
                ]}
                placement="bottomRight"
                icon={<EllipsisOutlined />}
              />
            )
          }
        ]}
        pagination={false}
        rowKey="id"
      />
    </PageWrapper>
  );
};

export default DoctorHHowItWorks;
