import { useCallback } from 'react';
import { App, Typography } from 'antd';
import { useOrganizationsStateContext } from 'providers/Organizations';

const useCheckOrganization = () => {
  const { selected } = useOrganizationsStateContext();
  const { modal } = App.useApp();

  const checkOrganizationWrapper = useCallback(
    (cb: () => void) => {
      if (selected !== 'all') {
        return cb();
      }

      modal.confirm({
        title: 'Warning',
        content: (
          <Typography.Text>
            Please choose an organization to proceed with this action
          </Typography.Text>
        ),
        type: 'warning',
        cancelButtonProps: {
          style: {
            display: 'none'
          }
        }
      });

      return;
    },
    [modal, selected]
  );

  return { checkOrganizationWrapper };
};

export default useCheckOrganization;
