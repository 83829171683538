import { FC, useCallback, useState } from 'react';
import { App, Button, Col, Form, Row, Select, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS } from 'gql/notifications/queries';
import { Notifications as NotificationsType } from 'gql/notifications/__generated__/Notifications';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import {
  NotificationType,
  NOTIFICATION_TYPES_LABELS,
  NOTIFICATION_ACTIONS_LABELS,
  NOTIFICATION_TRIGGERS,
  NOTIFICATION_TYPES
} from 'constants/notifications';
import { DELETE_NOTIFICATION } from 'gql/notifications/mutations';
import { useTranslation } from 'react-i18next';

import { NotificationDetails } from './components';
import { IconButton, PageWrapper } from 'components/ui';
import { checkRequiredValue } from 'utils/checkRequiredValue';

const PAGE_LIMIT = 10;

const Notifications: FC = () => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState('');
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page') || 0);
  const type = searchParams.get('type');
  const trigger = searchParams.get('trigger');
  const { modal } = App.useApp();

  const { data, loading, refetch } = useQuery<NotificationsType>(
    GET_NOTIFICATIONS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        query: {
          pagination: {
            page,
            limit: PAGE_LIMIT
          },
          type: type ? Number(type) : undefined,
          trigger: trigger ? Number(trigger) : undefined
        }
      }
    }
  );

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    onCompleted() {
      refetch();
    }
  });

  const onCloseDetails = useCallback(() => {
    setSelectedId('');
    setDetailsVisible(false);
  }, []);

  const onDeleteNotification = (id: string) => {
    modal.confirm({
      title: 'Are you sure?',
      onOk() {
        deleteNotification({
          variables: {
            id
          }
        });
      }
    });
  };

  const onChangeType = (v: string) => {
    searchParams.set('page', '0');

    if (v) {
      searchParams.set('type', v);
    } else {
      searchParams.delete('type');
    }

    setSearchParams(searchParams);
  };

  const onChangeTrigger = (v: string) => {
    searchParams.set('page', '0');

    if (v) {
      searchParams.set('trigger', v);
    } else {
      searchParams.delete('trigger');
    }

    setSearchParams(searchParams);
  };

  const notifications = data?.notifications?.data.results || [];
  const total = data?.notifications?.data.total || 0;

  return (
    <PageWrapper
      title="Notifications"
      color="white"
      extra={[
        <Button
          type="primary"
          key="add-doctor"
          onClick={() => setDetailsVisible(true)}
        >
          <PlusOutlined />
          Create
        </Button>
      ]}
    >
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Form.Item rules={[{ required: true }]}>
            <Select
              options={Object.entries(NOTIFICATION_TYPES).map(
                ([key, value]) => ({
                  label: key,
                  value: `${value}`
                })
              )}
              onChange={onChangeType}
              value={type}
              placeholder="Type"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item rules={[{ required: true }]}>
            <Select
              options={[
                ...Object.entries(NOTIFICATION_TRIGGERS).map(
                  ([key, value]) => ({
                    label: key,
                    value: `${value}`
                  })
                ),
                {
                  label: 'push & sms',
                  value: `${
                    NOTIFICATION_TRIGGERS.push + NOTIFICATION_TRIGGERS.sms
                  }`
                }
              ]}
              onChange={onChangeTrigger}
              value={trigger}
              placeholder="Action"
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Table
        dataSource={notifications}
        loading={loading}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render(title) {
              return (
                <ul style={{ padding: 0 }}>
                  {Object.keys(title).map((key, index) => (
                    <li key={index}>
                      <b>{key}: </b>
                      {title[key]}
                    </li>
                  ))}
                </ul>
              );
            }
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render(type: NotificationType) {
              return NOTIFICATION_TYPES_LABELS[type as NotificationType];
            }
          },
          {
            title: 'Trigger',
            dataIndex: 'trigger',
            key: 'trigger',
            render(trigger) {
              return Object.entries(NOTIFICATION_TRIGGERS)
                .map(([key, value]) =>
                  checkRequiredValue(trigger, value) ? key : null
                )
                .filter(i => i)
                .join(', ');
            }
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            render(action) {
              return t(`notifications.${NOTIFICATION_ACTIONS_LABELS[action]}`);
            }
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render(createdAt: string) {
              return dayjs(createdAt).format(FULL_DATE_FORMAT);
            }
          },
          {
            title: '',
            dataIndex: 'id',
            key: 'actions',
            align: 'right',
            render: id => (
              <Space>
                <IconButton
                  onClick={() => {
                    setDetailsVisible(true);
                    setSelectedId(id);
                  }}
                >
                  <EditOutlined />
                </IconButton>
                <IconButton onClick={() => onDeleteNotification(id)}>
                  <DeleteOutlined />
                </IconButton>
              </Space>
            )
          }
        ]}
        pagination={{
          total: total || 0,
          current: page + 1,
          onChange: p => {
            searchParams.set('page', `${p - 1}`);
            setSearchParams(searchParams);
          },
          showSizeChanger: false
        }}
        rowKey="id"
      />
      <NotificationDetails
        open={isDetailsVisible}
        id={selectedId}
        onClose={onCloseDetails}
      />
    </PageWrapper>
  );
};

export default Notifications;
