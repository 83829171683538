import { gql } from '@apollo/client';

import { USER_FRAGMENT } from './fragments';

export const GET_USERS = gql`
  query Users($query: GetUsersInput) {
    users(query: $query) {
      data {
        results {
          ...UserFragment
        }
        total
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_PATIENTS_LIST = gql`
  query GetPatients($query: PatientsQuerySearch!) {
    patientsList(query: $query) {
      data {
        results {
          id
          first_name
          last_name
          avatar
          phone
          email
          confirm
          lang
          created_at
          role {
            name
          }
        }
        total
      }
    }
  }
`;
