import { Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

export const StyledPrice = styled(Paragraph)`
  color: var(--primary);
  font-weight: 700;
  text-align: center;
  font-size: 32px;
`;

export const StyledList = styled.ul``;
