import styled from 'styled-components';
import { Button, Row } from 'antd';

export const CardRow = styled(Row)`
  border: 1px solid var(--border-color-base);
  padding: 24px;
  margin-bottom: 24px;
`;

// export const InfoWrapper = styled.div``;

export const Cover = styled.div<{ src?: string }>`
  ${({ src }) =>
    src &&
    `
    background-image: url(${src});
  `}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 240px;
  height: 148px;
  /* padding-bottom: 61.67%; */
  border-radius: 8px;
`;

// export const CardActionWrapper = styled.div`
//   margin-left: 24px;
// `;

export const ActionButton = styled(Button)`
  background-color: #ffffff;
`;
