import { gql } from '@apollo/client';

export const GET_ACTIVITY_LOGS = gql`
  query ActivityLogs($query: GetActivityLogs!) {
    activityLogs(query: $query) {
      data {
        total
        results {
          activity
          date
          device
          id
          ip
          user {
            id
            first_name
            last_name
            role {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_MEETING_LOGS = gql`
  query GetMeetingLogs($query: GetMeetingLogInput) {
    getAllMeetingLogs(query: $query) {
      data {
        total
        results {
          _id
          ip
          name
          options
          peerID
          roomID
          userAgent
          createdAt
        }
      }
    }
  }
`;
