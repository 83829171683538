import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_CALL_REVIEWS } from 'gql/reviews/queries';
import { Button, Empty, Row } from 'antd';
import { CallReviews } from 'gql/reviews/__generated__/CallReviews';

import { PageWrapper } from 'components/ui';
import { Review } from './components';

const PAGE_LIMIT = 10;

const CallReviewsPage: FC = () => {
  // translations
  const { t } = useTranslation();
  // states
  const [page, setPage] = useState(0);
  const [isPaging, setPaging] = useState(false);
  // gql
  const { data, fetchMore, loading } = useQuery<CallReviews>(GET_CALL_REVIEWS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        limit: PAGE_LIMIT,
        page: 0
      }
    }
  });

  const results = data?.callReviews?.data.results || [];
  const total = data?.callReviews?.data.total || 0;

  useEffect(() => {
    if (page) {
      setPaging(true);
      fetchMore({
        variables: {
          query: {
            page,
            limit: PAGE_LIMIT
          }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newResult = fetchMoreResult.callReviews?.data.results || [];

          if (!previousResult.callReviews?.data.results || !newResult) {
            return previousResult;
          }

          return {
            callReviews: {
              ...previousResult.callReviews,
              data: {
                ...previousResult.callReviews.data,
                results: [
                  ...previousResult.callReviews.data.results,
                  ...newResult
                ]
              }
            }
          };
        }
      }).finally(() => {
        setPaging(false);
      });
    }
  }, [fetchMore, page]);

  return (
    <PageWrapper
      title={t('call_reviews.page_title')}
      color="white"
      loading={loading}
    >
      {results.length ? (
        <>
          {results.map(item => (
            <Review
              key={`review-item-${item.id}`}
              appointmentId={item.appointment_id}
              issues={item.issues}
              review={item.review}
              satisfied={item.satisfied}
              sender={
                item.sender
                  ? {
                      firstName: item.sender?.first_name || '',
                      lastName: item.sender?.last_name || '',
                      email: item.sender?.email
                    }
                  : null
              }
              date={item.created_at}
            />
          ))}
          {total > results.length && (
            <Row justify="center">
              <Button
                type="default"
                onClick={() => setPage(prev => prev + 1)}
                loading={isPaging}
              >
                {t('call_reviews.load_more')}
              </Button>
            </Row>
          )}
        </>
      ) : (
        <Empty />
      )}
    </PageWrapper>
  );
};

export default CallReviewsPage;
