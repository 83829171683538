import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Link, useSearchParams } from 'react-router-dom';
import {
  DOCTOR_STATUS,
  DOCTOR_STATUSES,
  DOCTOR_STATUS_LABEL
} from 'constants/doctors';
import { GET_DOCTORS_BY_STATUS } from 'gql/doctors/queries';
import {
  DoctorsByStatus,
  DoctorsByStatus_doctorsByStatus_data_results as DoctorType
} from 'gql/doctors/__generated__/DoctorsByStatus';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { IconButton, PageWrapper } from 'components/ui';
import { AddDoctor } from './components';
import { Private } from 'components/shared';

const { Search } = Input;
const { Option } = Select;
const PAGE_LIMIT = 10;

const Doctors: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState<string>(DOCTOR_STATUSES.all);
  const [search, setSearch] = useState('');

  const page = Number(searchParams.get('page'));

  // graphql
  const { data, loading } = useQuery<DoctorsByStatus>(GET_DOCTORS_BY_STATUS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        pagination: {
          page: page || 0,
          limit: PAGE_LIMIT
        },
        status: status === DOCTOR_STATUSES.all ? undefined : status,
        name: search
      }
    }
  });

  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);

  const columns: ColumnProps<DoctorType>[] = useMemo(
    () => [
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('table.name'),
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => `${record.first_name} ${record.last_name}`
      },
      {
        title: t('table.email'),
        dataIndex: 'email',
        key: 'email',
        render: value => <a href={`mailto:${value}`}>{value}</a>
      },
      {
        title: t('table.phone'),
        dataIndex: 'phone',
        key: 'phone',
        render: value => <a href={`tel:${value}`}>{value}</a>
      },
      {
        title: t('table.status'),
        dataIndex: 'status',
        key: 'status',
        render: value =>
          !value
            ? t('doctors.waiting')
            : t(
                `doctors.${
                  DOCTOR_STATUS_LABEL[value as keyof typeof DOCTOR_STATUS_LABEL]
                }`
              )
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'id',
        width: '5%',
        align: 'right',
        render: (_, record) => (
          <Link to={`${record.id}/personal-information?lang=en`}>
            <IconButton>
              <EyeOutlined />
            </IconButton>
          </Link>
        )
      }
    ],
    [t]
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (page > 0) {
        searchParams.set('page', '0');
        setSearchParams(searchParams);
      }

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    },
    [page, searchParams, setSearchParams]
  );

  const results = data?.doctorsByStatus?.data.results || [];

  useEffect(() => {
    if (searchParams.get('status')) {
      setStatus(searchParams.get('status') || DOCTOR_STATUSES.active);
    }
  }, [searchParams, status]);

  const onStatusChange = useCallback(
    (val: string) => {
      searchParams.set('page', '0');
      searchParams.set('status', val);
      setSearchParams(searchParams);
      setStatus(val);
    },
    [searchParams, setSearchParams]
  );

  return (
    <PageWrapper
      title={t('doctors.page_title')}
      color="white"
      extra={[
        <Private
          key="add-doctor"
          route={PERMISSION_ROUTES.doctors}
          action={PERMISSION_ACTIONS.create}
        >
          <AddDoctor />
        </Private>
      ]}
    >
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              <Search
                placeholder={t('common.search')}
                onChange={onSearchChange}
              />
            </Form.Item>
            <Form.Item>
              <Select
                defaultValue={searchParams.get('status') || DOCTOR_STATUSES.all}
                style={{ width: 120 }}
                onChange={onStatusChange}
              >
                {DOCTOR_STATUS.map(item => (
                  <Option key={item.value} value={item.value}>
                    {t(`doctors.${item.label}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.doctorsByStatus?.data.total || 0,
          current: page + 1,
          onChange: p => {
            searchParams.set('page', `${p - 1}`);
            setSearchParams(searchParams);
          },
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default Doctors;
