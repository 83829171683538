import { Card, Typography } from 'antd';
import { FC } from 'react';

import { ISectionBlockProps } from './types';

const { Title } = Typography;

const SectionBlock: FC<ISectionBlockProps> = ({ title, children }) => {
  return (
    <Card>
      <Title level={4}>{title}</Title>
      {children}
    </Card>
  );
};

export default SectionBlock;
