import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
`;

export const LinkWrapper = styled.div`
  text-align: left;
  padding-top: 8px;
`;

export const InputContainer = styled.div`
  padding-bottom: 24px;
  justify-content: center;
  display: flex;
  .character {
    background-color: transparent;
    border: none;

    border-bottom: 2px solid rgba(164, 178, 206, 0.8);
    border-radius: 6px;
    background: rgba(243, 244, 245, 0.4);
    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      border-right-width: 1px;
      outline: 0;
    }
  }
  .character--selected {
    border: none;
    outline: none;
    border-bottom: 2px solid rgba(164, 178, 206, 0.8);
    position: relative;
  }
  .character--selected:empty::after {
    content: '';
    width: 1px;
    height: 30px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

  .character--inactive {
    border-bottom: 2px solid rgba(164, 178, 206, 0.8);
  }
`;
