import { Card } from 'antd';
import styled, { css } from 'styled-components';

export const StyledCard = styled(Card)`
  height: 100%;
  max-height: 218px;
  .ant-card-head {
    padding: 16px 20px;
  }
  .ant-card-body {
    padding: 16px 20px 20px;
  }

  :hover {
    box-shadow: 0px 5px 10px 0px #cbccd31a;
  }
`;

export const StyledParagraph = styled.p<{ $strong?: boolean }>`
  &&& {
    color: var(--text-color-80);
    font-weight: 400;
    margin-bottom: 4px;
    ${({ $strong }) =>
      $strong &&
      css`
        color: var(--text-color-100);
        font-weight: 500;
      `}
  }
`;
