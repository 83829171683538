/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import styled from 'styled-components';
import { Empty } from 'antd';

import { money } from 'utils/price';

type Props = {
  id: string;
  results: {
    value: number;
    category: string;
  }[];
};

const StyledRoot = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 70px;
    height: 20px;
    background-color: #ffffff;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
`;

const DonutChart: FC<Props> = ({ id, results }) => {
  const isEmpty = results.every(item => !item.value);

  useEffect(() => {
    if (isEmpty) {
      return;
    }

    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50)
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false
      })
    );

    series.labels.template.setAll({
      textType: 'circular',
      centerX: 0,
      centerY: 0
    });

    series.data.setAll(results);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15
      })
    );

    legend.data.setAll(series.dataItems);

    series.appear(1000, 100);

    const label = root.tooltipContainer.children.push(
      am5.Label.new(root, {
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        fill: am5.color(0x000000),
        paddingBottom: 60,
        fontSize: 30
      })
    );

    const totalPrice = results.reduce((acc, val) => acc + val.value, 0);

    label.set('text', money(totalPrice));

    return () => {
      root.dispose();
    };
  }, [id, isEmpty, results]);

  if (isEmpty) {
    return (
      <StyledRoot>
        <Empty description="No data to show, please adjust filters" />
      </StyledRoot>
    );
  }

  return <StyledRoot id={id} />;
};

export default memo(DonutChart);
