import { FC, memo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Col, Empty, Flex, Row } from 'antd';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { TranslatorPlans } from 'gql/translationPlans/__generated__/TranslatorPlans';
import { GET_TRANSLATION_PLANS } from 'gql/translationPlans/queries';
import { useUIDispatchContext } from 'providers/UI';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Private } from 'components/shared';
import { Loading, PageWrapper } from 'components/ui';
import { Filters, PlanItem, TranslationPlansDetails } from './components';
import { StyledPagination } from './styled';

const PAGE_LIMIT = 9;

const Plans: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page') || 0);
  const duration = Number(searchParams.get('duration')) || null;
  const search = searchParams.get('search') || '';

  const { toggleModal } = useUIDispatchContext();
  const { data, loading } = useQuery<TranslatorPlans>(GET_TRANSLATION_PLANS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        name: search,
        duration,
        pagination: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const results = data?.translatorPlans.data?.results || [];
  const total = data?.translatorPlans.data?.total;

  return (
    <PageWrapper
      title={t('translation_plans.page_title')}
      color="text-color-5"
      extra={[
        <Private
          route={PERMISSION_ROUTES.translatorPlans}
          action={PERMISSION_ACTIONS.create}
          key="add-translator"
        >
          <Button
            type="primary"
            onClick={() => toggleModal('translationPlans', true)}
          >
            <PlusOutlined />
            {t('translation_plans.add_new')}
          </Button>
        </Private>
      ]}
    >
      <Flex vertical>
        <Flex style={{ width: '100%' }} justify="start">
          <Filters />
        </Flex>
        {results.length ? (
          <>
            <Row gutter={[16, 16]} wrap>
              {results.map(item => (
                <Col sm={8} key={`plan-item-${item.id}`}>
                  <PlanItem
                    id={item.id}
                    name={item.name}
                    duration={item.duration}
                    price={item.price}
                    translation={`${item.lang_1} - ${item.lang_2}`}
                  />
                </Col>
              ))}
            </Row>
            <StyledPagination
              style={{ marginTop: 32 }}
              pageSize={9}
              defaultCurrent={1}
              current={page + 1}
              total={total}
              showSizeChanger={false}
              onChange={p => {
                searchParams.set('page', `${p - 1}`);
                setSearchParams(searchParams);
              }}
            />
          </>
        ) : loading ? (
          <Loading />
        ) : (
          <Row justify="center">
            <Empty />
          </Row>
        )}
        <TranslationPlansDetails />
      </Flex>
    </PageWrapper>
  );
};

export default memo(Plans);
