import { EditOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Empty, Space, Tag, Timeline, Typography } from 'antd';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import dayjs from 'dayjs';
import { useUIDispatchContext } from 'providers/UI';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Private } from 'components/shared';
import { makeJson } from 'utils/json';

const PaymentInfo: FC = () => {
  // navigation
  const params = useParams();
  // context
  const { toggleModal } = useUIDispatchContext();
  // graphql
  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'cache-only'
  });

  const appointment = data?.appointmentForAdmin?.data;

  const adminPayment = appointment?.admin_payment;
  const patientPayment = appointment?.patient_payment_info;

  if (!appointment) {
    return null;
  }

  return (
    <div>
      <Card
        title="Doctor payment"
        extra={
          <Private
            route={PERMISSION_ROUTES.appointments}
            action={PERMISSION_ACTIONS.updatePayment}
          >
            <Button
              icon={<EditOutlined />}
              onClick={() =>
                toggleModal('paymentStatus', true, { id: params?.id })
              }
            />
          </Private>
        }
        style={{ marginBottom: 24 }}
      >
        <Space>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Status:{' '}
          </Typography.Title>
          {adminPayment?.paid ? (
            <Tag color="green">Paid</Tag>
          ) : (
            <Tag color="red">Not Paid</Tag>
          )}
        </Space>
        {!!adminPayment?.paid && !!adminPayment?.payed_at && (
          <div style={{ marginTop: 16 }}>
            <Space>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Payment date:{' '}
              </Typography.Title>
              <Typography.Text>
                {dayjs(adminPayment.payed_at).format('YYYY-MM-DD HH:mm')}
              </Typography.Text>
            </Space>
          </div>
        )}
        <div style={{ marginTop: 16 }}>
          <Typography.Title level={5}>Notes</Typography.Title>
          <Typography.Paragraph>
            {adminPayment?.note || '-'}
          </Typography.Paragraph>
        </div>
      </Card>
      <Card title="Patient payment history">
        {patientPayment?.length ? (
          <Timeline
            items={patientPayment?.map(item => {
              const additionalInfo = makeJson(item.payment_info, null, 4);

              return {
                children: (
                  <Timeline.Item key={`Timeline-${item.id}`} color="#2552F3">
                    <p>
                      <span style={{ fontWeight: '700' }}>ID:</span> {item.id}
                    </p>
                    <p>
                      <span style={{ fontWeight: '700' }}>Date:</span>{' '}
                      {dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}
                    </p>
                    <p>
                      <span style={{ fontWeight: '700' }}>Source:</span>{' '}
                      {item.source}
                    </p>
                    {!!additionalInfo && (
                      <div>
                        <span style={{ fontWeight: '700' }}>
                          Additional info:
                        </span>{' '}
                        <pre style={{ backgroundColor: '#F9FAFC' }}>
                          {additionalInfo}
                        </pre>
                      </div>
                    )}
                  </Timeline.Item>
                )
              };
            })}
          />
        ) : (
          <Empty />
        )}
      </Card>
    </div>
  );
};

export default PaymentInfo;
