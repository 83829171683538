import { FC, useCallback, useMemo, useState } from 'react';
import { App, Modal, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENT_REPORT } from 'gql/financial/queries';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/dates';
import {
  GetAppointmentReport,
  GetAppointmentReport_getAppointmentReport_data_results
} from 'gql/financial/__generated__/GetAppointmentReport';
import { getIdStart } from '@medicusunion/front-utils';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import {
  EllipsisOutlined,
  InfoCircleOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useUIDispatchContext } from 'providers/UI';
import { useSearchParams } from 'react-router-dom';
import config from 'config';
import instance from 'services/api';
import styled from 'styled-components';

import { ActionMenu, PageWrapper } from 'components/ui';
import { money } from 'utils/price';
import { Filters } from './components';
import { FormFilters } from './components/Filters/types';
import { getFileUrl } from 'utils/file';

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 124px);
`;

const PAGE_LIMIT = 10;

const FinancialReports: FC = () => {
  const { modal } = App.useApp();
  const { toggleModal } = useUIDispatchContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialValues = useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    return {
      name: params.get('name') || undefined,
      patientPaid: params.get('patientPaid') || undefined
    };
  }, []);

  const [filters, setFilters] = useState<FormFilters>(initialValues);
  const [isIframeVisible, setIframeVisible] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  const page = Number(searchParams.get('page') || 0);
  const { data, loading } = useQuery<GetAppointmentReport>(
    GET_APPOINTMENT_REPORT,
    {
      variables: {
        query: {
          name: filters.name,
          paid: filters.patientPaid ? !!Number(filters.patientPaid) : undefined,
          pagination: {
            limit: PAGE_LIMIT,
            page
          }
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const results = data?.getAppointmentReport.data?.results || [];
  const total = data?.getAppointmentReport.data?.total || 0;

  const closeIframe = () => {
    setIframeVisible(false);
    setIframeUrl('');
  };

  const columns: ColumnProps<GetAppointmentReport_getAppointmentReport_data_results>[] =
    useMemo(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render(id) {
            return getIdStart(id);
          }
        },
        {
          title: 'Patient paid',
          dataIndex: 'paid',
          key: 'paid',
          width: '10%',
          render(paid) {
            return paid ? (
              <Tag style={{ width: 64, textAlign: 'center' }} color="green">
                Paid
              </Tag>
            ) : (
              <Tag style={{ width: 64, textAlign: 'center' }} color="red">
                Not Paid
              </Tag>
            );
          }
        },
        {
          title: 'Doctor Invoice Status',
          dataIndex: 'doctor_paid',
          key: 'doctor_paid',
          width: '20%',
          render(paid, record) {
            if (paid) {
              return (
                <Space size={8}>
                  <Tag style={{ width: 64, textAlign: 'center' }} color="green">
                    Paid
                  </Tag>
                  {record.doctor_invoice?.id ? (
                    <Tooltip title="Has been invoiced and the payment has been made">
                      <Space>
                        <InfoCircleOutlined /> (Invoiced)
                      </Space>
                    </Tooltip>
                  ) : (
                    <Tooltip title="There's been no invoice generated but the payment has been made">
                      <Space>
                        <InfoCircleOutlined /> (Not Invoiced)
                      </Space>
                    </Tooltip>
                  )}
                </Space>
              );
            }

            return (
              <Space size={8}>
                <Tag style={{ width: 64, textAlign: 'center' }} color="red">
                  Not Paid
                </Tag>
                {record.doctor_invoice?.id ? (
                  <Tooltip title="The appointment has been invoiced. The payment is still pending">
                    <Space>
                      <InfoCircleOutlined /> (Invoiced)
                    </Space>
                  </Tooltip>
                ) : (
                  <Tooltip title="An invoice has not been generated. And the payment is still pending">
                    <Space>
                      <InfoCircleOutlined /> (Not Invoiced)
                    </Space>
                  </Tooltip>
                )}
              </Space>
            );
          }
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          render(price) {
            return money(price);
          }
        },
        {
          title: 'Patient',
          dataIndex: 'patient',
          key: 'patient',
          render(patient) {
            return (
              <ul>
                {!!(patient.first_name || patient.last_name) && (
                  <li>
                    {patient.first_name} {patient.last_name}
                  </li>
                )}
                {!!patient.email && (
                  <li>
                    <b>Email: </b>
                    <a href={`mailto:${patient.email}`}>{patient.email}</a>
                  </li>
                )}
              </ul>
            );
          }
        },
        {
          title: 'Doctor',
          dataIndex: 'doctor',
          key: 'doctor',
          render(doctor) {
            return (
              <ul>
                {!!(doctor.first_name || doctor.last_name) && (
                  <li>
                    {doctor.first_name} {doctor.last_name}
                  </li>
                )}
                {!!doctor.email && (
                  <li>
                    <b>Email: </b>
                    <a href={`mailto:${doctor.email}`}>{doctor.email}</a>
                  </li>
                )}
              </ul>
            );
          }
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'id',
          width: '5%',
          align: 'right',
          render: (id, record) => {
            return (
              <ActionMenu
                data={[
                  {
                    title: 'See doctor invoice',
                    permission: {
                      route: PERMISSION_ROUTES.appointments,
                      action: PERMISSION_ACTIONS.appointmentViewPayment
                    },
                    async action() {
                      if (!record.doctor_invoice?.id) {
                        return modal.confirm({
                          title: 'Oops',
                          content:
                            "There's been no invoice generated for this appointment",
                          cancelButtonProps: {
                            style: { display: 'none' }
                          }
                        });
                      }

                      try {
                        setIframeVisible(true);
                        const url = record.doctor_invoice.file
                          ? getFileUrl(record.doctor_invoice.file, null)
                          : `${config.base_url}/appointments/invoice/${record.id}`;

                        const res = await instance.get(url, {
                          responseType: 'blob'
                        });

                        const dataUrl = URL.createObjectURL(res.data);

                        setIframeUrl(dataUrl);
                      } catch (err) {
                        closeIframe();
                        modal.error({
                          title: 'Oops',
                          content: <p></p>
                        });
                      }
                    }
                  },
                  {
                    title: 'Set doctor payment',
                    permission: {
                      route: PERMISSION_ROUTES.appointments,
                      action: PERMISSION_ACTIONS.updatePayment
                    },
                    action: () => toggleModal('paymentStatus', true, { id })
                  }
                ]}
                placement="bottomRight"
                icon={<EllipsisOutlined />}
              />
            );
          }
        }
      ],
      [modal, toggleModal]
    );

  const onFiltersSubmit = useCallback(
    (values: FormFilters) => {
      searchParams.set('page', '0');
      Object.entries(values).forEach(([key, value]) => {
        searchParams.set(key, value && value !== 'undefined' ? value : '');
      });
      setSearchParams(searchParams);
      setFilters({
        name: values.name && values.name !== 'undefined' ? values.name : '',
        patientPaid:
          values.patientPaid && values.patientPaid !== 'undefined'
            ? values.patientPaid
            : ''
      });
    },
    [searchParams, setSearchParams]
  );

  return (
    <PageWrapper title="Financial Reports" color="white">
      <Filters onSubmit={onFiltersSubmit} initialValues={initialValues} />
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total,
          current: page + 1,
          onChange: p => {
            searchParams.set('page', `${p - 1}`);
            setSearchParams(searchParams);
          },
          showSizeChanger: false
        }}
      />
      {isIframeVisible && (
        <Modal
          width={1000}
          onCancel={closeIframe}
          bodyStyle={{ paddingTop: 60, top: 20 }}
          footer={null}
          centered
          open
        >
          {iframeUrl ? (
            <iframe
              width={952}
              style={{ height: 'calc(100vh - 124px)', border: 'none' }}
              src={iframeUrl}
            />
          ) : (
            <StyledLoading>
              <LoadingOutlined style={{ fontSize: 50 }} />
              <Typography.Title level={2}>Loading...</Typography.Title>
            </StyledLoading>
          )}
        </Modal>
      )}
    </PageWrapper>
  );
};

export default FinancialReports;
