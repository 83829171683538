import styled from 'styled-components';

export const StyledDoctor = styled.div`
  margin-bottom: 26px;
  .ant-avatar {
    border: 2px solid #eff3ff;
  }
  .avatar-container {
    position: relative;
    .avatar-edit-icon {
      cursor: pointer;
      position: absolute;
      width: 32px;
      height: 32px;
      color: #18212c;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -8px;
      right: -8px;
      border: 1px solid #ffffff;
      background: #eff3ff;
      border-radius: 50%;
    }
  }
  .doctor-email {
    font-size: 14px;
    color: #63687a;
  }
  h5 {
    margin-bottom: 4px;
  }
`;
