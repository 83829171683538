import { Image, Space, Typography } from 'antd';
import { FC, Fragment } from 'react';

import { StyledTitle } from './styled';
import { IUserItemProps } from './types';

const { Title, Paragraph } = Typography;

const UserItem: FC<IUserItemProps> = ({ circle, name, url, info, onClick }) => {
  return (
    <Space size={16} align="start">
      <Image
        src={url}
        width={circle ? 148 : 240}
        height={circle ? 148 : 138}
        preview={false}
        style={{ objectFit: 'cover', borderRadius: circle ? '50%' : 10 }}
      />
      <div>
        {!onClick ? (
          <Title level={4}>{name}</Title>
        ) : (
          <StyledTitle type="link" onClick={onClick}>
            {name}
          </StyledTitle>
        )}

        {info.map((item, index) =>
          typeof item === 'string' ? (
            <Paragraph key={`info-item-${index}`} style={{ marginBottom: 4 }}>
              {item}
            </Paragraph>
          ) : (
            <Fragment key={`info-item-${index}`}>{item}</Fragment>
          )
        )}
      </div>
    </Space>
  );
};

export default UserItem;
