import { gql } from '@apollo/client';

export const GET_CONFIGS = gql`
  query GetConfig($query: GetConfigInput) {
    getConfig(query: $query) {
      data {
        results {
          body
          id
          img
          type
        }
      }
    }
  }
`;
