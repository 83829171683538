import { FC, useEffect, useMemo, useState } from 'react';
import { App, Col, Form, Row, Select, Table, Tag } from 'antd';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { GET_FAQ } from 'gql/faq/queries';
import { Faq } from 'gql/faq/__generated__/Faq';
import { useSearchParams } from 'react-router-dom';
import { GetServicesForAdmin } from 'gql/services/__generated__/GetServicesForAdmin';
import { GET_SERVICES } from 'gql/services/queries';
import { EllipsisOutlined } from '@ant-design/icons';
import { DELETE_FAQ } from 'gql/faq/mutations';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';

import { LanguageSwitcher } from 'components/shared';
import { ActionMenu, PageWrapper } from 'components/ui';
import CreateFaq from './components/CreateFaq';
import { showErrorMessage } from 'utils/showErrorMessage';
import UpdateFaq from './components/UpdateFaq';

const PAGE_LIMIT = 10;

const FAQ: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const client = useApolloClient();
  const { modal, message } = App.useApp();
  const lang = useMemo(() => searchParams.get('lang'), [searchParams]);
  const page = Number(searchParams.get('page') || 0);
  const service = searchParams.get('service');
  const [updateVisibleId, setUpdateVisibleId] = useState('');

  const { data: servicesData } = useQuery<GetServicesForAdmin>(GET_SERVICES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        name: '',
        pagination: {
          page: 0,
          limit: 999
        }
      }
    }
  });

  const services = useMemo(() => {
    return servicesData?.getServicesForAdmin?.data.results || [];
  }, [servicesData]);

  const { data: faqData, loading: isFetching } = useQuery<Faq>(GET_FAQ, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !lang,
    variables: {
      query: {
        lang,
        service_id: service,
        pagination: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const [deleteFaq] = useMutation(DELETE_FAQ, {
    onCompleted() {
      message.success('Successfully deleted');
      client.refetchQueries({ include: [GET_FAQ] });
    },
    onError: err => showErrorMessage(err)
  });

  const faq = faqData?.faq?.data?.results || [];
  const total = useMemo(() => {
    return faqData?.faq?.data?.total || 0;
  }, [faqData]);

  useEffect(() => {
    if (!lang) {
      searchParams.set('lang', 'en');
      setSearchParams(searchParams);
    }
  }, [lang, searchParams, setSearchParams]);

  const onServiceChange = (value: string) => {
    if (value) {
      searchParams.set('service', value);
    } else {
      searchParams.delete('service');
    }

    searchParams.set('page', '0');
    setSearchParams(searchParams);
  };

  const onClickDelete = (id: string) => {
    modal.confirm({
      title: 'Warning',
      content: <p>Are you sure?</p>,
      onOk: () => {
        deleteFaq({
          variables: {
            id
          }
        });
      }
    });
  };

  const onCloseUpdateModal = () => {
    setUpdateVisibleId('');
  };

  return (
    <PageWrapper
      title="FAQ"
      extra={[
        <LanguageSwitcher lang={`${lang}`} key="LanguageSwitcher" />,
        <CreateFaq maxSortIndex={total + 1} key="CreateFaq" />
      ]}
      color="white"
    >
      <Row>
        <Col sm={8}>
          <Form.Item>
            <Select
              allowClear
              style={{ width: 220 }}
              placeholder="Select service"
              size="large"
              onChange={onServiceChange}
              value={service}
              options={services.map(item => ({
                label: item.title,
                value: item.id
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Table
        dataSource={faq}
        columns={[
          {
            title: 'Display Order',
            key: 'sort_index',
            dataIndex: 'sort_index',
            width: '15%',
            align: 'center',
            render: value => (
              <Tag style={{ paddingBlock: 6, paddingInline: 12 }} color="blue">
                {value ? value : 'Not set'}
              </Tag>
            )
          },
          {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            width: '10%'
          },
          {
            title: 'Question',
            key: 'question',
            dataIndex: 'question',
            width: '45%'
          },
          {
            title: 'Answer',
            key: 'answer',
            dataIndex: 'answer',
            width: '45%'
          },
          {
            title: '',
            key: 'action',
            dataIndex: 'id',
            render: id => (
              <ActionMenu
                data={[
                  {
                    title: 'Edit',
                    permission: {
                      route: PERMISSION_ROUTES.faq,
                      action: PERMISSION_ACTIONS.update
                    },
                    action() {
                      setUpdateVisibleId(id);
                    },
                    menuProps: {
                      style: {
                        color: 'var(--primary)'
                      }
                    }
                  },
                  {
                    title: 'Delete',
                    permission: {
                      route: PERMISSION_ROUTES.faq,
                      action: PERMISSION_ACTIONS.delete
                    },
                    action() {
                      onClickDelete(id);
                    },
                    menuProps: {
                      style: {
                        color: 'var(--danger-color)'
                      }
                    }
                  }
                ]}
                placement="bottomRight"
                icon={<EllipsisOutlined />}
              />
            )
          }
        ]}
        loading={isFetching}
        rowKey="id"
        pagination={{
          total,
          current: page + 1,
          onChange: p => {
            searchParams.set('page', `${p - 1}`);
            setSearchParams(searchParams);
          },
          showSizeChanger: false
        }}
      />
      {!!updateVisibleId && (
        <UpdateFaq
          maxSortIndex={total + 1}
          id={updateVisibleId}
          onClose={onCloseUpdateModal}
        />
      )}
    </PageWrapper>
  );
};

export default FAQ;
