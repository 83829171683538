import { useMutation } from '@apollo/client';
import { Modal, Form, message, Input } from 'antd';
import client from 'apolloClient';
import { APPOINTMENT_STATUSES } from 'constants/appointments';
import { REJECT_APPOINTMENT } from 'gql/appointments/mutations';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorMessage } from 'utils/showErrorMessage';

const { TextArea } = Input;

const RejectModal: FC = () => {
  // form
  const [form] = Form.useForm();
  // translations
  const { t } = useTranslation();
  // context
  const { rejectAppointment: rejectAppointmentModal } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // graphql

  const closeModal = () => {
    toggleModal('rejectAppointment', false);
    form.resetFields();
  };

  const [rejectAppointment, { loading }] = useMutation(REJECT_APPOINTMENT, {
    onCompleted() {
      const isCanceled =
        rejectAppointmentModal.params?.status === APPOINTMENT_STATUSES.canceled;

      message.success(
        t(`messages.successfully_${isCanceled ? 'canceled' : 'rejected'}`)
      );
      closeModal();
      client.refetchQueries({ include: [GET_APPOINTMENT_ITEM] });
    },
    onError: err => showErrorMessage(err)
  });

  const onFinish = (values: { reason: string }) => {
    rejectAppointment({
      variables: {
        id: rejectAppointmentModal.params?.id,
        input: {
          status: rejectAppointmentModal.params?.status,
          reason: values.reason
        }
      }
    });
  };

  return (
    <Modal
      title={t(
        `appointments_details.${
          rejectAppointmentModal.params?.status ===
          APPOINTMENT_STATUSES.canceled
            ? 'cancel'
            : 'reject'
        }`
      )}
      open={rejectAppointmentModal.visible}
      onOk={form.submit}
      onCancel={closeModal}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label={t('appointments_details.enter_reason')}
          name="reason"
          rules={[{ required: true }]}
        >
          <TextArea style={{ height: 130 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(RejectModal);
