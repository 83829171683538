import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Table } from 'antd';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ColumnProps } from 'antd/lib/table';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SERVICES } from 'gql/services/queries';
import { DELETE_SERVICE } from 'gql/services/mutations';
import { useUIDispatchContext } from 'providers/UI';
import { useCheckOrganization } from 'hooks';
import {
  GetServicesForAdmin,
  GetServicesForAdmin_getServicesForAdmin_data_results as GetServiceItemType
} from 'gql/services/__generated__/GetServicesForAdmin';

import { OrganizationAlert, Private } from 'components/shared';
import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';

const { Search } = Input;

const PAGE_LIMIT = 10;

const Services: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const { toggleModal } = useUIDispatchContext();
  const { checkOrganizationWrapper } = useCheckOrganization();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const [getServices, { data, loading, refetch }] =
    useLazyQuery<GetServicesForAdmin>(GET_SERVICES, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });

  const [deleteService] = useMutation(DELETE_SERVICE, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  useEffect(() => {
    getServices({
      variables: {
        query: {
          name: search,
          pagination: {
            page,
            limit: PAGE_LIMIT
          }
        }
      }
    });
  }, [getServices, page, search]);

  const onDeleteService = useCallback(
    (id: number) => {
      toggleModal('confirm', true, {
        onConfirm: () =>
          deleteService({
            variables: {
              deleteServiceId: id
            }
          })
      });
    },
    [deleteService, toggleModal]
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    },
    []
  );

  const results = data?.getServicesForAdmin?.data.results || [];
  const total = data?.getServicesForAdmin?.data.total;

  const columns: ColumnProps<GetServiceItemType>[] = useMemo(
    () => [
      {
        title: t('common.id'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('service.title'),
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: t('service.short_desc'),
        dataIndex: 'short_desc',
        key: 'short_desc'
      },
      {
        title: t('service.organization'),
        dataIndex: 'organization',
        key: 'organization',
        render: (_, item) => item.organization?.name
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        align: 'right',
        render: (id: string) => (
          <ActionMenu
            data={[
              {
                title: t('table.edit'),
                permission: {
                  route: PERMISSION_ROUTES.blog,
                  action: PERMISSION_ACTIONS.update
                },
                action() {
                  checkOrganizationWrapper(() =>
                    navigate(`/services/details/${id}`)
                  );
                }
              },
              {
                title: t('table.delete'),
                permission: {
                  route: PERMISSION_ROUTES.services,
                  action: PERMISSION_ACTIONS.update
                },
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                },
                action: () => onDeleteService(+id)
              }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [checkOrganizationWrapper, navigate, onDeleteService, t]
  );

  return (
    <PageWrapper
      title={t('service.page_title')}
      color="white"
      extra={[
        <Private
          key="add-service-button"
          route={PERMISSION_ROUTES.services}
          action={PERMISSION_ACTIONS.create}
        >
          <OrganizationAlert>
            <Button
              type="primary"
              onClick={() => navigate('/services/details')}
            >
              <PlusOutlined />
              {t('service.add_new')}
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              <Search
                placeholder={t('common.search')}
                onChange={onSearchChange}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: total,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default Services;
