export const PLAN_DURATIONS = [
  {
    label: '15m',
    value: 0.25
  },
  {
    label: '30m',
    value: 0.5
  },
  {
    label: '1h',
    value: 1
  },
  {
    label: '2h',
    value: 2
  },
  {
    label: '3h',
    value: 3
  }
];
