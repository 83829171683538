import { Modal, Form, Input, Select } from 'antd';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC, memo, useEffect } from 'react';

const { TextArea } = Input;

const UpdateStatusModal: FC = () => {
  const [form] = Form.useForm();
  const { updateStatus } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();

  const closeModal = () => {
    toggleModal('updateStatus', false);
    form.resetFields();
  };

  useEffect(() => {
    if (updateStatus.visible) {
      const status = updateStatus?.params?.currentStatus;

      form.setFieldsValue({
        status,
        note: ''
      });
    }
  }, [form, updateStatus?.params?.currentStatus, updateStatus.visible]);

  const onFinish = (values: { note: string; status: number }) => {
    if (updateStatus.params?.onSubmit) {
      updateStatus.params.onSubmit(values);
    }
  };

  return (
    <Modal
      title="Request status"
      open={updateStatus.visible}
      onOk={form.submit}
      onCancel={closeModal}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Select
            options={
              updateStatus.params?.statusList
                ? Object.entries(updateStatus.params?.statusList).map(
                    ([value, label]) => ({
                      label,
                      value
                    })
                  )
                : []
            }
          />
        </Form.Item>
        <Form.Item label="Notes" name="note" rules={[{ required: true }]}>
          <TextArea style={{ height: 130 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(UpdateStatusModal);
