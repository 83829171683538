import { useQuery } from '@apollo/client';
import { Col, Dropdown, Flex, Row, Typography } from 'antd';
import { GetAppointmentCharts } from 'gql/analytics/__generated__/GetAppointmentCharts';
import { GetChartsData } from 'gql/analytics/__generated__/GetChartsData';
import {
  GET_APPOINTMENTS_CARTS_DATA,
  GET_CARTS_DATA
} from 'gql/analytics/queries';
import { EllipsisOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  APPOINTMENT_STATUSES,
  APPOINTMENT_STATUS_COMPlETED,
  APPOINTMENT_STATUS_REJECTED,
  APPOINTMENT_STATUS_VALUES
} from 'constants/appointments';
import { Link } from 'react-router-dom';

import { ButtonStyled, CardBottom, CardItem, Status } from './styled';
import CountCard from '../CountCard';
import { getStatusColor } from 'utils/appointments';
import { Loading } from 'components/ui';

const APPOINTMENT_STATUSES_TO_SHOW = [
  `${APPOINTMENT_STATUS_VALUES[APPOINTMENT_STATUS_COMPlETED]}`,
  `${APPOINTMENT_STATUS_VALUES[APPOINTMENT_STATUS_REJECTED]}`
];

const PATIENTS_STATUSES_TO_SHOW = ['confirm', 'email_confirm'];

const TotalCards = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<GetChartsData>(GET_CARTS_DATA, {
    fetchPolicy: 'cache-first'
  });

  const { data: appointmentsData, loading: appointmentsLoading } =
    useQuery<GetAppointmentCharts>(GET_APPOINTMENTS_CARTS_DATA, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });

  const resultAppointments = useMemo(
    () => appointmentsData?.getAppointmentCharts?.data,
    [appointmentsData?.getAppointmentCharts?.data]
  );

  const resultPatients = useMemo(
    () => data?.getChartsData?.data?.patients,
    [data?.getChartsData?.data?.patients]
  );

  const resultDoctors = useMemo(
    () => data?.getChartsData?.data?.doctors,
    [data?.getChartsData?.data?.doctors]
  );

  const appointmentStatuses = Object.entries(
    resultAppointments?.appointments_status || {}
  )?.map(([key, value]) => {
    const status = APPOINTMENT_STATUSES[
      key as keyof typeof APPOINTMENT_STATUSES
    ] as number;

    return {
      label: (
        <Link to={`/appointments?status=${status}`}>
          <Flex align="center" gap={4}>
            <Status $color={getStatusColor(status) || 'white'} />
            <Typography.Text style={{ lineHeight: 1 }} color="#18212C">{`${
              value || 0
            } (${t(`dashboard.${key}`)})`}</Typography.Text>
          </Flex>
        </Link>
      ),
      key: key
    };
  });

  const patientsStatuses = [
    {
      label: (
        <Link to="/access/patients?confirm=6">
          <Flex align="center" gap={4}>
            <Status $color="#35B678" />
            <Typography.Text style={{ lineHeight: 1 }} color="#18212C">{`${
              resultPatients?.confirm || 0
            } (Verified)`}</Typography.Text>
          </Flex>
        </Link>
      ),
      key: `confirm`
    },
    {
      label: (
        <Link to="/access/patients?confirm=4">
          <Flex align="center" gap={4}>
            <Status $color="#2552F3" />
            <Typography.Text style={{ lineHeight: 1 }} color="#18212C">{`${
              resultPatients?.email_confirm || 0
            } (Email Verified)`}</Typography.Text>
          </Flex>
        </Link>
      ),
      key: `email_confirm`
    },
    {
      label: (
        <Link to="/access/patients?confirm=2">
          <Flex align="center" gap={4}>
            <Status $color="#63687A" />
            <Typography.Text style={{ lineHeight: 1 }} color="#18212C">{`${
              resultPatients?.not_confirm || 0
            } (Not Verified)`}</Typography.Text>
          </Flex>
        </Link>
      ),
      key: `not_confirm`
    }
  ];

  const doctorsStatuses = [
    {
      label: (
        <Flex align="center" gap={4}>
          <Status $color="#2552F3" />
          <Typography.Text style={{ lineHeight: 1 }} color="#18212C">{`${
            resultDoctors?.active || 0
          } (Active)`}</Typography.Text>
        </Flex>
      ),
      key: `active`
    },
    {
      label: (
        <Flex align="center" gap={4}>
          <Status $color="#A4A8B5" />
          <Typography.Text style={{ lineHeight: 1 }} color="#18212C">{`${
            resultDoctors?.disabled || 0
          } (Inactive)`}</Typography.Text>
        </Flex>
      ),
      key: `disabled`
    }
  ];

  if (loading || appointmentsLoading) {
    return <Loading />;
  }

  return (
    <Row gutter={[16, 24]}>
      <Col lg={{ span: 8 }}>
        <CardItem hoverable>
          <CountCard
            title="Total Appointments"
            count={+(resultAppointments?.appointment_count || 0)}
            to="/appointments"
          >
            <CardBottom justify="space-between">
              <Flex align="center" gap={8}>
                {appointmentStatuses
                  ?.filter(item =>
                    APPOINTMENT_STATUSES_TO_SHOW.includes(item.key)
                  )
                  .map(item => (
                    <Typography.Text key={item.key + item.label}>
                      {item.label}
                    </Typography.Text>
                  ))}
              </Flex>
              {resultAppointments && (
                <Dropdown
                  menu={{ items: appointmentStatuses }}
                  trigger={['click']}
                >
                  <ButtonStyled
                    icon={
                      <EllipsisOutlined rotate={90} style={{ fontSize: 19 }} />
                    }
                    type="text"
                  />
                </Dropdown>
              )}
            </CardBottom>
          </CountCard>
        </CardItem>
      </Col>
      <Col lg={{ span: 8 }}>
        <CardItem hoverable>
          <CountCard
            title="Total Patients"
            count={+(resultPatients?.total || 0)}
            to={'/access/patients'}
          >
            <CardBottom justify="space-between">
              <Flex align="center" gap={8}>
                {patientsStatuses
                  .filter(
                    item => PATIENTS_STATUSES_TO_SHOW.indexOf(item.key) !== -1
                  )
                  .map(item => (
                    <Typography.Text key={item.key + item.label}>
                      {item.label}
                    </Typography.Text>
                  ))}
              </Flex>
              {resultPatients && (
                <Dropdown
                  menu={{ items: patientsStatuses }}
                  trigger={['click']}
                >
                  <ButtonStyled
                    icon={
                      <EllipsisOutlined rotate={90} style={{ fontSize: 19 }} />
                    }
                    type="text"
                  />
                </Dropdown>
              )}
            </CardBottom>
          </CountCard>
        </CardItem>
      </Col>
      <Col lg={{ span: 8 }}>
        <CardItem hoverable>
          <CountCard
            title="Total Doctors"
            count={Number(resultDoctors?.total || 0)}
            to={'/access/doctors'}
          >
            <CardBottom justify="space-between">
              <Flex align="center" gap={8}>
                {doctorsStatuses.map(item => (
                  <Typography.Text key={item.key + item.label}>
                    {item.label}
                  </Typography.Text>
                ))}
              </Flex>
            </CardBottom>
          </CountCard>
        </CardItem>
      </Col>
    </Row>
  );
};

export default TotalCards;
