import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { useUIDispatchContext, useModalStateContext } from 'providers/UI';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import client from 'apolloClient';
import { Plan } from 'gql/plans/__generated__/Plan';
import { PLAN_FRAGMENT } from 'gql/plans/fragments';
import { CREATE_PLAN, UPDATE_PLAN } from 'gql/plans/mutations';
import { CreatePlan } from 'gql/plans/__generated__/CreatePlan';
import { PLAN_DURATIONS, PLAN_TYPES, PLAN_TYPE_VALUES } from 'constants/users';
import { ValueOf } from 'types/custom';
import { PlanInput } from '__generated__/globalTypes';
import { useParams, useSearchParams } from 'react-router-dom';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';

import { PATTERNS } from 'utils/validation';
import { parseJson } from 'utils/json';
import { showErrorMessage } from 'utils/showErrorMessage';

const { TextArea } = Input;

const PlansModal: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { plans } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // states
  const [type, setType] = useState<ValueOf<typeof PLAN_TYPE_VALUES>>();
  // form
  const [form] = Form.useForm();
  // navigation
  const { id: doctorId } = useParams();
  const [searchParams] = useSearchParams();
  // graphql
  const lang = useMemo(() => searchParams.get('lang'), [searchParams]);

  const planId = plans.params?.id;

  const closeModal = useCallback(() => {
    toggleModal('plans', false);
  }, [toggleModal]);

  const planData: Plan | null = client.readFragment({
    id: `Plan:${planId}`,
    fragment: PLAN_FRAGMENT
  });

  const [createPlan, { loading: isCreating }] = useMutation<CreatePlan>(
    CREATE_PLAN,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
        closeModal();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [updatePlan, { loading: isUpdating }] = useMutation(UPDATE_PLAN, {
    onCompleted() {
      client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
      closeModal();
    },
    onError: err => showErrorMessage(err)
  });

  const handleSubmit = useCallback(
    (values: PlanInput) => {
      const features = values.features?.trim();

      const featuresArr = features?.length
        ? features?.split(',').map((item: string) => item.trim())
        : [];

      const input = {
        ...values,
        lang,
        price: Number(values.price),
        features: featuresArr
      };

      if (planId) {
        updatePlan({
          variables: {
            id: planId,
            input: {
              ...input,
              // esi profile id poxoxy mtqins a
              user_id: doctorId
            }
          }
        });
      } else {
        createPlan({
          variables: {
            input: {
              ...input,
              // esi profile id poxoxy mtqins a
              user_id: doctorId
            }
          }
        });
      }
    },
    [createPlan, doctorId, lang, planId, updatePlan]
  );

  useEffect(() => {
    if (plans.visible) {
      if (planData) {
        form.setFieldsValue({
          name: planData.name,
          price: planData.price,
          type: planData.type,
          duration: planData.duration,
          description: planData.description,
          features: planData.features
            ? parseJson(planData.features)?.join(', ') || ''
            : []
        });
        setType(planData.type);
      } else {
        form.resetFields();
        setType(undefined);
      }
    }
  }, [form, plans.visible, planData]);

  return (
    <Modal
      open={plans.visible}
      onCancel={closeModal}
      onOk={form.submit}
      confirmLoading={isUpdating || isCreating}
      title={t(planId ? 'doctor_profile.edit_plan' : 'doctor_profile.add_plan')}
    >
      <Form form={form} layout="vertical" size="large" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label={t('doctor_profile.name')}
          rules={[{ required: true }]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <Form.Item
          name="price"
          label={t('doctor_profile.price')}
          rules={[{ required: true }, { pattern: PATTERNS.price }]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <Row gutter={[16, 0]}>
          <Col span={type === PLAN_TYPE_VALUES.video_call ? 12 : 24}>
            <Form.Item
              name="type"
              label={t('doctor_profile.type')}
              rules={[{ required: true }]}
            >
              <Select
                options={PLAN_TYPES.map(item => ({
                  ...item,
                  label: t(`doctor_profile.${item.label}`)
                }))}
                onSelect={(value: ValueOf<typeof PLAN_TYPE_VALUES>) =>
                  setType(value)
                }
              />
            </Form.Item>
          </Col>
          {type === PLAN_TYPE_VALUES.video_call && (
            <Col span={12}>
              <Form.Item
                name="duration"
                label={t('doctor_profile.duration')}
                rules={[{ required: true }]}
              >
                <Select options={PLAN_DURATIONS} />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Form.Item
          rules={[{ required: true }]}
          name="description"
          label={t('doctor_profile.description')}
        >
          <TextArea />
        </Form.Item>
        <Form.Item name="features" label={t('doctor_profile.features')}>
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(PlansModal);
