import { gql } from '@apollo/client';

import { TRANSLATION_PLAN_FRAGMENT } from './fragments';

export const CREATE_TRANSLATION_PLAN = gql`
  mutation CreateTranslatorPlan($input: TranslatorPlanInput!) {
    createTranslatorPlan(input: $input) {
      data {
        ...TranslatorPlanFragment
      }
    }
  }
  ${TRANSLATION_PLAN_FRAGMENT}
`;

export const UPDATE_TRANSLATION_PLAN = gql`
  mutation UpdateTranslatorPlan($id: ID!, $input: TranslatorPlanInput!) {
    updateTranslatorPlan(id: $id, input: $input) {
      data {
        ...TranslatorPlanFragment
      }
    }
  }
  ${TRANSLATION_PLAN_FRAGMENT}
`;

export const DELETE_TRANSLATION_PLAN = gql`
  mutation DeleteTranslatorPlan($id: ID!) {
    deleteTranslatorPlan(id: $id) {
      message
    }
  }
`;

export const DELETE_TRANSLATION_REQUEST = gql`
  mutation DeleteTranslator($id: ID!) {
    deleteTranslator(id: $id) {
      message
    }
  }
`;
