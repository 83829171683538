import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification($input: NotificationInput) {
    createNotification(input: $input) {
      message
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: ID!, $input: NotificationInput) {
    updateNotification(id: $id, input: $input) {
      message
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      message
    }
  }
`;
