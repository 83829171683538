import {
  CalendarOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import { FC } from 'react';

import AnalyticsAppointments from '../AnalyticsAppointments';
import { CustomTabs } from './styled';
import DoctorsChart from '../DoctorsChart';
import PatientsCharts from '../PatientsCharts';

const TabsData = [
  {
    key: 'doctors_chart',
    label: `Doctors Chart`,
    children: <DoctorsChart />,
    icon: <TeamOutlined />
  },
  {
    key: 'patient_chart ',
    label: `Patient Chart `,
    children: <PatientsCharts />,
    icon: <UserOutlined />
  },
  {
    key: 'appointment_chart',
    label: `Appointment Chart`,
    children: <AnalyticsAppointments />,
    icon: <CalendarOutlined />
  }
];

const Charts: FC = () => {
  // const { data, loading } = useQuery<GetChartsData>(GET_CARTS_DATA, {
  //   fetchPolicy: 'cache-and-network',
  //   nextFetchPolicy: 'cache-first'
  // });

  // const results = useMemo(
  //   () => data?.getChartsData?.data,
  //   [data?.getChartsData?.data]
  // );
  return (
    <CustomTabs
      style={{ width: '100%' }}
      items={TabsData}
      size="large"
      destroyInactiveTabPane
    />
  );
};

export default Charts;
