import { Pagination } from 'antd';
import styled from 'styled-components';

export const StyledPagination = styled(Pagination)`
  &&& {
    margin: auto;
    li {
      background-color: var(--white);
      border-radius: 2px;
      button {
        border-radius: 2px;
      }
    }
  }
`;
