import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { GET_STORIES } from 'gql/stories/queries';
import { DELETE_STORY } from 'gql/stories/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useUIDispatchContext } from 'providers/UI';
import { ColumnProps } from 'antd/es/table';
import { STORIES_STATUSES, STORIES_TYPES } from 'constants/stories';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/dates';

import { OrganizationAlert, Private } from 'components/shared';
import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';

import { GetStoriesForAdminQuery } from '@/generated/graphql';

const { Search } = Input;
const { Paragraph } = Typography;

const PAGE_LIMIT = 10;

type StoryItemType = NonNullable<
  GetStoriesForAdminQuery['getStoriesForAdmin']
>['data']['results'][number];

const Stories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const { toggleModal } = useUIDispatchContext();

  const [getServices, { data, loading, refetch }] =
    useLazyQuery<GetStoriesForAdminQuery>(GET_STORIES, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });

  const [deleteStory] = useMutation(DELETE_STORY, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  useEffect(() => {
    getServices({
      variables: {
        query: {
          name: search,
          pagination: {
            page,
            limit: PAGE_LIMIT
          }
        }
      }
    });
  }, [getServices, page, search]);

  const onDeleteService = useCallback(
    (id: number) => {
      toggleModal('confirm', true, {
        onConfirm: () =>
          deleteStory({
            variables: {
              deleteStoryId: id
            }
          })
      });
    },
    [deleteStory, toggleModal]
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    },
    []
  );

  const columns: ColumnProps<StoryItemType>[] = useMemo(
    () => [
      {
        title: t('common.id'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('stories.thumbnail'),
        dataIndex: 'thumbnail',
        key: 'thumbnail',
        render: (_, item) => (
          <Avatar size={50} alt="thumbnail" src={getFileUrl(item.thumbnail)} />
        )
      },
      {
        title: t('stories.title'),
        dataIndex: 'title',
        key: 'title',
        render: (_, { title }) => (
          <div dangerouslySetInnerHTML={{ __html: title }} />
        )
      },
      {
        title: t('stories.type'),
        dataIndex: 'type',
        key: 'type',
        render: (_, item) => (
          <Paragraph style={{ margin: 0 }}>
            {t(`stories.${STORIES_TYPES[item.type]}`)}
          </Paragraph>
        )
      },
      {
        title: t('stories.status'),
        dataIndex: 'status',
        key: 'status',
        render: (_, item) => (
          <Paragraph style={{ margin: 0 }}>
            {t(`stories.${STORIES_STATUSES[item.status]}`)}
          </Paragraph>
        )
      },
      {
        title: t('stories.views'),
        dataIndex: 'views',
        key: 'views'
      },
      {
        title: t('stories.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => dayjs(value).format(DATE_FORMAT)
      },
      {
        title: t('stories.organization'),
        dataIndex: 'organization',
        key: 'organization',
        render: value => value.name
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        align: 'right',
        render: (id: string) => (
          <ActionMenu
            data={[
              {
                title: t('table.edit'),
                permission: {
                  route: PERMISSION_ROUTES.stories,
                  action: PERMISSION_ACTIONS.update
                },
                action() {
                  navigate(`/stories/details/${id}`);
                }
              },
              {
                title: t('table.delete'),
                permission: {
                  route: PERMISSION_ROUTES.stories,
                  action: PERMISSION_ACTIONS.delete
                },
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                },
                action: () => onDeleteService(+id)
              }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [navigate, onDeleteService, t]
  );

  const results = data?.getStoriesForAdmin?.data.results ?? [];
  const total = data?.getStoriesForAdmin?.data.total;

  return (
    <PageWrapper
      title={t('service.page_title')}
      color="white"
      extra={[
        <Private
          key="add-service-button"
          route={PERMISSION_ROUTES.stories}
          action={PERMISSION_ACTIONS.create}
        >
          <OrganizationAlert>
            <Button type="primary" onClick={() => navigate('/stories/details')}>
              <PlusOutlined />
              {t('stories.add_new')}
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Row>
        <Col sm={8}>
          <Space>
            <Form.Item>
              <Search
                placeholder={t('common.search')}
                onChange={onSearchChange}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: total,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default Stories;
