import { gql } from '@apollo/client';

export const SERVICE_LIST_FRAGMENT = gql`
  fragment ServiceList on Service {
    type
    title
    status
    slug
    short_desc
    link
    img
    id
    description
    keywords
    organization {
      id
      name
    }
  }
`;

export const SERVICE_LIST_FRAGMENT_BY_ID = gql`
  fragment ServiceTranslation on ServiceTranslation {
    type
    title
    status
    slug
    short_desc
    link
    img
    id
    description
    keywords
    icon
    subtitle
    main_image
    text
  }
`;
