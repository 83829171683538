import styled from 'styled-components';

export const StyledLink = styled.p`
  color: var(--primary);
  cursor: pointer;
  user-select: none;
  max-width: 500px;
  overflow: hidden;
  display: inline-block;
  margin: 0;
  word-break: break-all;
`;
