export const swapObject = (obj: Record<string, number | string>) => {
  const swapped: Record<string, number | string> = {};

  for (const key in obj) {
    const value = `${obj[key]}`;
    swapped[value] = key;
  }

  return swapped;
};
