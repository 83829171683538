import { useMutation, useQuery } from '@apollo/client';
import { Modal, Form, DatePicker, Select, Alert, Divider } from 'antd';
import client from 'apolloClient';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/dates';
import { AppointmentById } from 'gql/appointments/__generated__/AppointmentById';
import { UPDATE_APPROVED_DATE } from 'gql/appointments/mutations';
import { GET_APPOINTMENT_ITEM } from 'gql/appointments/queries';
import dayjs from 'dayjs';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC, memo, useEffect, useMemo } from 'react';

import { showErrorMessage } from 'utils/showErrorMessage';

const DateModal: FC = () => {
  // form
  const [form] = Form.useForm();
  // context
  const { dateModal } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // graphql
  const { data } = useQuery<AppointmentById>(GET_APPOINTMENT_ITEM, {
    skip: !dateModal.params?.id,
    variables: {
      id: dateModal.params?.id
    },
    fetchPolicy: 'cache-only'
  });

  const closeModal = () => {
    toggleModal('dateModal', false);
    form.resetFields();
  };

  const [updateApprovedDate, { loading }] = useMutation(UPDATE_APPROVED_DATE, {
    onCompleted() {
      closeModal();
      client.refetchQueries({ include: [GET_APPOINTMENT_ITEM] });
    },
    onError: err => showErrorMessage(err)
  });

  const date = useMemo(
    () =>
      data?.appointmentForAdmin?.data.dates.find(
        item => item.id === dateModal.params?.dateId
      ),
    [data?.appointmentForAdmin?.data.dates, dateModal.params?.dateId]
  );

  useEffect(() => {
    if (dateModal.visible) {
      form.setFieldsValue({
        accepted: date?.accepted ? 1 : 0,
        date: date?.date ? dayjs(date.date) : dayjs()
      });
    }
  }, [date, dateModal.visible, form]);

  const onFinish = (values: { date: string; accepted: number }) => {
    updateApprovedDate({
      variables: {
        id: date?.id,
        input: {
          confirm: values.accepted === 1,
          date: dayjs(values.date).format()
        }
      }
    });
  };

  return (
    <Modal
      title="Select date"
      open={dateModal.visible}
      onOk={form.submit}
      onCancel={closeModal}
      confirmLoading={loading}
      destroyOnClose
    >
      <Alert
        message="Warning"
        description="Please note that only one date can be accepted. If you wish to select a specific date, please be aware that it will overwrite any previously chosen dates."
        type="info"
        showIcon
      />
      <Divider />
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Accepted"
          name="accepted"
          rules={[{ required: true }]}
        >
          <Select
            options={[
              {
                label: 'Yes',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]}
          />
        </Form.Item>
        <Form.Item label="Date" name="date" rules={[{ required: true }]}>
          <DatePicker
            style={{ width: '100%' }}
            showTime={{ format: 'HH:mm', minuteStep: 30 }}
            format={`${DATE_FORMAT} ${TIME_FORMAT}`}
            disabledDate={date => {
              return date.diff(dayjs(), 'days') < 0;
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(DateModal);
